import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-product-view-only',
  templateUrl: './product-view-only.component.html',
  styleUrls: ['./product-view-only.component.scss']
})
export class ProductViewOnlyComponent implements OnInit {
  activeItem: any = null
  locationViseStock: any = []
  foundMessage = 'Searching ...'

  items: MenuItem[] = [
    { label: 'General', icon: 'pi pi-fw pi-home' },
    { label: 'Product Images', icon: 'pi pi-fw pi-file' },
    { label: 'Stock Management', icon: 'pi pi-fw pi-calendar' },
    { label: 'Shipping', icon: 'pi pi-fw pi-plus-circle' },
    { label: 'Additional', icon: 'pi pi-fw pi-plus-circle' },
    // { label: 'Label Printing', icon: 'pi pi-fw pi-plus-circle' },
  ]

  activeTab: any = { label: 'General', icon: 'pi pi-fw pi-home' }

  sellAs: any = null
  selectedDiscount: any = null
  selectedAgeRestriction: any = null
  selectedGender: any = null
  selectedDefaultPrice: any = null
  selectedTaxRate: any = null
  selectedSellQuantity: any = null
  selectedUom: any = null
  selectedProductSellType: any = null
  selectedDepartment: any = null
  selectedCategory: any = null
  selectedManufacturer: any = null
  selectedVendor: any = null
  selectedAltBarcodes: any = null
  selectedAltSKUs: any = null
  selectedAltTags: any = null

  taxRate = '(8.25%)'

  @Input() GID: any = ''

  constructor(
    private _productService: ProductsService,
    private _spinner: NgxSpinnerService,
    private _abd: AppBaseDataService,
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this._abd.showSpinner()
    try {
      let locations = ''
      const locs = JSON.parse(JSON.stringify(this._abd.locations.filter(f=>!f.isDeleted)))
      for (let i = 0; i < locs.length; i++)
        locations += locs[i].locationId + ','

      locations = locations.substring(0, locations.length - 1)

      this._productService.getItemStockStatus(this.GID, locations).subscribe(res => {
        if (res.success) {
          this.locationViseStock = res.data.stockDetails
        }
      })

      this._productService.getAllProductList4Inventory(this.makeParams()).subscribe(res => {
        if (res.success) {
          this._abd.hideSpinner()
          if (res.data.length > 0) {
            this.activeItem = res.data[0]
            let ai = this.activeItem
            let crs = this.activeItem.crsItemSKUs[0]
            let sd = this.activeItem.crsItemSKUs[0].shippingDimention
            let pi = this._productService.productInfo

            // console.log('--**--ITEM--**--', this.activeItem)
            // console.log('--**--ITEM-PRODUCT-INFO--**--', pi)
            this.selectedAltBarcodes = crs?.barcodes || []
            this.selectedAltSKUs = crs?.skus || []
            this.selectedAltTags = crs?.tags || []
            this.sellAs = pi.sellAs.find(f => f.value == this.activeItem.soldAs)
            if (this.sellAs) this.sellAs = this.sellAs.name
            else this.sellAs = 'undefined'

            if (crs.discountPrice > 0) this.selectedDiscount = pi.discount[1]
            else this.selectedDiscount = pi.discount[0]
            this.selectedAgeRestriction = pi.ageRestriction.find(f => f.value == ai.ageRestriction)
            this.selectedGender = pi.gender.find(f => f.value == ai.gender)
            this.selectedDefaultPrice = pi.defaultPrice.find(f => f.value == crs.defaultPrice)
            this.selectedSellQuantity = pi.sellQuantity.find(f => f.value == crs.sellQuantity)
            this.selectedUom = this._abd.uomCalc.find(f => f.id == crs.uomId)
            this.selectedProductSellType = ai.sellOnline ? this._abd.productSellType[0] : this._abd.productSellType[1]

            this.selectedDepartment = this._abd.departments.filter(f => f.id == ai.departmentId)[0]
            this.selectedCategory = this._abd.categories.filter(f => f.id == ai.categoryId)[0]
            this.selectedManufacturer = this._abd.manufacturers.find(f => f.id == ai.manufacturerId)
            this.selectedVendor = this._abd.vendors.find(f => f.id == ai.vendorId)

            if (!this.selectedDepartment) this.selectedDepartment = { departmentName: 'no department selected' }
            if (!this.selectedCategory) this.selectedCategory = { categoryName: 'no category selected' }
            if (!this.selectedManufacturer) this.selectedManufacturer = { manufacturerName: 'no manufacturer selected' }
            if (!this.selectedVendor) this.selectedVendor = { vendorName: 'no vendor selected' }
          }
          else {
            this.foundMessage = 'No product found!'
            this._abd.hideSpinner()
          }
        } else
          this._abd.hideSpinner()
      })
    } catch { this._abd.hideSpinner() }
  }

  makeParams() {
    let params = new HttpParams();

    params = params.append('locationId', '');
    params = params.append('departId', '');
    params = params.append('categoryId', '');
    params = params.append('vendorId', '');
    params = params.append('manufactureId', '');
    // params = params.append('sellOnline', 'true')

    params = params.append('pageNo', '0');
    params = params.append('pageSize', '1');

    params = params.append('filterValue', this.GID);

    return params
  }

  onTabItemClick(tab: any) {
    this.activeTab = tab.activeItem
  }

}
