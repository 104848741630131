import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-customer-group-report-detail',
  templateUrl: './customer-group-report-detail.component.html',
  styleUrls: ['./customer-group-report-detail.component.scss']
})
export class CustomerGroupReportDetailComponent implements OnInit {
  
  _globals = GlobalService
  page = 0
  rows = 10
  totalRecords = 0
  selectedRow:any;
  dataList:any[] = []
  @Input() List: any;
  //order
  orderParam = ''
  orderBy = 'asc'
  @ViewChild('p', { static: false }) paginator: Paginator;
 

  ngOnInit(): void {
    this.dataList = this.List;
  }


//   getDataList() {
//     this._spinner.show()
//     try {
//       const id = this._customerService.activeItem.custID
//       this._customerService.searchCustomerHistoryByCustomerId(id, this.page, this.rows).subscribe(res => {
//         if (res.success) {
//           this.dataList = res.data
//           this.totalRecords = res.totalRecords
//           this._spinner.hide()
//           //console.log('CustomerSalesHistoryComponent', this.dataList)
//         }
//       })
//     } catch { this._spinner.hide() }
//   }
//   onPageChange(e) {
//     e.totalRecords = this.totalRecords
//     this.rows = e.rows
//     this.page = e.page
//     this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)
//     // this.getDataList()
//   }
//   filterButtonPressed() {
//     this.page = 0
//     this.paginator?.changePage(0)
//     this.getDataList()
//   }
//   customSort(e) {
//     // const last = this.orderParam

//     // switch (e.sortField) {
//     //   case 'itemName':
//     //     this.orderParam = 'title'
//     //     break;
//     //   case 'barCode':
//     //     this.orderParam = 'Barcode'
//     //     break;
//     //   case 'skuCode':
//     //     this.orderParam = 'SkuCode'
//     //     break;
//     //   case 'stockInhand':
//     //     this.orderParam = 'StockInhand'
//     //     break;
//     //   case 'minOrderQty':
//     //     this.orderParam = 'MinOrderQty'
//     //     break;
//     //   case 'maxOrderQty':
//     //     this.orderParam = 'MaxOrderQty'
//     //     break;
//     //   case 'orderQuantity':
//     //     this.orderParam = 'OrderQuantity'
//     //     break;
//     //   case 'poStatus':
//     //     this.orderParam = 'PoStatus'
//     //     break;
//     //   default:
//     //     this.orderParam = ''
//     // }

//     // if (this.orderParam !== '') {
//     //   this.orderBy = this.orderBy == 'asc' ? 'desc' : 'asc'
//     //   if (last !== this.orderParam) this.orderBy = 'asc'
//     //   this.getDataList()
//     // }
//   }
//   onRowSelect(e) {
//     console.log(e.data)
//     // this._purchaseService.mapItemTonewEditItem(e.data)
   
//   }
//   callbackFuncFromFilter(event) {
//     this.getDataList()
//   }

// //   exportExcel() {
// //     let data =this.dataList.map((v)=>{
// //       return{
// //         ...v,product:v.productName+"\n"+v.barcode+"\n"+v.sku
// //       }
// //      })
// //     this._abd.exportExcel(data, this.colheader[0], this.colflds, 'Customer Purchase History Report')
// //   }

// //   exportPdf() {
// //  let data =this.dataList.map((v)=>{
// //   return{
// //     ...v,product:v.productName+"\n"+v.barcode+"\n"+v.sku
// //   }
// //  })
// //     this._abd.exportPdf(data, this.colheader, this.colflds, 'Customer Purchase History Report',false,false,false)
   
// //   }
}
