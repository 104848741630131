export let usTimeZoneList : any[] = [
    {"value":"America/Puerto_Rico","name":"Puerto Rico (Atlantic)"},
    {"value":"America/Phoenix","name":"Phoenix (MST)"},
    {"value":"America/Anchorage","name":"Anchorage (Alaska)"},
    {"value":"Pacific/Honolulu","name":"Honolulu (Hawaii)"},
    {"value":"America/New_York","name":"New York (Eastern)"},
    {"value":"America/Chicago","name":"Chicago (Central)"},
    {"value":"America/Denver","name":"Denver (Mountain)"},
    {"value":"America/Los_Angeles","name":"Los Angeles (Pacific)"},
]
export let WorldTimeZone:any[] = [
        { label:"United States (Puerto Rico)",value:"America/Puerto_Rico"},
        { label:"United States (Phoenix)",value:"America/Phoenix"},
        { label:"United States (Anchorage)",value:"America/Anchorage"},
        { label: 'United States (New York)', value: 'America/New_York' },
        { label: 'United States (Chicago)', value: 'America/Chicago' },
        { label: 'United States (Denver)', value: 'America/Denver' },
        { label: 'United States (Los Angeles)', value: 'America/Los_Angeles' },
        { label:"Pacific (Honolulu)",value:"Pacific/Honolulu"},
        { label: 'Canada (Toronto)', value: 'America/Toronto' },
        { label: 'Canada (Vancouver)', value: 'America/Vancouver' },
        { label: 'Brazil (São Paulo)', value: 'America/Sao_Paulo' },
        { label: 'United Kingdom', value: 'Europe/London' },
        { label: 'Germany', value: 'Europe/Berlin' },
        { label: 'France', value: 'Europe/Paris' },
        { label: 'Russia (Moscow)', value: 'Europe/Moscow' },
        { label: 'India', value: 'Asia/Kolkata' },
        { label: 'China (Beijing)', value: 'Asia/Shanghai' },
        { label: 'Japan (Tokyo)', value: 'Asia/Tokyo' },
        { label: 'Australia (Sydney)', value: 'Australia/Sydney' },
        { label: 'Australia (Perth)', value: 'Australia/Perth' },
        { label: 'South Africa', value: 'Africa/Johannesburg' },
        { label: 'Egypt', value: 'Africa/Cairo' },
        { label: 'Argentina (Buenos Aires)', value: 'America/Argentina/Buenos_Aires' },
        { label: 'Mexico (Mexico City)', value: 'America/Mexico_City' },
        { label: 'Saudi Arabia', value: 'Asia/Riyadh' },
        { label: 'Turkey (Istanbul)', value: 'Europe/Istanbul' },
        { label: 'Spain (Madrid)', value: 'Europe/Madrid' },
        { label: 'Italy (Rome)', value: 'Europe/Rome' },
        { label: 'Greece (Athens)', value: 'Europe/Athens' },
        { label: 'Ukraine (Kyiv)', value: 'Europe/Kiev' },
        { label: 'Poland (Warsaw)', value: 'Europe/Warsaw' },
        { label: 'Netherlands (Amsterdam)', value: 'Europe/Amsterdam' },
        { label: 'Sweden (Stockholm)', value: 'Europe/Stockholm' },
        { label: 'Switzerland (Zurich)', value: 'Europe/Zurich' },
        { label: 'Norway (Oslo)', value: 'Europe/Oslo' },
        { label: 'Denmark (Copenhagen)', value: 'Europe/Copenhagen' },
        { label: 'Finland (Helsinki)', value: 'Europe/Helsinki' },
        { label: 'Brazil (Rio de Janeiro)', value: 'America/Sao_Paulo' },
        { label: 'Chile (Santiago)', value: 'America/Santiago' },
        { label: 'Peru (Lima)', value: 'America/Lima' },
        { label: 'Colombia (Bogotá)', value: 'America/Bogota' },
        { label: 'Venezuela (Caracas)', value: 'America/Caracas' },
        { label: 'Malaysia (Kuala Lumpur)', value: 'Asia/Kuala_Lumpur' },
        { label: 'Singapore', value: 'Asia/Singapore' },
        { label: 'Indonesia (Jakarta)', value: 'Asia/Jakarta' },
        { label: 'Thailand (Bangkok)', value: 'Asia/Bangkok' },
        { label: 'Vietnam (Ho Chi Minh City)', value: 'Asia/Ho_Chi_Minh' },
        { label: 'South Korea (Seoul)', value: 'Asia/Seoul' },
        { label: 'New Zealand (Auckland)', value: 'Pacific/Auckland' },
        { label: 'Fiji', value: 'Pacific/Fiji' },
        { label: 'Tonga', value: 'Pacific/Tongatapu' },
        { label: 'Samoa', value: 'Pacific/Apia' },
        { label: 'Israel (Tel Aviv)', value: 'Asia/Jerusalem' },
        { label: 'Iran (Tehran)', value: 'Asia/Tehran' },
        { label: 'Pakistan (Karachi)', value: 'Asia/Karachi' },
        { label: 'Bangladesh (Dhaka)', value: 'Asia/Dhaka' },
        { label: 'Nepal (Kathmandu)', value: 'Asia/Kathmandu' },
        { label: 'Sri Lanka (Colombo)', value: 'Asia/Colombo' },
        { label: 'Myanmar (Yangon)', value: 'Asia/Yangon' },
        { label: 'Philippines (Manila)', value: 'Asia/Manila' },
        { label: 'Kazakhstan (Almaty)', value: 'Asia/Almaty' },
        { label: 'Uzbekistan (Tashkent)', value: 'Asia/Tashkent' },
        { label: 'Kyrgyzstan (Bishkek)', value: 'Asia/Bishkek' },
        { label: 'Georgia (Tbilisi)', value: 'Asia/Tbilisi' },
        { label: 'Armenia (Yerevan)', value: 'Asia/Yerevan' },
        { label: 'Azerbaijan (Baku)', value: 'Asia/Baku' },
        { label: 'Mongolia (Ulaanbaatar)', value: 'Asia/Ulaanbaatar' },
        { label: 'Afghanistan (Kabul)', value: 'Asia/Kabul' },
        { label: 'Iraq (Baghdad)', value: 'Asia/Baghdad' },
        { label: 'Syria (Damascus)', value: 'Asia/Damascus' },
        { label: 'Jordan (Amman)', value: 'Asia/Amman' },
        { label: 'Lebanon (Beirut)', value: 'Asia/Beirut' },
        { label: 'Kuwait', value: 'Asia/Kuwait' },
        { label: 'Qatar', value: 'Asia/Qatar' },
        { label: 'United Arab Emirates (Dubai)', value: 'Asia/Dubai' },
        { label: 'Oman (Muscat)', value: 'Asia/Muscat' },
        { label: 'Yemen (Aden)', value: 'Asia/Aden' },
        { label: 'Bahrain', value: 'Asia/Bahrain' },
        { label: 'Cyprus (Nicosia)', value: 'Asia/Nicosia' },
        { label: 'Malta', value: 'Europe/Malta' },
        { label: 'Iceland (Reykjavik)', value: 'Atlantic/Reykjavik' },
        { label: 'Greenland (Nuuk)', value: 'America/Godthab' },
        { label: 'Argentina (Buenos Aires)', value: 'America/Argentina/Buenos_Aires' },
        { label: 'Chile (Santiago)', value: 'America/Santiago' },
        { label: 'Ecuador (Guayaquil)', value: 'America/Guayaquil' },
        { label: 'Bolivia (La Paz)', value: 'America/La_Paz' },
        { label: 'Paraguay (Asuncion)', value: 'America/Asuncion' },
        { label: 'Uruguay (Montevideo)', value: 'America/Montevideo' },
        { label: 'Guyana (Georgetown)', value: 'America/Guyana' },
        { label: 'Suriname (Paramaribo)', value: 'America/Paramaribo' },
        { label: 'Venezuela (Caracas)', value: 'America/Caracas' }
      ];
    