<a
    class="layout-topbar-logo"
    routerLink=""
    style="
        display: block;
        text-align: center;
        border-bottom: 1px dashed #393945;
        margin-bottom: 20px;
    "
>
    <img
        [src]="_globals.whiteLabel.logoWhite"
        alt="Logo"
        height="80"
        width="150"
        [style]="{ 'margin-bottom': '30px' }"
    />
</a>

<p-scrollPanel
    [style]="{
        'margin-right': '0px',
        width: '100%',
        height: 'calc(87vh - 9rem)'
    }"
>

    <p-panelMenu
        [model]="this._abd.appLeftMenu"
        [style]="{ width: '100%' }"
        [multiple]="false"
    >
    </p-panelMenu>
</p-scrollPanel>
        <!-- Footer -->
        <div   style="
        display: block;
        text-align: center;
        padding-top:10px;
        margin-top: 20px;
        border-top: 1px dashed #393945;
    ">
            <span *ngIf="!_abd.isAdmin && _globals.whiteLabel.isDownloadPOSAPP === true" class="font-bold px-2 my-4 cursor-pointer open-app" style="color: var(--brandColor);" (click)="opnePosDownload()">
            Download Point of Sale App
        </span> 
        </div>
       