<div class="flex loyalty-progra perks-popup">
    <form [formGroup]="perkForm" (keydown.enter)="$event.preventDefault()"
        class="w-full" (ngSubmit)="saveForm()" autocomplete="off">
        <div class="col-12 md:col-12 card border-dotted-custom">
            <div class="p-fluid">
                <div class="field">
                    <label htmlfor="displayName">Display Name <span class="text-red-500">*</span></label>
                    <input
                        pinputtext=""
                        id="name1"
                        formControlName="displayName"
                        type="text"
                        [maxlength]="300"
                        class="p-inputtext p-component p-element" />
                    <div class="text-sm text-bluegray-300 mt-1">This will be shown to the user i.e. Get $5 off on any
                        purchase</div>
                    <div *ngIf="(!perkForm.get('displayName')?.valid
                            && (perkForm.get('displayName')?.dirty || perkForm.get('displayName')?.touched)
                        )
                            ">
                        <div class="input-required mt-1"
                            [hidden]="perkForm.get('displayName').value?.length == 0 && !perkForm.get('displayName').errors.required">
                            Please enter a Display Name
                        </div>
                    </div>
                </div>
                <div class="field w-4">
                    <label htmlfor="InputPointsExpiry">Status <span class="text-red-500">*</span></label>
                    <p-dropdown [options]="statusList" formControlName="status" optionValue="name" optionLabel="name" appendTo="body"> 
                    </p-dropdown>
                </div>
                <div class="field">
                    <div class="flex gap-2">
                        <div class="col-12 md:col-4 pl-0">
                            <label htmlfor="InputPointsExpiry">Reward Type <span class="text-red-500">*</span></label>
                            <p-dropdown
                                [options]="rewardTypeList"
                                formControlName="rewardType"
                                optionValue="name"
                                (onChange)="rewardType()"
                                optionLabel="name"
                                appendTo="body">
                            </p-dropdown>
                        </div>
                        <!-- (onInput)="validateGoal($event)" -->
                        <div class="col-12 md:col-4">
                            <label htmlfor="InputPointsExpiry">Points Goal <span class="text-red-500">*</span></label>
                            <p-inputNumber
                                [showButtons]="true"

                                [max]="1000000"
                                [step]="1"
                                 mode="decimal"
                                 [minFractionDigits]="2"
                                inputId="stacked"
                                formControlName="pointsGoal">
                            </p-inputNumber>
                            <div *ngIf="(!perkForm.get('pointsGoal')?.valid
                                && (perkForm.get('pointsGoal')?.dirty || perkForm.get('pointsGoal')?.touched)
                            )
                                ">
                                <div class="input-required mt-1"
                                    [hidden]="perkForm.get('pointsGoal').value?.length == 0 && !perkForm.get('pointsGoal').errors.required">
                                    Please enter a positive number
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field" [hidden]="discountType != 'Flat Discount'">
                    <label htmlfor="InputPointAccumulationThreshold">Discount Amount <span
                            class="text-red-500">*</span></label>
                    <div class="col-12 md:col-4">
                        <div class="p-inputgroup">
                            <!-- (onInput)="validateAmount($event)" -->
                            <span class="p-inputgroup-addon">$</span>
                            <p-inputNumber
                                inputId="locale-us"
                                mode="decimal"
                                locale="en-US"

                                [max]="1000000"
                                formControlName="discountAmount"
                                [maxFractionDigits]="2">
                            </p-inputNumber>

                        </div>
                          <div *ngIf="(!perkForm.get('discountAmount')?.valid
                                && (perkForm.get('discountAmount')?.dirty || perkForm.get('discountAmount')?.touched)
                            )
                                ">
                              <div class="input-required mt-1"
                                  [hidden]="perkForm.get('discountAmount').value?.length == 0 && !perkForm.get('discountAmount').errors.required">
                                  Please enter a positive number
                              </div>
                          </div>
                    </div>
                </div>
                <div class="field" [hidden]="discountType != 'Percentage Discount'">
                    <div class="flex gap-2">
                        <div class="col-12 md:col-4 pl-0">
                            <label htmlfor="InputPointAccumulationThreshold">Max Discount <span
                                    class="text-red-500">*</span>
                            </label>
                            <div class="col-12 md:col-12">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">$</span>
                                    <p-inputNumber inputId="locale-us" mode="decimal" locale="en-US"
                                        [max]="1000000" formControlName="maxDiscount" [maxFractionDigits]="2">
                                    </p-inputNumber>
                                </div>
                                 <div *ngIf="(!perkForm.get('maxDiscount')?.valid
                                && (perkForm.get('maxDiscount')?.dirty || perkForm.get('maxDiscount')?.touched)
                            )
                                ">
                                     <div class="input-required mt-1"
                                         [hidden]="perkForm.get('maxDiscount').value?.length == 0 && !perkForm.get('maxDiscount').errors.required">
                                         Please enter a positive number
                                     </div>
                                 </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-4">
                            <label htmlfor="InputPointAccumulationThreshold">Value <span
                                    class="text-red-500">*</span></label>
                            <div class="col-12 md:col-12">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">%</span>
                                    <p-inputNumber
                                        inputId="locale-us"
                                        mode="decimal"
                                        locale="en-US"

                                        [max]="100"
                                        formControlName="percentageDiscount"
                                        [maxFractionDigits]="2">
                                    </p-inputNumber>
                                </div>
                                 <div *ngIf="(!perkForm.get('percentageDiscount')?.valid
                                && (perkForm.get('percentageDiscount')?.dirty || perkForm.get('percentageDiscount')?.touched)
                            )
                                ">
                                     <div class="input-required mt-1"
                                         [hidden]="perkForm.get('percentageDiscount').value?.length == 0 && !perkForm.get('percentageDiscount').errors.required">
                                         Please enter a positive number
                                     </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field" [hidden]="discountType != 'Free Items'">

                    <div class="col-12 md:col-12 pl-0">
                        <div class="flex">
                            <div class="col-12 md:col-5 pl-0">
                                <label htmlfor="InputPointAccumulationThreshold">Product(s)
                                    <span class="text-red-500">*</span>
                                </label>
                                    <!-- (keydown.enter)="searchButtonClick($event)"-->

                                    <input type="text" [ngClass]="{'disabled-input': loadingSearchData}"
                                         pInputText [(ngModel)]="searchText"

                                        #search [ngModelOptions]=" {standalone: true}" [disabled]="loadingSearchData"
                                        placeholder="Search" />


                            </div>
                            <div class="col-12 md:col-3">
                                <label htmlfor="InputPointsExpiry">Category
                                    <span class="text-red-500">*</span>
                                </label>
                                <p-dropdown
                                    [options]="categoriesList "
                                    optionValue="id"
                                    optionLabel="categoryName"
                                    [filter]="true"
                                    [showClear]="true"
                                    [(ngModel)]="selectedCategory"
                                     [ngModelOptions]=" {standalone: true}"
                                    filterBy="categoryName"
                                    appendTo="body"
                                    >
                                </p-dropdown>

                            </div>
                            <div class="col-12 md:col-3">
                                <label htmlfor="InputPointsExpiry">Department
                                    <span class="text-red-500">*</span>
                                </label>
                                <p-dropdown
                                    [options]="departmentList"
                                    optionValue="id"
                                    optionLabel="departmentName"
                                    [filter]="true"
                                     [showClear]="true"
                                    [(ngModel)]="selectedDepartment"
                                     [ngModelOptions]=" {standalone: true}"
                                    filterBy="departmentName"
                                    appendTo="body"
                                    >
                                </p-dropdown>
                            </div>
                            <div class="col-12 md:col-1 search-button-container">
                                <p-button class="search-button" (click)="searchButtonClick($event)"
                                    >
                                     <i *ngIf="!loadingSearchData" class="pi pi-search m-right"></i>
                                     <i *ngIf="loadingSearchData" class="pi pi-spin pi-spinner m-right"></i>
                                </p-button>
                            </div>
                        </div>

                        <div class="text-sm text-bluegray-300 mt-1">Add single product or add multiple products as variant under the same group</div>
                    </div>
                </div>
                <div class="h-auto overflow-auto" [hidden]="discountType != 'Free Items'">
                    <div class="flex justify-content-end my-2">
                    <div class="flex justify-content-end">
                        <button class="p-button-warning" (click)="confirmRemove($event)"
                            *ngIf="productList?.length > 1"
                            pButton
                            type="button" label="Remove All"
                            icon="pi pi-trash"></button>
                    </div>
                    </div>
                    <div class="selected-product overflow-auto max-h-20rem">
                        <p-table
                            [value]="productList"
                            [autoLayout]="true"
                            [paginator]="false"
                            [scrollable]="true"
                            styleClass="p-datatable-sm"
                            [resizableColumns]="false"
                            scrollHeight="flex"
                            >
                            <ng-template pTemplate="header">
                                <tr>
                                    <th  class="justify-content-center"  style="width:100px">#</th>
                                    <th class="justify-content-start ">Products</th>
                                    <th class="justify-content-center">Cost</th>
                                    <th class="justify-content-center">Price A</th>
                                    <th class="justify-content-center quantity-filed">Quantity</th>
                                    <th class="justify-content-center">Action</th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td style="width:100px" class="select-none justify-content-center">
                                        {{ item.serial   }}</td>
                                    <td class="justify-content-start select-none">{{ item.product | slice:0:50 }} <span
                                            *ngIf="item.product.length > 50">...</span></td>
                                    <td class="justify-content-center select-none">{{ item.cost | currency }}</td>
                                    <td class="justify-content-center select-none">{{ item.priceA | currency }}</td>
                                    <td class="justify-content-center select-none">
                                        <p-inputNumber
                                            [(ngModel)]="item.quantity"
                                            [ngModelOptions]=" {standalone: true}"
                                            [max]="1"
                                            [disabled]="true"
                                            styleClass="text-center"
                                            [maxFractionDigits]="2"
                                            [ngClass]="{'input-border-red': !item.valid}"
                                            (onInput)="validateQuantity($event, item)"
                                            [min]="1">
                                        </p-inputNumber>
                                    </td>
                                    <td class="justify-content-center">
                                        <button
                                            pButton
                                            (click)="removeItem(item)"
                                            pRipple
                                            type="button"
                                            label="Remove"
                                            class="p-button-danger p-button-text"></button>
                                    </td>
                                </tr>
                            </ng-template>

                        </p-table>
                    </div>
                    <div class="flex" *ngIf="productList?.length == 0">
                        <div class="text-align-center" style="width: 100%">
                            <span class="control-description text-muted text-align-center">
                                No data found!</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="grid" style="margin-top: 10px">
            <div class="col" style="margin-top: 7px">
                <div class="flex justify-content-end flex-wrap card-container green-container mt-3">
                    <div class="flex align-items-center justify-content-center">
                        <button [disabled]="!perkForm.valid" pButton pRipple type="submit" label="Save"></button>
                    </div>
                    <div class="flex align-items-center justify-content-center ml-2">
                        <button pButton pRipple type="button" (click)="confirm($event)" label="Cancel"
                            class="p-button-outlined p-button-danger"></button>

                    </div>
                </div>
            </div>
        </div>
    </form>
</div>


<p-dialog header="Product(s)"
    [closable]="false"
    [(visible)]="showProductDialog"
    [resizable]="false"
    [modal]="true"
    [maximizable]="false"
    [contentStyle]="{height: '400px'}"
    [breakpoints]="{'960px': '95vw'}"
    [style]="{width: '80vw'}"
    [draggable]="false"
    styleClass="product-dialog"
    >
      <ng-template pTemplate>
            <p-table
                showLoader="true"
                rowHover="true"
                [autoLayout]="true"
                [loading]="isListLoading"
                [value]="searchList"
                [selectionPageOnly]="true"
                [lazy]="true"
                styleClass="p-datatable-sm"
                [customSort]="true"
                (onLazyLoad)="customSort($event)"
                [globalFilterFields]="['itemName']"
                [scrollable]="true"
                [resizableColumns]="false"
                scrollHeight="flex"
                [tableStyle]="{'min-width': '50rem'}"
                >

                <ng-template pTemplate="header">
                  <tr>
                        <th style="width: 4rem">
                            <p-checkbox name="selectAllInput" (onChange)="changeSelectAll()" [binary]="true"
                                [ngModelOptions]=" {standalone: true}" label="" [(ngModel)]="selectAll">
                            </p-checkbox>
                        </th>
                        <th pSortableColumn="itemName">
                            Product Name <p-sortIcon field="itemName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="skuCode">SKU <p-sortIcon field="skuCode"></p-sortIcon>
                        </th>
                        <th pSortableColumn="barcode">Barcode <p-sortIcon field="barcode"></p-sortIcon>
                        </th>
                        <th >Cost
                        </th>
                        <th>Sale Price
                        </th>
                  </tr>
                </ng-template>

              <ng-template pTemplate="body"  let-product>
                  <tr [pSelectableRow]="rowData">
                      <td class="cursor-pointer">
                          <p-checkbox name="product.itemName" (onChange)="updateStatus(product.checked, product)"
                              [binary]="true" label="Check" [ngModelOptions]=" {standalone: true}"
                              [(ngModel)]="product.checked">
                          </p-checkbox>
                      </td>
                      <td>
                          {{ product.itemName | slice : 0 : 40 }}
                          <span *ngIf="product.itemName.length > 40">...</span>
                      </td>
                      <td>{{ product.skuCode }}</td>
                      <td>{{ product.barcode }}</td>

                      <td style="text-align: center">
                          {{ product.cost | currency }}
                      </td>
                      <td style="text-align: center">
                          {{ product.priceA | currency}}
                      </td>
                  </tr>
              </ng-template>

          </p-table>


      </ng-template>
      <ng-template pTemplate="footer">
          <p-paginator [first]="first" (onPageChange)="onPageChange($event)" [rows]="rows" appendTo="body"
              dropdownScrollHeight="500px" pageLinkSize="5" [totalRecords]="totalRecords"
              [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]" [showCurrentPageReport]="true"
              currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}" #p>
          </p-paginator>
        <div class="flex justify-content-end flex-wrap my-2">

            <div class="flex align-items-center justify-content-center ml-2">
                <button [disabled]="isListLoading" pButton pRipple type="button" (click)="addItems()"
                    label="Add"></button>
            </div>
            <div class="flex align-items-center justify-content-center ml-2">
                <button [disabled]="isListLoading" pButton pRipple type="button" (click)="coseProductModal()"
                    label="Close"
                    class="p-button-outlined p-button-danger"></button>

            </div>
        </div>
      </ng-template>
</p-dialog>



