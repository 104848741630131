<form
    [formGroup]="editform"
    (ngSubmit)="editform.valid && saveFn()"
    autocomplete="off"
>
    <h2
        style="
            margin-top: 5px;
            margin-bottom: 10px;
            color: var(--bluegray-400);
            font-weight: 700;
        "
    >
        Account Information
    </h2>

    <div class="conn-card">
        <div class="grid">
            <div class="col">
                <label class="control-label">
                    <strong
                        >First Name
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <app-text-input
                    [formControl]="$any(editform.controls)['firstName']"
                    [name]="'First Name'"
                    [label]="'First Name'"
                    (input)="
                        _userService?.activeItem.firstName = $event.target.value
                    "
                ></app-text-input>
            </div>
            <div class="col">
                <label class="control-label">
                    <strong
                        >Last Name
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <app-text-input
                    [formControl]="$any(editform.controls)['lastName']"
                    [name]="'Last Name'"
                    [label]="'Last Name'"
                    (input)="
                        _userService?.activeItem.lastName = $event.target.value
                    "
                ></app-text-input>
            </div>
        </div>

        <div class="grid" style="margin-top: 15px">
            <div class="col">
                <label class="control-label">
                    <strong
                        >User Name
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <app-text-input
                    [formControl]="$any(editform.controls)['username']"
                    [name]="'User Name'"
                    [label]="'User Name'"
                    (input)="
                        _userService?.activeItem.username = $event.target.value
                    "
                    (keyup)="removeSpaceUserName()"
                ></app-text-input>
            </div>
            <div class="col" style="margin-top: 21px">
                <app-text-input
                    style="text-align: center"
                    [formControl]="$any(editform.controls)['domainName']"
                    [name]="'Last Name'"
                    [label]=""
                ></app-text-input>
            </div>
        </div>

        <div class="grid" style="margin-top: 15px">
            <div class="col">
                <div>
                    <label class="control-label">
                        <strong
                            >Email
                            <span style="color: red; font-size: 1.25rem"
                                >*</span
                            >
                        </strong></label
                    >
                    <app-text-input
                        [formControl]="$any(editform.controls)['email']"
                        [name]="'Email'"
                        [label]="'Email'"
                        (input)="
                            _userService?.activeItem.email = $event.target.value
                        "
                    ></app-text-input>
                </div>
            </div>

            <div class="col">
                <label class="control-label">
                    <strong
                        >Phone
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <app-phone-input
                    [formControl]="$any(editform.controls)['phone']"
                    (input)="
                        _userService.activeItem.phone = $event.target.value
                    "
                >
                </app-phone-input>
            </div>
        </div>

        <div class="grid" style="margin-top: 15px" *ngIf="_userService.isNew">
            <div class="col">
                <label class="control-label">
                    <strong
                        >Password
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >

                <p-password
                    id="password"
                    [formControl]="editform.controls['password']"
                    placeholder="Password"
                    [toggleMask]="true"
                    [feedback]="true"
                    [style]="{ width: '100%', 'margin-top': '8px' }"
                    [inputStyle]="{ width: '100%' }"
                    [strongRegex]="passwordValidatorString"
                    styleClass="w-full mb-5 p-password p-component p-inputwrapper p-input-icon-right"
                >
                    <ng-template pTemplate="footer">
                        <p-divider></p-divider>
                        <p class="mt-2">Suggestions</p>
                        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                            <li>At least one lowercase</li>
                            <li>At least one uppercase</li>
                            <li>At least one numeric</li>
                            <li>At least one special character</li>
                            <li>Between 8 and 16 characters</li>
                        </ul>
                    </ng-template>
                </p-password>
                <div
                    *ngIf="f.password.touched && f.password.invalid"
                    class="invalid-feedback"
                >
                    <div
                        class="invalid-feedback"
                        *ngIf="
                            f.password.errors?.required ||
                            f.password.errors?.passwordStrength
                        "
                        style="margin-top: -20px"
                    >
                        Your password must have lower case, upper case, special
                        character, numeric characters and length between 8-16
                        characters.
                    </div>
                </div>
            </div>
            <div class="col">
                <label class="control-label">
                    <strong
                        >Confirm Password
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >

                <p-password
                    id="confirmPassword"
                    [formControl]="editform.controls['confirmPassword']"
                    placeholder="Password Confirm"
                    [toggleMask]="true"
                    [feedback]="true"
                    [style]="{ width: '100%', 'margin-top': '8px' }"
                    [inputStyle]="{ width: '100%' }"
                    styleClass="w-full mb-5 p-password p-component p-inputwrapper p-input-icon-right"
                >
                    <ng-template pTemplate="footer">
                        <p-divider></p-divider>
                        <p class="mt-2">Suggestions</p>
                        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                            <li>At least one lowercase</li>
                            <li>At least one uppercase</li>
                            <li>At least one numeric</li>
                            <li>At least one special character</li>
                            <li>Between 8 and 16 characters</li>
                        </ul>
                    </ng-template>
                </p-password>
                <div
                    *ngIf="
                        f.confirmPassword.touched && f.confirmPassword.invalid
                    "
                    class="invalid-feedback"
                >
                    <div
                        class="invalid-feedback"
                        *ngIf="
                            f.confirmPassword.errors?.required ||
                            f.confirmPassword.errors?.conpasswordStrength
                        "
                        style="margin-top: -20px"
                    >
                        Mismatch password.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
    <br />
    <h2
        style="
            margin-top: 5px;
            margin-bottom: 10px;
            color: var(--bluegray-400);
            font-weight: 700;
        "
    >
        Contact Information
    </h2>

    <div class="conn-card">
        <div class="grid">
            <div class="col">
                <label class="control-label">
                    <strong
                        >Street
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <app-text-input
                    [formControl]="$any(editform.controls)['street']"
                    [name]="'street'"
                    [label]="'Street'"
                    (input)="
                        _userService?.activeItem.street = $event.target.value
                    "
                ></app-text-input>
            </div>
        </div>
        <br />
        <!-- <div class="grid">
            <div class="col">
                <label class="control-label">
                    <strong
                        >Zip Code
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <app-text-input
                    formControlName="postalCode"
                    [label]=""
                    [name]="'zip code'"
                    (input)="zipInputEvent($event.target.value)"
                ></app-text-input>
            </div>

            <div class="col">
                <label class="control-label">
                    <strong
                        >City
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <span class="p-input-icon-right" [style]="{ width: '100%' }">
                    <i class="pi pi-spin pi-spinner" *ngIf="showCityWait"></i>
                    <app-text-input
                        formControlName="city"
                        [label]="''"
                        (input)="
                            _userService?.activeItem.city = $event.target.value
                        "
                    ></app-text-input>
                </span>
            </div>

            <div class="col">
                <label class="control-label">
                    <strong
                        >State
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <p-dropdown
                    [style]="{
                        width: '100%',
                        'min-width': '200px',
                        'margin-top': '7px'
                    }"
                    class="p-dropdown-1"
                    appendTo="body"
                    [options]="usaStates"
                    [(ngModel)]="_userService.activeItem.selectedState"
                    optionLabel="name"
                    [filter]="true"
                    [disabled]="_userService.isViewOnly"
                    filterBy="name"
                    [ngModelOptions]="{ standalone: true }"
                ></p-dropdown>
            </div>
        </div> -->
        <app-zipcode-city-state-three-col [enable]="_userService.isViewOnly"></app-zipcode-city-state-three-col>
    </div>
    <br />
    <br />
    <h2
        style="
            margin-top: 5px;
            margin-bottom: 10px;
            color: var(--bluegray-400);
            font-weight: 700;
        "
    >
        Managers Override
    </h2>

    <div class="conn-card">
        <div class="grid">
            <div class="col">
                <label class="control-label">
                    <strong
                        >User Card-1
                        <span
                            style="color: red; font-size: 1.25rem"
                        ></span> </strong
                ></label>
                <app-text-input
                    [formControl]="$any(editform.controls)['overrideCode1']"
                    [name]="'User Card-1'"
                    [label]=""
                    (input)="
                        _userService?.activeItem.overrideCode1 =
                            $event.target.value
                    "
                ></app-text-input>
            </div>
            <div class="col">
                <label class="control-label">
                    <strong
                        >User Card-2
                        <span
                            style="color: red; font-size: 1.25rem"
                        ></span> </strong
                ></label>
                <app-text-input
                    [formControl]="$any(editform.controls)['overrideCode2']"
                    [name]="'User Card-2'"
                    [label]=""
                    (input)="
                        _userService?.activeItem.overrideCode2 =
                            $event.target.value
                    "
                ></app-text-input>
            </div>
        </div>
    </div>
    <br />
    <br />
    <h2
        style="
            margin-top: 5px;
            margin-bottom: 10px;
            color: var(--bluegray-400);
            font-weight: 700;
        "
    >
        User Locations
        <span style="color: red; font-size: 1.75rem">*</span>
    </h2>

    <div class="conn-card">
        <div class="grid">
            <div class="col">
                <p-pickList
                    [source]="locations"
                    [target]="selectedLocations"
                    [disabled]="_userService.isViewOnly"
                    sourceHeader="Available"
                    targetHeader="Selected"
                    dragdrop="true"
                    [responsive]="true"
                    filterBy="locationName"
                    sourceFilterPlaceholder="Search by name"
                    targetFilterPlaceholder="Search by name"
                >
                    <ng-template let-product pTemplate="item">
                        <div class="product-item">
                            <div class="product-list-detail">
                                {{ product.locationName }}
                            </div>
                        </div>
                    </ng-template>
                </p-pickList>
            </div>
        </div>
    </div>
</form>

<br />
<br />
<h2
    style="
        margin-top: 5px;
        margin-bottom: 10px;
        color: var(--bluegray-400);
        font-weight: 700;
    "
>
    User Rights & Roles
    <span style="color: red; font-size: 1.75rem">*</span>
</h2>
<div class="conn-card mb-0">
    <div class="grid">
        <div class="col">
            <h5
                style="
                    margin-top: 5px;
                    margin-bottom: 10px;
                    color: var(--bluegray-400);
                    font-weight: 700;
                "
            >
                Roles
                <span style="color: red; font-size: 1.75rem">*</span>
            </h5>
            <div class="grid">
                <ng-container style="margin-top: 20px">
                    <div class="col" *ngFor="let role of this.localRoles">
                        <p-inputSwitch
                            [(ngModel)]="role.selected"
                            (onChange)="rightsOnChange()"
                            [disabled]="_userService.isViewOnly"
                        ></p-inputSwitch>
                        <label class="control-label" style="margin-left: 10px"
                            ><strong
                                >{{ role.name }}
                                <span
                                    style="color: red; font-size: 1.25rem"
                                ></span> </strong
                        > </label><i class="pi pi-fw pi-info-circle text-1xl" tooltipPosition="right" pTooltip="{{role.toolTip}}" ></i>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <br />
    <br />
    <div class="flex justify-content-between flex-wrap" *ngIf="12 > 21">
        <div class="flex align-items-start justify-content-center m-2">
            <h5
                style="
                    margin-top: 5px;
                    margin-bottom: 10px;
                    color: var(--bluegray-400);
                    font-weight: 700;
                "
            >
                Rights
                <span style="color: red; font-size: 1.75rem">*</span>
            </h5>
        </div>
        <div class="flex align-items-center justify-content-end m-2">
            <button
                pButton
                pRipple
                type="button"
                [label]="selectButtonLabel"
                [disabled]="_userService.isViewOnly"
                class="p-button-outlined p-button-success"
                (click)="selectAllButtonClick()"
            ></button>
        </div>
    </div>

    <div class="grid" *ngIf="12 > 21">
        <div class="col-6" *ngFor="let right of treeRights">
            <p-accordion>
                <p-accordionTab [header]="right.name" [selected]="true">
                    <p-tree
                        [value]="right.children"
                        selectionMode="checkbox"
                        [(selection)]="selectedRights"
                    ></p-tree>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>
<span class="control-description text-muted" *ngIf="isBusinessManager()"> Business Manager rights are system-defined and cannot be customized.</span>
<br />
<br />
<!-- POS Rights -->
<h2
    style="
        margin-top: 5px;
        margin-bottom: 10px;
        color: var(--bluegray-400);
        font-weight: 700;
    "
>
    POS Rights
    <span style="color: red; font-size: 1.75rem">*</span>
</h2>
<div class="conn-card">
    <div class="flex align-items-center justify-content-end m-2">
        <button
            pButton
            pRipple
            type="button"
            [label]="posSelectButtonLabel"
            class="p-button-outlined p-button-success"
            [disabled]="_userService.isViewOnly || isBusinessManager()"
            (click)="posSelectAllButtonClick()"
            ></button>
            <!-- [disabled]="_userService.isViewOnly || isBusinessManager()" -->
    </div>
    <div class="grid">
        <div class="col-6">
            <p-tree
                [value]="posRights1"
                selectionMode="checkbox"
                [(selection)]="selectedPosRights1"
            ></p-tree>
        </div>
        <div class="col-6">
            <p-tree
                [value]="posRights2"
                selectionMode="checkbox"
                [(selection)]="selectedPosRights2"
            ></p-tree>
        </div>
    </div>
</div>
<!-- /POS Rights -->
<br />
<br />
<!-- Back Office Rights -->
<h2
    style="
        margin-top: 5px;
        margin-bottom: 10px;
        color: var(--bluegray-400);
        font-weight: 700;
    "
>
        Back Office Rights
    <span style="color: red; font-size: 1.75rem">*</span>
</h2>
<div class="conn-card">
    <div class="flex align-items-center justify-content-end m-2">
        <button
            pButton
            pRipple
            type="button"
            [label]="backOfficeSelectButtonLabel"
            class="p-button-outlined p-button-success"
            [disabled]="_userService.isViewOnly || isBusinessManager()"
            (click)="backOfficeSelectAllButtonClick()"
            ></button>
            <!-- [disabled]="_userService.isViewOnly || isBusinessManager()" -->
    </div>
    <div class="grid">
        <div class="col-6">
            <p-tree
                [value]="backOfficeRights1"
                selectionMode="checkbox"
                [(selection)]="selectedBackOfficeRights1"
                ></p-tree>
                <!-- (onNodeUnselect)="nodeUnselect($event)" -->
        </div>
        <div class="col-6">
            <p-tree
                [value]="backOfficeRights2"
                selectionMode="checkbox"
                [(selection)]="selectedBackOfficeRights2"
                ></p-tree>
                <!-- (onNodeUnselect)="nodeUnselect($event)" -->
        </div>
    </div>
</div>
<!-- / Back Office Rights -->
<div class="form-group">
    <div
        class="flex justify-content-end flex-wrap card-container green-container mt-3"
    >
        <div class="flex align-items-center justify-content-center">
            <button
                pButton
                pRipple
                type="submit"
                label="Save"
                (click)="saveFn()"
                [disabled]="
                    (!editform.valid && !isFormValidFn()) ||
                    _userService.isViewOnly
                "
                style="width:120px;"
            ></button>
        </div>
        <div class="flex align-items-center justify-content-center ml-2">
            <button
                pButton
                pRipple
                type="button"
                label="Cancel"
                class="p-button-outlined p-button-danger"
                (click)="cancelButtonClick()"
                style="width:120px;"
            ></button>
        </div>
    </div>
</div>

<p-dialog [(visible)]="displayPasswordError" [style]="{ width: '30vw' }">
    <ng-template pTemplate="header">
        <h3>Header content here</h3>
    </ng-template>
    <p class="mt-2">Suggestions</p>
    <ul style="line-height: 1.5">
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>At least one special character</li>
        <li>Between 8 and 16 characters</li>
    </ul>
    <ng-template pTemplate="footer">
        <button
            type="button"
            pButton
            label="Ok"
            (click)="displayPasswordError = false"
        ></button>
    </ng-template>
</p-dialog>
