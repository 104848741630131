<form
 [formGroup]="editform"
 autocomplete="off"
>
<div class="flex xl:flex-row lg:flex-row flex-column">

        <div class="xl:col-6 lg:col-6 col-12">
            <label class="control-label">
                <strong>
                    Customer Groups
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>
            <br />
        <p-multiSelect
        [options]="customersList"
        [formControl]="$any(editform.controls)['customersGroupIds']"
        placeholder="Search Customer Group Name"
        optionLabel="title"
        optionValue="id"
        display="chip"
        appendTo="body"
        [style]="{ width: '100%', 'margin-top': '10px' }"
        [emptyMessage]="isLoadCustomerGroup ? 'Loading...':'No results found'"
        [dropdownIcon]="isLoadCustomerGroup ? 'pi pi-spin pi-spinner':'pi pi-chevron-down'"
    ></p-multiSelect>
     <div *ngIf="$any(editform.controls)['customersGroupIds']?.invalid && $any(editform.controls)['customersGroupIds']?.touched" class="invalid-feedback">
        Please Select Customer Groups
    </div> 
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <label class="control-label">
                <strong>
                   Points Value
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>
            <br />
            <app-text-input
                [formControl]="$any(editform.controls)['minSpendAmountReward']"
                [name]="'Dollar Value'"
                [description]="'Define value of each dollar to points i.e. $1 = X Points'"
                [label]=""
            >
            </app-text-input>
        </div> 
</div>
<div class="flex xl:flex-row lg:flex-row flex-column">
    <div class="xl:col-6 lg:col-6 col-12">
        <label class="control-label">
            <strong>
               Minimum Purchase Required for points Accumulation ($)
                <span style="color: red; font-size: 1.25rem">*</span>
            </strong>
        </label>
        <br />
        <app-text-input
            [formControl]="$any(editform.controls)['minimumCartValueForPoints']"
            [name]="'Minimum Purchase'"
            [description]="'Minimum purchase needed for points accumulation i.e. X Dollars'"
            [label]=""
        >
        </app-text-input>
    </div> 
    <div class="xl:col-6 lg:col-6 col-12">
            <label class="control-label">
                <strong>
                   Maximum Points Allowed
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>
            <br />
            <app-text-input
                [formControl]="$any(editform.controls)['maximumPointsPerTransaction']"
                [name]="'Maximum Points'"
                [description]="'Maximum limit to points accumulation'"
                [label]=""
            >
            </app-text-input>
        </div>

 </div>
 <div class="flex xl:flex-row lg:flex-row flex-column">
  <div class="xl:col-6 lg:col-6 col-12">
    <label class="control-label">
        <strong>
         Points Expiry
            <!-- <span style="color: red; font-size: 1.25rem">*</span> -->
        </strong>
    </label>
    <br />
    <p-dropdown
    [options]="expiryDurationList"
    [formControl]="$any(editform.controls)['pointsExpiration']"
    [disabled]="isViewOnly"
    optionLabel="title"
    ></p-dropdown>
</div>   
 </div>

 <br>
 <div class="flex py-3">
            <label htmlfor="InputDiscountDiscountedItems">
                Allow points accumulation on discounted items
            </label>
            <div class="px-2">
                <p-inputSwitch
                    [formControl]="$any(editform.controls)['accumulatePointsOnDiscountedItems']"
                    [disabled]="isViewOnly"
                    inputId="InputDiscountDiscountedItems">
                </p-inputSwitch>
                <label class="control-label" for="InputDiscountDiscountedItems"><strong> Enable</strong></label>
            </div>
        </div>
 <div class="flex py-2">
            <label htmlfor="InputDiscountDeptItems">
                Allow points accumulation on open department items
            </label>
            <div class="px-2">
                <p-inputSwitch
                    [formControl]="$any(editform.controls)['accumulatePointsOnOpenDeptItems']"
                    [disabled]="isViewOnly"
                    inputId="InputDiscountDeptItems">
                </p-inputSwitch>
                <label class="control-label" for="InputDiscountDeptItems"><strong> Enable</strong></label>
            </div>
        </div>
 <p-divider style="margin-top: 10px"></p-divider>
 <div class="grid">
     <div class="col">
         <div
             class="flex justify-content-end flex-wrap card-container green-container mt-3"
         >
             <div class="flex align-items-center justify-content-center">
                 <button
                     pButton
                     pRipple
                     type="submit"
                     label="Save"
                     [disabled]="!editform.valid"
                     *ngIf="!isViewOnly"
                     [style]="{ width: '120px' }"
                     (click)="saveFn()"
                 ></button>
             </div>
             <div
                 class="flex align-items-center justify-content-center ml-2"
             >
                 <button
                     pButton
                     pRipple
                     type="button"
                     label="Cancel"
                     class="p-button-outlined p-button-danger"
                     (click)="cancelButtonClick()"
                     [style]="{ width: '120px' }"
                 ></button>
             </div>
         </div>
     </div>
 </div>
</form>


