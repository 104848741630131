import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ProductsService } from 'src/app/_services/products.service';
import { PurchaseService } from 'src/app/_services/purchase.service';
import { VendorsService } from 'src/app/_services/vendors.service';

@Component({
  selector: 'app-purchase-order-create-edit',
  templateUrl: './purchase-order-create-edit.component.html',
  styleUrls: ['./purchase-order-create-edit.component.scss']
})
export class PurchaseOrderCreateEditComponent implements OnInit {
  locations: any = null
  vendors: any = null

  searchText = ''
  lastSearchText = ' '
  isLoading = false
  isListLoading = false
  isDuplicate = false

  dataList: any = []
  searchList: any = []
  selectedItemsRows: any = null

  productCost = 0.0
  shippingCost = 0.0
  grandTotal = 0

  isViewOnly = false
  isEdit = false
  isNew = false
  isEnableDeleteButton = false

  gsubtotal = 0
  gdiscount = 0
  gtax = 0
  gitems = 0

  isDataLoaded = false

  activeItem: any
  notes = ''
  method = 'MANUAL'

  selectedShippingCost = ''
  enableShippingCostInput: boolean = false;

  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel

  totalQuantity: number = 0;
  quantityBasedShipping: number = 0;
  isFooterNoteEnabled : boolean = false;
  footerNotesText: string = '';
  constructor(
    public _purchaseService: PurchaseService,
    public _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private _productService: ProductsService,
    private _messageService: MessageService,
    public _vendorService: VendorsService,
    private _router: Router,
    private _confirmationService: ConfirmationService,
    public _filterService: FilterServiceService,

  ) { }

  ngOnInit(): void {
    this.activeItem = { notes: '' }

    this.locations = this._abd.initActLocations()
    this.vendors = this._abd.initActVendors()

    if (!this.locations || !this.vendors) {
      this._messageService.add({ severity: 'error', summary: '[S-12.85.67] Missing basic data!' })
      window.history.back()
    }

    let ser = this._purchaseService
    let obj = this._purchaseService.newEditItem

    this.isViewOnly = obj.isViewOnly
    this.isEdit = obj.isEdit
    this.isNew = obj.isNew

      this.getFooterNotesConfigValue();
    if (this.isNew) {
      this._purchaseService.blankNewEditItem()

      this._purchaseService.newEditItem.PONumber = this._purchaseService.generatePoNumber()
      this._purchaseService.newEditItem.Dated = new Date()
      this._purchaseService.newEditItem.RequestedById = this._globals.userInfo.userID
      this._purchaseService.newEditItem.RequestedBy = this._globals.userInfo.displayName
      this._purchaseService.newEditItem.POStatus = 'NEW'

      this._purchaseService.newEditItem.isViewOnly = false
      this._purchaseService.newEditItem.isEdit = false
      this._purchaseService.newEditItem.isNew = true

      this.isViewOnly = this._purchaseService.newEditItem.isViewOnly
      this.isEdit = this._purchaseService.newEditItem.isEdit
      this.isNew = this._purchaseService.newEditItem.isNew
    //  Auto PO------------------
      if(this._purchaseService.poSelectedList.length > 0){
        this.dataList = this._purchaseService.poSelectedList;
        this._purchaseService.poSelectedList = []
        this._purchaseService.newEditItem.locationObj = this.locations.find(f => f.locationId == this._filterService.selectedLocation.locationId)
        this._filterService.selectedLocation = []
        if(this._filterService.selectedVendor !== null){
          this._purchaseService.newEditItem.vendorObj= this.vendors.find(f => f.id == this._filterService.selectedVendor.id)
        }
      }
    //  Auto PO------------------
    }
    else if (this.isEdit || this.isViewOnly) this.doEdit()

    this.calculateTotals()
  }

    getFooterNotesConfigValue() {
        try {
            this._purchaseService.getPurchaseOrderConfigurationDetails().subscribe((r) => {
                if (r.success) {
                    this.isFooterNoteEnabled = r.data.po_enablefooternote;
                    this.footerNotesText = r.data.po_footernote
                }
                else {
                    this._messageService.add({ severity: 'error', summary: 'Unable to load saved values of configuration' })
                }
            }, error => {
                this._messageService.add({ severity: 'error', summary: 'Unable to load saved values of configuration' })
            })
        } catch (error) {

        }
    }

  doEdit() {
    try {
      let ser = this._purchaseService
      this._spinner.show()
      this._purchaseService.searchSingleOrder(ser.newEditItem.Id).subscribe(res => {
        if (res.success) {
          this._spinner.hide()
          this.method=this._purchaseService.newEditItem.POType

          this.activeItem = res.data
          this.shippingCost = res.data.shipping
          this.dataList = []
          let pd = res.data.poItems
          this._purchaseService.newEditItem.RequestedById = this.activeItem.requestedById
          this._purchaseService.newEditItem.RequestedBy = this.activeItem.requestedBy
          this._purchaseService.newEditItem.locationObj = this.locations.find(f => f.locationId == this.activeItem.locationId)
          this._purchaseService.newEditItem.vendorObj= this.vendors.find(f => f.id == this.activeItem.vendorId)

          this.selectedShippingCost = ''
          if (this.activeItem.shippingByItem) {
            this.selectedShippingCost = 'enable'
          }
          else if (this.activeItem.dividedShippingItems)
            this.selectedShippingCost = 'divide'
          else this.selectedShippingCost = 'no-divide'

          for (let i = 0; i < pd.length; i++) {
            let uom = this._abd.uom.find(f => f.id == pd[i].uomId)
            let obj = pd[i]
            obj.srNo = i + 1
            obj.expiryDate = new Date()
            obj.description = pd[i].productName
            obj.receivedQty = pd[i].orderedQty
            obj.itemSkuRealId = pd[i].itemSKUId


            obj.costPrice = pd[i].costPrice
            obj.shipping = pd[i].shipping //--pd[i].shipping ? pd[i].shipping : 0
            obj.netCost = 0
            if (uom) obj.uomDescription = uom.unitName

            this.dataList.push(obj)
          }

          this.calculateTotals()
          this.isDataLoaded = true
        } else {
          this._spinner.hide()
          this.isDataLoaded = false
        }
      })
    } catch { this._spinner.hide() }
  }

  locationChanged(e) { }

  vendorChanged(e) {
    this.lastSearchText = ''
  }

  searchButtonClick(e) {
    if (this._purchaseService.newEditItem.isViewOnly) return
    if (!this.searchText.length) return
    if (this.searchText == this.lastSearchText) {
      this.op.toggle(e)
      return
    }

    this.isLoading = true
    this.isListLoading = true
    this.performSearch(e)
  }

  performSearch(e) {
    let ser = this._purchaseService.newEditItem

    let params = new HttpParams()
    params = params.append('startDate', '1800-01-01');
    params = params.append('endDate', formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));

    params = params.append('pageNo', 0);
    params = params.append('pageSize', 99999);
    params = params.append('locationId', ser.locationObj.id)
   // params = params.append('vendorId', ser.vendorObj.id)
    params = params.append('filterValue', this.searchText)

    this._spinner.show()
    this._productService.getAllProductList(params).subscribe(res => {
      if (res.success) {
        this._spinner.hide()

        this.isLoading = false
        this.isListLoading = false
        this.searchList = res.data
        for (let i = 0; i < this.searchList.length; i++) {
          this.searchList[i].receivedQty = 0
          this.searchList[i].totalCost = 0
          this.searchList[i].selected = false
          //this.searchList[i].costPrice = 12.13 // to be deleted
        }

        this.lastSearchText = this.searchText

        if (this.searchList.length == 1) {
          this.insertRow(this.searchList[0])
          this.op.hide()
          return
        } else
          if (this.searchList.length > 1) this.op.toggle(e)
          else {
            this._messageService.add({ severity: 'error', summary: 'No Product found with this search filter' })
            this.op.hide()
          }
      }
    })
  }

  selectAllChanged(event) {
    if (event.checked) {
      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = true
      }
    } else {
      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = false
      }
    }

    this.showDeleteButton()
  }

  selectionChanged(e) {
    this.showDeleteButton()
  }

  showDeleteButton() {
    if (this.isViewOnly) return false
    //if(this.isEnableDeleteButton) return true
    if (this.dataList.length < 1) return false

    for (let i = 0; i < this.dataList.length; i++) {
      if (this.dataList[i].selected) {
        return true
      }
    }

    return false
  }

  onRowSelect(e) {
    this.op.hide()
    this.insertRow(e.data)
  }

  insertRow(e) {
    let data = JSON.parse(JSON.stringify(e))
    data.expiryDate = new Date()
    const found = this.dataList.find(f => f.productId == data.productId)
    if (found) {
      this._messageService.add({ severity: 'error', summary: 'Product already selected ', detail: data.itemName })
      return
    }

    let sku = ''
    // if (this.isEdit)
    //   sku = data.skuItemCode
    // else
    //   sku = data.skuCode

    let uom = this._abd.uom.find(f => f.id == data.uomId)
    data.uomId = uom.id
    data.uomDescription = uom.unitName
    data.productName = data.itemName
    data.description = data.itemName
    data.itemSKUId = data.skuCode
    data.itemSkuRealId = data.itemSkuId
    data.isValueChanged = true
    data.receivedQty = 1
    data.subTotal = data.costPrice * data.receivedQty
    data.discountPercent = 0
    data.discountAmount = 0
    data.taxAmount = 0
    data.netTotal = data.subTotal - data.discountAmount
    data.totalCost = data.netTotal - data.taxAmount
    data.lineNotes = ''
    data.shipping = 0
    data.notes = ''
    data.selected = false
    //data.skuItemCode =  data.skuCode
    this.op.hide()

    this.dataList.push(data)
    this.calculateTotals()
  }

  removeItem() {
    this.dataList = this.dataList.filter(f => !f.selected)
    for (let i = 0; i < this.searchList.length; i++) {
      this.searchList[i].selected = false
    }
    this.isEnableDeleteButton = true
    this.calculateTotals()
  }

  calculateTotals() {
    this.gsubtotal = 0
    this.gdiscount = 0
    this.gtax = 0
    this.gitems = 0

    let sum = 0
    let length = 0
    let tmpNetCost=0

    try { length = this.dataList.length }
    catch { length = 0 }

    if (length < 1) return

    let data = this.dataList

    this.shippingCost = !this.shippingCost ? 0 : this.shippingCost

    this.totalQuantity = this.dataList.reduce((a,b)=> a+ b.receivedQty,0)
    this.quantityBasedShipping = (parseFloat(parseFloat(this.shippingCost.toString()).toFixed(2)) / this.totalQuantity )

    for (let i = 0; i < data.length; i++) {
      let dr = data[i]
      dr.srNo = i + 1
      dr.costPrice = !dr.costPrice ? 0 : dr.costPrice
      dr.receivedQty = !dr.receivedQty ? 0 : dr.receivedQty
      dr.discountPercent = !dr.discountPercent ? 0 : dr.discountPercent
      dr.taxRate = !dr.taxRate ? 0 : dr.taxRate
      this.shippingCost = !this.shippingCost ? 0 : this.shippingCost

      dr.netCost = dr.costPrice * dr.receivedQty
      tmpNetCost=dr.netCost
      dr.discountAmount = dr.netCost * (dr.discountPercent / 100)
      dr.netTotal = dr.netCost - dr.discountAmount

      dr.taxAmount = dr.netTotal * (dr.taxRate / 100)
      dr.totalCost = dr.netTotal + dr.taxAmount
      dr.itemLevelItemCost = dr.netTotal + dr.taxAmount  + dr.shipping;

      //dr.netCost=dr.netTotal

      this.gsubtotal += tmpNetCost
      this.gdiscount += dr.discountAmount
      this.gtax += dr.taxAmount
      sum += dr.totalCost
    }

    if (this.enableShippingCostInput) {
      let sc = 0
      for (let i = 0; i < data.length; i++) {
        sc += (data[i].shipping ? data[i].shipping : 0)
      }
      this.shippingCost = sc
    }
    else if (this.selectedShippingCost === 'divide') {

      for (let i = 0; i < data.length; i++) {
        let sc = this.dataList[i].receivedQty * this.quantityBasedShipping
        sc =  parseFloat(parseFloat(sc.toString()).toFixed(2))
        this.dataList[i].shipping = sc
      }
    }

    this.grandTotal = sum + this.shippingCost
  }

  disableShippingColumn() {
    if (this.isViewOnly) return true
    if (!this.selectedShippingCost) return true
  //  if (this.selectedShippingCost === 'enable') return false
    return true
  }

  discountAmountChanged(e, item) {
    try {
      if (e.value > 100) item.discountPercent = 100
      else item.discountPercent = e.value
      item.isValueChanged = true
    }
    catch { }
    this.calculateTotals()
  }

  taxAmountChanged(e, item) {
    try {
      item.taxRate = e.value
    }
    catch {
      item.subTotal = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  shippingChanged(e) {
    this.shippingCost = e.value
    this.calculateTotals()
  }

  itemShippingChanged(e, item) {
    try {
      item.shipping = e.value
      // this.calculateTotals()
    }
    catch {
    }
    this.calculateTotals()
  }

  orderedQtyChanged(e, item) {
    try {
      item.receivedQty = e.value
      if (!item.receivedQty) item.receivedQty = 0
      this.calculateTotals()
    }
    catch {
      item.totalCost = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  costPriceChanged(e, item) {
    try {
      item.costPrice = e.value
      if (!item.costPrice) item.costPrice = 0
    }
    catch {
      item.subTotal = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  costPriceValid(item: any) {
    if (!item.isCostValueChanged) return true
    if (!item.costPrice) return false
    if (item.costPrice < 0) return false
    return true
  }

  doShowFooter() {
    if (this.dataList.length > 0) return true
    else false
  }

  shippingCostChanged(e) {
    this.shippingCost = e.value
    this.calculateTotals()
  }

  editButtonClick() {
    if (this.isViewOnly) {
      this._purchaseService.newEditItem.isViewOnly = false
      this._purchaseService.newEditItem.isEdit = true
      this.isViewOnly = false
      this.isEdit = true
    }
    else if (this.isEdit) {
      this.isViewOnly = true
      this.isEdit = false
    }
  }

  isOk2Save() {
    const obj = this._purchaseService.newEditItem
    let ret = true

    if (this.dataList.length < 1) ret = false
    try {
      const find = this.dataList.filter(f => f.receivedQty > 0)
      if (!find || find.length < 1) ret = false

      if (!obj.locationObj) ret = false
      if (!obj.vendorObj) ret = false
      if (!obj.Dated) ret = false
    } catch { ret = false }

    return ret
  }

  saveFn(what: string) {
    let obj = this._purchaseService.newEditItem
    this.calculateTotals()

    if (!this.isOk2Save()) return
    const lst = this.dataList.filter(f => f.receivedQty > 0)

    if (!lst.length) {
      this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Nothing to save' })
      return
    }

    let mlist = []
    let netTotal = 0
    let taxTotal = 0
    let discountTotal = 0

    for (let i = 0; i < lst.length; i++) {
      let sku = ''
      if (this.isEdit)
        sku = lst[i].skuItemCode
      else
        sku = lst[i].skuCode

      const itm = lst[i]
      const m = {

        itemSKUId: itm.itemSkuRealId,
        notes: itm.notes,
        quantity: itm.receivedQty,
        costPrice: this._globals.roundNumbers(itm.costPrice),
        subTotal: this._globals.roundNumbers(itm.netCost),
        discountPercent: itm.discountPercent,
        discountAmount: this._globals.roundNumbers(itm.discountAmount),
        taxRate: itm.taxRate,
        taxAmount: this._globals.roundNumbers(itm.taxAmount),
        Shipping: this._globals.roundNumbers(itm.shipping),
        totalAmount: this._globals.roundNumbers(itm.totalCost),
        updatedById: this._globals.userInfo.userID,
        updatedBy: this._globals.userInfo.displayName,
      }
      mlist.push(m)

      netTotal += lst[i].netCost
      taxTotal += lst[i].taxAmount
      discountTotal += lst[i].discountAmount
    }
    const data = {
      id: this.isNew ? '-1' : this.activeItem.id,
      poNumber: obj.PONumber,
      POType: this.method,
      dated:formatDate(obj.Dated, 'MM/dd/yyyy', 'en-US'),
      //dated: obj.Dated,
      dueDate: formatDate(obj.Dated, 'MM/dd/yyyy', 'en-US'),
      vendorId: obj.vendorObj.id,
      requestedById: this._globals.userInfo.userID,
      requestedBy: this._globals.userInfo.displayName,
      locationId: obj.locationObj.id,
      notes: this.activeItem.notes,
      poStatus: what,
      paymentStatus: 'PENDING',
      subTotal: this._globals.roundNumbers(netTotal),
      netTotal: this._globals.roundNumbers(this.grandTotal),
      taxTotal: this._globals.roundNumbers(taxTotal),
      discountTotal: this._globals.roundNumbers(discountTotal),
      shipping: this._globals.roundNumbers(this.shippingCost),
      grandTotal: this._globals.roundNumbers(this.grandTotal),
      ShippingByItem: this.selectedShippingCost == 'enable' ? true : false,
      DividedShippingItems: this.selectedShippingCost == 'divide' ? true : false,
      "POItems": mlist,
        "FooterNotes": this.isFooterNoteEnabled ? this.footerNotesText : ''
    }

    this._spinner.show()
    this._purchaseService.purchaseOrderPost(data).subscribe(res => {
      const msg = what == 'DRAFT' ? 'Purchase Order saved successfully!' : 'Purchase Order Posted successfully!'
      if (res.success) {
        this._messageService.add({
          severity: 'success',
          summary: 'Success', detail: msg
        });
        this._spinner.hide()
        if (!this.isDuplicate) window.history.back()
        else {
          let obj = this._purchaseService.newEditItem

          obj.isViewOnly = false
          obj.isEdit = false
          obj.isNew = true

          this.isViewOnly = obj.isViewOnly
          this.isEdit = obj.isEdit
          this.isNew = obj.isNew

          if (this.isNew) {
            obj.PONumber = this._purchaseService.generatePoNumber()
            obj.Dated = new Date()
            obj.RequestedById = this._globals.userInfo.userID
            obj.RequestedBy = this._globals.userInfo.displayName
            obj.Id = null
          }

          this.calculateTotals()
        }
      }
      else {
        this._messageService.add({
          severity: 'error',
          summary: 'Error', detail: 'Unable to save Purchase Order!'
        })
      }
    })
  }

  showVendorInfoDialog = false
  showVendorInfoDialogFn() {
    this._vendorService.isViewOnly = true
    this._vendorService.activeItem = this._purchaseService.newEditItem.vendorObj
    this.showVendorInfoDialog = true
  }

  shippingOptionRadioButtonClicked() {
    // if (this.selectedShippingCost == 'enable') this.shippingCost = 0
    // for (let i = 0; i < this.dataList.length; i++) {
    //   this.dataList[i].shipping = 0
    // }
    if(!this.enableShippingCostInput){
        this.shippingCost = 0;
        this.dataList.map(f=> f.shipping = 0)
    }
    this.calculateTotals()
  }

//   doCancel() {
//     this._confirmationService.confirm({
//       header: `Cancel changes?`,
//       message: 'Changes you made will be lost. Are you sure you want to proceed?',
//       accept: () => {
//         try {
//           window.history.back()
//         } catch { this._abd.hideSpinner() }
//       },
//     });
//   }

doCancel() {
    this._confirmationService.confirm({
      header: `Cancel changes?`,
      message: 'Changes you made will be lost. Are you sure you want to proceed?',
      accept: () => {
        try {
          this._router.navigate(['app/purchase-orders'])
        } catch { this._abd.hideSpinner() }
      },
    });
  }

//   doCancel() {
//     this._confirmationService.confirm({
//         header: `Cancel changes?`,
//         message: 'Changes you made will be lost. Are you sure you want to proceed?',
//         accept: () => {
//           try {
//             window.history.back()
//           } catch { this._abd.hideSpinner() }
//         },
//       });
//   }



}
