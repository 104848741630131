<div class="conn-card" style="width: 100%">
  <app-report-top-filter-bar [paraReportName]="'importinventory'"></app-report-top-filter-bar>
  <div class="grid" style="background-color: white;" *ngIf="importStatus==='started'">
    <div class="col-12">
      <h6>Plese wait while import is in progress...</h6>
    </div>

  </div>

  <div class="grid" style="background-color: white;" *ngIf="importStatus==='completed'">
    <div class="col-12">
      <h6>Inventory Import completed</h6>
      <p>
        {{importMessage}}
      </p>
      <p-button (click)="importStatus=''">Go back</p-button>
    </div>

  </div>
  <div class="grid" style="background-color: white;" *ngIf="importStatus==='error'">
    <div class="col-12">
      <h6>Inventory Import failed</h6>
      <p>
        {{importMessage}}
      </p>
      <p-button (click)="importStatus=''">Try Again</p-button>

    </div>

  </div>
  <div class="grid" *ngIf="importStatus===''">
    <div class="col-8">
      <p-toast></p-toast>
      <div class="nodata" *ngIf="activeLocations.length===0">
        <p>No location exists to manage Stock, please add location first!</p>
        <p-button (click)="gotoaddlocation()">Add Location</p-button>
      </div>
      <div class="card" *ngIf="activeLocations.length>0">
        <div class="p-float-label locationDropDown" *ngIf="activeLocations.length>1">
          <p-dropdown [style]="{ width: '100%', 'min-width': '120px' }" class="p-dropdown-1" [options]="activeLocations"
            [(ngModel)]="selectedLocation" optionLabel="locationName"></p-dropdown>
          <label for="multiselect">Selection Location</label>
        </div>
        <br>

        <p>

          <p-radioButton name="importoption" value="skip" label="Skip existing products" [(ngModel)]="importOption"
            aria-selected="true"></p-radioButton>
          <br>
          <p-radioButton name="importoption" value="updateProducts" label="Overwrite Matching Products"
            [(ngModel)]="importOption"></p-radioButton>
          <br>

          <p-radioButton name="importoption" value="updateOnlyStocks" label="Only Update Stock for matching products"
            [(ngModel)]="importOption"></p-radioButton>
        </p>
        <p-fileUpload name="myfile[]" [chooseLabel]="'Choose import file'" [customUpload]="true" (uploadHandler)="uploaderFn($event)" accept=".csv"
          maxFileSize="5000000">
          <ng-template pTemplate="toolbar">
            <div><br>Max File size : 5MB</div>
          </ng-template>
          <ng-template let-file pTemplate="file">
            <div class="filepan" style="color:#444">{{file.name}} - {{file.size}} bytes</div>
          </ng-template>
          <ng-template pTemplate="content" let-files>
            <div></div>
          </ng-template>
        </p-fileUpload>



      </div>
    </div>

    <div class="col-4">
      <h4>CSV Template</h4>
      <p>In order to upload inventory via CSV file, you must follow CSV Template.</p>
      <!-- <p> <a href="https://cloudstoragesvc.blob.core.windows.net/commonfiles/Production/ImportProductsCSVTemplate.csv" target="_blank">Download Import Template File.</a></p> -->
      <p> <a href="https://cloudstoragels.blob.core.windows.net/commonfiles/Production/ImportProductsCSVTemplate.csv" target="_blank">Download Import Template File.</a></p>
      <h4>Guide to prepare CSV file </h4>
      <p><a href="https://cloudstoragesvc.blob.core.windows.net/commonfiles/Production/ConnectImportInventoryInstructions.docx" target="_blank">Download Guide</a></p>

    </div>

  </div>
</div>