<!-- <div class="conn-grid-2x"> -->
    <div>
        <div>
            <div>
                <br />
                <form
                    [formGroup]="printingConfigurationform"
                    (ngSubmit)="printingConfigurationform.valid && saveFn()"
                    autocomplete="off"
                >

                    <div class="mb-4">
                        <p-inputSwitch [formControl]="$any(printingConfigurationform.controls)['enableConfigurationForm']" inputId="rememberme8"></p-inputSwitch>
                        <label class="control-label" for="rememberme8"><strong> Enable Printing</strong></label>
                    </div>
                    <br/>

                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['receiptPrinting']"
                            inputId="rememberme1"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme1">Enable Receipt Printing</label>
                    </div>
                    <br/>

                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['paymentPrinting']"
                            inputId="rememberme2"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme2">Enable Electronic Payment Printing</label>
                    </div>
                    <br/>

                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['cashdropPrinting']"
                            inputId="rememberme3"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme3">Enable Cash Drop Printing</label>
                    </div>
                    <br/>

                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['payinPrinting']"
                            inputId="rememberme11"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme11">Enable PayIn Printing</label>
                    </div>
                    <br/>
                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['payoutPrinting']"
                            inputId="rememberme12"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme12">Enable PayOut Printing</label>
                    </div>
                    <br/>
                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['cashCountPrinting']"
                            inputId="rememberme4"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme4">Enable Cash Count Printing</label>
                    </div>
                    <br/>
                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['isEnableReprint']"
                            inputId="rememberme15"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme15">Enable Reprint Functionality</label>
                    </div>
                    <br/>
                    <div class="flex flex-column" *ngIf="f.isEnableReprint.value === true">
                        <label class="control-label mb-2"><strong>Reprint Type
                       </strong></label>
                        <p-dropdown
                        [style]="{width:'100%'}"
                        [formControl]="$any(printingConfigurationform.controls)['reprintType']"
                        class="p-dropdown-1"
                        [options]="rePrintType"
                        optionLabel="title"
                        appendTo="body"
                        optionValue="value"
                    >
                    </p-dropdown>
                    </div>
                    <br *ngIf="f.isEnableReprint.value === true" />
                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['printAdditionalReceipt']"
                            inputId="rememberme13"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme13">Print Additional Receipt</label>
                    </div>
                    <br/>
                    <div class="flex flex-column" *ngIf="f.printAdditionalReceipt.value === true">
                        <label class="control-label mb-2"><strong>No. of Invoice Copies to Print
                       </strong></label>
                        <p-dropdown
                        [style]="{width:'100%'}"
                        [formControl]="$any(printingConfigurationform.controls)['invoicePrintCopyCount']"
                        class="p-dropdown-1"
                        [options]="noOfCopiesPrint"
                        optionLabel="title"
                        appendTo="body"
                        optionValue="value"
                    >
                    </p-dropdown>
                    </div>
                    <br *ngIf="f.printAdditionalReceipt.value === true" />
                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['printBackOfficeCopy']"
                            inputId="rememberme14"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme14">Print Backoffice Copy</label>
                    </div>
                    <br/>
                    <div class="flex flex-column" *ngIf="f.printBackOfficeCopy.value === true">
                        <label class="control-label mb-2"><strong>Backoffice Copy Print
                       </strong></label>
                        <p-dropdown
                        [style]="{width:'100%'}"
                        [formControl]="$any(printingConfigurationform.controls)['backOfficeCopyPrinter']"
                        class="p-dropdown-1"
                        [options]="backOfficieCopy"
                        optionLabel="title"
                        appendTo="body"
                        optionValue="value"
                    >
                    </p-dropdown>
                    </div>
                    <br *ngIf="f.printBackOfficeCopy.value === true" />
                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['barcodePrinting']"
                            inputId="rememberme6"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme6">Enable Barcode Printing On Receipt</label>
                    </div>
                    <div class="flex" style="list-style: none; margin: 20px;">
                        <li>
                            <p-image src="https://cloudstoragesvc.blob.core.windows.net/commonfiles/DefaultImages/SampleQRCode.png" alt="QR Code" ></p-image>
                        </li>
                        <li>
                            <p-image src="https://cloudstoragesvc.blob.core.windows.net/commonfiles/DefaultImages/SampleBarcode.png" alt="Barcode"></p-image>
                        </li>
                    </div>
                    <!-- <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['voidItemPrinting']"
                            inputId="rememberme5"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme5">Show Void Item On Receipt</label>
                    </div> -->
                    <br/>

                    <div class="ml-6">
                        <h5>Footer Text</h5>
                        <textarea [formControl]="$any(printingConfigurationform.controls)['footerText']"
                         rows="5" cols="30" pInputTextarea></textarea><br/>
                         <span class="control-description text-muted"> Reboot of POS App required for Sync </span>
                    </div>

                    <div
                        class="flex justify-content-end flex-wrap card-container green-container mt-3"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <button
                                pButton
                                pRipple
                                type="submit"
                                label="Save"
                                [disabled]="!printingConfigurationform.valid"
                            ></button>
                        </div>
                        <div
                            class="flex align-items-center justify-content-center ml-2"
                            *ngIf ="singupBusinessConfig == true"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                label="Cancel"
                                class="p-button-outlined p-button-danger"
                                (click)="cancelButtonClick()"
                            ></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
