import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Subscription } from 'rxjs';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { AuditTrialService } from 'src/app/_services/audit-trial.service';
import { BaseService } from 'src/app/_services/base.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-audit-trial',
  templateUrl: './audit-trial.component.html',
  styleUrls: ['./audit-trial.component.scss']
})
export class AuditTrialComponent implements OnInit  {

  _globals = GlobalService
  page = 0
  rows = 10
  totalRecords = 0
  selectedRow:any = '';
  showDetail:boolean = false;
  locationIds: any[];
  activityTypeIds: any[];
  operationTypeIds: any[];
  usersIds: any[];
  aduitTrialList: any = []
  
  //order
  orderParam = ''
  orderBy = 'asc'
  @ViewChild('p', { static: false }) paginator: Paginator;
  actionType:string = null
  colheader = [[
    'User Name', 'Location', 'Date-Time','Activity Type', 'Operation'
    , 'Old Details', 'Modification Details'
  ]];

  colflds = ['userName', 'location', 'date', 'activityType',
    'operation','oldDetails','modificationDetails' ];
  searchText: string;
  usersList: any;
 isUserListLoad:boolean = false;
  constructor(
    public _customerService: CustomersService,
    private _spinner: NgxSpinnerService,
    public _auditTrialServices:AuditTrialService,
    public _filterService:FilterServiceService,
    public _abd:AppBaseDataService,
    public _userService:UserService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Audit Trial'
    if(!this._filterService.dateRange || this._filterService.dateRange.length<1){
      this._filterService.initDates()
    }
    this.getDataList()
    this.getUsersList()
  }
  getDataList() {
    try {
        this._abd.showSpinner();
        this.searchText = this._filterService.searchText;
        // let params = new HttpParams();

        this.page = this.page < 1 ? 0 : this.page;
        this.rows = this.rows < 1 ? 10 : this.rows;
        // const outfilter = {
        //     searchText: this.searchText ? this.searchText : '',
        // };
        let obj ={
          'pageSize': this.rows,
          'pageNo':this.page,
          'eventType':this._filterService.selectedActivityType,
          'operationType':this._filterService.selectedOperationType,
          "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
          "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),

        }

      this._auditTrialServices.getAuditTrialSummaryList(obj).subscribe((res) => {
            this._abd.hideSpinner();
            if (res.success) {
            this.aduitTrialList = res.data;
           this.totalRecords = res.totalRecords;
         
            }
        });
    } catch {
      this._abd.hideSpinner();
    }
}
getUsersList() {

  const obj:any = {
    "Name": "GETALLEMPLOYEES",
    "User": {
      "ParentID": (this._globals?.userInfo.parentID != '' ? this._globals.userInfo.userID : this._globals.userInfo.parentID),
      "CompanyName": this._globals?.userInfo.businessSetup[0].businessName.toUpperCase()
    },
    "PageNo": 0,
    "PageSize": 999999,
  }
   setTimeout(() => {
    try {
     this._userService.getAllUsers(obj)
    .subscribe((res) => {
   this.usersList = res.data
   this.isUserListLoad =true
    })
    } catch { 
      this.usersList=[]
    }
  }, 500);

}
getUserWithId(email:any){
  if(this.isUserListLoad && email !== undefined){
    return this.usersList.filter((v)=>v.email === email)[0]?.displayName
  }else{
    return;
  }
}
  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)
    this.getDataList()
  }
  filterButtonPressed() {
    this.page = 0
    this.paginator?.changePage(0)
    this.getDataList()
  }
  customSort(e) {
    const last = this.orderParam

    switch (e.sortField) {
      case 'itemName':
        this.orderParam = 'title'
        break;
      case 'barCode':
        this.orderParam = 'Barcode'
        break;
      case 'skuCode':
        this.orderParam = 'SkuCode'
        break;
      case 'stockInhand':
        this.orderParam = 'StockInhand'
        break;
      case 'minOrderQty':
        this.orderParam = 'MinOrderQty'
        break;
      case 'maxOrderQty':
        this.orderParam = 'MaxOrderQty'
        break;
      case 'orderQuantity':
        this.orderParam = 'OrderQuantity'
        break;
      case 'poStatus':
        this.orderParam = 'PoStatus'
        break;
      default:
        this.orderParam = ''
    }

    if (this.orderParam !== '') {
      this.orderBy = this.orderBy == 'asc' ? 'desc' : 'asc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }
  onRowSelect(e) {
    this._auditTrialServices.selectedAudit = e.data;
   this.actionType = e.data?.eventType
    this.showDetail = true

  }
  callbackFuncFromFilter(event) {
    this.getDataList()
  }
  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;

    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }
  getActivityType() {
    let self = this;
    this.activityTypeIds = [];
    let selA = this._filterService.selectedActivityType;
    selA = (typeof selA != 'undefined' && selA instanceof Array) ? selA : [selA];
    selA.map(function (value: { value: { toString: () => string; }; }) {
      self.activityTypeIds.push(value.value.toString())
    })
  }
  getOperationType() {
    let self = this;
    this.operationTypeIds = [];
    let selO = this._filterService.selectedOperationType;
    selO = (typeof selO != 'undefined' && selO instanceof Array) ? selO : [selO];
    selO.map(function (value: { value: { toString: () => string; }; }) {
      self.operationTypeIds.push(value.value.toString())
    })
  }
  getUsers() {
    let self = this;
    this.usersIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.usersIds.push(value?.locationId.toString())
    })
  }
  exportExcel() {
    let data =this.aduitTrialList.map((v)=>{
      return{
        ...v,product:v.productName+"\n"+v.barcode+"\n"+v.sku
      }
     })
    this._abd.exportExcel(data, this.colheader[0], this.colflds, 'Audit Trial')
  }

  exportPdf() {
 let data =this.aduitTrialList.map((v)=>{
  return{
    ...v,product:v.productName+"\n"+v.barcode+"\n"+v.sku
  }
 })
    this._abd.exportPdf(data, this.colheader, this.colflds, 'Audit Trial',false,false,false)
   
  }
}
