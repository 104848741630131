<div>
    <!-- <label class="control-label"
        ><strong>
            Customer Group
            <span style="color: red; font-size: 1.25rem">*</span>
        </strong></label> -->
    <!-- <br /> -->
    <!-- (ngSubmit)="editform.valid && saveFn()" -->
    <form [formGroup]="editform" (ngSubmit)="editform.valid" autocomplete="off" >
      <div class="flex justify-content-around">
            <span class="px-2 col-4">
       <label class="control-label"
        ><strong>
            Customer Group
            <span style="color: red; font-size: 1.25rem">*</span>
        </strong></label>
    <br />
        <app-text-input
            formControlName="customerGroupName"
            [label]=""
            [name]="'Customer Group Name'"
            [description]="'Please enter a Customer Group Name'"
        ></app-text-input>
       </span>
      
       <span class="px-2 col-4">
       <label class="control-label"
        ><strong>
            Description 
            <span style="color: red; font-size: 1.25rem">*</span>
        </strong></label>
    <br />
        <app-text-input
            formControlName="description"
            [label]=""
            [name]="'Customer Group Description'"
            [description]="'Please enter a Customer Group Description'"
        ></app-text-input>
       </span>
       <span class="px-2 col-4">
        <label class="control-label mb-2"
         ><strong>
             Price
             <span style="color: red; font-size: 1.25rem">*</span>
         </strong></label>
     <br />
     <p-dropdown
     formControlName="price"
     [style]="{width:'100%','margin-top': '8px',height:'46px'}"
     class="p-dropdown-1"
     [options]="priceList"
     optionLabel="name"
     placeholder=" "
     [dropdownIcon]="isLoading ? 'pi pi-spin pi-spinner':'pi pi-chevron-down'"
 ></p-dropdown>
         <!-- <app-text-input
             formControlName="customerGroupName"
             [label]=""
             [name]="'Customer Group Name'"
             [description]="'Please enter a Product Price'"
         ></app-text-input> -->
        </span>
      </div>
    
 
        <br />

        <div
            class="flex justify-content-end flex-wrap card-container green-container mt-3"
        >
            <div class="flex align-items-center justify-content-center">
                <button
                    *ngIf="this.whatAction == 'Edit'"
                    pButton
                    pRipple
                    type="button"
                    (click)="saveFn()"
                    [label]="whatAction"
                ></button>

                <button
                    *ngIf="this.whatAction == 'Save'"
                    pButton
                    pRipple
                    type="submit"
                    [disabled]="
                        (!editform.valid || _customerService.isViewOnly) &&
                        this.whatAction !== 'Save'
                    "
                    [label]="whatAction"
                ></button>
                <!-- [disabled]="!editform.valid || _customerService.isViewOnly" -->
            </div>
        </div>
    </form>
</div>
<app-report-top-filter-bar
    [paraReportName]="'customer-group-list'"
    (filterCallbackFunc)="callbackFuncFromFilter($event)"
></app-report-top-filter-bar>
    <p-table
        [value]="dataList"
        [(selection)]="selectedProducts4"
        dataKey="code"
        responsiveLayout="scroll"
        [rows]="rows"
        [selectionPageOnly]="true"
        [loading]="loadingCustomer"
    >
     <ng-template pTemplate="header">
         <tr>
             <th style="width: 3rem">
                <p-checkbox
                [(ngModel)]="selectAll"
                [binary]="true"
                label=""
                (onChange)="selectAllChanged($event)"
            ></p-checkbox>

             </th>
            <th style="text-align: center">Name</th>
            <th style="text-align: center">Phone</th>
            <th style="text-align: center">City</th>
            <th style="text-align: center">State</th>
            <th style="text-align: center">Zip</th>
         </tr>
     </ng-template>
     <ng-template pTemplate="body" let-item>
        <tr>
            <td>
                <p-checkbox
                    [(ngModel)]="item.selected"
                    [binary]="true"
                    (onChange)="selectionChanged($event,item)"
                ></p-checkbox>

            </td>
            <td> {{ item.firstName + " " + item.lastName }}</td>
            <td>{{ _globals.generatePhoneFormatUS(item.phone) }}</td>
            <td style="text-align: center">{{ item.city }}</td>
            <td style="text-align: center">{{ item.state }}</td>
            <td style="text-align: center">{{ item.postalCode }}</td>
         </tr>
     </ng-template>
 </p-table>
 <p-paginator
 #p
 *ngIf="dataList?.length > 0"
 (onPageChange)="onPageChange($event)"
 [rows]="rows"
 [totalRecords]="totalRecords"
 [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
 [showCurrentPageReport]="true"
 currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"
 ></p-paginator>
 <ng-container *ngIf="dataList?.length">
    <p-divider></p-divider>
    <div class="flex justify-content-end flex-wrap card-container">
        <p class="required" *ngIf="showError && editform.controls.customerGroupName.status == 'INVALID'">Please provide customer
            group name</p>
        <div class="flex align-items-center justify-content-center" style="margin-left: 20px">

            <button
                *ngIf="!_customerService.isViewOnly"
                pButton
                type="button"
                label="Save"
                (click)="saveGroupName()"
                ></button>
                <!-- [disabled]="!editform.valid" -->
        </div>
        <div
            class="flex align-items-center justify-content-center"
            style="margin-left: 20px"
        >
            <button
                pButton
                pRipple
                type="button"
                label="Cancel"
                class="p-button-outlined p-button-danger"
                (click)="cancelCustomerGroupModal()"
            ></button>
        </div>
    </div>
</ng-container>

