
    <div class="conn-card" style="width: 100%">
        <div class="conn-grid">
            <div>
                <h2 class="bs-page-header">
                    Auto PO
                </h2>
             
            </div>
        
            <div
                style="margin-top: 5px"
            >
                <div class="child2">
        
                    <div
                        class="p-float-label hide-1024"
                        *ngIf=" _abd.initActLocations()?.length > 1
                        "
                    >
                        <p-dropdown
                            [style]="{
                                height: '46px',
                                width: '100%',
                                'min-width': '200px'
                            }"
                            class="p-dropdown-1"
                            [options]="_abd.locations"
                            [(ngModel)]="_filterService.selectedLocation"
                            optionLabel="locationName"
                            [showClear]="canClearShowlocationsDropdown"
                            placeholder=" "
                        ></p-dropdown>
                        <label for="">Locations</label>
                    </div>
                    <!-- Vendors -->
                    <div
                    class="p-float-label hide-1024">
                    <p-dropdown
                        [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
                        class="p-dropdown-1"
                        appendTo="body"
                        [options]="_abd.vendors"
                        [(ngModel)]="_filterService.selectedVendor"
                        optionLabel="vendorName"
                        [showClear]="true"
                        placeholder=" "
                    ></p-dropdown>
                    <label for="multiselect">Vendors</label>
                </div>
                <!-- /Vendors -->
                
        
        
                    <button
                        [style]="{ 'margin-left': '-7px' }"
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-search"
                        class="p-button-text"
                        (click)="filterButtonPressed()"
                    ></button>

                </div>
            </div>
        </div>
        <!-- <app-report-top-filter-bar
            [paraReportName]="'auto-generate-purchase-order'"
            (filterCallbackFunc)="callbackFuncFromFilter()"
        >
        </app-report-top-filter-bar> -->

        <br />
        <div style="overflow: auto;">
               <p-table
            #dt
            styleClass="p-datatable-striped"
            [autoLayout]="true"
            styleClass="p-datatable-striped"
            [tableStyle]="{ width: 'max-content' }"
            [value]="dataList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
            selectionMode="single"
            [(selection)]="selectedRow"
            dataKey="id"
            [lazy]="true"
            (onLazyLoad)="customSort($event)"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center">
                        <p-checkbox
                            [(ngModel)]="selectAll"
                            [binary]="true"
                            (onChange)="selectAllChanged($event)"
                        >
                        </p-checkbox>
                    </th>
                    <th pSortableColumn="itemName" style="text-align: center">
                        Product<p-sortIcon field="itemName"></p-sortIcon>
                    </th>
                    <!-- <th pSortableColumn="barCode" style="text-align: center">
                        Barcode<p-sortIcon field="barCode"></p-sortIcon>
                    </th> -->
                    <th pSortableColumn="skuCode" style="text-align: center">
                        SKU<p-sortIcon field="skuCode"></p-sortIcon>
                    </th>
                    <th pSortableColumn="stockInhand" style="text-align: center">Quantity on Hand <p-sortIcon field="stockInhand"></p-sortIcon></th>
                    <th pSortableColumn="minOrderQty" style="text-align: center">Min Order Quantity <p-sortIcon field="minOrderQty"></p-sortIcon></th>
                    <th pSortableColumn="maxOrderQty" style="text-align: center">Max Order Quantity <p-sortIcon field="maxOrderQty"></p-sortIcon></th>
                    <th pSortableColumn="orderQuantity" style="text-align: center">Order Quantity <p-sortIcon field="orderQuantity"></p-sortIcon></th>
                    <!-- <th style="text-align: center">Min Stock</th> -->
                     <!--<th style="text-align: center">Max Stock</th> -->
                    <th pSortableColumn="poStatus" style="text-align: right">PO Status <p-sortIcon field="poStatus"></p-sortIcon></th>
               
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr
                    class="text-center"
                    [ngClass]="item.selected == true ? 'p-highlight' : ''"
                >
                    <td style="text-align: center">
                        <p-checkbox
                            [(ngModel)]="item.selected"
                            [binary]="true"
                            (onChange)="selectionChanged($event)"
                        >
                        </p-checkbox>
                    </td>
                   
                    <td style="text-align: center">{{ item.title }}</td>
           
                    <!-- <td style="text-align: center">{{ item.barcode}}</td> -->
                    <td style="text-align: center">{{ item.skuCode }}</td>
                    <td style="text-align: center">{{ item.stockInhand }}</td>
                    <td style="text-align: center">{{ item.minOrderQty }}</td>
                    <td style="text-align: center">{{ item.maxOrderQty }}</td>
                    <td style="text-align: center">{{ item.orderQuantity }}</td>
                    <!-- <td style="text-align: center">{{ item.minLevel }}</td> -->
                    <!-- <td style="text-align: center">{{ item.maxLevel }}</td> -->
                    <td style="text-align: center">{{ item.poStatus }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="10" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>

            </ng-template>
        </p-table> 
        </div>
    

        <p-paginator
        *ngIf="dataList?.length > 0"
            [first]="first"
            (onPageChange)="onPageChange($event)"
            [rows]="rows"
            [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"
            #p
        >
        </p-paginator>
    </div>


<div class="grid" >
    <div class="col">
        <div
            class="flex justify-content-between flex-wrap card-container green-container mt-3"
        >
     <div class="flex align-items-center justify-content-center">
        <span>
           Max Stock - ( Stock-In Hand + Already Orderd)
        </span>
     </div>     
     <div  class="flex align-items-center justify-content-center">
                <div
                class="flex align-items-center justify-content-center mr-2"
            >
                <button
                    pButton
                    pRipple
                    type="button"
                    label="Cancel"
                    class="p-button-outlined p-button-danger"
                   [style]="{ width: '120px' }"
                   (click)="ClosePO()"
                ></button>
            </div>  
            <div class="flex align-items-center justify-content-center">
                <button
                    pButton
                    pRipple
                    type="submit"
                    [label]="'Create PO'"
                    (click)="createPO()"
                    [disabled]="_purchaseService.poSelectedList.length < 1"
                    
                ></button>
            </div>
     </div>
    
        </div>
    </div>
</div>     