<div class="conn-grid-col">
    <div>
        <h4 style="font-weight: normal" class="mt-0">Filters</h4>
    </div>

    <!-- <div class="conn-grid-col" *ngIf="paraReportName === 'inventory-report'"> -->
        <div class="p-float-label hide-1024" *ngIf="showProductSearchTextInput">
            <input
                type="text"
                id="ProductSearchTextInput"
                [(ngModel)]="_filterService.ProductSearchTextInput"
                pInputText
                [style]="{
                    'min-width': '200px',
                    'font-size': '85.rem'
                }"
                (ngModelChange)="onSearchTextChanged()"
            />
            <label for="ProductSearchTextInput">{{ searchTextLabel }}</label>
        </div> 
        <div class="p-float-label" *ngIf="showSearchText">
        <input
            type="text"
            pInputText
            [(ngModel)]="_filterService.searchText"
            [style]="{ 'min-width': '100%' }"
            (ngModelChange)="onSearchTextChanged()"
        />
        <label for="multiselect">{{ searchTextLabel }}</label>
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="
            showLocationsMultiselect && baseFilterOptions.locations?.length > 1
        "
    >
        <p-multiSelect
            [options]="baseFilterOptions.locations"
            [(ngModel)]="_filterService.selectedLocation"
            optionLabel="locationName"
            display="chip"
            appendTo="body"
            [style]="{ width: '100%', 'min-width': '120px' }"
        ></p-multiSelect>
        <label for="multiselect">Locations</label>
    </div>
   <!-- Activity Type -->
    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showActivityType"
    >
    <p-dropdown
    [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
    class="p-dropdown-1"
    appendTo="body"
    [options]="_auditTrialService?.activityType"
    [(ngModel)]="_filterService.selectedActivityType"
    optionLabel="value"
    optionValue="value"
></p-dropdown>
    <!-- <p-multiSelect
            [options]="_auditTrialService?.activityType"
            [(ngModel)]="_filterService.selectedActivityType"
            optionLabel="title"
            display="chip"
            appendTo="body"
            [style]="{ width: '100%', 'min-width': '120px' }"
        ></p-multiSelect> -->
        <label for="multiselect">Activity Type</label>
    </div>
<!--  /Activity Type -->
   <!-- Opertion Type -->
    <div
    
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showOperationType"
    >
    <p-dropdown
    [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
    class="p-dropdown-1"
    appendTo="body"
    [options]="_auditTrialService?.operationType"
    [(ngModel)]="_filterService.selectedOperationType"
    optionLabel="name"
    optionValue="value"
></p-dropdown>
<!-- <p-multiSelect
            [options]="_auditTrialService?.operationType"
            [(ngModel)]="_filterService.selectedOperationType"
            optionLabel="title"
            display="chip"
            appendTo="body"
            [style]="{ width: '100%', 'min-width': '120px' }"
        ></p-multiSelect> -->
        <label for="multiselect" >Opertion Type</label>
    </div>
<!--  /Opertion Type -->
   <!-- Select User -->
    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showUsers"
    >
    <p-dropdown
    [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
    class="p-dropdown-1"
    appendTo="body"
    [options]="usersList"
    [(ngModel)]="_filterService.selectedUser"
    optionLabel="displayName"
    [autoDisplayFirst]="false"
></p-dropdown>
    <!-- <p-multiSelect
            [options]="usersList"
            [(ngModel)]="_filterService.selectedUser"
            optionLabel="displayName"
            display="chip"
            appendTo="body"
            [style]="{ width: '100%', 'min-width': '120px' }"
        ></p-multiSelect> -->
        <label for="multiselect">Select User</label>
    </div>
<!--  /Select User -->
    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showLocationsDropdown && baseFilterOptions.locations?.length > 1"
    >
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="baseFilterOptions.locations"
            [(ngModel)]="_filterService.selectedLocation"
            optionLabel="locationName"
            [showClear]="true"
            placeholder=" "
        ></p-dropdown>
        <label for="multiselect">{{ locationDropdownText }}</label>
    </div>
    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="
            showExtra1LocationsDropdown &&
            baseFilterOptions.locations?.length > 1
        "
    >
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="baseFilterOptions.locations"
            [(ngModel)]="_filterService.selectedExtra1Location"
            optionLabel="locationName"
            [showClear]="true"
            placeholder=" "
        ></p-dropdown>
        <label for="multiselect">{{ locationExtra1DropdownText }}</label>
    </div>
    <!-- <br /> -->
    <div
        class="p-float-label"
        *ngIf="showProductTypeDropDown"
        style="margin-top: 30px"
    >
        <p-dropdown
            [style]="{
                height: '46px',
                width: '100%',
                'min-width': '120px'
            }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="_abd.productSellTypeFilter"
            [(ngModel)]="_filterService.selectedProductSellTypeFilter"
            optionLabel="Type"
            [showClear]="false"
            placeholder=" "
        ></p-dropdown>
        <label for="multiselect">{{ labelProductTypeDropDown }}</label>
    </div>
    <div class="p-float-label" *ngIf="showSelectedInvAdj">
        <p-dropdown
            [style]="{
                height: '46px',
                width: '100%',
                'min-width': '120px'
            }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="_abd.inventoryAdjustmentTypes"
            [(ngModel)]="_filterService.selectedInventoryAdjustment"
            optionLabel="title"
            [showClear]="true"
            placeholder=" "
        >
        </p-dropdown>
        <label for="multiselect23">Adjustment Type</label>
    </div>
    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showDepartmentDropdown"
    >
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="_abd.actDepartments"
            [(ngModel)]="_filterService.selectedDepartment"
            optionLabel="departmentName"
            [showClear]="true"
            placeholder=" "
        ></p-dropdown>
        <label for="multiselect">Departments</label>
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showDepartmentDropdownMultiselect"
    >
        <p-multiSelect
            display="chip"
            appendTo="body"
            [options]="_abd.actDepartments"
            [(ngModel)]="_filterService.selectedDepartment"
            optionLabel="departmentName"
            [style]="{ width: '100%', 'min-width': '120px' }"
        ></p-multiSelect>
        <label for="multiselect">Departments</label>
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showGroupByDropdown"
    >
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="_abd.groupby"
            [(ngModel)]="_filterService.selectedGroup"
            optionLabel="title"
            [showClear]="true"
            placeholder=" "
        ></p-dropdown>
        <label for="multiselect">Group By</label>
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showChannelByDropdown"
    >
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="_abd.channel"
            [(ngModel)]="_filterService.selectedChannel"
            optionLabel="title"
            [showClear]="false"
            placeholder=" "
        ></p-dropdown>
        <label for="multiselect">Apply By</label>
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showTransferTypeByDropdown"
    >
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="_abd.transferType"
            [(ngModel)]="_filterService.selectedTranferType"
            optionLabel="title"
            [showClear]="false"
            placeholder=" "
        ></p-dropdown>
        <label for="multiselect">Transfer Type</label>
    </div>

    <div class="p-float-label" style="margin-top: 30px" *ngIf="showTenderType">
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="_abd.tenderType"
            [(ngModel)]="_filterService.selectedTenderType"
            optionLabel="label"
            [showClear]="false"
            placeholder=" "
        ></p-dropdown>
        <label for="multiselect">Tender Type</label>
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showTransferStatusByDropdown"
    >
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="_abd.transferStatus"
            [(ngModel)]="_filterService.selectedTranferStatus"
            optionLabel="title"
            [showClear]="false"
            placeholder=" "
        ></p-dropdown>
        <label for="multiselect">Transfer Status</label>
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showCatsDropdown"
    >
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="_abd.categories"
            [(ngModel)]="_filterService.selectedCategory"
            optionLabel="categoryName"
            [showClear]="true"
            placeholder=" "
        ></p-dropdown>
        <label for="multiselect">Categories</label>
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showCatsDropdownMultiselect"
    >
        <p-multiSelect
            display="chip"
            appendTo="body"
            [options]="_abd.categories"
            [(ngModel)]="_filterService.selectedCategory"
            optionLabel="categoryName"
            [style]="{ width: '100%', 'min-width': '120px' }"
        ></p-multiSelect>
        <label for="multiselect">Categories</label>
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showVendorsDropdown"
    >
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="_abd.vendors"
            [(ngModel)]="_filterService.selectedVendor"
            optionLabel="vendorName"
            [showClear]="true"
            placeholder=" "
        ></p-dropdown>
        <label for="multiselect">Vendors</label>
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showManufacturerDropdown"
    >
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="_abd.manufacturers"
            [(ngModel)]="_filterService.selectedManufacturer"
            optionLabel="manufacturerName"
            [showClear]="true"
            placeholder=" "
        ></p-dropdown>
        <label for="multiselect">Manufacturer</label>
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showTransactionTypesDropdown"
    >
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="_abd.transactionTypes"
            [(ngModel)]="_filterService.selectedTransactionType"
            optionLabel="transactionType"
            [showClear]="false"
            placeholder=" "
        ></p-dropdown>
        <label for="dropdown">Transaction Types</label>
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showTransactionTypesDropdown"
    >
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="_abd.invoiceStatus"
            [(ngModel)]="_filterService.selectedInvoiceStatus"
            optionLabel="title"
            [showClear]="false"
            placeholder=" "
        ></p-dropdown>
        <label for="dropdown">Status Type</label>
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showRegisterDropdown"
    >
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="_abd.registers"
            [(ngModel)]="_filterService.selectedRegisters"
            optionLabel="locationName"
            [showClear]="true"
            placeholder=" "
            [disabled]="_abd.registers?.length < 1"
        ></p-dropdown>
        <label for="multiselect">{{ registerDropdownText }}</label>
        <p class="text-sm text-red-300" *ngIf="_filterService.registers?.length < 1">
            no register(s) found.
        </p>
    </div>
    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showRegistersMultiselect"
    >
    <p-multiSelect
    display="chip"
    appendTo="body"
    [options]="_abd.registers"
    [(ngModel)]="_filterService.selectedRegisters"
    optionLabel="registerName"
    [style]="{ width: '100%', 'min-width': '120px' }"
    [disabled]="_abd.registers?.length < 1"
></p-multiSelect>
 
        <label for="multiselect">{{ registerDropdownText }}</label>
        <!-- <p class="text-sm text-red-300" *ngIf="_filterService.registers?.length < 1">
            no register(s) found.
        </p> -->
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showStatus && statusList?.length > 1"
    >
        <p-dropdown
            [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
            class="p-dropdown-1"
            appendTo="body"
            [options]="statusList"
            [(ngModel)]="_filterService.selectedStatus"
            optionLabel="name"
            [showClear]="true"
            placeholder=" "
        ></p-dropdown>
        <label for="multiselect">Select Status</label>
    </div>

    <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showCashier && _abd.cashiers?.length > 0"
    >
        <p-multiSelect
            [options]="_abd.cashiers"
            [(ngModel)]="_filterService.selectedCashier"
            optionLabel="firstName"
            display="chip"
            appendTo="body"
            [style]="{ width: '100%', 'min-width': '120px' }"
        ></p-multiSelect>
        <label for="multiselect">{{ labelCashier }}</label>
        <!-- <p class="text-sm text-red-300" *ngIf="showCashierMessage">
            if cashier list not shown, find from search text area.
        </p> -->
    </div>
    <!-- Employee -->
    <!-- <div
        class="p-float-label"
        style="margin-top: 30px"
        *ngIf="showEmployee && _abd.cashiers?.length > 0"
    >
        <p-multiSelect
            [options]="_abd.cashiers"
            [(ngModel)]="_filterService.selectedCashier"
            optionLabel="displayName"
            display="chip"
            appendTo="body"
            [style]="{ width: '100%', 'min-width': '120px' }"
        ></p-multiSelect>
        <label for="multiselect">Employee</label>
    </div> -->
   <!-- /Employee -->
    <div
    class="p-float-label"
     style="margin-top: 30px"
    *ngIf="showCouponCodeDiscountTypes"
>
    <p-dropdown
        [style]="{
            height: '46px',
            width: '100%',
            'min-width': '140px'
        }"
        class="p-dropdown-1"
        [options]="couponDiscountTypeList"
        [(ngModel)]="_filterService.selectedCouponCodeDiscountType"
        optionLabel="viewValue"
        [showClear]="true"
        placeholder=" "
    ></p-dropdown>
    <label for="multiselect">Discount Type</label>
     </div>
    
     <!-- <div
    class="p-float-label"
     style="margin-top: 30px"
    *ngIf="showPromotionStatus"
>
    <p-dropdown
        [style]="{
            height: '46px',
            width: '100%',
            'min-width': '140px'
        }"
        class="p-dropdown-1"
        [options]="_couponCodeService.status"
        [(ngModel)]="_filterService.selectedPromotionStatus"
        optionLabel="viewValue"
        [showClear]="false"
        placeholder=" "
    ></p-dropdown>
    <label for="multiselect">Promotion Status</label>
     </div> -->
     <!-- <div
    class="p-float-label"
     style="margin-top: 30px"
    *ngIf="showSubscriptionPlan"
>
<p-multiSelect
[options]="subscriptionPlanList"
[(ngModel)]="_filterService.selectedSubscriptionPlan"
[style]="{
    height: '46px',
    width: '100%',
    'min-width': '140px'
}"
optionLabel="viewValue"
[filter]="false"
appendTo="body"
>
<ng-template pTemplate="header">
<div class="checkbox-all-text">Select All</div>
</ng-template>
</p-multiSelect>
   <label for="multiselect">Subscription plan</label>
     </div> -->
   <div class="grid" *ngIf="showDiscountAmountRange">
 <div
    class="p-float-label col"
     style="margin-top: 30px"
>
<input
type="text"
pInputText
[(ngModel)]="_filterService.startAmount"
[style]="{ 'width': '100%' }"
/>   
    <label for="multiselect">Amount Range</label>
     </div>
     <div
    class="p-float-label col"
     style="margin-top: 30px"
    
>
<input
type="text"
pInputText
[(ngModel)]="_filterService.endAmount"
[style]="{ 'width': '100%' }"
/>   
     </div>
   </div>
    
   
     
     <div
        [style]="{ 'min-width': '100%', 'margin-top': '30px' }"
        *ngIf="showDateRange"
    >
        <div class="p-inputgroup">
            <div class="p-float-label">
                <p-calendar
                    [(ngModel)]="_filterService.dateRange"
                    selectionMode="range"
                    [readonlyInput]="false"
                    inputId="multiple"
                    class="filter"
                    baseZIndex="0"
                    appendTo="body"
                    [style]="{ 'min-width': '100%' }"
                ></p-calendar>
                <label for="multiselect">Date Range</label>
            </div>
            <button
                (click)="opd.toggle($event)"
                type="button"
                pButton
                pRipple
                icon="pi pi-angle-down"
                styleClass="p-button-warn"
            ></button>
        </div>
    </div>

    <div
        [style]="{ 'min-width': '100%', 'margin-top': '30px' }"
        *ngIf="showSingleDate"
    >
        <div class="p-inputgroup">
            <div class="p-float-label">
                <p-calendar
                    [(ngModel)]="_filterService.singleDate"
                    selectionMode="single"
                    [readonlyInput]="false"
                    inputId="multiple"
                    class="filter"
                    baseZIndex="0"
                    appendTo="body"
                    [style]="{ 'min-width': '100%' }"
                ></p-calendar>
                <label for="multiselect">Date</label>
            </div>
        </div>
    </div>

    <!-- <div *ngIf="showSingleDate">
        <p-calendar
            [(ngModel)]="_filterService.singleDate"
            selectionMode="single"
            [readonlyInput]="false"
            inputId="multiple"
            class="filter"
            baseZIndex="0"
            appendTo="body"
            [style]="{ 'min-width': '100%' }"
        >
        </p-calendar>
        <label for="multiselect">Date</label>
    </div> -->

    <div *ngIf="showSearchButton">
        <button
            pButton
            type="button"
            label="Search"
            icon="pi pi-search"
            (click)="filterButtonPressed()"
            iconPos="right"
            [style]="{ 'margin-top': '30px', 'min-width': '100%' }"
        ></button>
    </div>

    <div class="timeselector">
        <p-overlayPanel #opd>
            <p-menu
                #cm
                [model]="items"
                (click)="opd.hide()"
                [style]="{ 'min-width': '200px' }"
            ></p-menu>
        </p-overlayPanel>
    </div>
    <!-- </div> -->
</div>
