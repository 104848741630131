<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            <button
                pButton
                pRipple
                type="button"
                label="Edit"
                class="p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-pencil"
                iconPos="right"
                (click)="editButtonClicked()"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Change Status"
                [style]="{ width: '100%' }"
                class="p-button-danger p-button-text"
                icon="pi pi-undo"
                iconPos="right"
                (click)="statusButtonClicked()"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Delete"
                [style]="{ width: '100%' }"
                class="p-button-danger p-button-text"
                icon="pi pi-trash"
                iconPos="right"
                (click)="deleteButtonClicked()"
            ></button>
        </div>
    </ng-template>
</p-overlayPanel>
<div>
<!-- Top Header -->
<p-divider
align="left"
type="dashed"
[style]="{ 'padding-left': '1rem', 'margin-top': '.02rem' }"
>
<div class="inline-flex align-items-center">
     <button
        pTooltip="Add New Points Accumulation"
        pButton
        pRipple
        type="button"
        icon="pi pi-plus"
        class="p-button-rounded p-button-warning p-button-text add-btn"
        (click)="addNewClick()"
    ></button>
    <b
        style="margin-left: 5px; color: var(--action-button)"
        class="add-btn"
        (click)="addNewClick()"
        >Add New Points Accumulation </b
    >

</div>
</p-divider>
<!--/Top Header -->
    <br />
    <p-table
        #dt
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        *ngIf="dataList?.length > 0"
        [autoLayout]="true"
        responsiveLayout="scroll"
        [paginator]="false"
        [rows]="rows"
        [customSort]="true"
        (onLazyLoad)="customSort($event)"
        [lazy]="true"
        selectionMode="single"
        (onRowSelect)="onRowSelect($event)"
    >
        <ng-template pTemplate="header">
            <tr>
                <th  style="text-align: center">Customer Group Name</th>
                <th  style="text-align: center">Points Value <i class="pi pi-info-circle text-sm" pTooltip="Define value of each dollar to points i.e. $1 = X Points"></i></th>
                <th  style="text-align: center">Points Accumulation <i class="pi pi-info-circle text-sm" pTooltip="Minimum purchase needed for points accumulation i.e. X Dollars"></i></th>
                <th  style="text-align: center">Max Points Allowed <i class="pi pi-info-circle text-sm" pTooltip="Maximum limit to points accumulation"></i></th>
                <th  style="text-align: center">Point Expiry</th>
                <th  style="text-align: center">Status</th>
                <!-- <th pSortableColumn="status" style="text-align: center">Status<p-sortIcon field="status"></p-sortIcon></th> -->
                <th style="text-align: center">Action</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item >
            <tr [pSelectableRow]="item" class="text-center">
                <td style="text-align: center">{{ getCustomerNames(item.customerGroup) }}</td>
                <td style="text-align: center">{{ item.minSpendAmountReward }}</td>
                <td style="text-align: center">{{ item.minCartValue }}</td>
                <td style="text-align: center">{{ item.maxAllowedPoints }}</td>
                <td style="text-align: center">{{ item.pointsExpireString }}</td>
                <td style="text-align: center">
                    <span *ngIf="item.active == false" class="disable">Disable</span>
                    <span *ngIf="item.active == true" class="enable">Enable</span>
                </td>
                <td style="text-align: center">
                    <button
                        pButton
                        pRipple
                        icon="pi pi-angle-down"
                        iconPos="right"
                        type="button"
                        label="Actions"
                        class="n-action-button"
                        (click)="op.toggle($event)"
                        (click)="actionButtonClick(item)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length == 0">
        <div class="text-align-center" style="width: 100%">
            <span class="control-description text-muted text-align-center">
                No data found!</span
            >
        </div>
    </div>
    <div>
        <p-paginator
            #p
            [first]="first"
            *ngIf="dataList?.length"
            (onPageChange)="onPageChange($event)"
            [rows]="rows"
            [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Records: {first} of {last}, Total Records: {totalRecords}"
        >
        </p-paginator>
    </div>
</div>

<p-dialog
    *ngIf="_rewardProgramService.showEditForm"
    header="Edit Points Accumulation"
    [(visible)]="_rewardProgramService.showEditForm"
    [style]="{ width: '60%' }"
    modal="true"
>
    <app-points-accumulation-form
        [isNew]="false"
        [isViewOnly]="isViewOnly"
        (onSubmit)="refrestDataList($event)"
    ></app-points-accumulation-form>
</p-dialog>
<ng-container *ngIf="_rewardProgramService.showNewEditForm">
    <p-dialog
        header="Add New Points Accumulation"
        [(visible)]="_rewardProgramService.showNewEditForm"
        [style]="{ width: '60%' }"
        modal="true"
    >
        <app-points-accumulation-form
            [singupBusinessConfig]="true"
            [isNew]="true"
            [isViewOnly]="false"
            (onSubmit)="refrestDataList($event)"
        ></app-points-accumulation-form>
    </p-dialog>
</ng-container>
