import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import jsPDF from "jspdf";
import "jspdf-autotable";
import { FilterServiceService } from 'src/app/_services/filter-service.service';

@Component({
  selector: 'app-daily-cash-summary-bd',
  templateUrl: './daily-cash-summary-bd.component.html',
  styleUrls: ['./daily-cash-summary-bd.component.scss']
})
export class DailyCashSummaryBdComponent implements OnInit {
  page = 0
  rows = 10
  totalRecords = 0

  tables: any = null
  cashDrops: any = null
  cashDropTotal = 0

  topTags: any = null
  tenderType =
    {
      cash: 1,
      creditCard: 3,
      zelle: 17,
      venomo: 18,
      paypal: 19
    }

  _globals = GlobalService

  addedAmount: number = 0
  removedAmount: number = 0

  constructor(
    private _bs: BaseService,
    public _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    if(!this._filterService.dateRange || this._filterService.dateRange.length<1)
    this._filterService.initDates()

    this._globals.componentName = 'Cash Report (Detail)'
    this.fillInitData()
    this.getDataList()
    //this.dummyData()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  makeCashierList() {
    let data = JSON.parse(JSON.stringify(this.tables))

    let arr = []
    for (let i = 0; i < data.length; i++) {
      let obj = data[i].data
      if (obj) {
        for (let j = 0; j < obj.length; j++) {
          try {
            const ff = arr.find(f => f.id == obj[i].cashierId)
            if (!ff) {
              arr.push({ id: obj[i].cashierId, name: obj[i].cashierName })
            }
          } catch { }
        }
      }
    }

    let obj = this.cashDrops
    if (obj) {
      for (let j = 0; j < obj.length; j++) {
        const ff = arr.find(f => f.id == obj[j].cashierId)
        if (!ff) {
          arr.push({ id: obj[j].cashierId, name: obj[j].cashierName })
        }
      }
    }

    this._abd.cashiers = arr

  }

  getDataList() {
    const url = environment.InventoryBaseURL + environment.Report

    try {
      this._spinner.show()
      this._bs.httpPost(url, JSON.stringify(this.getParas())).subscribe(res => {
        if (res.success) {
          this._filterService.selectedCashier = null

          this.cashDrops = res.data.cashDrop
          this.tables = [
            { id: 1, title: 'Pay-Out', data: res.data.payOut, total: 0 },
            { id: 2, title: 'Pay-In', data: res.data.payIn, total: 0 },
            { id: 3, title: 'Till', data: res.data.till, total: 0 },
          ]

          for (let i = 0; i < this.tables.length; i++) {
            let obj = this.tables[i]
            obj.total = 0

            if(i == 2){
                this.addedAmount =  res.data.till.reduce((a,b)=> a+b.addedAmount,0);
                this.removedAmount =  res.data.till.reduce((a,b)=> a+b.removedAmount,0);
            }
            if (obj && obj.data.length > 0) {
              for (let j = 0; j < obj.data.length; j++) {
                obj.total += obj.data[j].total
              }
            }
          }

          this.cashDropTotal = 0
          let obj = this.cashDrops
          if (obj && obj.length > 0) {
            for (let i = 0; i < obj.length; i++) {
              this.cashDropTotal += obj[i].total
            }
          }

          this.topTags = [
            { id: 1, title: 'System Cash', value: res.data.totalSystemCash },
            { id: 2, title: 'Cash Discount', value: res.data.cashDiscount },
            { id: 3, title: 'Drops', value: res.data.totalDrop },
            { id: 4, title: 'Over/Short', value: res.data.totalOverShort },
          ]

          this.makeCashierList()
        }
        this._spinner.hide()
      })
    }
    catch {
      //console.log('**tender-detail-report**', url)
      this._spinner.hide()
    }
  }

  getLocationsList() {
    let locationIds = [];
    // locationIds.push(this._abd.locations[0].locationId) // to be deleted later
    let sel = this._filterService.selectedLocation
    if (!sel) return locationIds

    sel = (typeof sel != 'undefined' && sel instanceof Array) ? sel : [sel];
    sel.map(function (value: { locationId: { toString: () => string; }; }) {
      locationIds.push(value.locationId.toString())
    })
    return locationIds
  }

  getRegistersList() {
    let ids = [];
    let sel = this._filterService.selectedRegister;
    if (!sel) return ids

    for (let i = 0; i < sel.length; i++)
      ids.push(sel[i].id)

    return ids
  }

  getCashierList() {
    let ids = [];
    let sel = this._filterService.selectedCashier;
    if (!sel) return ids

    for (let i = 0; i < sel.length; i++)
      ids.push(sel[i].id)

    return ids
  }

  getParas() {

    if (!this._filterService.selectedLocation) {
      this._filterService.selectedLocation = JSON.parse(JSON.stringify(this._abd.locations))
    }

    const data = {
      "ReportName": "CashDetailReport",
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.getLocationsList(),
        "CashierIds": this.getCashierList(),
        "filterValue": this._filterService.searchText,
      }
    }
    return data
  }

  getOverShortColor(tag) {
    if (tag.id !== 3) return ""
    if (tag.id == 3 && tag.value < 0) return "background: var(--red-400); color: white;"
    else if (tag.id == 3 && tag.value > 0) return "background: var(--brandColor); color: white;"
    else return ""
  }

  showCashDropsSummary() {
    try {
      if (this.cashDrops && this.cashDrops.length > 0) return true
      else return false
    } catch { return false }
  }

  fillInitData() {
    this.topTags = [
      { id: 1, title: 'System Cash', value: 0 },
      { id: 2, title: 'Cash Discount', value: 0 },
      { id: 3, title: 'Drops', value: 0 },
      { id: 4, title: 'Over/Short', value: 0 },
    ]

    this.tables = [
      { id: 1, title: 'Pay-Out', data: null, total: 0 },
      { id: 2, title: 'Pay-In', data: null, total: 0 },
      { id: 3, title: 'Till', data: null, total: 0 },
    ]
  }

  calculateSummaryTotal(what) {
    try {
      const r = this.topTags.find(f => f.id == what)
      return r.value
    }
    catch { return 0 }
  }

  filterButtonPressed() {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  getColSpan(val) {
    if(val=='Till') return 6
    else return 5
  }

  exportExcel() {
    let header = ['Location', 'Cashier Name', 'Reg', 'Date/Time', 'Reason', 'Total']
    const ws = []
    let sum = 0

    for (let i = 0; i < this.tables.length; i++) {

      let tmp = []
      sum = 0
      const xd = this.tables[i].data

      tmp.push([this.tables[i].title])
      if (xd.length < 1) {
        tmp.push(header)
        tmp.push(['NO DATA TO SHOW'])
        ws.push(tmp)
        continue
      }
      tmp.push([''])

      tmp.push(header)
      for (let j = 0; j < xd.length; j++) {
        let inner = []
        inner.push(xd[j].locationName)
        inner.push(xd[j].cashierName)
        inner.push(xd[j].registerName)
        inner.push(formatDate(new Date(xd[j].date), 'MM/dd/yyyy, h:mm a', 'en-US'))
        inner.push(xd[j].reason)
        inner.push('$ ' + xd[j].total.toFixed(2))
        tmp.push(inner)
        sum += xd[j].total
      }

      let inner = []
      inner.push('')
      inner.push('')
      inner.push('')
      inner.push('')
      inner.push('TOTAL:')
      inner.push('$ ' + sum.toFixed(2))
      tmp.push(inner)

      ws.push(tmp)
    }

    header = ['Location', 'Cashier Name', 'Reg', 'Date/Time', '$100', '$50', '$20', '$10', '$5', '$2', '$1', '25 cents (Quarters)', '10 cents (Dimes)', '5 cents (Nickels)', '1 cents (Penny)', 'Total']
    let tmp = []
    sum = 0
    const xd = this.cashDrops
    tmp.push(header)
    for (let j = 0; j < xd.length; j++) {
      let inner = []
      inner.push(xd[j].locationName)
      inner.push(xd[j].cashierName)
      inner.push(xd[j].registerName)
      inner.push(formatDate(new Date(xd[j].date), 'MM/dd/yyyy, h:mm a', 'en-US'))
      inner.push(xd[j].dollar100Qty)
      inner.push(xd[j].dollar50Qty)
      inner.push(xd[j].dollar20Qty)
      inner.push(xd[j].dollar10Qty)
      inner.push(xd[j].dollar5Qty)
      inner.push(xd[j].dollar2Qty)
      inner.push(xd[j].dollar1Qty)
      inner.push(xd[j].cent25Qty)
      inner.push(xd[j].cent10Qty)
      inner.push(xd[j].cent5Qty)
      inner.push(xd[j].cent1Qty)
      inner.push('$ ' + xd[j].total.toFixed(2))
      sum += xd[j].total
      tmp.push(inner)
    }

    let inner = []
    inner.push('')
    inner.push('')
    inner.push('')
    inner.push('')
    inner.push('')
    inner.push('')
    inner.push('')
    inner.push('')
    inner.push('')
    inner.push('')
    inner.push('')
    inner.push('')
    inner.push('')
    inner.push('')
    inner.push('TOTAL:')
    inner.push('$ ' + sum.toFixed(2))
    tmp.push(inner)

    ws.push(tmp)

    const workbook = {
      Sheets: {
        'PayOut': XLSX.utils.aoa_to_sheet(ws[0]),
        'PayIn': XLSX.utils.aoa_to_sheet(ws[1]),
        'Till': XLSX.utils.aoa_to_sheet(ws[2]),
        'CashDrop': XLSX.utils.aoa_to_sheet(ws[3]),
      },
      SheetNames: ['PayOut', 'PayIn', 'Till', 'CashDrop']
    };

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const filename = 'daily-cash-summary-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.xlsx'
    FileSaver.saveAs(data, filename);

  }

  exportPdf() {
    let cols1 = [
      { dataKey: "locationName", title: "Location", type: 'string' },
      { dataKey: "cashierName", title: "Cashier Name", type: 'string' },
      { dataKey: "registerName", title: "Reg", type: 'string' },
      { dataKey: "date", title: "Date/Time", type: 'date' },
      { dataKey: "reason", title: "Reason", type: 'string' },
      { dataKey: "total", title: "Total", type: 'currency' },
    ]

    for (let i = 0; i < this.tables.length; i++) {
      const t = this.tables[i].data
      if (t.length > 0) {
        let r = {
          reason: 'TOTAL:',
          total: '$ ' + (this.tables[i].total).toFixed(2),
        }
        t.push(r)
      }
    }

    let data1 = this._abd.parseForExport(this.tables[0].data, cols1)
    let data2 = this._abd.parseForExport(this.tables[1].data, cols1)
    let data3 = this._abd.parseForExport(this.tables[2].data, cols1)

    let cols2 = [
      { dataKey: "locationName", title: "Location", type: 'string' },
      { dataKey: "cashierName", title: "Cashier Name", type: 'string' },
      { dataKey: "registerName", title: "Reg", type: 'string' },
      { dataKey: "date", title: "Date/Time", type: 'date' },
      { dataKey: "dollar100Qty", title: "$100", type: 'string' },
      { dataKey: "dollar50Qty", title: "$50", type: 'string' },
      { dataKey: "dollar20Qty", title: "$20", type: 'string' },
      { dataKey: "dollar10Qty", title: "$10", type: 'string' },
      { dataKey: "dollar5Qty", title: "$5", type: 'string' },
      { dataKey: "dollar2Qty", title: "$2", type: 'string' },
      { dataKey: "dollar1Qty", title: "$1", type: 'string' },
      { dataKey: "cent25Qty", title: "25 cents (Quarters)", type: 'string' },
      { dataKey: "cent10Qty", title: "10 cents (Dimes)", type: 'string' },
      { dataKey: "cent5Qty", title: "5 cents (Nickels)", type: 'string' },
      { dataKey: "cent1Qty", title: "1 cent (Penny)", type: 'string' },
      { dataKey: "total", title: "Total", type: 'currency' },
    ]

    const r = {
      'cent1Qty': 'TOTAL:',
      'total': '$ ' + (this.cashDropTotal).toFixed(2),
    }
    this.cashDrops.push(r)

    const data4 = this._abd.parseForExport(this.cashDrops, cols2)

    let doc = new jsPDF('l', 'mm', 'a3');
    doc.setFontSize(30)
    doc.setTextColor(40)
    doc.text("Cash Report (Detail)", 150, 20)

    doc.setFontSize(25)
    doc.setTextColor(40)
    doc.text("Pay-Out", 15, 30)
    doc['autoTable'](cols1, data1, { startY: 35 })

    doc.addPage();
    doc.setFontSize(25)
    doc.setTextColor(40)
    doc.text("Pay-In", 15, 15)
    doc['autoTable'](cols1, data2, { startY: 20 })

    doc.addPage();
    doc.setFontSize(25)
    doc.setTextColor(40)
    doc.text("Till", 15, 15)
    doc['autoTable'](cols1, data3, { startY: 20 })

    doc.addPage();
    doc.setFontSize(25)
    doc.setTextColor(40)
    doc.text("Cash Drop", 15, 15)
    doc['autoTable'](cols2, data4, { startY: 20 })

    let filename = 'daily-cash-summary-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.pdf'
    doc.save(filename);
  }



}
