import { Component, OnInit } from '@angular/core';

interface CheckBoxOptions {
    title: string;
    active: boolean
}

@Component({
  selector: 'app-printing-interaction',
  templateUrl: './printing-interaction.component.html',
  styleUrls: ['./printing-interaction.component.scss']
})
export class PrintingInteractionComponent implements OnInit {

    InputCashierAlert: boolean = false;
    sendEmailWhenPointsRedeemed: boolean = false;
    sendEmailWhenPointsAccumulated: boolean = false;
    InputPrintPointsOnReceipt: boolean = false;
    InputDisplayPointsOnSC: boolean = false;
    InputSMSNotification: boolean = false;
    InputEmailNotification: boolean = false;
    printInfoList: CheckBoxOptions[] = [];
    smsNotificationList: CheckBoxOptions[] = [];
    constructor() {
        this.printInfoList = [
            { title: 'Customer First Name', active: false},
            { title: 'Customer Last Name', active: false},
            { title: 'Customer Phone', active: false},
            { title: 'Customer Email', active: false},
            // { title: 'Customer ID', active: false },
            { title: 'Current Loyalty Points', active: false},
            {title: 'Reward Perk Information', active: false},
        ];

        this.smsNotificationList = [
            { title: 'Send SMS when points are redeemed', active: true},
            { title: 'Send SMS when points are accumulate', active: false},
        ];
    }

    ngOnInit(): void {
    }

}
