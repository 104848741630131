import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorldTimeZone } from 'src/app/_data/time-zones';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';

export interface TimeTrackingDetailReport {
  employeeID:string
  employeeName:string
  employeeRole:string
  employeeEmail:string
  date:string
  clockInTimes:any
  clockOutTimes:any
  totalHours?:any
  reason?:any
}

@Component({
  selector: 'app-time-tracking-detail-report',
  templateUrl: './time-tracking-detail-report.component.html',
  styleUrls: ['./time-tracking-detail-report.component.scss']
})
export class TimeTrackingDetailReportComponent implements OnInit, OnDestroy {
 
  _globals = GlobalService
  timeZoneList = WorldTimeZone
  dataList: TimeTrackingDetailReport[] = [];
  totalTime:any

  colheader = this._abd.reports.filter(x => x.componentName == 'Time Tracking Detail Report')[0].colheader;
  domColHeader = this.colheader[0];
  colflds = this._abd.reports.filter(x => x.componentName == 'Time Tracking Detail Report')[0].colflds;
  summaryHeader = this._abd.reports.filter(x => x.componentName == 'Time Tracking Detail Report')[0].summaryHeader;
  domSummaryHeader = this.summaryHeader[0];
  domSummaryflds = this._abd.reports.filter(x => x.componentName == 'Time Tracking Detail Report')[0].summaryflds;


  Reason = 
  [
    { id:0, value:'Checkin' },
     { id:1, value:'Checkout'},
     { id:2, value:'Break'},
     {id:3, value:'Other'},
  ]

  csvcols = []
  exportColumns: any = ''


  constructor(private _bs: BaseService,
    private _abd: AppBaseDataService,
    private currencyPipe: CurrencyPipe,
    public _filterService: FilterServiceService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    this._globals.componentName = 'Time Tracking Detail Report'
      if(this._globals.userInfo.businessSetup[0]?.standardsForms !== undefined && this._globals.userInfo.businessSetup[0]?.standardsForms?.timeZone !== "" ){
      this._filterService.selectedTimeZone = this.timeZoneList.filter((v)=>v.value === this._globals.userInfo.businessSetup[0]?.standardsForms.timeZone)[0]
    }else{
      this._filterService.selectedTimeZone = this.timeZoneList[0]
    }
    if(!this._filterService.dateRange || this._filterService.dateRange.length<1)
    this._filterService.initDates()
 
    this._filterService.selectedLocation = this._abd.locations;
        this.getDataList()
       }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getDataList() {
    let data
    if(this._filterService?.selectedLocation?.locationId !== undefined){
       data = {
      "ReportName": 'TimeTrackingDetailReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationId": this._filterService.selectedLocation.locationId,
        "filterValue": this._filterService.searchText,
        "TimeZone": this._filterService.selectedTimeZone.value
      }
    }
    }else{
       data = {
        "ReportName": 'TimeTrackingDetailReport',
        "Parameter": {
          "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
          "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
           "filterValue": this._filterService.searchText,
           "TimeZone": this._filterService.selectedTimeZone.value
        }
      }
    }

    // const url = environment.InventoryBaseURL + environment.Report;
    const url = this._abd.reports.filter(x => x.paraReportName == 'time-tracking-summary-report')[0].reportURL;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      this.dataList = this.dataList.map((v)=>{
        return{
          clockOutTimes:this.checkClockOutTime(v),
          ...v,totalHours:this.calculateTotalTimeDifference(v.clockInTimes,v.clockOutTimes),reason:this.getReason(v.reason)
        }
      })
      let datalist = this.dataList.map((v)=>v.totalHours.filter((x)=>x !== "-"))
         let totalHours =  datalist.flat(Infinity)
          this.totalTime = this.getTotalHours(totalHours)

      this._abd.hideSpinner();
   
    })
  }
  getReason(value:any) {
    return value.map(element =>  this.Reason.filter((x)=>x.id === element)[0].value )
 
  }
  checkClockOutTime(v: TimeTrackingDetailReport) {
     v.clockInTimes.map((c,i)=>{
      if(v.clockOutTimes[i] === undefined ){
      return   v.clockOutTimes[i] = "0001-01-01T00:00:00"
      }

     })
  }


  filterButtonPressed() {
    this.getDataList()
  }


  callbackFuncFromFilter() {

    this.getDataList()
  }
  getTotal(array){
    if(array !== undefined){
    const sum = array
    .map(item => parseFloat(item)) 
    .filter(num => !isNaN(num)) 
    .reduce((accumulator, num) => accumulator + num, 0).toFixed(2);
  
      return sum
    }

  }
  exportExcel() {
let list = this.dataList.map((v) => {
  let datalist = []; 
  for (let i = 0; i < v.clockInTimes.length; i++) {
    let obj = {
      date:i === 0 ? v.date : '',
      employeeId:i === 0 ? v.employeeID : '',
      employeeName:i === 0 ? v.employeeName : '',
      employeeRole:i === 0 ? v.employeeRole : '',
      employeeEmail:i === 0 ? v.employeeEmail : '',
      clockIn:v.clockInTimes[i],
      clockOut:v.clockOutTimes[i],
      totalHours:v.totalHours[i]
    };
    datalist.push(obj);
  }
  return datalist;
});
list = list.flat(Infinity)
    this._abd.exportExcel(list, this.summaryHeader[0], this.colflds, 'Time Tracking Detail Report')
  }

  exportPdf() {
    let list = this.dataList.map((v) => {
      let datalist = []; 
      for (let i = 0; i < v.clockInTimes.length; i++) {
        let obj = {
          date:i === 0 ? v.date : '',
          employeeId:i === 0 ? v.employeeID : '',
          employeeName:i === 0 ? v.employeeName : '',
          employeeRole:i === 0 ? v.employeeRole : '',
          employeeEmail:i === 0 ? v.employeeEmail : '',
          clockIn:v.clockInTimes[i],
          clockOut:v.clockOutTimes[i],
          totalHours:v.totalHours[i]
        };
        datalist.push(obj);
      }
      return datalist;
    });
    list = list.flat(Infinity)
     this._abd.exportPdf(list, this.summaryHeader, this.colflds, 'Time Tracking Detail Report', false,false,false)
  }



  calculateTotalTimeDifference(timeArray1: string[], timeArray2: string[]): string[] {
    timeArray2 = timeArray2.filter((v)=>v !== "0001-01-01T00:00:00")
    timeArray1 = timeArray1.map((v)=>formatDate(v, 'h:mm', 'en-US'))
    timeArray2 = timeArray2.map((v)=>formatDate(v, 'h:mm', 'en-US'))

    const totalTimeArray: string[] = [];

    for (let i = 0; i < timeArray1.length; i++) {
     
      if(timeArray2[i] !== undefined){
        const [hours1, minutes1] = timeArray1[i].split(":").map(Number);
        const [hours2, minutes2] = timeArray2[i].split(":").map(Number);

        const totalMinutes1 = hours1 * 60 + minutes1;
        const totalMinutes2 = hours2 * 60 + minutes2;

        const totalDifferenceInMinutes = totalMinutes2 - totalMinutes1;

        const totalHours = Math.floor(totalDifferenceInMinutes / 60);
        const totalMinutes = totalDifferenceInMinutes % 60;

        const totalTimeDifference = `${totalHours}:${totalMinutes.toString().padStart(2, '0')}`;
        totalTimeArray.push(totalTimeDifference);
      }else{
        totalTimeArray.push("-");
      }  

    }

    return totalTimeArray;
  }

  getTotalHours(timeArray: any) {
    let totalMinutes = 0;
     for (const time of timeArray) {
       if(time !== "-"){
       const [hours, minutes] = time.split(':').map(Number);
       totalMinutes += hours * 60 + minutes;
       }     
      }
     const totalHours = Math.floor(totalMinutes / 60);
     const remainingMinutes = totalMinutes % 60;
     return `${totalHours}:${remainingMinutes.toString().padStart(2, '0')}`;
   }
   
}
