import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, ValidatorFn, AbstractControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BizzCustomersService } from 'src/app/_business-dashboard/_services/bizz-customers.service';
import { TenderScreenConfig } from 'src/app/_models/configuration-widgets/business-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-bizz-cash-discount-configuration',
  templateUrl: './bizz-cash-discount-configuration.component.html',
  styleUrls: ['./bizz-cash-discount-configuration.component.scss']
})
export class BizzCashDiscountConfigurationComponent implements OnInit {

  cashDiscountForm: FormGroup;
  currentSelectedLocation:any = null;
  cashDiscountSettingList:any =null
  locationsList:any[] = null
  
    constructor(private _fb: FormBuilder,
              public _bizzCustomerService: BizzCustomersService,       
              public _businessSetupService: BusinessSetupService,
              private _connMessageService: ConnMessageService,
              private _confirmationService: ConfirmationService,
              private _messageService: MessageService,
              public _abd: AppBaseDataService) { }

  ngOnInit(): void {
    this.getLocations()
 
  }  
  getLocations() {
    try{
        this._abd.getTenderLocations(this._bizzCustomerService.activeItem.currentSubscription.businessNameWithDomain).subscribe(res=>{

          if(res.success){
            this.locationsList = res.data
            if(this.locationsList.length > 0){
              this.getTenderConfigration(this.locationsList[0]?.locationId)
            }else{
                 setTimeout(() => {
                     this.getTenderConfigration(this.currentSelectedLocation?.locationId)
                     }, 1000);
               }
          }else{
            this.locationsList = []
          }
        },(error)=>{
          this.locationsList = []
        })
      }
      catch{
        this.locationsList = []
      }
  }

  // locationChanged(locationId:any){
  //  this.cashDiscountSettingList = null
  //  this.getTenderConfigration(this.currentSelectedLocation?.locationId)
  // }
  createForm(): void {
    this.cashDiscountForm = this._fb.group({
      locationId: this.cashDiscountSettingList.locationId,
      enableTip: this.cashDiscountSettingList.enableTip || false,
      promptCustomerTip: this.cashDiscountSettingList.promptCustomerTip,
      enableCashDisc: this.cashDiscountSettingList.enableCashDisc,
      tenderTypes: this._fb.array([])
    });

    this.cashDiscountSettingList.tenderTypes.forEach(tenderType => {
       this.tenderTypes.push(this.addTenderType(tenderType)) 
    });

    this.cashDiscountForm.valueChanges.subscribe(data => {
      this.cashDiscountSettingList = data;
    });
  }
  get f(){
    return this.cashDiscountForm?.controls
  }
  get tenderTypes() {
    return this.cashDiscountForm.get('tenderTypes') as FormArray;
  }
  addTenderType(tenderType: any): FormGroup { 
    return this._fb.group({
      locationId: [tenderType.locationId],
      tenderId:[tenderType.tenderId],
      tenderType: [tenderType.tenderType],
      displayName: [tenderType.displayName],
      cashDiscountPer: [tenderType.cashDiscountPer,[Validators.required,this.rangeValidator(0, 100),Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      enableTip: [tenderType.enableTip],
      promptCustomerTip: [tenderType.promptCustomerTip],
      enableCashDisc: [tenderType.enableCashDisc],
      status: [tenderType.status],
      isCash: [tenderType.isCash],
      showRemarks: [tenderType.showRemarks]
    });

  }

  getTenderConfigration(value?:any){
  let model = `locationid=${value !== undefined ? value : 0}&BackOffice=true&BusinessName=${this._bizzCustomerService.activeItem.currentSubscription.businessNameWithDomain}`
  try{
      // this._abd.showSpinner()
      this._abd.getTenderConfiguration(model).subscribe(res=>{
        if(res.success){
          this.cashDiscountSettingList = res.data
          this.createForm()
          // this._abd.hideSpinner()
        }else{
          this.cashDiscountSettingList = {}
        }
      })
    }
    catch{
      // this._abd.hideSpinner()
    }
  } 


  saveFn(){
  //   if(this.locationsList.length < 1){
  //     return  this._messageService.add({ severity: 'error', summary: 'Location required. Please add a location to proceed.' })
  // }
  let isCashDiscount = this.cashDiscountForm.value.tenderTypes.filter((x) => x.cashDiscountPer < 0 || x.cashDiscountPer > 100).length
  if(isCashDiscount > 0 || !this.tenderTypes.valid){
   return  this._messageService.add({ severity: 'error', summary: 'Cash Discount (%)  must be in between 0 to 100' })
}
// let locations = this.currentSelectedLocation?.locationId !== undefined ? this.currentSelectedLocation?.locationId : 0
let locations =  [0, ...this.locationsList.map(location => location.locationId)].join(",")
     
let queryString = `BackOffice=true&LocationIds=${locations}&BusinessName=${this._bizzCustomerService.activeItem.currentSubscription.businessNameWithDomain}`
// let queryString = `BackOffice=true&LocationIds=0&BusinessName=${this._bizzCustomerService.activeItem.currentSubscription.businessNameWithDomain}`
    try{
      this._abd.showSpinner()
      this._businessSetupService.saveAdminTenderConfiguration(queryString,this.cashDiscountForm.value).subscribe(res=>{
        if(res.success){
           this._connMessageService.showToastMessage(`Tender settings saved successfully!`, 'success')
          this._businessSetupService.showTenderConfiguration = false
        }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving tender settings!`, 'error')
      this._abd.hideSpinner()
    }
  }
  saveOnAllLocations(){
     if(this.locationsList.length < 1){
      return  this._messageService.add({ severity: 'error', summary: 'Location required. Please add a location to proceed.' })
  } 
  let isCashDiscount = this.cashDiscountForm.value.tenderTypes.filter((x) => x.cashDiscountPer < 0 || x.cashDiscountPer > 100).length
     if(isCashDiscount > 0 || !this.tenderTypes.valid){
      return  this._messageService.add({ severity: 'error', summary: 'Cash Discount (%)  must be in between 0 to 100' })
  } 

  //  this._confirmationService.confirm({
  //     message: 'Are you sure you want to apply cash discount configurations to all locations?',
  //     header: 'Cash Discount Configurations',
  //     icon: 'pi pi-info-circle',
  //     acceptLabel: 'Yes',
  //     rejectLabel: 'No',
  //     accept: () => {
        let locations;
        locations = this.locationsList.map((x)=>x.locationId).join(',') 
       if(locations <1){
        locations = 0
       }
        let queryString = `BackOffice=true&LocationIds=${locations}&BusinessName=${this._bizzCustomerService.activeItem.currentSubscription.businessNameWithDomain}`
          try{
            this._abd.showSpinner()
            this._businessSetupService.saveAdminTenderConfiguration(queryString,this.cashDiscountForm.value).subscribe(res=>{
              if(res.success){
                 this._connMessageService.showToastMessage(`Tender settings saved successfully!`, 'success')
                this._businessSetupService.showTenderConfiguration = false
              }
            })
          }
          catch{
            this._connMessageService.showToastMessage(`Error saving tender settings!`, 'error')
            this._abd.hideSpinner()
          }
    //   },
    //   reject: () => {
    //   }
    // });
 
 
  }



  cancelButtonClick(){
    this._businessSetupService.showTenderConfiguration = false
  }
  rangeValidator(minValue: number, maxValue: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (isNaN(value) || value < minValue || value > maxValue) {
        return { 'range': true };
      }
      return null;
    };}
}
