 <div class="flex justify-content-end">
  <i class="pi pi-times p-4 pr-0 cursor-pointer" (click)="dismiss()"></i> 
 </div> 
 <div class="m-4">
    
  <div class="flex justify-content-center align-items-center">
   <h1 *ngIf="!isUserCheckedIn">
   Check-In
 </h1>
   <h1 *ngIf="isUserCheckedIn">
   Check-Out
 </h1>
 </div>

 
 <div class="grid align-items-center">
   
   <!-- Clock In -->
   <!-- <ng-container *ngIf="!isUserCheckedIn"> -->

    <div class=" col-3">
              <label class="control-label">
                  <strong>
                     Location
                  <span style="color: red; font-size: 1.25rem">*</span>
             </strong> </label>      
  </div>  
          <div class="col-9 p-0 row">
            <div class="w-full">
              <p-dropdown
              [style]="{}"
              [(ngModel)]="clockInOutLocation"
              class="p-dropdown-1"
              [options]="userLocations"
              (onChange)="locationChanged($event)"
              optionLabel="locationName"
              appendTo="body"
              [disabled]="isUserCheckedIn"
          >
          </p-dropdown>

            </div>
          </div>  
  
      <!-- <div class=" col-3">
              <label class="control-label">
                  <strong>
                     Role
                  <span style="color: red; font-size: 1.25rem">*</span>
             </strong> </label>      
  </div>  
          <div class="col-9 p-0 row">
            <div class="w-full">
              <p-dropdown
              [style]="{}"
              [(ngModel)]="clockInOutRole"
              class="p-dropdown-1"
              [options]="userRoles"
              optionLabel="name"
              [autoDisplayFirst]="true"
              appendTo="body"
          >
          </p-dropdown>
        </div>
      </div> -->
      <div class=" col-3">
              <label class="control-label">
                  <strong>
                     Time
                  <span style="color: red; font-size: 1.25rem">*</span>
             </strong> </label>      
  </div>  
          <div class="col-9 p-0 row">
            <div class="w-full">
              <p-calendar [(ngModel)]="date" appendTo="body" [hourFormat]="12" [timeOnly]="true" (ngModelChange)="onDateChanged($event)"></p-calendar>
              <!-- <p-dropdown
              [style]="{}"
              [(ngModel)]="clockInOutRole"
              class="p-dropdown-1"
              [options]="userRoles"
              optionLabel="name"
              [autoDisplayFirst]="true"
              appendTo="body"
          >
          </p-dropdown> -->
        </div>
      </div>
      <!-- <div class=" col-3">
          
      </div>   -->
    <!-- <div class="col-9 p-0 row">
     

        <div class="mt-2">
          <p-checkbox
          [formControl]="isCheckOutReason"
              [binary]="true"
              styleClass="mr-2"
              inputId="CheckOutReason"
          ></p-checkbox>
          <label for="CheckOutReason">Check-In though Register</label>
      </div>
   </div> -->

<!-- <ng-container *ngIf="isCheckOutReason.value">
      <div class=" col-3">
        <label class="control-label">
            <strong>
               Register
            <span style="color: red; font-size: 1.25rem">*</span>
        </strong></label>      
      </div>  
    <div class="col-9 p-0 row">
      <div class="w-full">
        <p-dropdown
        [style]="{}"
        [(ngModel)]="clockInOutRegister"
        class="p-dropdown-1"
        [options]="_abd.registers"
        optionLabel="registerName"
        [showClear]="true"
        [autoDisplayFirst]="false"
        appendTo="body"
    >
    </p-dropdown>
  </div>
   </div>
   <div class="col-3">&nbsp;</div>  
<div class="col-9" >
  <span class="control-description text-muted">Please select the register on which you want to check-In.</span>
</div>
</ng-container> -->
  
  
<!-- </ng-container> -->
<!-- /Clock In -->
   <!-- Clock Out -->
   <ng-container *ngIf="isUserCheckedIn">
    <div class=" col-3">
      <label class="control-label">
          <strong>
             Reason
         <span style="color: red; font-size: 1.25rem">*</span>
   </strong>    </label>      
</div>  
  <div class="col-9 p-0 row">
    <div class="w-full">
      <p-dropdown
      [style]="{}"
      [(ngModel)]="checkOutReason"
      class="p-dropdown-1"
      [options]="reasonList"
      optionLabel="value"
      [autoDisplayFirst]="true"
      appendTo="body"
  >
  </p-dropdown>
</div>
</div>
</ng-container>
   <!-- /Clock Out -->
  <div class="flex align-items-center justify-content-center mt-5 w-full cursor-pointer ">
     <div class="flex align-content-center justify-content-center  " *ngIf="isLoadingLocation && (!isUserCheckedIn || isUserCheckedIn )">
      <i class="pi pi-spin pi-spinner" style="color:var(--brandColor);font-size:20px" ></i>
    </div> 
    <p *ngIf="!isAllowed && !isLoadingLocation && !isUserCheckedIn" style="color:red;">You are not authorized to check-In at this location. Please select another location to proceed.</p>  
      <span class="check_In_btn" (click)="clockIn()" *ngIf="!isUserCheckedIn && !isLoadingLocation && isAllowed">
          <i class="pi pi-clock"></i>   Check-In
   </span>
        <span class="check_Out_btn" (click)="clockOut()" *ngIf="isUserCheckedIn && !isLoadingLocation">
          <i class="pi pi-clock"></i>   Check-Out
        </span>
        </div>
 
</div>
</div>