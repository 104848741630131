<form
    style="width: 100%"
    [formGroup]="editform"
    (ngSubmit)="editform.valid && saveFn()"
    autocomplete="off"
>
    <div class="conn-grid-4x">
       
        <div>
            <label class="control-label">
                <strong
                    >Weight
                    <span
                        style="color: red; font-size: 1.25rem"
                    ></span> </strong
            ></label>
        </div>
        <div>
            <label class="control-label">
                <strong
                    >Weight Unit
                    <span
                        style="color: red; font-size: 1.25rem"
                    ></span> </strong
            ></label>
        </div>
        <div></div>
    </div>
    <div class="conn-grid-4x">
        
        <div>
            <p-inputNumber
                [style]="{ 'margin-top': '7px' }"
                formControlName="weight"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
                (onInput)="onInput($event, 'shippingWeight')"
            ></p-inputNumber>
            <!-- POINT {{_productService.shippingWeight}} -->
        </div>
        <div>
            <p-dropdown
                [style]="{
                    width: '100%',
                    
                    'margin-top': '7px'
                }"
                class="p-dropdown-1"
                appendTo="body"
                [options]="_abd.WeightUOM"
                [(ngModel)]="_productService.selectedShippingWeight"
                optionLabel="title"
                [filter]="false"
                filterBy="title"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="_productService.isViewOnly"
            ></p-dropdown>
        </div>
        <div></div>
    </div>
    <br />
    <br />
    <div class="conn-grid-3x">
        <div>
            <label class="control-label">
                <strong>Dimensions</strong>
            </label>
        </div>
        <div></div>
        <div></div>
    </div>
    <div class="conn-grid-3x">
        <div>
            <p-dropdown
                [style]="{
                    width: '100%',
                    'min-width': '150px',
                    'margin-top': '7px'
                }"
                class="p-dropdown-1"
                appendTo="body"
                [options]="_abd.DimensionUOM"
                [(ngModel)]="_productService.selectedShippingDimension"
                optionLabel="title"
                [filter]="false"
                filterBy="title"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="_productService.isViewOnly"
            ></p-dropdown>
        </div>
        <div></div>
        <div></div>
    </div>

    <br />
    <br />
    <div class="formgrid grid">
        <div class="field col">
            <label>
                <strong>Height</strong>
            </label>
            <br />
            <p-inputNumber
                [style]="{ 'margin-top': '7px' }"
                formControlName="height"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
                (onInput)="onInput($event, 'shippingHeight')"
            ></p-inputNumber>
        </div>
        <div class="field col">
            <label>
                <strong>Width</strong>
            </label>
            <br />
            <p-inputNumber
                [style]="{ 'margin-top': '7px' }"
                formControlName="width"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
                (onInput)="onInput($event, 'shippingWidth')"
            ></p-inputNumber>
        </div>
        <div class="field col">
            <label>
                <strong>Depth</strong>
            </label>
            <br />
            <p-inputNumber
                [style]="{ 'margin-top': '7px' }"
                formControlName="length"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
                (onInput)="onInput($event, 'shippingLength')"
            ></p-inputNumber>
        </div>
    </div>
</form>
