<p-overlayPanel #picpreview>
    <ng-template pTemplate>
        <div class="image-preview">
            <img [src]="_productService.thImages[i].image" />
        </div>
    </ng-template>
</p-overlayPanel>
<div>
    <div class="flex justify-content-end flex-wrap">
        <div
            class="flex align-items-center justify-content-center text-bluegray-300"
            style="font-weight: 700px; margin-bottom: 7px"
        >
            image(s) {{ _productService.thImages.length }} of 10
        </div>
    </div>

    <div
        class="uploadbox"
        style="
            min-height: auto;
            padding: 1.5rem 1.75rem;
            text-align: center;
            cursor: pointer;
            border: 1px dashed var(--brandColor);
            border-radius: 0.475rem !important;
            background: #edf1f5;
        "
    >
        <div class="grid">
            <div class="col-2 upload-btn-div">
                <label
                    data-kt-image-input-action="change"
                    data-bs-toggle="tooltip"
                    title="Add/Change image"
                >
                    Click to upload images
                    <br />
                    <br />
                    <i
                        class="pi pi-cloud-upload"
                        style="color: var(--bs-primary); font-size: 3rem"
                    ></i>
                    <input
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg, .webp"
                        multiple="true"
                        (change)="handleFileInputMultiple($event)"
                        [hidden]="true"
                        [disabled]="!ok2AddThumbnails() || _productService.isViewOnly"
                    />
                    <input type="hidden" name="avatar_remove" />
                </label>
            </div>
            <div class="col">
                <ng-container>
                    <div class="grid">
                        <div
                            *ngFor="
                                let image of _productService.thImages;
                                let i = index
                            "
                            class="img-container conn-card"
                        >
                            <a
                            *ngIf="!_productService.isViewOnly"
                                class="rm-btn"
                                pTooltip="Remove Image"
                                (click)="onRemoveClick($event, i)"
                                ><i class="pi pi-minus-circle"></i
                            ></a>
                            <p-image
                                [src]="image"
                                preview="true"
                                alt="Image"
                                width="150px"
                            ></p-image>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
