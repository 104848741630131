import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { RewardProgramService } from 'src/app/_services/reward-program.service';


@Component({
  selector: 'app-points-accumulation-form',
  templateUrl: './points-accumulation-form.component.html',
  styleUrls: ['./points-accumulation-form.component.scss']
})
export class PointsAccumulationFormComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>()
    editform: FormGroup
    customersList:any[] = []
    isLoadCustomerGroup:boolean = false;
       
    pointsAccumulationData: any;
    expiryDurationList: any[];

    @Input() isNew: boolean = true
    @Input() isViewOnly: boolean 
    @Output() onSubmit = new EventEmitter();
    
    constructor(
        public _filterService: FilterServiceService,
        public _abd: AppBaseDataService,
        public _rewardProgramService: RewardProgramService,
        private _fb:FormBuilder,
        public _customerService: CustomersService,
        private _connMessageService: ConnMessageService,
      ) { }
  
    ngOnInit(): void {
      this.expiryDurationList = [
        { name: 'Never', title: 'Never' },
        { name: 'EveryWeek', title: 'Weekly' },
        { name: 'EveryMonth', title: 'Monthly' },
        { name: 'Yearly', title: 'Yearly' },
    ];
    // this.getCustomersList()
      this.pointsAccumulationData = {
        customersGroupId:this._rewardProgramService.activeItem?.customerGroup,
        minimumSpendForReward:this._rewardProgramService.activeItem?.minSpendAmountReward,
        minimumCartValueForPoints:this._rewardProgramService.activeItem?.minCartValue,
        maximumPointsPerTransaction:this._rewardProgramService.activeItem?.maxAllowedPoints,
        pointsExpiration:this.expiryDurationList.filter((v)=>v.name === this._rewardProgramService.activeItem?.pointsExpireString)[0],
        accumulatePointsOnDiscountedItems:this._rewardProgramService.activeItem?.pointsAccumulationOnDiscountedItems || false,
        accumulatePointsOnOpenDeptItems:this._rewardProgramService.activeItem?.pointsAccumulationOnOpenDeptItems || false,  
        active:this._rewardProgramService.activeItem?.active || true,  
      }
        this.initForm();
    }
  
    ngOnDestroy(): void {
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
    }
  
    getCustomersList() {
      this.isLoadCustomerGroup = true
      let params = new HttpParams();
      let page = 0
      let rows = 99999
  
      params = params.append('pageNo', page.toString());
      params = params.append('pageSize', rows.toString());
  
      if (this._abd.searchText.length) params = params.append('filterValue', this._abd.searchText.trim());
      this._customerService.getAllCustomerGroups(params).subscribe(res => {
        this.customersList = res.data
        this.isLoadCustomerGroup = false
        if(!this.isNew){
            // this.setcustomerGroupNameValue(this.pointsAccumulationData.customersGroupId)
        }
      })
    }
    setcustomerGroupNameValue(value:any) {
      setTimeout(() => {
        this.editform.patchValue({customersGroupIds:value})
      }, 500);
    }
    get f() {
      return this.editform.controls;
    }
  
    initForm() {
    this.editform = this._fb.group({
      customersGroupIds: [{value: [], disabled: this.isViewOnly}, [Validators.required]],
      minSpendAmountReward: [{value: this.pointsAccumulationData.minimumSpendForReward, disabled: this.isViewOnly}, [Validators.required,Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      minimumCartValueForPoints: [{value: this.pointsAccumulationData.minimumCartValueForPoints, disabled: this.isViewOnly}, [Validators.required,Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      maximumPointsPerTransaction: [{value: this.pointsAccumulationData.maximumPointsPerTransaction, disabled: this.isViewOnly}, [Validators.required,Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      pointsExpiration: [{value: this.pointsAccumulationData.pointsExpiration, disabled: this.isViewOnly}, [Validators.required]],
      accumulatePointsOnDiscountedItems: [{value: this.pointsAccumulationData.accumulatePointsOnDiscountedItems, disabled: this.isViewOnly}],
      accumulatePointsOnOpenDeptItems: [{value: this.pointsAccumulationData.accumulatePointsOnOpenDeptItems, disabled: this.isViewOnly}],
         })
    }
  
    saveFn(){
  
      this._abd.showSpinner();
  
     const formData: any = {
      customerGroup:this.f.customersGroupIds.value, 
      minSpendAmountReward:parseInt(this.f.minSpendAmountReward.value),
      minimumSpendForReward:1,
      minCartValue:parseInt(this.f.minimumCartValueForPoints.value),
      maxAllowedPoints:parseInt(this.f.maximumPointsPerTransaction.value),
      pointsExpire:this.f.pointsExpiration.value.name,
      pointsAccumulationOnDiscountedItems:this.f.accumulatePointsOnDiscountedItems.value,
      pointsAccumulationOnOpenDeptItems:this.f.accumulatePointsOnOpenDeptItems.value,
      active:this.pointsAccumulationData.active,
        }
  
      if(this.isNew){
        this._rewardProgramService.savePointsAccumulation(formData).subscribe(res => {
          if(res.success){
            this.cancelButtonClick();
            this.onSubmit.emit(true)
            this._connMessageService.showToastMessage('Channel added successfully', 'success');
          } 
          else {
            this._connMessageService.showToastMessage(`Channel could not be added.`, 'error');
            this._abd.hideSpinner()
          }
        })
      }
      else{
        this._rewardProgramService.updatePointsAccumulation(this._rewardProgramService.activeItem?.id,formData).subscribe(res => {
          if(res.success){
            this.cancelButtonClick();
            this.onSubmit.emit(true)
            this._connMessageService.showToastMessage('Channel updated successfully', 'success');
          } 
          else {
            this._connMessageService.showToastMessage(`Channel could not be updated.`, 'error');
            this._abd.hideSpinner()
          }
        })
      }
    }
  
    cancelButtonClick(){
      this._rewardProgramService.showNewEditForm = false;
      this._rewardProgramService.showEditForm = false;
    }
  } 