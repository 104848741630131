import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorldTimeZone } from 'src/app/_data/time-zones';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';

export interface TimeTrackingSummaryReport {
  businessName:string
  employeeName:string
  locationName:string
  registerName:string
  date:string
  clockInTimes?:string
  clockOutTimes?:string
  totalHours?:number
  overTime?:number
  shortHour?:number
  locationId?: number
  fTotalHours?:string
  fOverTime?:string
  fShortHour?:string
  currentStatus?:string
  clockOutSummary?:string
  clockInSummary?:string
}




@Component({
  selector: 'app-time-tracking-summary-report',
  templateUrl: './time-tracking-summary-report.component.html',
  styleUrls: ['./time-tracking-summary-report.component.scss']
})
export class TimeTrackingSummaryReportComponent implements OnInit, OnDestroy {
  _globals = GlobalService
  timeZoneList = WorldTimeZone
  dataList: TimeTrackingSummaryReport[] = [];

  calculateTime:any;

  colheader = this._abd.reports.filter(x => x.componentName == 'Time Tracking Summary Report')[0].colheader;
  domColHeader = this.colheader[0];
  colflds = this._abd.reports.filter(x => x.componentName == 'Time Tracking Summary Report')[0].colflds;
  summaryHeader = this._abd.reports.filter(x => x.componentName == 'Time Tracking Summary Report')[0].summaryHeader;
  domSummaryHeader = this.summaryHeader[0];
  domSummaryflds = this._abd.reports.filter(x => x.componentName == 'Time Tracking Summary Report')[0].summaryflds;

  exportColumns: any = ''


  constructor(private _bs: BaseService,
    private _abd: AppBaseDataService,
    private currencyPipe: CurrencyPipe,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Time Tracking Summary Report'
    if(this._globals.userInfo.businessSetup[0]?.standardsForms !== undefined && this._globals.userInfo.businessSetup[0]?.standardsForms?.timeZone !== "" ){
      this._filterService.selectedTimeZone = this.timeZoneList.filter((v)=>v.value === this._globals.userInfo.businessSetup[0]?.standardsForms.timeZone)[0]
    }else{
      this._filterService.selectedTimeZone = this.timeZoneList[0]
    }
    if(!this._filterService.dateRange || this._filterService.dateRange.length<1)
    this._filterService.initDates()

    this._filterService.selectedLocation = this._abd.locations;
      this.getDataList()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }


  getDataList() {
    let data
    if(this._filterService?.selectedLocation?.locationId !== undefined){
       data = {
      "ReportName": 'TimeTrackingReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationId": this._filterService.selectedLocation.locationId,
        "filterValue": this._filterService.searchText,
        "TimeZone": this._filterService.selectedTimeZone.value
      }
    }
    }else{
       data = {
        "ReportName": 'TimeTrackingReport',
        "Parameter": {
          "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
          "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
           "filterValue": this._filterService.searchText,
           "TimeZone": this._filterService.selectedTimeZone.value
        }
      }
    }
   
   

    // const url = environment.InventoryBaseURL + environment.Report;
    const url = this._abd.reports.filter(x => x.paraReportName == 'time-tracking-summary-report')[0].reportURL;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
        this.calculateTime ={
          totalHoues:this.getTotalHours(this.dataList.map((v)=>v?.fTotalHours !== undefined ? v?.fTotalHours : "0:00")),
          shortHours:this.getTotalHours(this.dataList.map((v)=>v?.fShortHour !== undefined ? v?.fShortHour : "0:00")),
         overTime:this.getTotalHours(this.dataList.map((v)=>v?.fOverTime !== undefined ? v?.fOverTime : "0:00")),

        }
        
      
      this._abd.hideSpinner();
   
    })
  }
  getTotalHours(timeArray: any) {
   let totalMinutes = 0;
    for (const time of timeArray) {
      
      const [hours, minutes] = time.split(':').map(Number);
      totalMinutes += hours * 60 + minutes;
    }
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    return `${totalHours}:${remainingMinutes.toString().padStart(2, '0')}`;
  }
  getUserName(id){
     return this._abd.cashiers.filter((v)=>v.userId === id)[0]?.displayName
  }

  filterButtonPressed() {
      this.getDataList()
  }


  callbackFuncFromFilter() {

      this.getDataList()
  }
  exportExcel() {
    let list =this.dataList.map((v)=>{
      return{
        ...v,
        clockIn:v.clockInTimes,
        clockOut:v.clockOutTimes,
      }
    })
    this._abd.exportExcel(list, this.summaryHeader[0], this.colflds, 'Time Tracking Summary Report')
  }

  exportPdf() {
    let list =this.dataList.map((v)=>{
      return{
        ...v,
        clockIn:v.clockInTimes,
        clockOut:v.clockOutTimes,
      }
    })
    this._abd.exportPdf(list, this.colheader, this.colflds, 'Time Tracking Summary Report', false,false,false)
  }

}
