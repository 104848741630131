import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { RewardProgramService } from 'src/app/_services/reward-program.service';


@Component({
  selector: 'app-points-accumulation',
  templateUrl: './points-accumulation.component.html',
  styleUrls: ['./points-accumulation.component.scss']
})
export class PointsAccumulationComponent implements OnInit {

    _globals = GlobalService
  @ViewChild('op') op: OverlayPanel
  page = 0
  rows = 10
  first = 0
  totalRecords = 0
  dataList: any = null
  searchText = ''
  lastSearchText = ''
  masterDataList: any = null
  isViewOnly = false
  orderParam = ''
  orderBy = 'asc'
  customersList:any[] = []
  isLoadCustomerGroup:boolean = false;
  @ViewChild('p', { static: false }) paginator: Paginator;

  colHeader = this._abd.reports.filter(x => x.paraReportName == 'Coupon Codes')[0].summaryHeader;
  colflds = this._abd.reports.filter(x => x.paraReportName == 'Coupon Codes')[0].colflds;

  constructor(
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    private _confirmationService: ConfirmationService,
    public _abd: AppBaseDataService,
    public _filterService: FilterServiceService,
    public _rewardProgramService: RewardProgramService,
    private _messageService: MessageService,
    public _customerService: CustomersService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Coupon Codes'
    this.getCustomersList()
  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      let ppages = this._filterService.getPages(this._globals.componentName)
      if (ppages) {
        let lastpage = parseInt(ppages.page)
        if (lastpage < 0) lastpage = 0
        if (lastpage > 0) {
          this.page = lastpage
          this.first = lastpage * this.rows
        }
      }
      let filter = this._filterService.getFilters(this._globals.componentName)
      if (filter) {
        if (filter.filterValue) this._filterService.searchText = filter.filterValue
      } 
      clearInterval(ti)
      this.getDataList()
    }, 500);
  }

  getDataList() {
    this.page = this.page < 0 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    const obj = {
      "PageNo": this.page,
      "PageSize": this.rows,
      "filterValue": this._filterService.searchText,
      // "discountValue": this._filterService.selectedCouponCodeDiscountType !== null ? this._filterService.selectedCouponCodeDiscountType.id: null,
      "orderParam":this.orderParam,
      "orderBy":this.orderBy,
    }

    this._abd.showSpinner()
     try {
       this._rewardProgramService.getPointsAccumulation(obj).subscribe(res => {
         if (res.success) {
           this.totalRecords = res.totalRecords
           this.dataList = res.data
          this.masterDataList = this.dataList;
           this._abd.hideSpinner()

        }
      })

      this._filterService.updateFilters(this._globals.componentName, obj)
      this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })

    } catch { this._abd.hideSpinner() }
  }
  getCustomersList() {
    this.isLoadCustomerGroup = true
    let params = new HttpParams();
    let page = 0
    let rows = 99999
    params = params.append('pageNo', page.toString());
    params = params.append('pageSize', rows.toString());
    if (this._abd.searchText.length) params = params.append('filterValue', this._abd.searchText.trim());
    this._customerService.getAllCustomerGroups(params).subscribe(res => {
      this.customersList = res.data
      this.totalRecords = res.totalRecords
      this.isLoadCustomerGroup = false

    })
  }
  getCustomerNames(idsToFilter:any[]){
  let filteredData = this.customersList.filter(item => idsToFilter.includes(item.id));
  return filteredData.map((v)=>v.title)
 }

  ngOnDestroy() {
    this._globals.componentName = ''
  }
  addNewClick(){
    this.isViewOnly = false
    this._rewardProgramService.activeItem = null
    this._rewardProgramService.showNewEditForm = true;
  }

  refrestDataList({ e }: { e: any; }) {
    this.getDataList();
  }
  statusButtonClicked() {
    this.op.hide();
    let activate = 'Do you wish to activate?'
    let deactivate = 'Do you wish to deactivate?'
    this._confirmationService.confirm({
        message: this._rewardProgramService.activeItem.active === true ? deactivate : activate ,
        header: 'Change Status',
        icon: 'pi pi-info-circle',
        accept: () => {
            this._abd.showSpinner();
            this._rewardProgramService.activeItem.active = !this._rewardProgramService.activeItem.active
            try {
                this._rewardProgramService
                    .updatePointsAccumulation(
                      this._rewardProgramService.activeItem.id,this._rewardProgramService.activeItem
                    )
                    .subscribe((res) => {
                        this._abd.hideSpinner();
                        this.getDataList();
                    });
            } catch {
                this._abd.hideSpinner();
            }
        },
        reject: () => {},
    });
}
  deleteButtonClicked() {
    this.op.hide()
    this._confirmationService.confirm({
      message: 'Deleting process causes permanent loss of information. Are you sure to proceed',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._abd.showSpinner()
        try {
          this._rewardProgramService.deletePointsAccumulation(this._rewardProgramService.activeItem.id).subscribe(res => {
            if (res.success) {
              this.getDataList();
              this._connMessageService.showToastMessage(`Sales Channel deleted!`, 'success')
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    });
  }
  actionButtonClick(item: any) {
    this._rewardProgramService.activeItem = JSON.parse(JSON.stringify(item))
  }
  editButtonClicked() {
    this.isViewOnly = false
    this._rewardProgramService.showEditForm = true;
  }

  onPageChange(e) {
    this.page = e.page
    this.rows = e.rows
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)

    this.getDataList()
  }

 

  onRowSelect(e) {
    this.isViewOnly = true
    this._rewardProgramService.activeItem = JSON.parse(JSON.stringify(e.data))
    this._rewardProgramService.showEditForm = true;
  } 

  customSort(e) {
    // const last = this.orderParam
    // switch (e.sortField) {

    //   case 'discountName':
    //     this.orderParam = 'discountName'
    //     break;
    //   case 'discountValueType':
    //     this.orderParam = 'discountValueType'
    //     break;
    //   case 'discountValue':
    //     this.orderParam = 'discountValue'
    //     break;
    //   case 'isActive':
    //     this.orderParam = 'isActive'
    //     break;
    //   default:
    //     this.orderParam = ''
    // }
    // if (this.orderParam !== '') {
    //   this.orderBy = e.sortOrder == 1 ? 'asc' : 'desc'
    //   if (last !== this.orderParam) this.orderBy = 'asc'
    //   this.getDataList()
    // }
  }

}
