import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Paginator } from 'primeng/paginator';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { InventoryService } from 'src/app/_services/inventory.service';
import { environment } from 'src/environments/environment';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inventory-count',
  templateUrl: './inventory-count.component.html',
  styleUrls: ['./inventory-count.component.scss']
})

export class InventoryCountComponent implements OnInit {
  dataList: any = null
  locations: any = []

  page = 0
  rows = 10
  first = 0
  totalRecords = 0

  selectAll = false
  searchText = ''
  lastSearchText = ''
  autoAdjustQty = true

  selectedRow: any = ''

  gProductId: any = null

  //order
  orderParam = ''
  orderBy = ''

  _globals = GlobalService
  @ViewChild('p', { static: false }) paginator: Paginator;


  colheader = [[
    'Barcode', 'SkuCode', 'Item Name'
    , 'Opening Stock', 'Used Since', 'Now on Hand'
  ]];

  colflds = ['barcode', 'skuCode', 'itemName',
    'openingStock', 'usedSince', 'stockInHand'];

  csvcols = []
  exportColumns: any = ''

  constructor(
    // private fb: FormBuilder,
    private _bs: BaseService,
    private _inventoryService: InventoryService,
    private _connMessageService: ConnMessageService,
    private _spinner: NgxSpinnerService,
    private _confirmationService: ConfirmationService,
    private _abd: AppBaseDataService,
    private _router: Router,
    private _filterService: FilterServiceService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    this._globals.componentName = 'Inventory Count'
    this._abd.searchText = ''

  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      let ppages = this._filterService.getPages(this._globals.componentName)
      if (ppages) {
        let lastpage = parseInt(ppages.page)
        if (lastpage < 0) lastpage = 0
        if (lastpage > 0) {
          this.page = lastpage
          this.first = lastpage * this.rows
        }
      }

      let filter = this._filterService.getFilters(this._globals.componentName)
      if (filter) {
        if (filter.searchText) this._filterService.searchText = filter.searchText
        const rr = this._abd.getProductSellTypeFilter(filter.selectedProductSellTypeFilter)
        if (filter.selectedProductSellTypeFilter) this._filterService.selectedProductSellTypeFilter = rr
        // if (filter.startDate) this._filterService.dateRange[0] = new Date(filter.startDate)
        // if (filter.endDate) this._filterService.dateRange[1] = new Date(filter.endDate)

        if (filter.selectedLocation) this._filterService.selectedLocation = filter.selectedLocation
        if (filter.selectedDepartment) this._filterService.selectedDepartment = filter.selectedDepartment
        if (filter.selectedVendor) this._filterService.selectedVendor = filter.selectedVendor
        if (filter.selectedManufacturer) this._filterService.selectedManufacturer = filter.selectedManufacturer
        if (filter.selectedCategory) this._filterService.selectedCategory = filter.selectedCategory
      }

      let oorderby = this._filterService.getOrderby(this._globals.componentName)
      if (oorderby) {
        this.orderParam = oorderby.orderParam
        this.orderBy = oorderby.orderBy
      }


      this.getInventoryCountInitData()
      clearInterval(ti)
    }, 700);
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getInventoryCountInitData() {
    this.locations = this._abd.initActLocations()
    this._abd.selectedLocation = this.locations[0]
    if(!this._filterService.selectedLocation) this._filterService.selectedLocation = this.locations[0]
    this._abd.selectedCategory = null
    this._abd.selectedCategory = null
    this._abd.selectedVendor = null
    this._abd.selectedManufacturer = null
    this.getDataList()
  }

  makeParams() {
    let params = new HttpParams();
    let startDate: any = null
    let endDate: any = null


    if (!this._filterService.dateRange || this._filterService.dateRange.length < 1) {
      this._filterService.dateRange = []
      startDate = (new Date(new Date().setDate(new Date().getDate() - 7)))
      endDate = (new Date())
    } else {
      startDate = new Date(this._filterService.dateRange[0])
      endDate = new Date(this._filterService.dateRange[1])
    }
    // startDate=new Date(2010, 2, 30)
    params = params.append('startDate', formatDate(new Date(startDate), 'MM/dd/yyyy', 'en-US'));
    params = params.append('endDate', formatDate(new Date(endDate), 'MM/dd/yyyy', 'en-US'));
    // console.log(this._filterService.selectedLocation);
    
    if (this._filterService.selectedLocation) params = params.append('locationId', this._filterService.selectedLocation?.id?.toString());
    if (this._filterService.selectedDepartment) params = params.append('departId', this._filterService.selectedDepartment?.id?.toString());
    if (this._filterService.selectedCategory) params = params.append('categoryId', this._filterService.selectedCategory?.id?.toString());
    if (this._filterService.selectedVendor) params = params.append('vendorId', this._filterService.selectedVendor?.id?.toString());
    if (this._filterService.selectedManufacturer) params = params.append('manufactureId', this._filterService.selectedManufacturer?.id.toString());

    try {
      if (this.searchTextSearch.length > 0) this.page = this.paginator.paginatorState.page - 1
      else this.page = this.paginator.paginatorState.page
      //console.log('page--', this.paginator.paginatorState.page)
    } catch { }

    this.page = this.page < 1 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    params = params.append('pageNo', this.page.toString());
    params = params.append('pageSize', this.rows.toString());

    let sellOnline = this._filterService.selectedProductSellTypeFilter?.id;
    if (sellOnline) {
      if (sellOnline == 1)
        params = params.append('sellOnline', 'true')
      else if (sellOnline == 2)
        params = params.append('sellOnline', 'false')
    } else {
      sellOnline = 3
      // params = params.append('sellOnline', 'true')
    }

    if (this._filterService.searchText.length > 0) params = params.append('filterValue', this._filterService.searchText);

    const outfilter = {
      // startDate: startDate,
      // endDate: endDate,
      searchText: this._filterService.searchText ? this._filterService.searchText : '',
      selectedProductSellTypeFilter: sellOnline,
      selectedLocation: this._filterService.selectedLocation,

      selectedCategory: this._filterService.selectedCategory,
      selectedVendor: this._filterService.selectedVendor,
      selectedManufacturer: this._filterService.selectedManufacturer,
      selectedDepartment: this._filterService.selectedDepartment,
    }

    this._filterService.updateFilters(this._globals.componentName, outfilter)
    this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })


    return params
  }

  async getDataList() {
    this._abd.showSpinner()
    let qryParas = null
    if (this.orderParam && this.orderParam.length > 0) {
      qryParas = `?orderParam=${this.orderParam}&orderBy=${this.orderBy}`
      this._filterService.updateOrderby(this._globals.componentName, { orderParam: this.orderParam, orderBy: this.orderBy })
    }

    let url = environment.InventoryBaseURL + environment.GetStockStatusReport;
    if (qryParas) url += qryParas

    this._bs.httpGetParams(url, this.makeParams()).subscribe(res => {
      if (res.success) {
        this._abd.hideSpinner()
        this.totalRecords = res.totalRecords
        this.dataList = res.data

        for (let i = 0; i < this.dataList.length; i++) {
          this.dataList[i].selected = false
          this.dataList[i].changedStockInHand = this.dataList[i].stockInHand >= 0 ? this.dataList[i].stockInHand : 0
        }
      }
    })
  }

  clearSearchText() {
    this._abd.searchText = ''
    this.filterButtonPressed()
  }

  filterButtonPressed() {
    const xx = this.isDataChanged()
    if (xx) {
      this._confirmationService.confirm({
        message: 'Data change detected. Proceeding further will lose changes. \nDo you want to proceed without saving?',
        header: 'Proceed Confirmation',
        icon: 'pi pi-times-circle',
        accept: () => {
          this.filterButtonPressedProcess()
          return
        },
        reject: () => {
          return
        }
      });
    } else {
      this.filterButtonPressedProcess()
    }
  }

  filterButtonPressedProcess() {
    for (let i = 0; i < this.dataList?.length; i++) {
      this.dataList[i].selected = false
    }

    this._globals.sleep(500).then(() => {
      this.page = 0
      // this.paginator?.changePage(0)
      try {
        this.paginator?.changePage(0)
      } catch { }
      this.getDataList()
    })

  }

  searchTextSearch() {
    // if (this._abd.searchText.length < 1 && this.lastSearchText.length < 1) return
    if (this._filterService.searchText === this.lastSearchText) return

    this.lastSearchText = this._filterService.searchText
    this.filterButtonPressed()
  }

  async onPageChange(e) {
    // if (e.page === this.page - 1) return
    const xx = await this.isDataChanged()

    if (xx) {
      this._confirmationService.confirm({
        message: 'Data change detected. Proceeding further will loose changes. \nDo you want to proceed without saving?',
        header: 'Proceed Confirmation',
        icon: 'pi pi-times-circle',
        accept: () => {
          e.totalRecords = this.totalRecords
          this.rows = e.rows
          this.page = e.page + 1
          this.getDataList()
        },
        reject: () => {
          try {
            this.paginator.changePage(this.page - 1)
          } catch { }

          return
        }
      });
    } else {
      e.totalRecords = this.totalRecords
      this.rows = e.rows
      this.page = e.page + 1
      this.getDataList()
    }

  }

  selectAllChanged(event) {
    if (event.checked) {
      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = event.checked
      }
    } else {
      for (let i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].stockInHand !== this.dataList[i].changedStockInHand)
          this.dataList[i].selected = true
        else
          this.dataList[i].selected = false
      }
    }
  }

  stockQuantityChanged(productId) {
    for (let i = 0; i < this.dataList.length; i++) {
      if (this.dataList[i].productId === productId) {
        if (this.dataList[i].stockInHand !== this.dataList[i].changedStockInHand)
          this.dataList[i].selected = true
        else
          this.dataList[i].selected = false
        break
      }

    }
  }

  postInventory() {
    let AdjustInventory = false;
    if (this.autoAdjustQty) {
      this._confirmationService.confirm({
        message: 'Posting Inventory will Adjust the Quantity on Hand. Do you wish to continue? ',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.autoAdjustQty = true;
          this.postInventoryRequest();
        },
        reject: () => { this.autoAdjustQty = false; }
      });
    }
    else {
      this.postInventoryRequest();
    }
  }

  postInventoryRequest() {

    this._abd.showSpinner()
    try {
      let model: any = []
      for (let i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].selected) {
          model.push({
            locationId: this.dataList[i].locationId.toString(),
            skuCode: this.dataList[i].skuCode,
            quantity: this.dataList[i].changedStockInHand ?? 0,
            productId: this.dataList[i].productId,
            adjustInventory: this.autoAdjustQty
          })
        }
      }
      const outData = {
        data: model
      }
      this._inventoryService.saveInventoryVerificationCount(outData).subscribe(res => {
        if (res.success) {
          // this._abd.hideSpinner()
          this.getDataList()
          this._connMessageService.showToastMessage('Operation successfull', 'success')
        }
      })
    } catch {
      this._abd.hideSpinner()
    }
  }

  callbackFuncFromFilter(event) {
    // this.getDataList()
    this.filterButtonPressed()
  }

  exportExcel() {
    // this._globals.exportExcel(this.dataList, this.colflds, 'Inventory Count')
  }

  exportPdf() {
    let data = []

    for (let i = 0; i < this.dataList.length; i++) {
      let row = []
      for (let j = 0; j < this.colflds.length; j++)
        row.push(this.dataList[i][this.colflds[j]])
      data.push(row)
    }

    // this._globals.exportPdf(data, this.colheader, 'Inventory Count')
  }

  isDataChanged(): boolean {
    if (!this.dataList) return false
    let changed = false
    for (let i = 0; i < this.dataList.length; i++) {
      if (this.dataList[i].selected) {
        changed = true
        break
      }
    }
    return changed
  }

  createProduct() {
    this._router.navigate(['app/new-product'])
  }

  onRowSelect(e) {
    console.log('on-row-select', e)
    const el = e.originalEvent.target.className
    if (el.includes('p-checkbox-box')) return

    this.gProductId = e.data.itemSkuId
  }

  onRowUnselect(e) { }

  selectionChanged(event) {
    let ret = false
    if (!event.checked) {
      this.selectAll = this.selectAll ? !this.selectAll : this.selectAll
    }

    for (let i = 0; i < this.dataList.length; i++)
      if (this.dataList[i].selected) {
        ret = true
        break
      }

    if (event.checked) {
      const sel = this.dataList.filter(f => f.selected)
      if (sel.length == this.dataList.length) this.selectAll = true
      else this.selectAll = false
    }

    this._globals.showProductDelButton = ret
  }

  customSort(e) {
    const last = this.orderParam

    switch (e.sortField) {
      case 'itemName':
        this.orderParam = 'title'
        break;
      case 'barcode':
        this.orderParam = 'Barcode'
        break;
      case 'skuCode':
        this.orderParam = 'SkuCode'
        break;
      default:
        this.orderParam = ''
    }

    if (this.orderParam !== '') {
      this.orderBy = e.sortOrder == 1 ? 'asc' : 'desc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }

}
