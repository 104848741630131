<!-- <h3
    style="
        margin-top: 0px;
        font-size: 1.7rem;
        color: var(--bs-active-primary);
        font-weight: 700px;
    "
>
    Hardware Details
</h3>

<ng-container *ngFor="let lob of localObj">
    <div class="nas-grid-2x">
        <div class="nas-label">Title</div>
        <div class="nas-value">
            {{ lob.title }}
        </div>
    </div>

    <br />
    <div class="nas-grid-2x">
        <div class="nas-label">Description</div>
        <div class="nas-value">
            {{ lob.description }}
        </div>
    </div>

    <br />
    <div class="nas-grid-2x">
        <div class="nas-label">Price</div>
        <div class="nas-value">
            {{ lob.price | currency: "USD":"symbol" }}
        </div>
    </div>

    <br />
    <div class="nas-grid-2x">
        <div class="nas-label">Details</div>
        <div class="nas-value">
            <ul class="nas-ul" style="list-style-type:square;">
                <li  *ngFor="let feature of lob.features"
                style="font-weight:700px; margin-bottom: 7px;">{{ feature.data }}</li>
              </ul>
        </div>
    </div>

    <br>
</ng-container> -->

<br>
<br>

<h3
    style="
        margin-top: 0px;
        font-size: 1.7rem;
        color: var(--bs-active-primary);
        font-weight: 700px;
    "
>
    Details
</h3>
<p-divider></p-divider>
<br />
<p-table
    styleClass="p-datatable-striped"
    [autoLayout]="true"
    [tableStyle]="{ width: 'max-content' }"
    responsiveLayout="scroll"
    [value]="hardwareObj"
    rowHover="true"
>
    <ng-template pTemplate="header">
        <tr>
            <th>Hardware/Accessories</th>
            <!-- <th>Model</th> -->
            <!-- <th>Serial Number</th> -->
            <th style="text-align: center">Qty</th>
            <th style="text-align: center">Price Including Tax</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td >{{item.title}}</td>
          
            <!-- <td >{{item.model}}</td>   -->
            <!-- <td >{{item.serialNo}} </td> -->
            <td style="text-align: center">{{item.qty}}</td>
            <td style="text-align: center">{{item.totalPrice | currency}}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" style="text-align: center">
        <tr>
            <td colspan="17" style="text-align: center">
                No data found
            </td>
        </tr>
    </ng-template>
</p-table>

