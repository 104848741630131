import { GlobalService } from 'src/app/_services/global.service';
import { LocationsService } from 'src/app/_services/locations.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { UsStates } from 'src/app/_data/us-states';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';

@Component({
  selector: 'app-location-new-edit',
  templateUrl: './location-new-edit.component.html',
  styleUrls: ['./location-new-edit.component.scss']
})
export class LocationNewEditComponent implements OnInit {
  @Input() isNew: boolean = true
  @Input() isViewOnly: boolean = false


  _globals = GlobalService
  editform: FormGroup
  usaStates = UsStates
  showCityWait = false

    @Output() locationUpdated = new EventEmitter<any>()
  constructor(
    public _abd: AppBaseDataService,
    private _fb: FormBuilder,
    public _locationService: LocationsService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    public _businessSetupService: BusinessSetupService
  ) { }

  ngOnInit(): void {
   this._locationService.clickButton$.subscribe((v)=>{
    this._locationService.blankActiveItem()
    this.initForm()
    this.initFormEdit()
   })
    // this.blankInputFields()
    this.initForm()
    this.initFormEdit()
  }

  ngAfterViewInit() { }

  ngOnDestroy() {
    this._locationService.blankActiveItem()
  }

  initFormEdit() {
    try {
      if (this.isNew) {
        // this._locationService.blankActiveItem().then(res => {
        //   return
        // })
        this._locationService.blankActiveItem()
        // return
      }
      // //console.log('this._locationService.activeItem', this._locationService.activeItem);
      // //console.log('this.usaStates', this.usaStates);
      const st = this.usaStates.filter(s => s.abbreviation == this._locationService.activeItem.state)
      this._locationService.activeItem.selectedState = st[0];
      // this.editform.patchValue({ zip: this._locationService.activeItem.zip })
      let formDirective: FormGroupDirective
      formDirective.resetForm()
      this.editform.reset()
    } catch (error) {

    }
  }

  blankInputFields() {
    this._locationService.blankActiveItem()

    // this.editform.reset()
    // Object.keys(this.editform.controls).forEach(key => {
    //   this.editform.get(key).setErrors(null) ;
    // })
    // let formDirective: FormGroupDirective
    // formDirective.resetForm()
    // this.editform.reset()
  }

  get f() {
    return this.editform.controls;
  }

  initForm() {
    this.editform = this._fb.group({
      locationName: [{ value: this._locationService.activeItem?.locationName, disabled: this.isViewOnly }, Validators.required],
      phone: [{ value: this._locationService.activeItem?.phone, disabled: this.isViewOnly }, this._abd.phoneValidator()],
      manageInventory: [{ value: this._locationService.activeItem?.manageInventory, disabled: this.isViewOnly }],
      allowShipping: [{ value: this._locationService.activeItem?.allowShipping, disabled: this.isViewOnly }],
      acceptOnlineOrders: [{ value: this._locationService.activeItem?.acceptOnlineOrders, disabled: this.isViewOnly }],
      address: [{ value: this._locationService.activeItem?.address, disabled: this.isViewOnly }, Validators.required],
      zip: [
        { value: this._locationService.activeItem?.zip, disabled: this.isViewOnly },
        [Validators.required]
      ],
      city: [{ value: this._locationService.activeItem?.city, disabled: this.isViewOnly }, Validators.required],
    })
  }

  zipInputEvent(value: string) {
    // alert(value.replace(/\D/g, '').length+'  '+value.replace(/\D/g, ''))
    if (!value || value.replace(/\D/g, '').length !== 5) return
    this.showCityWait = true
    this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
      this.showCityWait = false
      if (res.success && res.data.city != undefined) {
        this._locationService.activeItem.city = res.data.city + '';
        const st = this.usaStates.filter(s => s.abbreviation == res.data.state)
        this._locationService.activeItem.selectedState = st[0];
        this.editform.patchValue({ city: res.data.city })
        // //console.log('city-data', this._vendorService.activeItem)
      } else {
        this._locationService.activeItem.city = "Invalid zip code"
        this.editform.patchValue({ city: "Invalid zip code" })
        this._locationService.activeItem.selectedState = this.usaStates[0]
      }
    })
  }

  async saveFn() {
    this._abd.showSpinner()
    const zip = this._abd.validateZipcode(this.editform.get('zip').value).subscribe(res => {
      if (res) {
        const obj = {
          id: this._locationService.activeItem.id,
          LocationName: this._locationService.activeItem.locationName,
          ManageInventory: this.editform.get('manageInventory').value, //this._locationService.activeItem.manageInventory,
          Phone: this.editform.get('phone').value.replace(/\D/g, ''), //this._locationService.activeItem.phone.replace(/\D/g,''),
          Address: this._locationService.activeItem.address,
          City: this._locationService.activeItem.city,
          State: this._locationService.activeItem.selectedState.abbreviation,
          zip: this.editform.get('zip').value.slice(0, 5), //this._locationService.activeItem.zip,
          Country: "US",
          LocationId: this._globals.userInfo.businessSetup[0].businessId,
          AllowShipping: this.editform.get('allowShipping').value, //this._locationService.activeItem.allowShipping,
          AcceptOnlineOrders: true, //this._locationService.activeItem.acceptOnlineOrders
          // AcceptOnlineOrders: this.editform.get('acceptOnlineOrders').value, //this._locationService.activeItem.acceptOnlineOrders
        }

        if (!this.isNew)
          obj.LocationId = this._locationService.activeItem.id

        this._locationService.createUpdateItem(obj, this.isNew).subscribe(res => {
          this._abd.loadInit().subscribe(ress => {
            this.refreshData()
            this.cancelButtonClick()
            this._abd.hideSpinner()
              this.locationUpdated.emit(true);
            return ress
          })
        })
      } else {
        this._connMessageService.showToastMessage(`Invalid postal code.`, 'error');
        this._abd.hideSpinner()
      }
    })

  }

  cancelButtonClick() {
    this.editform.reset()
    this._locationService.isEditLocation = false
    this._locationService.isNewLocation = false
    this._businessSetupService.isEditLocation = false;
  }

  refreshData() {
    const loc = this._abd.locations.filter(f =>
      (!f.isDeleted && f.active)
    )
    this._abd.initLocations(loc)
  }





}
