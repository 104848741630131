<h3
style="
    margin-top: 0px;
    font-size: 1.7rem;
    color: var(--bs-active-primary);
    font-weight: 700px;
"
>
Cash Discount Configurations
</h3>
<p-divider></p-divider>
<br />


     
            <div>
                <div *ngIf="locationsList === null">
                    <i class="pi pi-spin pi-spinner  flex justify-content-center align-items-center" style="color: var(--brandColor);font-size: x-large;" ></i>     
                   </div>
                <!-- <div class="mb-2 " *ngIf="locationsList !== null">
                
                    <label class="control-label"><strong>Location
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label>
                                <p-dropdown
                                [style]="{width:'100%',margin:'5px 0px'}"
                                [(ngModel)]="currentSelectedLocation"
                                (onChange)="locationChanged($event)"
                                class="p-dropdown-1"
                                [options]="locationsList"
                                optionLabel="locationName"
                                appendTo="body"
                            >
                            </p-dropdown></div> -->
         <br /> 
         <div *ngIf="cashDiscountSettingList === null && locationsList !== null " >
        <i class="pi pi-spin pi-spinner  flex justify-content-center align-items-center" style="color: var(--brandColor);font-size: x-large;" ></i>     
       </div>
                        
         
                <div *ngIf="cashDiscountSettingList !== null">
                      <form
                    [formGroup]="cashDiscountForm"
                    autocomplete="off"
                >
                
                <!-- <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(cashDiscountForm.controls)['enableTip']"
                        inputId="rememberme7"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme7">Enable Tip</label>
                </div>
                <br/>
                <div  *ngIf="f.enableTip?.value === true">
                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(cashDiscountForm.controls)['promptCustomerTip']"
                        inputId="rememberme8"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme8">Enable Prompt Customer For Tip</label>
                </div>
                </div>

                <br /> -->
                <div formArrayName="tenderTypes">
                    <div *ngFor="let tender of cashDiscountForm.get('tenderTypes')['controls']; let i = index" [formGroupName]="i" class="flex align-items-center py-2">
                    <div class="field-checkbox col-2 p-0 m-0"  style="color: var(--bluegray-300); font-weight: 600px;"> 
                    <label > {{ tender.get('displayName').value }}</label>
                    </div>
               
                    <div class="field-checkbox col-3 p-0 m-0">
                        <p-checkbox
                        formControlName="enableCashDisc"
                        inputId="enableCashDisc{{i+1}}"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="enableCashDisc{{i+1}}">Enable Cash Discount</label>
                    </div>  
                    <!-- <br />   -->
                     <div class="field-checkbox col-3 p-0 m-0">
                    <label class="mx-2 col-6">Cash Discount(%) <span style="color: red; font-size: 1.25rem">*</span> </label> <span class="col-3">
                        <input type="text" pInputText class="px-2" [ngClass]="{'disabled-input': !tender.get('enableCashDisc').value }" [style]="{width:'100%',padding:'8px'}" formControlName="cashDiscountPer" />
                    </span>   
                    </div> 
                    <!-- <br />    -->
                     <div class="field-checkbox col-3 p-0 m-0">
                        <p-checkbox
                        formControlName="showRemarks"
                         inputId="showRemarks{{i+3}}"
                         [binary]="true"
                         styleClass="mr-2"
                     ></p-checkbox>
                     <label for="showRemarks{{i+3}}">Enable Remarks</label>
                    </div> 
                    <!-- <br /> -->
               
            </div>
                </div>
          
                <!-- <br/> -->


                    <div
                        class="flex justify-content-end flex-wrap card-container green-container mt-3"
                    >
                        <div class="flex align-items-center justify-content-center">
                               <!-- <button
                                class="mx-2"
                                pButton
                                pRipple
                                type="button"
                                (click)="saveOnAllLocations()"
                                label="Apply to All Locations"
                                ></button>  -->
                                 <button
                                pButton
                                pRipple
                                type="submit"
                                label="Save"
                                (click)="saveFn()"
                                 ></button>
                       
                        </div>
                        <!-- <div
                            class="flex align-items-center justify-content-center ml-2"
                            *ngIf ="singupBusinessConfig == true"
                            >
                            <button
                                pButton
                                pRipple
                                type="button"
                                label="Cancel"
                                class="p-button-outlined p-button-danger"
                                (click)="cancelButtonClick()"
                            ></button>
                        </div> -->
                    </div>
                </form>   
                </div>
                  
             
            </div>
     

 