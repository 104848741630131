<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <!-- <div class="conn-grid" style="margin-top: 1px"> -->
        <div></div>
        <button
            pButton
            pRipple
            type="button"
            label="Edit"
            class="p-button-text"
            [style]="{ width: '100%' }"
            icon="pi pi-pencil"
            iconPos="right"
            (click)="optionEditClicked()"
        ></button>
        <button
            pButton
            pRipple
            type="button"
            label="Change Status"
            class="p-button-danger p-button-text"
            [style]="{ width: '100%' }"
            icon=""
            iconPos="right"
            (click)="optionStatusChangeClicked()"
        ></button>
        <button
            pButton
            pRipple
            type="button"
            label="Delete"
            class="p-button-danger p-button-text"
            [style]="{ width: '100%' }"
            icon=""
            iconPos="right"
            (click)="optionDeleteClicked()"
        ></button>
        <!-- </div> -->
    </ng-template>
</p-overlayPanel>

<!-- this is the one -->
<div class="fadeindown animation-duration-500 flex" *ngIf="1 == 1">
    <div class="conn-card" style="width: 100%">
        <app-report-top-filter-bar
            [paraReportName]="'customer-group'"
            (filterCallbackFunc)="callbackFuncFromFilter($event)"
            (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
            (exportExcelFn)="exportExcel()"
            (exportPdfFn)="exportPdf()"
            (exportCsvFn)="dt.exportCSV()"
            (deleteItemFn)="deleteMultiple()"
        ></app-report-top-filter-bar>
        <br />

        <p-table
            *ngIf="dataList?.length > 0"
            #dt
            [columns]="csvcols"
            styleClass="p-datatable-striped"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="dataList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            selectionMode="single"
            [(selection)]="selectedRow"
            dataKey="id"

        >
            <ng-template pTemplate="header">
                <tr>
                    <!-- <th style="text-align: center"> -->
                        <!-- <p-checkbox
                            [(ngModel)]="selectAll"
                            [binary]="true"
                            (onChange)="selectAllChanged($event)"
                        ></p-checkbox> -->
                    <!-- </th> -->

                    <th style="text-align: center">Date Created</th>
                    <th style="text-align: center">Group Name</th>
                    <th style="text-align: center">Status</th>
                    <th style="text-align: center">Customer Count</th>
                    <th style="text-align: center">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr [pSelectableRow]="item">
                <!-- <tr
                    [pSelectableRow]="item"
                    class="text-center {{
                        item.selected == true ? 'p-highlight' : ''
                    }}"
                > -->
                    <!-- <td style="text-align: center">
                        <p-checkbox
                            [(ngModel)]="item.selected"
                            [binary]="true"
                            (onChange)="selectionChanged(item)"
                        ></p-checkbox>
                    </td> -->
                    <td style="text-align: center">{{ item.createdDate | date:'MM/dd/yyyy' }}</td>
                    <td style="text-align: center" >{{ item.title }}</td>
                    <td
                        style="text-align: center"
                        class="{{
                            item.isActive == true
                                ? 'Active badge'
                                : 'In-Active badge'
                        }}"
                    >
                        <span>{{
                            item.isActive == true ? "Active" : "Inactive"
                        }}</span>
                    </td>
                    <td style="text-align: center;">
                        {{item.customersCount}}
                    </td>

                    <td style="text-align: center">
                        <button
                            pButton
                            pRipple
                            icon="pi pi-angle-down"
                            iconPos="right"
                            type="button"
                            label="Actions"
                            class="n-action-button"
                            (click)="op.toggle($event)"
                            (click)="actionButtonClick(item)"
                        ></button>

                        <!-- (click)="op.toggle($event)" -->
                        <!-- (click)="actionButtonClick(item)" -->
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="flex" *ngIf="dataList?.length == 0">
            <div class="text-align-center" style="width: 100%">
                <span class="control-description text-muted text-align-center">
                    No data found!</span
                >
            </div>
        </div>
        <p-paginator
            #p
            *ngIf="dataList?.length > 0"
            (onPageChange)="onPageChange($event)"
            [rows]="rows"
            [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"
        ></p-paginator>
    </div>
</div>

 <p-dialog
    *ngIf="_customerService.showNewGroupEdit"
    header="Update Customer Group"
    [(visible)]="_customerService.showNewGroupEdit"
    [style]="{ width: '80%' }"
    modal="true"
>
<!-- <app-group-customers (loadFreshDataEvent)="refrestDataList($event)">
</app-group-customers> -->
     <app-new-edit-customer-group [isNew]="false" (loadFreshDataEvent)="refrestDataList($event)"></app-new-edit-customer-group>
</p-dialog>

<!-- <p-dialog
    *ngIf="_customerService.showNewNewGroupEdit"
    header="Add Customer Group Name"
    [(visible)]="_customerService.showNewNewGroupEdit"
    [style]="{ width: '80%' }"
    modal="true"
> -->
<!-- <app-group-customers (loadFreshDataEvent)="refrestDataList($event)">
</app-group-customers>
     <app-new-edit-customer-group [isNew]="true" (loadFreshDataEvent)="refrestDataList($event)"></app-new-edit-customer-group>
</p-dialog> -->

<p-dialog
    *ngIf="_customerService.showNewNewGroupEdit"
    [(visible)]="_customerService.showNewNewGroupEdit"
    header="Customer Group"
     [breakpoints]="{'960px': '75vw', '640px': '100vw'}"   [style]="{width: '90vw'}" modal="true">
    <app-group-customers [groupInfo]="groupInfo" [isNew]="true" (loadFreshDataEvent)="refrestDataList($event)"
    (cancelCustomerGroup)="hideCustomerGroupModal($event)">
    </app-group-customers>
</p-dialog>
<ng-content *ngIf="_customerService.showNewGroupEdit"></ng-content>
<p-dialog
    *ngIf="_customerService.showNewGroupEdit"
    [(visible)]="_customerService.showNewGroupEdit"
    header="Customer Group"
     [breakpoints]="{'960px': '75vw', '640px': '100vw'}"   [style]="{width: '90vw'}" modal="true">
    <app-group-customers [groupInfo]="groupInfo" [isNew]="false" (loadFreshDataEvent)="refrestDataList($event)"
    (cancelCustomerGroup)="hideCustomerGroupModal($event)">
    </app-group-customers>
</p-dialog>
