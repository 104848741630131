import { map, Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { APIURL, environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionPlanService {
    selectedSubscription: any = ''
    selectedHardware = []

    constructor(private _bs: BaseService, private http: HttpClient) { }

    getSubscriptionPlanList(): Observable<any> {
        return this._bs.httpGet(APIURL + '/auth-svc/api/GetSubscriptions').pipe(map(res => {
            return res
        }))
    }

    getBusinessInvoice() {
        return this._bs.httpGet(APIURL + '/account-svc/api/GetBusinessInvoice').pipe(map(res => {
            return res
        }))
    }
    getBusinessBillingInvoices(businessName="") {
       
        return this._bs.httpGet(APIURL + '/account-svc/api/GetBusinessInvoices',true,businessName).pipe(map(res => {
            return res
        }))
    }
    getBusinessUserPaymentProfile() {
        return this._bs.httpGet(APIURL + '/account-svc/api/GetPaymentProfile').pipe(map(res => {
            return res
        }))
    }
    cancelSubscription() {

        const url = APIURL + '/account-svc/api/' + environment.CancelBusinessSubscription
        let ret = false
        return this._bs.httpPost(url, {}).pipe(map(res => {
            return res
        }))
    }
    cancelSubscriptionByCustomer(isStripe?:boolean) {
       let url;
        if(isStripe === true){
        url = APIURL + '/account-svc/api/BusinessSubscription?operation=Cancel&IsStripe=true'
         }else{
        url = APIURL + '/account-svc/api/BusinessSubscription?operation=Cancel' 
         }
        // const url = APIURL + '/account-svc/api/BusinessSubscription?operation=Cancel'
        let ret = false
        return this._bs.httpPut(url, {}).pipe(map(res => {
            this.cancelSubscription();

            return res
        }))
    }
    upgradeSubscription(newplan: {},upgrade=true, execute="true",isStripe?:boolean , businessName="" ) {
        let ret:any;
        try {
            let action="SubscriptionPlanDowngrade"
            if(upgrade)
            action="SubscriptionPlanUpgrade"
            let url:any;
                 if(isStripe === true){
                     url = APIURL + "/account-svc/api/BusinessSubscription?operation=Update&updateItem="+action+"&execute="+execute+"&IsStripe=true"
                 }else{
                     url = APIURL + "/account-svc/api/BusinessSubscription?operation=Update&updateItem="+action+"&execute="+execute
                 }

            return this._bs.httpPut(url, newplan,false,true,businessName).pipe(map(res => {

                return res
            }))
        }
        catch
        {

        }


    }

    renewSubscription() {

        const url = APIURL + '/account-svc//api/' + environment.UpdateBusinessSubscription
        let ret = false
        return this._bs.httpPost(url, {}).pipe(map(res => {
            return res
        }))
    }
}
