<form
 [formGroup]="editform"
 autocomplete="off"
 (ngSubmit)="editform.valid && saveFn()"
>
 <div class="conn-card">
     <div class="conn-grid-2x">
         <div>
             <label class="control-label">
                 <strong>
                     Coupon Name
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />

             <app-text-input
                 [formControl]="$any(editform.controls)['discountName']"
                 [name]="'Coupon Name'"
                 [label]=""
             >
             </app-text-input>
         </div>
         <div>
             <label class="control-label">
                 <strong>
                    Sales Channel
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />

             <p-dropdown
                 [formControl]="$any(editform.controls)['appNotificationText']"
                 [style]="{}"
                 class="p-dropdown-1"
                 [options]="salesChannelList"
                 optionLabel="viewValue"
                 [autoDisplayFirst]="false"
               ></p-dropdown>
             <div *ngIf="$any(editform.controls)['appNotificationText'].invalid && $any(editform.controls)['appNotificationText'].touched" class="invalid-feedback">
                Please enter a Sales Channel
            </div>
         </div>
         <!-- <div>
             <label class="control-label">
                 <strong>
                     Customer Type
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />

             <p-dropdown
                 [formControl]="$any(editform.controls)['customerType']"
                 [style]="{}"
                 class="p-dropdown-1"
                 [options]="customerTypeList"
                 optionLabel="viewValue"
                 [autoDisplayFirst]="false"
             ></p-dropdown>
             <div *ngIf="$any(editform.controls)['customerType'].invalid && $any(editform.controls)['customerType'].touched" class="invalid-feedback">
                Please enter a Customer Type
            </div>
         </div> -->

         <div>
             <label class="control-label">
                 <strong>
                    Discount Type
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />

             <p-dropdown
                 [formControl]="$any(editform.controls)['discountValueType']"
                 [style]="{}"
                 class="p-dropdown-1"
                 [options]="discountTypeList"
                 optionLabel="viewValue"
                 [autoDisplayFirst]="false"
             ></p-dropdown>
             <div *ngIf="$any(editform.controls)['discountValueType'].invalid && $any(editform.controls)['discountValueType'].touched" class="invalid-feedback">
                Please enter a Discount Type
            </div>
         </div>

         <div *ngIf="f.discountValueType?.value?.id !== 2">
             <label class="control-label">
                 <strong>
                     Value <span *ngIf="f.discountValueType?.value?.id ==0">
                        ($)
                    </span>
                    
                     <span *ngIf="f.discountValueType?.value?.id == 1">
                         (%)
                     </span>
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />
             <app-text-input
             [formControl]="$any(editform.controls)['discountValue']"
             [name]="'Value'"
             [label]=""
         ></app-text-input>
         <div class="invalid-feedback" *ngIf="editform.get('discountValue').hasError('maxFractionalDigits')">
            Maximum of 2 fractional digits allowed after decimal.
          </div>        
       </div>

         <div *ngIf="f.discountValueType?.value?.id == 1">
             <label class="control-label">
                 <strong>
                    Max Discount ($)
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />
             <app-text-input
             [formControl]="$any(editform.controls)['maximumAllowed']"
             [name]="'Value'"
             [label]=""
             [description]="maxDiscountDesc"
              ></app-text-input>
              <div class="invalid-feedback" *ngIf="editform.get('maximumAllowed').hasError('maxFractionalDigits')">
                Maximum of 2 fractional digits allowed after decimal.
              </div> 
         </div>

         <div *ngIf="f.discountValueType?.value?.id !== 2">
             <label class="control-label">
                 <strong>
                     Max Coupon Use
                 </strong>
             </label>
             <br />
             <app-text-input
             [formControl]="$any(editform.controls)['maxCouponUse']"
             [name]="'Max Coupon Use'"
             [label]=""
             [description]="maxCouponUseDesc"
              ></app-text-input>
             <br />
         </div>

         <div *ngIf="f.discountValueType?.value?.id !== 2">
             <label class="control-label">
                 <strong>
                     Validity
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />

             <p-dropdown
                 [formControl]="$any(editform.controls)['validity']"
                 [style]="{}"
                 class="p-dropdown-1"
                 [options]="validityList"
                 optionLabel="viewValue"
             >
             </p-dropdown>
             <div *ngIf="$any(editform.controls)['validity'].invalid && $any(editform.controls)['validity'].touched" class="invalid-feedback">
                Please enter a Validity
            </div>
         </div>

         <div *ngIf="f.validity?.value?.id == 1 && f.discountValueType?.value?.id !== 2">
             <label class="control-label">
                 <strong>
                     Date Range
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />

             <p-calendar
                 [formControl]="$any(editform.controls)['validityDate']"
                 selectionMode="range"
                 [style]="{}"
                 [readonlyInput]="false"
                 inputId="multiple"
                 class="filter"
                 baseZIndex="0"
                 appendTo="body"
             >
             </p-calendar>
             <div *ngIf="$any(editform.controls)['validityDate'].invalid && $any(editform.controls)['validityDate'].touched" class="invalid-feedback">
                Please enter a Date Range
            </div>
         </div>

         <div *ngIf="f.discountValueType?.value?.id !== 2">
             <label class="control-label">
                 <strong>
                     Allowed days
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />

             <p-multiSelect
                 [formControl]="$any(editform.controls)['allowedDaysList']"
                 [style]="{}"
                 [options]="allowedDaysList"
                 optionLabel="viewValue"
                 [filter]="false"
                 [showToggleAll]="true"
             >
             <ng-template pTemplate="header">
                <div class="checkbox-all-text">Select All</div>
              </ng-template>
             </p-multiSelect>
             <div *ngIf="$any(editform.controls)['allowedDaysList'].invalid && $any(editform.controls)['allowedDaysList'].touched" class="invalid-feedback">
                Please enter a Allowed Days
            </div>
         </div>

         <div *ngIf="f.discountValueType?.value?.id !== 2">
            <label class="control-label">
                <strong>
                    Subscription Plan 
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>
            <br /> 
            <p-multiSelect
            [formControl]="$any(editform.controls)['allowedLocationsList']"
            [style]="{}"
            [options]="allowedLocationsList"
            [dropdownIcon]="subscriptionPlanDropdownIcon"
            optionLabel="viewValue"
            [filter]="false"
        >
        <ng-template pTemplate="header">
           <div class="checkbox-all-text">Select All</div>
         </ng-template>
        </p-multiSelect>

            <span class="control-description text-muted">Coupon will be applied to the selected plan</span>
             <div *ngIf="$any(editform.controls)['allowedLocationsList'].invalid && $any(editform.controls)['allowedLocationsList'].touched" class="invalid-feedback">
                Please enter a Subscription Plan
            </div>
        </div>

         <div>
            <label class="control-label">
                <strong>
                    Status
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>
            <br />
            <p-dropdown
                [formControl]="$any(editform.controls)['isActive']"
                [style]="{}"
                class="p-dropdown-1"
                [options]="statusList"
                optionLabel="viewValue"
                [autoDisplayFirst]="false"
            >
            </p-dropdown>
            <div *ngIf="$any(editform.controls)['isActive'].invalid && $any(editform.controls)['isActive'].touched" class="invalid-feedback">
                Please enter a Status
            </div>
        </div>


     </div>

     <div>
         <label class="control-label">
             <strong>
                 Coupon Codes 
                 <span style="color: red; font-size: 1.25rem">*</span>
             </strong>
         </label>
         <br />
         <p-chips
             type="text"
             [formControl]="$any(editform.controls)['couponCodesList']"
             separator=","
             addOnBlur="true"
             (onAdd)="validateKey($event)"
             [allowDuplicate]="false"
             [max]="1"
         ></p-chips>
         <!-- (i.e., AbC123,123ABC,ABC123DEF) -->
         <span class="control-description text-muted" >
        <u>Instructions:</u>
        <li>One code for one coupon only.</li>
        <li>Coupon code must not contain special characters. </li>
        <li>Please use minimum 4 and maximum 10 alphanumeric characters only.</li>
            <!-- One code for one coupon only.Coupon code must not contain special characters. Please use minimum 4 and maximum 10 alphanumeric characters only. -->
        </span>
         <!-- <span class="control-description text-muted" >One code for one coupon only.(Max code characters allowed = 10, i.e., AbC%123456)</span> -->
         <div *ngIf="$any(editform.controls)['couponCodesList'].touched ">
            <div
            *ngIf="$any(editform.controls)['couponCodesList'].invalid"
            class="invalid-feedback"
        >
               Please enter a Code
        </div>
         </div>
     </div>
     <div>
        <label class="control-label">
            <strong>
                URL 
                <span style="color: red; font-size: 1.25rem">*</span>
            </strong>
        </label>
        <br />
        <app-text-input
        [formControl]="$any(editform.controls)['printText']"
        [name]="'Url'"
        [label]=""
    ></app-text-input>
    <div *ngIf="genratedLink">
        <span class="control-description text-muted">{{genratedLink}}</span> &nbsp;&nbsp;&nbsp;<i style="color: var(--brandColor);" class="pi pi-copy cursor-pointer" (click)="copy(genratedLink)"></i>
    </div>

  </div>
 </div>

 <!-- <br /> -->
 <p-divider style="margin-top: 10px"></p-divider>
 <div class="grid">
     <div class="col">
         <div
             class="flex justify-content-end flex-wrap card-container green-container mt-3"
         >
             <div class="flex align-items-center justify-content-center">
                 <button
                     pButton
                     pRipple
                     type="submit"
                     label="Save"
                     [disabled]="!editform.valid"
                     *ngIf="!isViewOnly"
                     [style]="{ width: '120px' }"
                 ></button>
             </div>
             <div
                 class="flex align-items-center justify-content-center ml-2"
             >
                 <button
                     pButton
                     pRipple
                     type="button"
                     label="Cancel"
                     class="p-button-outlined p-button-danger"
                     (click)="cancelButtonClick()"
                     [style]="{ width: '120px' }"
                 ></button>
             </div>
         </div>
     </div>
 </div>
</form>


