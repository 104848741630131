import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    Validators,
    ValidatorFn,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { SalesChannelService } from 'src/app/_business-dashboard/_services/bizz-sales-channel.services';
import { Status } from 'src/app/_models/coupon-management';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { PurchaseService } from 'src/app/_services/purchase.service';

@Component({
    selector: 'app-po-send-email',
    templateUrl: './po-send-email.component.html',
    styleUrls: ['./po-send-email.component.scss'],
})
export class PoSendEmailComponent implements OnInit {
    _global = GlobalService;

    destroy$: Subject<boolean> = new Subject<boolean>();
    editform: FormGroup;
    subject: string;
    textValue: string;
    emailValidatorString = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    dataObj: any;

    @Input() emailData: any;

    @Output() onSubmit = new EventEmitter();

    @Output() onOpenPO = new EventEmitter<any>();
    inputSendEmail: any;
    isFooterNoteEnabled: any;
    footerNotesText: any;
    vendors: any;
    po_bodynote: any;
    po_enablebodynote: any;

    constructor(
        private _fb: FormBuilder,
        public _purchaseService: PurchaseService,
        private _spinner: NgxSpinnerService,
        private _connMessageService: ConnMessageService,
        public _abd: AppBaseDataService,
        private datePipe: DatePipe
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.getData();
    }
    getData() {
        this._spinner.show();
        this._purchaseService
            .searchSingleOrder(this._purchaseService.newEditItem.Id, false)
            .subscribe((res) => {
                if (res.success) {
                    this.dataObj = res.data;
                    this.vendors = this._abd.initActVendors();
                    let netTotal = res.data?.poItems.map((v) => {
                        return v.netTotal;
                    });
                    this.dataObj.vendorObj = this.vendors.find(
                        (f) => f.id == this.dataObj.vendorId
                    );
                    this.patchValues();
                    this._spinner.hide();
                }
            });
    }
    patchValues() {
        let date = formatDate(this.dataObj?.createdDate, 'MM/dd/yyyy', 'en-US');
        this.subject =
            'PO - ' +
            this._global.userInfo.businessSetup[0].businessDisplayName +
            ' PO# - ' +
            this.dataObj?.poNumber;
        this.editform
            .get('fromEmail')
            .setValue([this._global.userInfo?.email || '']);
        this.getFooterNotesConfigValue();
        if (this.dataObj?.vendorObj?.email) {
            this.editform
                .get('toEmails')
                .setValue([this.dataObj?.vendorObj?.email]);
        }

        this.f.subject.setValue(this.subject || '');
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getFooterNotesConfigValue() {
        try {
            this._spinner.show();
            this._purchaseService
                .getPurchaseOrderConfigurationDetails()
                .subscribe((r) => {
                    if (r.success) {
                        this.po_enablebodynote = r.data.po_enablebodynote;
                        this.po_bodynote = r.data.po_bodynote;
                        this.isFooterNoteEnabled = r.data.po_enablefooternote;
                        if (this.isFooterNoteEnabled) {
                            this.footerNotesText = r.data.po_footernote;
                        }
                        if (this.po_enablebodynote) {
                            this.textValue = `
            ${this.po_bodynote}
           `;
                            this.f.body.setValue(this.textValue);
                        }
                        this._spinner.hide();
                    }
                });
        } catch (error) {}
    }

    get f() {
        return this.editform.controls;
    }

    initForm() {
        this.editform = this._fb.group({
            toEmails: [{ value: [], disabled: false }, [Validators.required]],
            fromEmail: [{ value: [], disabled: true }, [Validators.required]],
            subject: [{ value: this.subject }, Validators.maxLength(50000)],
            body: [{ value: this.textValue }, Validators.maxLength(50000)],
        });
    }

    saveFn() {
        this._abd.showSpinner();
        let obj = {
            FromEmail: this.f.fromEmail.value[0], //string
            ToEmails: this.f.toEmails.value,
            Subject: this.f.subject.value, //string
            Body: this.f.body.value, //string
            Attachment: [
                {
                    FileName:  ' PO - ' +this.dataObj?.poNumber+'.pdf', //string
                    FileContent: this.openPDF('attachPDF'), // Base64-encoded content of the file
                },
            ],
        };
        try {
            this._purchaseService.sendEmail(obj).subscribe((res) => {
                if (res === 'Email sent successfully.') {
                    this.cancelButtonClick();
                    this._abd.hideSpinner();
                    this._connMessageService.showToastMessage(
                        'Email successfully sended',
                        'success'
                    );
                } else {
                    this._connMessageService.showToastMessage(
                        `Email could not be sended.`,
                        'error'
                    );
                    this._abd.hideSpinner();
                }
            });
        } catch (error) {
            this._connMessageService.showToastMessage(
                `Email could not be sended.`,
                'error'
            );
            this._abd.hideSpinner();
        }
    }
    openPreviewPO() {
        // this.onOpenPO.emit()
        this.openPDF('openPDF');
    }
    openPDF(value) {
        let obj;
        let colHeader = [
            [
                'Sr#',
                
                
                'Product',
                'UOM',
                'Ordered Qty',
               
                
                'Unit Cost ($)',
                'Discount (%)',
                
                'Tax (%)',
                'Shipping ($)',
                'Total Cost ($)',
            ],
        ];
        let colflds = [
            'srNo',
            
            
            'productSkuBarcode',
            'uomDescription',
            'orderedQty',
            
            
            'costPrice',
            'discountPercent',
           
            'taxRate',
            'shipping',
            'totalAmount',
        ];
        let state = this._global.usaStates.filter((v)=>v.abbreviation === this._global.userInfo.businessSetup[0]?.state)[0]?.name || ""
          let poStatus = 
        obj = {
            location: this.dataObj?.locationName || '',
            bAddress:this._global.userInfo.businessSetup[0]?.address || "",
            // bAddress:this._global.userInfo.businessSetup[0]?.address +","+this._global.userInfo.businessSetup[0]?.zip +","+this._global.userInfo.businessSetup[0]?.city+","+ state|| "",
            bContact:this._global.userInfo.businessSetup[0]?.phone || "",
            bEmail:this._global.userInfo?.email || "",
            poNumber: this.dataObj?.poNumber || '',
            requestedBy: this.dataObj?.requestedBy || '',
            createdDate: this.dataObj?.createdDate || '',
            receiveDate: this.datePipe.transform(
                this.dataObj?.receiveDate !== undefined
                    ? this.dataObj?.receiveDate
                    : this.dataObj?.dueDate,
                'yyyy-MM-ddTHH:mm:ss.SSSSSSS'
            ),
            poType: this.dataObj?.poType || '',
            poStatus: this.dataObj?.poStatus || '',
            company: this.dataObj?.vendorObj?.company || '',
            vendorName: this.dataObj?.vendorObj?.vendorName || '',
            address: this.dataObj?.vendorObj?.city || '',
            email: this.dataObj?.vendorObj?.email || '',
            phone: this.dataObj?.vendorObj?.phone || '',
            account: this.dataObj?.vendorObj?.account || '',
            note: this.dataObj?.notes || '',
            subTotal: this.dataObj?.subTotal.toString() || '0.00',
            discountTotal: this.dataObj?.discountTotal.toString() || '0.00',
            taxTotal: this.dataObj?.taxTotal.toString() || '0.00',
            shipping: this.dataObj?.shipping.toString() || '0.00',
            grandTotal: this.dataObj?.grandTotal.toString() || '',
            footerNoteText:this.footerNotesText || ""
        };
        let doc;
        for (let i = 0; i < this.dataObj?.poItems.length; i++) {
            this.dataObj.poItems[i].srNo = i + 1;
            this.dataObj.poItems[i].productSkuBarcode = this.dataObj.poItems[i].productName +"\n"+this.dataObj.poItems[i].barcode +"\n"+this.dataObj.poItems[i].skuCode
            let dr = this.dataObj.poItems[i];
            let pc = dr.costPrice * dr.orderedQty;
            let disc = 0;
            if (dr.discountPercent > 0) disc = pc * (dr.discountPercent / 100);
            dr.discountAmount = disc;
            dr.gsubtotal = pc.toFixed(2);
            this.dataObj.poItems[i].netTotal = (pc - disc).toFixed(2);
        }
        doc = this._abd.exportPOPdf(
            this.dataObj?.poItems,
            colHeader,
            colflds,
            obj,true
        );
        if (value === 'openPDF') {
            const pdfBlob = doc.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);
            return window.open(pdfUrl, '_blank');
        } else if (value === 'attachPDF') {
            let document = doc.output('datauristring');
            let docum = document.split(',');
            return docum[1];
        }
    }

    validateKey(e: any) {
        const input = e.value;
        const isValidEmail = this.validateEmail(input);
        let currentEmail = this.editform.get('fromEmail').value;
        if (isValidEmail) {
            currentEmail = [...currentEmail]; // Add the new chip
            this.editform.get('fromEmail').setValue(currentEmail); // Update the form control
        } else {
            currentEmail = [...currentEmail]; // Add the new chip
            currentEmail = currentEmail.slice(0, currentEmail.length - 1); // Add the new chip
            this.editform.get('fromEmail').setValue(currentEmail); // Update the form control
            // Optionally, clear the input or provide feedback
        }
    }
    validateEmail(email: string): boolean {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        // return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    }
    cancelButtonClick() {
        this._purchaseService.isSendEmail = false;
    }
}
