import { PaginatorComponent } from 'src/app/_forms/paginator/paginator.component';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Paginator } from 'primeng/paginator';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { FilterServiceService } from 'src/app/_services/filter-service.service';

@Component({
  selector: 'app-sales-invoices',
  templateUrl: './sales-invoices.component.html',
  styleUrls: ['./sales-invoices.component.scss'],
})
export class SalesInvoicesComponent implements OnInit {
  @ViewChild('p', { static: false }) paginator: Paginator;
  @ViewChild('paginator', { static: false }) Apppaginator: PaginatorComponent
  _globals = GlobalService

  dataList: any = null

  page = 0
  rows = 10
  first = 0
  totalRecords = 0

  //order
  orderParam = ''
  orderBy = ''

  selectedRow: any = ''

  colheader = [[
    'Order #', 'Date', 'Time', 'Customer', 'Mode', 'Txn Type', 'Status Type',
    'Location', 'Register', 'Cashier', 'Grand Total'
  ]];

  colflds = ['invoiceNumber', 'date', 'time', 'customer', 'salesMode', 'transactionTypeString', 'postedStatusString', 'location', 'register', 'cashier', 'purchase'];

  constructor(
    private _reportService: ReportsService,
    private _bs: BaseService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private _router: Router,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Sales Invoices'
  }

  ngAfterViewInit() {
    if (!this._filterService.selectedInvoiceStatus) this._filterService.selectedInvoiceStatus = this._abd.invoiceStatus[0]
    if (!this._filterService.selectedTransactionType) this._filterService.selectedTransactionType = this._abd.transactionTypes[0]
    if (!this._filterService.selectedProductSellTypeFilter) this._filterService.selectedProductSellTypeFilter = this._abd.productSellTypeFilter[0]

    //-- restore page number **begin
    let ti = null
    ti = setInterval(() => {
      //--
    
      if (!this._filterService.dateRange || this._filterService.dateRange.length < 1)
        this._filterService.initDates()

      this._filterService.selectedLocation = JSON.parse(JSON.stringify(this._abd.locations))
      this._filterService.selectedInvoiceStatus = this._abd.invoiceStatus[0]
      this._filterService.selectedInvoiceType = this._abd.invoiceType[0]
      this._filterService.selectedProductSellTypeFilter = this._abd.productSellTypeFilter[0]

      let ppages = this._filterService.getPages(this._globals.componentName)
      if (ppages) {
        let lastpage = parseInt(ppages.page)
        if (lastpage < 0) lastpage = 0
        if (lastpage > 0) {
          this.page = lastpage
          this.first = lastpage * this.rows
        }
      }

      let filter = this._filterService.getFilters(this._globals.componentName)
      if (filter) {
        this._filterService.dateRange[0] = new Date(filter.startDate)
        this._filterService.dateRange[1] = new Date(filter.endDate)
        this.page = filter.pageNo
        this.rows = filter.pageSize
        this._filterService.selectedTransactionType = this._abd.transactionTypes.find(f => f.id == filter.postedStatus)
        this._filterService.selectedInvoiceStatus = this._abd.invoiceStatus.find(f => f.id == filter.invoiceType)
        if (filter.filterValue) this._filterService.searchText = filter.filterValue
        this._filterService.selectedProductSellTypeFilter = this._abd.productSellTypeFilter.find(f => f.Value == filter.orderPlacement)

        this._filterService.selectedLocation = []
        for (let i = 0; i < filter.locationIds.length; i++) {
          const d = this._abd.locations.find(f => f.locationId == filter.locationIds[i])
          if(d) this._filterService.selectedLocation.push(d)
        }
        const dd = this._abd.locations
        //--
      }

      clearInterval(ti)
      this.getDataList()
      //--
    }, 200);
    //-- restore page number **end
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  makeParas() {
    this.page = this.page < 1 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    let locationIds = []
    if (this._filterService.selectedLocation && this._filterService.selectedLocation.length) {
      for (let i = 0; i < this._filterService.selectedLocation.length; i++)
        locationIds.push(this._filterService.selectedLocation[i].id)
    }


    const data = {
      startDate: formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
      endDate: formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
      locationIds: locationIds,
      pageNo: this.page,
      pageSize: this.rows,
      postedStatus: this._filterService.selectedInvoiceStatus?.id,
      invoiceType: this._filterService.selectedTransactionType ? this._filterService.selectedTransactionType.id : -1,
      filterValue: this._filterService.searchText,
      'orderPlacement': this._filterService.selectedProductSellTypeFilter?.Value,
      orderParam: this.orderParam,
      orderBy: this.orderBy
    }

    this._filterService.updateFilters(this._globals.componentName, data)
    this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })


    return JSON.stringify(data)
  }

  getDataList() {
    const paras = this.makeParas()
    if (!paras) return;

    this._abd.showSpinner()
    let url = environment.OrderBaseURL + environment.FilterInvoiceHistory
    this._bs.httpPost(url, paras).subscribe(res => {
      // this._bs.httpGetParams(url, this.makeParas()).subscribe(res => {
      if (res.success) {
        this._abd.hideSpinner()
        this.dataList = res.data
        this.totalRecords = res.totalRecords
        for (let i = 0; i < this.dataList.length; i++) {
          this.dataList[i].salesMode = this.dataList[i].isOnlineOrder ? 'Online' : 'In-Store'
        }
        //console.log('INVOICE', this.dataList)
      }
    })
  }

  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)
    //console.log(e)
    this.getDataList()
  }

  onRowSelected(e) {
    this._reportService.selectedInvoiceRow = this.selectedRow
    this._router.navigate([`app/sales-invoice-detail`])
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  filterButtonPressed() {
    this.page = 0
    //this.paginator?.changePage(0)
    this.getDataList()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList, this.colheader[0], this.colflds, 'Inventory Overview')
  }

  exportPdf() {
    // let data = []

    // for (let i = 0; i < this.dataList.length; i++) {
    //   let row = []
    //   for (let j = 0; j < this.colflds.length; j++)
    //     row.push(this.dataList[i][this.colflds[j]])
    //   data.push(row)
    // }

    this._abd.exportPdf(this.dataList, this.colheader, this.colflds, 'Inventory Overview')
  }

  dummy() {
    this.paginator?.changePage(4)
  }

  customSort(e) {
    const last = this.orderParam
    switch (e.sortField) {

      case 'invoiceNumber':
        this.orderParam = 'InvoiceNumber'
        break;
      case 'date':
        this.orderParam = 'Dated'
        break;
      case 'customer':
        this.orderParam = 'CustomerName'
        break;
      case 'salesMode':
        this.orderParam = 'IsOnlineOrder'
        break;
      case 'transactionTypeString':
        this.orderParam = 'InvoiceType'
        break;
      case 'postedStatusString':
        this.orderParam = 'PostedStatus'
        break;
      case 'location':
        this.orderParam = 'LocationId'
        break;
      case 'register':
        this.orderParam = 'RegisterId'
        break;
      case 'cashier':
        this.orderParam = 'UserName'
        break;
      default:
        this.orderParam = ''
    }


    if (this.orderParam !== '') {
      this.orderBy = e.sortOrder == 1 ? 'asc' : 'desc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }


}
