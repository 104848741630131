import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { InventoryService } from 'src/app/_services/inventory.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cash-discount-sales-report',
  templateUrl: './cash-discount-sales-report.component.html',
  styleUrls: ['./cash-discount-sales-report.component.scss']
})
export class CashDiscountSalesReportComponent implements OnInit, OnDestroy {
 
  _globals = GlobalService

  dataList: any[] = [];
  locationDataList: any[] = [];
  yearDataList: any[] = [];

  colheader = this._abd.reports.filter(x => x.componentName == 'Cash Discount Sales Report')[0].colheader;
  domColHeader = this.colheader[0];
  colflds = this._abd.reports.filter(x => x.componentName == 'Cash Discount Sales Report')[0].colflds;
  // domSummaryHeader = this._abd.reports.filter(x => x.componentName == 'Cash Discount Sales Report')[0].summaryHeader[0];
  // domSummaryflds = this._abd.reports.filter(x => x.componentName == 'Cash Discount Sales Report')[0].summaryflds;


  csvcols = []
  exportColumns: any = '';
  activeState: boolean[] = [true, false, false];
  locationIds: string[] = [];
  totalRecords: any = 0;

  constructor(private _inventoryService: InventoryService,
    private _bs: BaseService,
    private _reportService: ReportsService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    public _filterService: FilterServiceService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    this._filterService.selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }
    this._globals.componentName = 'Cash Discount Sales Report'

    if (!this._filterService.dateRange || this._filterService.dateRange.length < 1)
      this._filterService.initDates()

    this._filterService.selectedLocation = this._abd.locations;
    this.getDataList();
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  getDataList() {
    this.getLocationsList();

    const data = {
      "ReportName": 'CashDiscountReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        'OrderPlacement': this._filterService.selectedProductSellTypeFilter?.Value
      }
    }

    const url = this._abd.reports.filter(x => x.componentName == 'Cash Discount Sales Report')[0].reportURL;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      this.totalRecords = res.totalRecords
      for(let i=0; i<this.dataList.length; i++) {
        this.dataList[i].time=this.dataList[i].time.split('.')[0]
      }

      this._abd.hideSpinner();
    })
  }

  filterButtonPressed() {
    this.getDataList()
  }


  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList, this.colheader[0], this.colflds, 'Cash Discount Sales Report')
  }

  exportPdf() {
    this._abd.exportPdf(this.dataList, this.colheader, this.colflds, 'Cash Discount Sales Report', false)
  }

}
