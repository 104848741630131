import { Injectable } from '@angular/core';
import { Subject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppBaseDataService } from './app-base-data.service';
import { BaseService } from './base.service';
import { ConnMessageService } from './conn-message.service';
import { GlobalService } from './global.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  _globals = GlobalService
  clickEvent = new Subject<any>();
  clickButton$ = this.clickEvent.asObservable();
  isEditLocation = false
  isNewLocation = false
  searchText = ''

  activeItem = {
    locationName: '',
    phone: '',
    manageInventory: true,
      allowShipping: true,
      acceptOnlineOrders: true,
    address: '',
    zip: null,
    city: null,
    selectedState: null,
    id: null,
    state: ''
  }

  constructor(
    private _bs: BaseService,
    private _abd: AppBaseDataService,
    private _connMessageService: ConnMessageService,
    private _userService: UserService,
  ) { }

  blankActiveItem() {
  return this.activeItem = {
      locationName: '',
      phone: '',
      manageInventory: true,
        allowShipping: true,
        acceptOnlineOrders: true,
      address: '',
      zip: null,
      city: null,
      selectedState: null,
      id: null,
      state: ''
    }
  }
  // blankActiveItem() {
  //   return new Promise((resolve, reject)=> {
  //     this.activeItem.locationName = ''
  //   this.activeItem.phone = ''
  //   this.activeItem.manageInventory = false
  //   this.activeItem.allowShipping = false
  //   this.activeItem.acceptOnlineOrders = false
  //   this.activeItem.address = ''
  //   this.activeItem.zip = ''
  //   this.activeItem.city = ''
  //   resolve('resolved')
  //   })
  // }

  getAllLocations(params: any) {
    let url = environment.BusinessBaseURL + environment.GetAllLocations
    return this._bs.httpGet(url, params).pipe(map(res => {
      if (res.success) {
        return res
      }
    }))
  }

  createUpdateItem(obj: any, isNew: boolean) {
    let url = ''
    if (isNew) url = environment.BusinessBaseURL + environment.CreateLocation
    else url = environment.BusinessBaseURL + environment.EditLocationSetup

    // if (!isNew) url += '/' + obj.id

    if (!isNew) {
      return this._bs.httpPut(
        url,
        JSON.stringify(obj), true).pipe(map(res => {
          if (res.success) {
            this._userService.getUserByUserName(this._globals.userInfo.username).subscribe(res => { })
            this._connMessageService.showToastMessage('Location updated successfully.', 'success');
            return res
          }
        }))
    } else {
      return this._bs.httpPost(
        url,
        JSON.stringify(obj), true).pipe(map(res => {
          if (res.success) {
            this._userService.getUserByUserName(this._globals.userInfo.username).subscribe(res => { })
            this._connMessageService.showToastMessage('Location saved successfully.', 'success');
            return res
          }
        }))
    }
  }

  deleteItem(locationId: any) {

    let url = environment.BusinessBaseURL + "DeleteLocation" + "/" + locationId;
    return this._bs.httpDelete(url).pipe(map(res => {
      if (res.success)
        this._userService.getUserByUserName(this._globals.userInfo.username).subscribe(res => { })
      // this._connMessageService.showToastMessage('Location deleted successfully.', 'success');
      return res;
    }))
  }

  isLocationAvailable() {
    try {
      const limit = this._globals.userInfo.businessSetup[0].noOfLocations
      const loc = this._abd.locations.filter(f => !f.isDeleted && f.active)
      const consumed = loc.length
      return limit - consumed > 0 ? true : false
    }
    catch {
      return false
    }
  }


}
