<!-- <div class="card border-dotted-custom">
    <div class="flex  flex-wrap w-full  card-container s-container ">
        <div class="flex  justify-content-start flex-column flex-grow-1 ">
            <div class="w-12 flex align-items-center py-2 justify-content-start font-bold text-2xl">
                Subscribed Plan: {{planName}}
            </div>
            <div class="w-12 flex align-items-center py-2 justify-content-start font-bold
                    text-xl billing-color" *ngIf="!planInfo.isFreeTrial">
                Grand Total: {{_bizzCustomerService.activeItem.currentSubscription.total| currency}}
                <span class="mx-1" *ngIf="planDuration == 1"> /month</span>
                <span class="mx-1" *ngIf="planDuration == 12"> /year</span>

            </div>
            <div *ngIf="planInfo.isFreeTrial" class="w-12 flex align-items-center py-2 justify-content-start text-lg
                    text-md link">
                {{messageText}}
            </div>
            <span *ngIf="planInfo.isFreeTrial">
                <div class="flex flex-row flex-wrap flex-grow-1  py-2 flex align-items-center
                        justify-content-start  text-lg" *ngFor="let info of currentPlanGeneralInformation">
                    <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link">
                        {{info.title}}
                    </div>
                    <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 font-bold justify-content-start text-md link">
                        {{info.value}}
                    </div>
                </div>
            </span>
            <span *ngIf="!planInfo.isFreeTrial">
                <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                        justify-content-start flex-grow-1 text-lg">
                    <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link">
                        Subscription Status
                    </div>
                    <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 font-bold justify-content-start text-md link">
                        {{_bizzCustomerService.activeItem.currentSubscription.status ? 'Active': 'Inactive'}}
                    </div>
                </div>
                <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                        justify-content-start flex-grow-1 text-lg">
                    <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link">
                        Expires on
                    </div>
                    <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 font-bold justify-content-start text-md link">
                        {{_bizzCustomerService.activeItem.currentSubscription.subscriptionExpire | date:'M/d/yyyy'}}
                    </div>
                </div>
                <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                        justify-content-start flex-grow-1 text-lg" *ngFor="let info of subscribedPackages">
                    <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link">
                        {{info.title}}
                        <span class="mx-1" *ngIf="info.qty> 0"> ({{info.qty}})</span>
                    </div>
                    <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 font-bold justify-content-start text-md link">
                        {{info.totalPrice | currency}}
                    </div>
                </div>
                <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                        justify-content-start flex-grow-1 text-lg">
                    <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link">
                        Tax
                    </div>
                    <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 font-bold justify-content-start text-md link">
                        {{currentSubscription.tax | currency}}
                    </div>
                </div>
            </span>

            <div class="w-full flex align-items-center py-2 justify-content-end text-lg">
                <div class="flex w-2 col-12 text-md link">

                    <button pButton pRipple type="button" *ngIf="!cancellationRequest" [disabled]="disabledTrue"
                        (click)="cancelSubscription('c')" label="Cancel Subscription"
                        class="p-button-outlined font-bold p-button-secondary text-color-secondary"></button>
                </div>
                <div class="flex w-2  text-md link" *ngIf="!upgradeRequested">
                    <p-button label="Upgrade Subsciption" [disabled]="disabledTrue" (click)="cancelSubscription('r')">
                    </p-button>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- Sam Changes Start -->

<div class=" subscription-page" *ngIf="!loadingContent">
    <div class="card border-dotted-custom" *ngIf="_bizzCustomerService.activeItem.isFreeTrial">
        <div class="flex  flex-wrap w-full  card-container s-container ">
            <div class="flex  justify-content-start flex-column flex-grow-1 ">
                <div class="w-12 flex align-items-center py-2 justify-content-start font-bold text-2xl">
                    Subscribed Plan: {{planName}} <button pButton pRipple type="button" label="Upgrade"
                        class="p-button-text" (click)="changePlanButtonClicked()"></button>
                </div>
                <div class="w-12 flex align-items-center py-2 justify-content-start text-lg
                    text-md link">

                    <div>
                        <h2>Welcome to {{who?.wlName}}</h2>
                        <p>Dear Valued {{who?.wlName}} User,</p>
                        <p>We're thrilled you're making the most of our 30-day free trial.
                            However, we want to remind you that at the end of this trial period,
                            any data uploaded, including Products, Customers, Vendors, etc., will not be retained,
                            unless upgraded to paid plan.</p>
                        <p>If you're considering upgrading to one of our subscription plans to safeguard your data and
                            continue enjoying {{who?.wlName}}, please feel free to reach out to our support team.</p>
                        <p>Thank you for considering {{who?.wlName}} for your needs.</p>

                    </div>
                </div>
                <span *ngIf="_bizzCustomerService.activeItem.isFreeTrial">
                    <div class="flex flex-row flex-wrap flex-grow-1  py-2 flex align-items-center
                        justify-content-start  text-lg" *ngFor="let info of currentPlanGeneralInformation">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link">
                            {{info.title}}
                        </div>
                        <div
                            class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 font-bold justify-content-start text-md link">
                            {{info.value}}
                        </div>
                    </div>
                </span>


            </div>
        </div>
    </div>
    <div class="grid" *ngIf="!_bizzCustomerService.activeItem.isFreeTrial">
        <div class="col">
            <div class="conn-card">
                <h3 style="
                    margin-top: 0px;
                    font-size: 1.25rem;
                    color: var(--bs-active-primary);
                    font-weight: 700px;
                ">
                    Subscription Plan


                    <p-button label="Upgrade Plan" [disabled]="disabledTrue"
                        *ngIf="_globals.userInfo.businessSetup[0].isFreeTrial" (click)="upgradePlan()"></p-button>

                </h3>

                <p-divider></p-divider>

                <span *ngIf="!_bizzCustomerService.activeItem.isFreeTrial">
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                    justify-content-start flex-grow-1 text-lg">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link nas-label">
                           Current Subscription Plan
                        </div>
                        <div
                            class="flex xxl:col-9 xl:col-9 lg:col-8 md:col-8 font-bold justify-content-start text-md link nas-value">
                            {{planName}}
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                justify-content-start flex-grow-1 text-lg">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link nas-label">
                            Subscription Status
                        </div>
                        <div
                            class="flex xxl:col-9 xl:col-9 lg:col-8 md:col-8 font-bold justify-content-start text-md link nas-value">
                            {{_bizzCustomerService.activeItem?.currentSubscription?.status ? 'Active': 'Inactive'}}
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                    justify-content-start flex-grow-1 text-lg">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link nas-label">
                            Payment Interval
                        </div>
                        <div
                            class="flex xxl:col-9 xl:col-9 lg:col-8 md:col-8 font-bold justify-content-start text-md link nas-value">
                            {{ _bizzCustomerService.activeItem.currentSubscription?.subscriptionInterval == 1 ? 'Monthly'
                            : 'Yearly' }}
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                justify-content-start flex-grow-1 text-lg">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link nas-label">
                            Fixed Charges (mnthly/ yrly)
                        </div>
                        <div
                            class="flex xxl:col-9 xl:col-9 lg:col-8 md:col-8 font-bold justify-content-start text-md link nas-value">
                            {{_bizzCustomerService.activeItem.currentSubscription?.total| currency}}
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                justify-content-start flex-grow-1 text-lg">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link nas-label">
                            Next Payment Due Date
                        </div>
                        <div
                            class="flex xxl:col-9 xl:col-9 lg:col-8 md:col-8 font-bold justify-content-start text-md link nas-value">
                            {{_bizzCustomerService.activeItem.currentSubscription?.subscriptionExpire|date:'M/d/yyyy'}}
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
            justify-content-start flex-grow-1 text-lg"
                        *ngIf="!_bizzCustomerService.activeItem.isFreeTrial && !_bizzCustomerService.activeItem.currentSubscription?.lastChargeDate=='-'">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link nas-label">
                            Last Charge
                        </div>
                        <div *ngIf="!_bizzCustomerService.activeItem.isFreeTrial && !_bizzCustomerService.activeItem.currentSubscription?.lastChargeDate=='-'"
                            class="flex xxl:col-9 xl:col-9 lg:col-8 md:col-8 font-bold justify-content-start text-md link nas-value">

                            {{_bizzCustomerService.activeItem.currentSubscription?.lastChargeDate |date:'M/d/yyyy'}}
                        </div>

                    </div>
                </span>

                <br />
                <br />

                <div class="grid">
                    <div class="col headingCol">
                        <h3 style="
                        margin-top: 10px;
                        margin-bottom: 0px;
                        font-size: 1.25rem;
                        color: var(--bs-active-primary);
                        font-weight: 700px;
                    ">
                           Subscription Details
                        </h3>
                    </div>
                    <div class="col buttonCol">

                        <button pButton type="button" label="Purchase Addons" style="width: 170px"
                            (click)="purchaseAddonHandler()"></button>
                    </div>
                </div>

                <p-divider></p-divider>
                <br />
                <br />
                <p-table styleClass="p-datatable-striped" [tableStyle]="{ width: 'max-content' }"
                    [value]="subscribedPackages" responsiveLayout="scroll" rowHover="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Subscription/Addons</th>
                            <th style="text-align: center">Qty</th>
                            <th style="text-align: center">Price</th>
                            <th style="text-align: center">Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-info>
                        <tr>
                            <td> {{info.title}}</td>
                            <td style="text-align: center">

                                <ng-container
                                    *ngIf="info.title=='Allowed Locations' || info.title=='Allowed Registers'">
                                    {{info.qty}}
                                </ng-container>
                            </td>
                            <td style="text-align: center">{{info.totalPrice | currency }}</td>
                            <td style="text-align: center">
                                <!-- <button pButton type="button" label="Change Plan" style="width: 170px"
                                    (click)="upgradePlan()" *ngIf="info.packageTypeString=='Subscription'"></button> -->
                                <button pButton type="button" label="Add More" style="width: 170px"
                                    (click)="buyLocationFormHandler()"
                                    *ngIf="info.directPurchase && info.packageTypeString !='Subscription' && info.title=='Locations'"></button>
                                <button pButton type="button" label="Add More" style="width: 170px"
                                    (click)="buyRegisterFormHandler()"
                                    *ngIf="info.directPurchase && info.packageTypeString !='Subscription' && info.title=='Registers'"></button>
                                <button pButton type="button" label="Unsubscribe" style="width: 170px"
                                    (click)="generateSupportRequest('Unsubscribe Request ! for '+ info.title +' Subscription ')"
                                    *ngIf="info.directPurchase==false && info.packageTypeString !='Locations' && info.packageTypeString !='Registers'"></button>
                                <!-- <button
                        pButton
                        type="button"
                        label="Support Request"
                        style="width: 170px"
                        (click)="generateSupportRequest()"
                        *ngIf="info.directPurchase==false  && info.packageTypeString !='Subscription' && (info.packageTypeString !='BasicLoyalty')"
                    ></button> -->

                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                       <tr>

                            <td style="text-align: left">Sub Total</td>
                            <td></td>
                            <td style="text-align: center"> {{ currentSubscription?.subTotal | currency }}</td>
                            <td></td>
                        </tr>
                        <!-- <tr>

                            <td style="text-align: left">Discount</td>
                            <td></td>
                            <td style="text-align: center"> {{currentSubscription.totalDiscountAmount ? currentSubscription.totalDiscountAmount : 0 | currency}}</td>
                            <td></td>
                        </tr> -->
                        <tr>

                            <td style="text-align: left">Tax</td>
                            <td></td>
                            <td style="text-align: center"> {{currentSubscription?.tax | currency}}</td>
                            <td></td>
                        </tr>
                        <tr>

                            <td style="text-align: left">Total Charges</td>
                            <td></td>
                            <td style="text-align: center">{{_bizzCustomerService.activeItem.currentSubscription?.total |  currency }}</td>
                            <td></td>
                        </tr>

                    </ng-template>
                    <ng-template pTemplate="emptymessage" style="text-align: center">
                        <tr>
                            <td colspan="17" style="text-align: center">
                                No data found
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <button *ngIf="!cancellationRequest && !_bizzCustomerService.activeItem.isFreeTrial && _globals.whiteLabel?.isStripe === false"
                    [disabled]="disabledTrue" pButton pRipple type="button" label="Cancel Subscription"
                    class="p-button-text" (click)="cancelSubscription('c')"></button>
            </div>
        </div>
    </div>
</div>
<div class="field xxl:col-12 xl:col-12 col-12 md:col-6" *ngIf="loadingContent">
    <p-skeleton styleClass="mb-2"></p-skeleton>
    <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton height="2rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="10rem" height="4rem"></p-skeleton>
</div>

<p-dialog header="Confirmation" [(visible)]="ShowCustomConfirmation" [modal]="true" [draggable]="false"
    [resizable]="false">
    <div *ngIf="upgradeSucessMessage.length==0" class="plan-upgrade-message">
        <strong>You have chosen to {{ CurrentAction==1 ? 'upgrade' : 'downgrade' }} your plan.</strong>
        <br><br>
        <table class="p-datatable-table ng-star-inserted upgrade-plan">
            <tr class="ng-star-inserted">
                <td>From: <strong>{{ planName }} plan</strong></td>
                <td><strong>${{ currentSubscriptionCharges }}</strong> {{
                    (this._bizzCustomerService.activeItem?.currentSubscription?.subscriptionInterval === 12
                    ? '/ Year '
                    : '/ Month') + '(Subscription + Addons + Tax)' }} </td>
            </tr>
            <tr class="ng-star-inserted">
                <td>To: <strong>{{ selectedPlanForSubscription?.title }} plan</strong></td>
                <td><strong>${{ newSubscriptionCharges }}</strong> {{
                    (this._bizzCustomerService.activeItem?.currentSubscription?.subscriptionInterval === 12
                    ? '/ Year'
                    : '/ Month') + '(Subscription + Addons + Tax)' }} </td>
            </tr>
        </table>
        <p-divider *ngIf="subscriptionChangeNotes.length>0">
            <p style="font-size: 18px;">{{subscriptionChangeNotes}}
            </p>
        </p-divider>
        <br>
        Would you like to proceed?
    </div>
    <div *ngIf="upgradeSucessMessage.length>0" class="plan-upgrade-message">
        <p>You have sucessfully updated your plan</p>
    </div>

    <div class="flex flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button pButton type="button" label="Confirm" *ngIf="upgradeSucessMessage.length==0" class="ml-2"
                style="width: 160px" (click)="performAction()"></button>
            <button pButton type="button" label="Ok" class="ml-2" style="width: 160px"
                (click)="ShowCustomConfirmation=false" *ngIf="upgradeSucessMessage.length>0"></button>
            <button pButton type="button" label="Cancel" *ngIf="upgradeSucessMessage.length==0" class="ml-2"
                style="width: 160px" (click)="ShowCustomConfirmation=false"></button>

        </div>
    </div>

    <div style="color:red" *ngIf="ErrorMessage.length>0">
        <br>
        <br>
        {{ErrorMessage}}
    </div>
</p-dialog>



<p-dialog header="Confirmation" [(visible)]="showSubscriptionModal" [modal]="true" [style]="{ width: '50vw' }"
    [draggable]="true" [resizable]="false">
    <p class="m-0">
        Thank you for reaching out to us regarding your request. We have received your message and our customer support
        team will be in touch with you shortly to assist you with your query.
    </p>
</p-dialog>

<p-dialog header="Add Registers" [(visible)]="showBuyMoreRegisterForm" [blockScroll]="true" modal="true" [breakpoints]="{
    '960px': '80vw',
    '640px': '90vw',
    '250px': '100vw'
}" [draggable]="false" [style]="{ width: '60vw' }">
    <ng-container>
        <p>
            You are going to change number of Registers available to you.
            Buying any additional location will be charged according to your selected payment interval and subscription
            plan.

        </p>
        <br />
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Current Registers
            </div>
            <div class="">
                <div class="">
                    <input type="number" min="0" placeholder="Enter a number" [(ngModel)]='currentRegisters.qty'
                        disabled pInputText style="width: 100%;" />
                </div>

            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Additional Registers
            </div>
            <div class="">
                <input type="number" min="0" placeholder="Enter a number" [(ngModel)]="buyRegQty" pInputText />
                <button pButton type="button" label="Update Cart" class="ml-2" style="width: 130px"
                    (click)="registerQtyChanged($event)" [disabled]="buyRegQty==0"></button>

            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Additional Charges
            </div>
            <div class="">
                <input [(ngModel)]="buylocationList.additionalCost " [(ngModel)]='currentRegisters.qty' disabled
                    (onInput)="locationQtyChanged($event)" pInputText style="width: 100%;" />


            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">New Charges
            </div>
            <div class="">
                <p-inputNumber mode="currency" [(ngModel)]="buyRegisterList.additionalCost" currency="USD"
                    locale="en-US" [disabled]="true"></p-inputNumber>
            </div>
        </div>
        <!-- <div class="" *ngIf="buyRegisterList.note.length>0">
        <div class="nas-label">{{buyRegisterList.note}}
        </div>

    </div> -->
    </ng-container>

    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button pButton type="button" label="Purchase" class="ml-2" style="width: 130px"
                (click)="postBuyRegisters()" [disabled]="buyRegQty==0"></button>
        </div>

    </div>
</p-dialog>

<p-dialog header="Add More Inventory Locations" [(visible)]="showBuyMoreLocationForm" [blockScroll]="true" modal="true"
    [draggable]="false" [breakpoints]="{
    '960px': '80vw',
    '640px': '90vw',
    '250px': '100vw'}" [style]="{ width: '60vw' }">
    <ng-container>
        <p>
            You are going to change number of locations available to you. Buying any additional location will be charged
            according to your selected payment interval and subscription plan.
            First register for every new location is free!.
        </p>
        <br />
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Current Inventory Locations
            </div>
            <div class="">
                <div class="">
                    <input type="number" min="0" placeholder="Enter a number" [(ngModel)]='currentLocations.qty'
                        disabled (onInput)="locationQtyChanged($event)" pInputText style="width: 100%;" />
                </div>

            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Additional Location to Buy
            </div>
            <div class="">
                <input type="number" min="0" placeholder="Enter a number" [(ngModel)]="buyLocQty" pInputText />
                <button pButton type="button" label="Update Cart" class="ml-2" style="width: 130px"
                    (click)="locationQtyChanged($event)" [disabled]="buyLocQty==0"></button>

            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Additional Cost
            </div>
            <div class="">
                <input [(ngModel)]="buylocationList.additionalCost " placeholder="Enter a number"
                    [(ngModel)]='currentLocations.qty' disabled (onInput)="locationQtyChanged($event)" pInputText
                    style="width: 100%;" />


            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">New Charges
            </div>
            <div class="">
                <p-inputNumber mode="currency" [(ngModel)]="buylocationList.newSubscriptionCharges" currency="USD"
                    locale="en-US" [disabled]="true"></p-inputNumber>
            </div>
        </div>
        <!-- <div class="" *ngIf="buylocationList.note.length>0">
        <div class="nas-label">{{buylocationList.note}}
        </div>

    </div> -->
    </ng-container>
    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button pButton type="button" label="Purchase" class="ml-2" style="width: 130px" (click)="postBuyLocation()"
                [disabled]="buyLocQty==0"></button>
        </div>

    </div>
</p-dialog>

<p-dialog header="Support Request" [(visible)]="showSupportRequestForm" [blockScroll]="true" modal="true" [breakpoints]="{
        '960px': '80vw',
        '640px': '90vw',
        '250px': '100vw'
    }" [draggable]="false" [style]="{ width: '40vw' }">
    <ng-container>
        <br />
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Subject
            </div>
            <div class="">
                <input type="text" [(ngModel)]="generalQuestion" pInputText style="width: 100%;" />
            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Anything to add?
            </div>
            <div class="">
                <textarea [rows]="5" [cols]="30" [(ngModel)]="generalMessage" pInputTextarea
                    style="width: 100%;">​</textarea>
            </div>
        </div>
    </ng-container>
    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button pButton type="button" label="Submit Request" class="ml-2" (click)="submitGeneralRequest()"></button>
        </div>
    </div>
</p-dialog>
<p-dialog header="Contact Support for Additional Addons " [(visible)]="talkToExpertForm" [blockScroll]="true"
    modal="true" [breakpoints]="{
        '960px': '80vw',
        '640px': '90vw',
        '250px': '100vw'
    }" [draggable]="false" [style]="{ width: '40vw' }">
    <ng-container>
        <br />
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Your Question
            </div>
            <div class="">
                <input type="text" [(ngModel)]="addtionalAddonsQuestion" pInputText style="width: 100%;" />
            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Details
            </div>
            <div class="">
                <textarea [rows]="5" [cols]="30" [(ngModel)]="additionalAddonRequestionMessage" pInputTextarea
                    style="width: 100%;">​</textarea>

            </div>
        </div>
    </ng-container>
    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button pButton type="button" label="Submit Request" class="ml-2" style="width: 160px"
                (click)="submitTalkExpertFormRequest()"></button>
        </div>
    </div>
</p-dialog>

<p-dialog header="Addons and optional Features​" [(visible)]="showPurchaseAddon" [blockScroll]="true" modal="true"
    [breakpoints]="{
        '960px': '80vw',
        '640px': '90vw',
        '250px': '100vw'
    }" [draggable]="false" [style]="{ width: '40vw' }">
    <ng-container *ngIf="!_bizzCustomerService.activeItem.isFreeTrial">

        <p-table styleClass="p-datatable-striped" [tableStyle]="{ width: 'max-content'}"
            [value]="getSubPlan?.plans[0]?.featureData" responsiveLayout="scroll" rowHover="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>Addon</th>
                    <th style="text-align: left">Price</th>
                    <!-- <th style="text-align: left">Status</th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-info>

                <tr *ngIf="info.typeString=='StaticWebsite'">
                    <td>Website/ Basic eCommerce Store</td>
                    <td style="text-align: left"> Starting from {{info.price | currency }} </td>
                </tr>
                <tr *ngIf="info.typeString=='LoyaltyProgram'">
                    <td>{{info.title}}</td>
                    <td style="text-align: left">{{info.price | currency }} </td>
                    <!-- <td style="text-align: left">
                        <button pButton type="button" label="Request Support" (click)="generateSupportRequest('Support Request Loyalty Program Subscription')"></button>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="17" style="text-align: center">
                        No data found
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>

</p-dialog>

<p-dialog header="Select Website​" [(visible)]="showWebsiteSelectionPopup" [blockScroll]="true" modal="true"
    [breakpoints]="{
    '960px': '80vw',
    '640px': '90vw',
    '250px': '100vw'
}" [draggable]="false" [style]="{ width: '70vw' }">
    <app-signup-website-and-online-store>

    </app-signup-website-and-online-store>

</p-dialog>

<p-dialog header="Select Loyalty Program" [(visible)]="showLoyaltyProgramSelectionPopup" [blockScroll]="true"
    modal="true" [breakpoints]="{
    '960px': '80vw',
    '640px': '90vw',
    '250px': '100vw'
}" [draggable]="false" [style]="{ width: '70vw' }">
    <app-signup-loyalty-program>

    </app-signup-loyalty-program>

</p-dialog>
<p-dialog header="Please select subscription to modify" modal="true" [closable]="false"
    [(visible)]="displaySubscriptionPlan" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '95vw'}">

    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="closeSubscriptionModal(1)" class="p-button-secondary"
            label="Close"></button>
        <button pButton type="button" [disabled]="!planChanged" class="checkout-popup-btn-save"
            (click)="closeSubscriptionModal(2)" label="Save Changes"></button>
    </ng-template>
    <app-subscription-plans-layout [showFooter]="false" [showTrial]="false" [AlreadySubscribedCustomer]="false"
        [DefaultInterval]="_bizzCustomerService.activeItem?.currentSubscription?.subscriptionInterval"
        [AllowQtyChange]="_bizzCustomerService.activeItem.isFreeTrial"
        (subscriptionPlanChanged)="subscriptionPlanChanged($event)"
        (closeDialogSubscriptionPlan)="closeDialogSubscriptionPlan($event)"></app-subscription-plans-layout>