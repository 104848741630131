<div *ngIf="!activeItem">
    <p
        class="text-lg font-bold text-red-400 shadow-1"
        style="text-align: center; margin: 30px; padding: 20px"
    >
        {{ foundMessage }}
    </p>
</div>

<div class="conn-grid-main create-product" *ngIf="activeItem">
    <div>
        <p-tabMenu
            #tab
            [model]="items"
            [activeItem]="activeTab"
            (click)="onTabItemClick(tab)"
        >
            <ng-template pTemplate="item" let-item let-i="index">
                <div>
                    <span>
                        <i
                            style="color: var(--info-color)"
                            class="{{ item.icon }}"
                        ></i>
                    </span>
                    {{ item.label }}
                </div>
            </ng-template>
        </p-tabMenu>

        <div
            *ngIf="activeTab.label === 'General'"
            class="fadeinright animation-duration-200 mt-3"
        >
            <div class="shadow-3 p-5 border-round-md">
                <h2 class="text-xl text-blue-500 font-bold">General</h2>
                <div class="conn-grid">
                    <div>
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 10px"
                                ><strong>Product Name </strong></label
                            >
                            <br />
                            <app-text-input
                                [(ngModel)]="activeItem.itemName"
                                [label]=""
                                placeholder="Product name"
                                [name]="'Product Name'"
                                [disabled]="true"
                            ></app-text-input>
                        </div>
                    </div>

                    <div style="margin-top: 20px">
                        <div class="form-group">
                            <label
                                class="control-label"
                                style="margin-right: 10px"
                                ><strong>Description</strong></label
                            >
                            <br />
                            <textarea
                                [(ngModel)]="activeItem.productDescription"
                                style="
                                    width: 100%;
                                    height: 15vh;
                                    margin-top: 10px;
                                "
                                [rows]="3"
                                pInputTextarea
                                autoResize="autoResize"
                                [disabled]="true"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="shadow-3 p-5 border-round-md">
                <h2 class="text-xl text-blue-500 font-bold">Properties</h2>
                <div class="grid">
                    <div class="col-12 md:col-6 lg:col-6 xl:col-6">
                        <label class="control-label" style="margin-right: 10px"
                            ><strong>Product Type</strong></label
                        >
                        <br />
                        <p-dropdown
                            [options]="_productService.productInfo.itemType"
                            [(ngModel)]="selectedProductType"
                            optionLabel="name"
                            [style]="{ width: '100%', 'margin-top': '10px' }"
                            [disabled]="true"
                        >
                        </p-dropdown>
                    </div>

                    <div class="col-12 md:col-6 lg:col-6 xl:col-6">
                        <label class="control-label" style="margin-right: 10px"
                            ><strong>Sold as </strong></label
                        >
                        <br />
                        <app-text-input
                            [disabled]="true"
                            [(ngModel)]="sellAs"
                            [label]=""
                            [name]="'Sold as'"
                        ></app-text-input>
                    </div>

                    <div class="col-12 md:col-6 lg:col-6 xl:col-6">
                        <label class="control-label" style="margin-right: 15px"
                            ><strong>Discount</strong></label
                        >
                        <br />
                        <p-dropdown
                            [options]="_productService.productInfo.discount"
                            [(ngModel)]="selectedDiscount"
                            optionLabel="name"
                            [style]="{ width: '100%', 'margin-top': '10px' }"
                            [disabled]="true"
                        >
                        </p-dropdown>
                    </div>

                    <div class="col-12 md:col-6 lg:col-6 xl:col-6">
                        <label class="control-label" style="margin-right: 15px"
                            ><strong>Age Restriction</strong></label
                        >
                        <br />
                        <p-dropdown
                            [options]="
                                _productService.productInfo.ageRestriction
                            "
                            [(ngModel)]="selectedAgeRestriction"
                            optionLabel="name"
                            [style]="{ width: '100%', 'margin-top': '10px' }"
                            [disabled]="true"
                        >
                        </p-dropdown>
                    </div>

                    <div class="col-12 md:col-6 lg:col-6 xl:col-6">
                        <label class="control-label" style="margin-right: 15px"
                            ><strong>Gender Specific </strong></label
                        >
                        <br />
                        <p-dropdown
                            [options]="_productService.productInfo.gender"
                            [(ngModel)]="selectedGender"
                            optionLabel="name"
                            [style]="{ width: '100%', 'margin-top': '10px' }"
                            [disabled]="true"
                        >
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <br />
            <div class="shadow-3 p-5 border-round-md">
                <h2 class="text-xl text-blue-500 font-bold">Inventory</h2>
                <div class="grid">
                    <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 10px"
                                ><strong
                                    >SKU (Stock Keeping Unit)
                                </strong></label
                            >
                            <br />
                            <app-text-input
                                [disabled]="true"
                                [(ngModel)]="activeItem.crsItemSKUs[0].skuCode"
                                [label]=""
                                [name]="'SKU'"
                            ></app-text-input>
                        </div>
                    </div>
                    <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 10px"
                                ><strong>Barcode </strong></label
                            >
                            <br />
                            <app-text-input
                                [disabled]="true"
                                [(ngModel)]="activeItem.crsItemSKUs[0].barcode"
                                [name]="'Bar Code'"
                                id="inventory-product-barcode"
                            ></app-text-input>
                        </div>
                    </div>
                    <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 10px"
                                ><strong>Min Stock Level</strong></label
                            >
                            <br />
                            <app-text-input
                                [disabled]="true"
                                [(ngModel)]="activeItem.crsItemSKUs[0].minLevel"
                                [name]="'Minimum level'"
                                id="inventory-product-minLevel"
                            ></app-text-input>
                        </div>
                    </div>
                </div>
                <br />
                <div class="grid">
                    <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 10px"
                                ><strong>Max Stock Level</strong></label
                            >
                            <br />
                            <app-text-input
                                [disabled]="true"
                                [(ngModel)]="activeItem.crsItemSKUs[0].maxLevel"
                                [name]="'Max level'"
                                id="inventory-product-maxLevel"
                            ></app-text-input>
                        </div>
                    </div>
                    <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 10px"
                                ><strong>Reorder Level</strong></label
                            >
                            <br />
                            <app-text-input
                                [disabled]="true"
                                [(ngModel)]="
                                    activeItem.crsItemSKUs[0].reorderLevel
                                "
                                [name]="'Reorder Level'"
                                id="inventory-product-reorderLevel"
                            ></app-text-input>
                        </div>
                    </div>
                    <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 10px"
                                ><strong>Minimum Order Quantity</strong></label
                            >
                            <br />
                            <app-text-input
                                [disabled]="true"
                                [(ngModel)]="
                                    activeItem.crsItemSKUs[0].minOrderQty
                                "
                                [name]="'Minimum Order Quantity'"
                                id="inventory-product-minOrderQty"
                            ></app-text-input>
                        </div>
                    </div>
                    <!-- <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 10px"
                                ><strong>Maximum Order Quantity</strong></label
                            >
                            <br />
                            <app-text-input
                                [disabled]="true"
                                [(ngModel)]="
                                    activeItem.crsItemSKUs[0].maxOrderQty
                                "
                                [name]="'Maximum Order Quantity'"
                                id="inventory-product-maxOrderQty"
                            ></app-text-input>
                        </div>
                    </div> -->
                </div>
                <br />
              <!-- Auto PO -->
                <div class="grid">
                    <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                        <div>
                            <div class="p-field-checkbox">
                                <p-checkbox
                                    [disabled]="true"
                                    [binary]="true"
                                    value="Track Stock"
                                    [(ngModel)]="activeItem.crsItemSKUs[0].autoPurchaseOrder"
                                    inputId="ny"
                                ></p-checkbox>
                                <label for="ny" style="margin-left: 10px"
                                    >Auto PO</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Auto PO -->
                <div class="grid">
                    <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                        <div>
                            <div class="p-field-checkbox">
                                <p-checkbox
                                    [disabled]="true"
                                    [binary]="true"
                                    value="Track Stock"
                                    [(ngModel)]="activeItem.trackStock"
                                    inputId="ny"
                                ></p-checkbox>
                                <label for="ny" style="margin-left: 10px"
                                    >Track Stock</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid" *ngIf="activeItem.trackStock === true">
                    <div class="col-12 md:col-12 lg:col-12 xl:col-12 px-4 mx-4">
                        <div>
                            <div
                                class="p-field-checkbox"
                                style="margin-top: 10px"
                            >
                                <p-checkbox
                                    [disabled]="true"
                                    [binary]="true"
                                    value="Allow Negative Stock"
                                    [(ngModel)]="activeItem.allowNegativeStock"
                                    inputId="sf"
                                ></p-checkbox>
                                <label for="sf" style="margin-left: 10px"
                                    >Allow Negative Stock</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <div class="shadow-3 p-5 border-round-md">
                <h2 class="text-xl text-blue-500 font-bold">Pricing</h2>
                <div class="grid">
                    <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                        <div class="p-field-checkbox">
                            <p-checkbox
                                [disabled]="true"
                                name="group11"
                                value="Taxable"
                                [binary]="true"
                                [(ngModel)]="activeItem.isTaxable"
                                inputId="ny1"
                            ></p-checkbox>
                            <label for="ny1" style="margin-left: 10px"
                                >Product is Taxable</label
                            >
                        </div>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                        <div class="p-field-checkbox" style="margin-top: 10px">
                            <p-checkbox
                                [disabled]="true"
                                name="group11"
                                value="Open Price"
                                [binary]="true"
                                [(ngModel)]="activeItem.isOpenPrice"
                                inputId="sf1"
                            ></p-checkbox>
                            <label for="sf1" style="margin-left: 10px"
                                >Open Price - Prompt for price on POS</label
                            >
                        </div>
                    </div>
                </div>
                <br />
                <div class="grid">
                    <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 15px"
                                ><strong>Price A </strong></label
                            >
                            <br />

                            <div class="p-grid">
                                <div class="p-col-12">
                                    <div
                                        class="p-inputgroup"
                                        [style]="{
                                            width: '100%',
                                            'margin-top': '10px'
                                        }"
                                    >
                                        <span class="p-inputgroup-addon"
                                            >$</span
                                        >
                                        <p-inputNumber
                                            [disabled]="true"
                                            [(ngModel)]="
                                                activeItem.crsItemSKUs[0]
                                                    .salePriceA
                                            "
                                            mode="decimal"
                                            [minFractionDigits]="2"
                                            [maxFractionDigits]="2"
                                        ></p-inputNumber>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 15px"
                                ><strong>Price B</strong></label
                            >
                            <br />
                            <div class="p-grid">
                                <div class="p-col-12">
                                    <div
                                        class="p-inputgroup"
                                        [style]="{
                                            width: '100%',
                                            'margin-top': '10px'
                                        }"
                                    >
                                        <span class="p-inputgroup-addon"
                                            >$</span
                                        >
                                        <p-inputNumber
                                            [disabled]="true"
                                            [(ngModel)]="
                                                activeItem.crsItemSKUs[0]
                                                    .salePriceB
                                            "
                                            mode="decimal"
                                            [minFractionDigits]="2"
                                            [maxFractionDigits]="2"
                                        ></p-inputNumber>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 15px"
                                ><strong>Price C</strong></label
                            >
                            <br />
                            <div class="p-grid">
                                <div class="p-col-12">
                                    <div
                                        class="p-inputgroup"
                                        [style]="{
                                            width: '100%',
                                            'margin-top': '10px'
                                        }"
                                    >
                                        <span class="p-inputgroup-addon"
                                            >$</span
                                        >
                                        <p-inputNumber
                                            [disabled]="true"
                                            [(ngModel)]="
                                                activeItem.crsItemSKUs[0]
                                                    .salePriceC
                                            "
                                            mode="decimal"
                                            [minFractionDigits]="2"
                                            [maxFractionDigits]="2"
                                        ></p-inputNumber>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="grid">
                    <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 15px"
                                ><strong>Default Price</strong></label
                            >
                            <br />

                            <div class="p-grid">
                                <div class="p-col-12">
                                    <div
                                        class="p-inputgroup"
                                        [style]="{
                                            width: '100%',
                                            'margin-top': '10px'
                                        }"
                                    >
                                        <p-dropdown
                                            [disabled]="true"
                                            [options]="
                                                _productService.productInfo
                                                    .defaultPrice
                                            "
                                            [(ngModel)]="selectedDefaultPrice"
                                            optionLabel="name"
                                            [style]="{ width: '100%' }"
                                        >
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 15px"
                                ><strong>Cost Price</strong></label
                            >
                            <br />
                            <div class="p-grid">
                                <div class="p-col-12">
                                    <div
                                        class="p-inputgroup"
                                        [style]="{
                                            width: '100%',
                                            'margin-top': '10px'
                                        }"
                                    >
                                        <span class="p-inputgroup-addon"
                                            >$</span
                                        >
                                        <p-inputNumber
                                            [disabled]="true"
                                            [(ngModel)]="
                                                activeItem.crsItemSKUs[0]
                                                    .costPrice
                                            "
                                            mode="decimal"
                                            [minFractionDigits]="2"
                                            [maxFractionDigits]="2"
                                        ></p-inputNumber>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="grid">
                    <div class="col">
                        <div>
                            <label class="control-label"
                                ><strong>Tax Rate </strong></label
                            >
                            <br />
                            <app-text-input
                                *ngIf="activeItem.isTaxable"
                                [disabled]="true"
                                [(ngModel)]="taxRate"
                            ></app-text-input>
                            <div
                                *ngIf="!activeItem.isTaxable"
                                class="text-md text-400 mt-5"
                            >
                                Item is non-taxable
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div>
                            <label class="control-label"
                                ><strong>Sell Quantity </strong></label
                            >
                            <br />
                            <p-dropdown
                                [disabled]="true"
                                [options]="
                                    _productService.productInfo.sellQuantity
                                "
                                [(ngModel)]="selectedSellQuantity"
                                optionLabel="name"
                                [style]="{
                                    width: '100%',
                                    'margin-top': '10px'
                                }"
                            >
                            </p-dropdown>
                        </div>
                    </div>
                    <div
                        class="col"
                        *ngIf="_productService.selectedSellQuantity.value === 2"
                    >
                        <div>
                            <label class="control-label"
                                ><strong>Case Quantity</strong></label
                            >
                            <br />
                            <p-inputNumber
                                [disabled]="true"
                                [(ngModel)]="activeItem.crsItemSKUs[0].caseQty"
                                [style]="{
                                    width: '100%',
                                    'margin-top': '12px'
                                }"
                            ></p-inputNumber>
                        </div>
                    </div>
                    <div class="col">
                        <div>
                            <label
                                ><strong>UOM (Unit of Measure) </strong></label
                            >
                            <br />
                            <p-dropdown
                                [disabled]="true"
                                [options]="_abd.uom"
                                [(ngModel)]="selectedUom"
                                optionLabel="unitName"
                                [style]="{
                                    width: '100%',
                                    'margin-top': '10px'
                                }"
                            >
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="activeTab.label === 'Shipping'">
            <br />
            <div class="shadow-3 p-5 border-round-md">
                <h2 class="text-xl text-blue-500 font-bold">
                    Shipping Information
                </h2>
                <div class="conn-grid-4x">
                    <div>
                        <label class="control-label">
                            <strong
                                >Weight
                                <span
                                    style="color: red; font-size: 1.25rem"
                                ></span> </strong
                        ></label>
                    </div>
                    <div>
                        <label class="control-label">
                            <strong>Weight Unit</strong></label
                        >
                    </div>
                    <div></div>
                </div>
                <div class="conn-grid-4x">
                    <div>
                        <p-inputNumber
                            [disabled]="true"
                            [(ngModel)]="
                                this.activeItem.crsItemSKUs[0].shippingDimention
                                    .weight
                            "
                            [style]="{ 'margin-top': '7px' }"
                            mode="decimal"
                            [minFractionDigits]="2"
                            [maxFractionDigits]="2"
                        ></p-inputNumber>
                    </div>
                    <div>
                        <app-text-input
                            [disabled]="true"
                            [(ngModel)]="
                                activeItem.crsItemSKUs[0].shippingDimention
                                    .weightUOMString
                            "
                        ></app-text-input>
                    </div>
                    <div></div>
                </div>
                <br />
                <br />
                <div class="conn-grid-3x">
                    <div>
                        <label class="control-label">
                            <strong>Dimensions</strong>
                        </label>
                    </div>
                    <div></div>
                    <div></div>
                </div>
                <div class="conn-grid-3x">
                    <div>
                        <app-text-input
                            [disabled]="true"
                            [(ngModel)]="
                                activeItem.crsItemSKUs[0].shippingDimention
                                    .dimensionUOMString
                            "
                        ></app-text-input>
                    </div>
                    <div></div>
                    <div></div>
                </div>

                <br />
                <br />
                <div class="formgrid grid">
                    <div class="field col">
                        <label>
                            <strong>Height</strong>
                        </label>
                        <br />
                        <app-text-input
                            [disabled]="true"
                            [(ngModel)]="
                                activeItem.crsItemSKUs[0].shippingDimention
                                    .height
                            "
                        ></app-text-input>
                    </div>
                    <div class="field col">
                        <label>
                            <strong>Width</strong>
                        </label>
                        <br />
                        <app-text-input
                            [disabled]="true"
                            [(ngModel)]="
                                activeItem.crsItemSKUs[0].shippingDimention
                                    .width
                            "
                        ></app-text-input>
                    </div>
                    <div class="field col">
                        <label>
                            <strong>Length</strong>
                        </label>
                        <br />
                        <app-text-input
                            [disabled]="true"
                            [(ngModel)]="
                                activeItem.crsItemSKUs[0].shippingDimention
                                    .length
                            "
                        ></app-text-input>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="activeTab.label === 'Additional'">
            <br />
            <div class="shadow-3 p-5 border-round-md">
                <h2 class="text-xl text-blue-500 font-bold">Additonal</h2>
                <div>
                    <div
                        class="flex justify-content-between align-items-end flex-wrap card-container purple-container"
                    >
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 10px"
                                ><strong>Product Information URL </strong>
                            </label>
                        </div>
                    </div>
                    <app-text-input
                        [disabled]="true"
                        [(ngModel)]="activeItem.descriptionURL"
                    ></app-text-input>
                    <br />
                    <div>
                        <p-checkbox
                            [disabled]="true"
                            [(ngModel)]="activeItem.printAsQRCodeOnReceipt"
                            binary="true"
                            inputId="binary"
                            label="Print as QR Code on Receipt"
                        ></p-checkbox>
                    </div>
                </div>
                <br /><br />

                <div>
                    <div
                        class="flex justify-content-between align-items-end flex-wrap card-container purple-container"
                    >
                        <div>
                            <label
                                class="control-label"
                                style="margin-right: 10px"
                                ><strong>Notes </strong>
                            </label>
                        </div>
                    </div>
                    <textarea
                        pInputTextarea
                        [disabled]="true"
                        [(ngModel)]="activeItem.notes"
                        [rows]="5"
                        pInputTextarea
                        autoResize="autoResize"
                        style="width: 100%; margin-top: 7px"
                    ></textarea>

                    <br /><br />
                    <p-checkbox
                        [disabled]="true"
                        [(ngModel)]="activeItem.printOnInvoice"
                        binary="true"
                        inputId="binary"
                        label="Print Notes at the end of Receipt"
                    ></p-checkbox>

                    <br />
                    <br />
                    <p-checkbox
                        [disabled]="true"
                        [(ngModel)]="activeItem.showNotesWhen"
                        binary="true"
                        inputId="binary"
                        label="Show Notes to cashier when item is ringed"
                    ></p-checkbox>
                </div>
            </div>
        </div>

        <div *ngIf="activeTab.label === 'Stock Management'">
            <br />
            <div class="shadow-3 p-5 border-round-md">
                <p-table
            #dt
            styleClass="p-datatable-striped"
            [autoLayout]="true"
            styleClass="p-datatable-striped"
            [tableStyle]="{ width: 'max-content' }"
            [value]="locationViseStock"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
        <ng-template pTemplate="header">
            <tr>
                <th style="text-align: left">Location</th>
                <th style="text-align: center">OPENING QUANTITY</th>
                <th style="text-align: center">quantity on hand</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{item.locationName}}</td>
                <td style="text-align: center">{{item.openingStock}}</td>
                <td style="text-align: center">{{item.stockOnHands}}</td>
            </tr>
        </ng-template>
    </p-table> 
            </div>
        </div>


        <div *ngIf="activeTab.label === 'Product Images'">
            <br />
            <div class="shadow-3 p-5 border-round-md">
                <h2 class="text-xl text-blue-500 font-bold">Gallery Images</h2>
                <div
                    class="fadeinright animation-duration-200 flex"
                    style="width: 100%"
                >
                    <p-galleria
                        [value]="activeItem.itemPictures"
                        [numVisible]="5"
                    >
                        <ng-template pTemplate="item" let-item>
                            <img [src]="item.picture" [alt]="item.itemsId" />
                        </ng-template>
                        <ng-template pTemplate="thumbnail" let-item>
                            <div
                                class="grid grid-nogutter justify-content-center"
                            >
                                <img
                                    style="width: 60px; padding: 10px"
                                    [src]="item.picture"
                                />
                            </div>
                        </ng-template>
                    </p-galleria>
                </div>
            </div>
        </div>
    </div>

    <div style="margin-top: 60px">
        <div
            class="shadow-3 p-5 border-round-md fadeinleft animation-duration-100"
        >
            <p class="text-xl text-blue-500 font-bold">Thumbnail</p>
            <div class="flex justify-content-center">
                <p *ngIf="!activeItem.image" class="text-red-300">No Image</p>
                <p-image
                    *ngIf="activeItem.image"
                    [src]="activeItem.image"
                    alt=""
                    width="150"
                ></p-image>
            </div>
        </div>

        <div class="fadeinleft animation-duration-100">
            <br />
            <div
                class="shadow-3 p-5 border-round-md fadeinleft animation-duration-100"
            >
                <h2 class="text-xl text-blue-500 font-bold">Where to Sell</h2>
                <div class="sub-widget-w-margin-10">
                    <p-dropdown
                        [disabled]="true"
                        [style]="{ width: '100%', 'min-width': '200px' }"
                        class="p-dropdown-1"
                        appendTo="body"
                        [options]="_abd.productSellType"
                        [(ngModel)]="selectedProductSellType"
                        optionLabel="Type"
                    ></p-dropdown>
                </div>
            </div>
        </div>

        <br />
        <div class="fadeinleft animation-duration-100">
            <div class="conn-card">
                <h2 class="text-xl text-blue-500 font-bold">Product Details</h2>
                <div class="sub-widget-w-margin-10">
                    <h3 class="w-title card-title">Department</h3>
                    <app-text-input
                        [disabled]="true"
                        [(ngModel)]="selectedDepartment.departmentName"
                    ></app-text-input>
                </div>

                <div class="sub-widget-w-margin-10">
                    <!-- category -->
                    <h3 class="w-title card-title">Category</h3>
                    <app-text-input
                        [disabled]="true"
                        [(ngModel)]="selectedCategory.categoryName"
                    ></app-text-input>
                </div>

                <div class="sub-widget-w-margin-10">
                    <!-- Manufacter -->
                    <h3 class="w-title card-title">Manufacturer</h3>
                    <app-text-input
                        [disabled]="true"
                        [(ngModel)]="selectedManufacturer.manufacturerName"
                    ></app-text-input>
                </div>

                <div class="sub-widget-w-margin-10">
                    <!-- Vendor -->
                    <h3 class="w-title card-title">Vendor</h3>
                    <app-text-input
                        [disabled]="true"
                        [(ngModel)]="selectedVendor.vendorName"
                    ></app-text-input>
                </div>
                <div class="sub-widget-w-margin-10">
                    <!-- Alt-Barcodes -->
                    <h3 class="w-title card-title">Alternate Barcodes</h3>
                    <p-chips
                    [disabled]="true"
                    type="text"
                    separator=","
                    addOnBlur="true"
                    [allowDuplicate]="true"
                    [(ngModel)]="selectedAltBarcodes"
                     >
                    </p-chips>
                </div>
                <div class="sub-widget-w-margin-10">
                    <!-- Alt-SKUs -->
                    <h3 class="w-title card-title">Alternate SKUs</h3>
                    <p-chips
                    [disabled]="true"
                    type="text"
                    separator=","
                    addOnBlur="true"
                    [allowDuplicate]="true"
                    [(ngModel)]="selectedAltSKUs"
                     >
                    </p-chips>
                </div>
                <div class="sub-widget-w-margin-10">
                    <!-- Alt-Tags -->
                    <h3 class="w-title card-title">Tags</h3>
                    <p-chips
                    [disabled]="true"
                    type="text"
                    separator=","
                    addOnBlur="true"
                    [allowDuplicate]="true"
                    [(ngModel)]="selectedAltTags"
                     >
                    </p-chips>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 2px solid var(--gray-200);
    border-width: 0 0 1px 0;
    font-weight: 500;
    color: var(--gray-600);
    text-transform: uppercase;
    background: var(--gray-200);
    transition: none;
}
</style>
