<form [formGroup]="editform" autocomplete="off">
    <div class="conn-card">
        <div class="flex row py-2">
            <div class="col-12 pl-0">
                <label class="control-label">
                    <strong>
                        From
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <p-chips type="text" [formControl]="$any(editform.controls)['fromEmail']" separator=","
                    (addOnBlur)="false" [allowDuplicate]="false" (onAdd)="validateKey($event)"></p-chips>
            </div>


        </div>
        <div class="flex row py-2">
            <div class="col-12 pl-0">
                <label class="control-label">
                    <strong>
                        To
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <p-chips type="text" [formControl]="$any(editform.controls)['toEmails']" separator="," addOnBlur="true"
                    [allowDuplicate]="false" [max]="1" (onAdd)="validateKey($event)"></p-chips>
            </div>


        </div>

        <div class="flex row py-2">
            <div class="col-12 pl-0">
                <label class="control-label">
                    <strong>
                        Subject
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <app-text-input [formControl]="$any(editform.controls)['subject']" [name]="'Subject'" [label]="">
                </app-text-input>
            </div>


        </div>
        <div class="flex row py-2">
            <div class="col-12 pl-0">
                <label class="control-label">
                    <strong>
                        Body
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <p-editor formControlName="body" [style]="{'height':'320px','width':'100%'}">
                </p-editor>
            </div>


        </div>


    </div>

    <p-divider style="margin-top: 10px"></p-divider>
    <div class="grid">
        <div class="col">
            <div class="flex justify-content-between flex-wrap card-container green-container mt-3">
                <div class="flex align-items-center justify-content-center">
                    <button pButton pRipple type="submit" label="Preview PO" (click)="openPreviewPO()"
                        [style]="{ width: '120px' }"></button>
                </div>
                <div class="flex align-items-center justify-content-center">
                    <div class="flex align-items-center justify-content-center mx-2">
                        <button pButton pRipple type="button" label="Cancel" class="p-button-outlined p-button-danger"
                            (click)="cancelButtonClick()" [style]="{ width: '120px' }"></button>
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <button pButton pRipple type="submit" label="Send" [style]="{ width: '120px' }"
                            [disabled]="!editform.valid" (click)="saveFn()"></button>
                    </div>

                </div>

            </div>
        </div>
    </div>
</form>