<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'time-tracking-summary-report'"
        (filterCallbackFunc)="callbackFuncFromFilter()"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    ></app-report-top-filter-bar>

    <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped" [tableStyle]="{ width: 'max-content' }"   responsiveLayout="scroll" [autoLayout]="true" *ngIf="dataList?.length > 0">
        <ng-template pTemplate="header">
            <tr>
                <th ><strong>Date</strong> </th>
                <th ><strong>Business</strong> </th>
                <th ><strong>Location</strong> </th>
                <!-- <th ><strong>Register</strong> </th> -->
                <th ><strong>Employee</strong> </th>
                <th ><strong>Clock-In Time</strong> </th>
                <th ><strong>Clock-Out Time</strong> </th>
                <th ><strong>Hours Worked</strong> </th>
                <th ><strong>Overtime</strong> </th>
                <th ><strong>Hours Remaining</strong> </th>
                  </tr>
        </ng-template>
        <ng-template pTemplate="body" let-timeTracking let-expanded="expanded">
            <tr>
                <td>{{timeTracking?.date | date:'MM/dd/yyyy'}}</td>
                <td>{{ timeTracking?.employeeName }}</td>
                <td>{{ timeTracking?.locationName }}</td>
                <!-- <td>{{ timeTracking?.registerName}}</td> -->
                <td>
                    {{ timeTracking?.employeeName }}
                </td>
                <td>
                    {{ timeTracking?.clockInSummary  }}
                </td>
                <td>
                    {{ timeTracking?.clockOutSummary  }}
                </td>  
                <td class="text-align-center">
                    {{ timeTracking?.fTotalHours !== undefined ? timeTracking?.fTotalHours : "--"}}
                </td>   
                 <td class="text-align-center">
                    {{ timeTracking?.fOverTime !== undefined ? timeTracking?.fOverTime : "--" }}
                </td>
                <td class="text-align-center">
                    {{ timeTracking?.fShortHour !== undefined ? timeTracking?.fShortHour : "--" }}
                </td>
            
              
                
            </tr>
        </ng-template>
        <ng-template  pTemplate="footer" let-timeTracking>
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="2" colspan="1" style="text-align: center">
                    <strong>Total</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <!-- <td></td> -->
                <td></td>
                <td></td>
                <td style="text-align: left">
                    {{calculateTime?.totalHoues}}
                </td>
                <td style="text-align: left">
                    {{calculateTime?.overTime }}
                </td>
                <td style="text-align: left">
                    {{calculateTime?.shortHours}}
                </td>
            </tr>
        </ng-template>

    </p-table>
    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>

</div>
