import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoyaltyConfig } from '../_models/reward-program/loyalty-configuration';
import { Perk, PerksReward, SaveRegularReward } from '../_models/reward-program/perk-model';
import { LoyaltyConfigType_HTTP_Response } from '../_models/reward-program/reward-program-response';
import { AppBaseDataService } from './app-base-data.service';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class RewardProgramService {

    _globals = GlobalService
    showNewEditTieredModal: boolean = false;
    isFirstTimeLoading: boolean = true;
    activeItem:any = null
    showNewEditForm:boolean = false;
    showEditForm:boolean = false;
    discountRewardObj = <PerksReward>{}
    constructor(private _bs: BaseService,
        private _abd: AppBaseDataService) { }


    getRegularDiscount(loadPerks: boolean) {
        let url = environment.BusinessBaseURL + environment.GetBusinessLoyaltyConfiguration + "?includePerks=" + loadPerks
        return this._bs.httpGetParams(url).pipe(map((res: any) => {
            return res
        }))
    }

    getTieredDiscount(paras: any) {
        let url = environment.BusinessBaseURL + environment.PerksReward
        return this._bs.httpGetParams(url, paras).pipe(map((res: any) => {
            return res
        }))
    }


    saveRegularReward(model: SaveRegularReward) {
        let url = environment.BusinessBaseURL + environment.RegularLoyaltyConfig
        return this._bs.httpPost(url, model).pipe(map((res: any) => {
            return res
        }))
    }
    getPointsAccumulation(paras: any) {
        let url = environment.BusinessBaseURL + "business-config"
        return this._bs.httpGetParams(url, paras).pipe(map((res: any) => {
            return res
        }))
    }


    savePointsAccumulation(model: any) {
        let url = environment.BusinessBaseURL + "business-config"
        return this._bs.httpPost(url, model).pipe(map((res: any) => {
            return res
        }))
    }
    updatePointsAccumulation(id:any,model: any) {
        let url = environment.BusinessBaseURL + "business-config/"+id
        return this._bs.httpPut(url, model).pipe(map((res: any) => {
            return res
        }))
    }
    deletePointsAccumulation(Id: any) {
        let url = environment.BusinessBaseURL + "business-config" + "/" + Id;
        return this._bs.httpDelete(url).pipe(map((res: any) => {
            return res
        }))
    }
    saveGeneralRewardConfiguration(model: LoyaltyConfig) {
        let url = environment.BusinessBaseURL + environment.BGeneralLoyaltyConfig
        return this._bs.httpPost(url, model).pipe(map((res: any) => {
            return res
        }))
    }

    saveTieredRewardInfo(model: PerksReward) {
        let url = environment.BusinessBaseURL + environment.PerksReward
        return this._bs.httpPost(url, model).pipe(map((res: any) => {
            return res
        }))
    }

    deleteTiered(tieredId: any) {
        let url = environment.BusinessBaseURL + environment.PerksReward + "/" + tieredId;
        return this._bs.httpDelete(url).pipe(map((res: any) => {
            return res
        }))
    }

    getLoyaltyConfigType() {
        let url = environment.BusinessBaseURL + environment.LoyaltyConfigType
        return this._bs.httpGetParams(url).pipe(map((res: LoyaltyConfigType_HTTP_Response) => {
            return res
        }))
    }

    saveRewardType(typeName: string) {
        let url = environment.BusinessBaseURL + environment.LoyaltyConfigType
        let param = JSON.stringify({
            "RewardType": typeName
        })
        return this._bs.httpPost(url, param).pipe(map((res: any) => {
            return res
        }))
    }



}
