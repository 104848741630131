<ng-container>
    <div class="conn-card">
        <app-report-top-filter-bar
            [paraReportName]="'inventory-count'"
            (filterCallbackFunc)="callbackFuncFromFilter($event)"
            (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
            (exportExcelFn)="exportExcel()"
            (exportPdfFn)="exportPdf()"
        ></app-report-top-filter-bar>
        <!-- (exportCsvFn)="dt.exportCSV()" -->
        <br />
        <!-- <ng-container *ngIf="dataList?.length"> -->
        <p-table
            #dt
            [columns]="csvcols"
            styleClass="p-datatable-striped"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="dataList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
            selectionMode="single"
            [(selection)]="selectedRow"
            dataKey="productId"
            (onRowSelect)="onRowSelect($event)"
            (onRowUnselect)="onRowUnselect($event)"
            [lazy]="true"
            (onLazyLoad)="customSort($event)"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center">
                        <p-checkbox
                            [(ngModel)]="selectAll"
                            [binary]="true"
                            (onChange)="selectAllChanged($event)"
                        ></p-checkbox>
                    </th>
                    <th *ngIf="_abd.locations.length > 1">Location</th>
                    <th pSortableColumn="itemName">
                        <p-sortIcon field="itemName"></p-sortIcon>Product Name
                    </th>
                    <th pSortableColumn="skuCode" style="text-align: center">
                        <p-sortIcon field="skuCode"></p-sortIcon>SKU
                    </th>
                    <th pSortableColumn="barcode" style="text-align: center">
                        <p-sortIcon field="barcode"></p-sortIcon>Barcode
                    </th>
                    <th style="text-align: center">Opening QTY.</th>
                    <th style="text-align: center">Sold</th>
                    <th style="text-align: center">QTY. on Hand</th>
                    <th style="text-align: center">Counted</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr
                    [pSelectableRow]="item"
                    class="text-center"
                    [style]="{ 'max-height': '20px' }"
                >
                    <td style="text-align: center">
                        <p-checkbox
                            [(ngModel)]="item.selected"
                            [binary]="true"
                            (onChange)="selectionChanged($event)"
                        ></p-checkbox>
                    </td>
                    <td *ngIf="_abd.locations.length > 1">
                        {{ item.locationName }}
                    </td>
                    <td>
                        {{ item.itemName | slice : 0 : 35 }}
                        <span *ngIf="item.itemName.length > 35">...</span>
                    </td>
                    <td style="text-align: center">{{ item.skuCode }}</td>
                    <td style="text-align: center">{{ item.barcode }}</td>

                    <td style="text-align: center">
                        {{ item.openingStock }}
                    </td>
                    <td style="text-align: center">{{ item.usedSince }}</td>
                    <td style="text-align: center">
                        {{ item.stockInHand }}
                    </td>
                    <td style="text-align: center">
                        <p-inputNumber
                            [min]="0"
                            [showButtons]="true"
                            buttonLayout="horizontal"
                            spinnerMode="horizontal"
                            decrementButtonClass="n-action-button"
                            incrementButtonClass="n-action-button"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                            [(ngModel)]="item.changedStockInHand"
                            [inputStyle]="{
                                width: '60px',
                                'text-align': 'center'
                            }"
                            [inputClass]="p - inputnumber22"
                            [minFractionDigits]="0"
                            [maxFractionDigits]="0"
                            (onBlur)="stockQuantityChanged(item.productId)"
                            (onInput)="stockQuantityChanged(item.productId)"
                        ></p-inputNumber>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="10" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>

                <tr>
                    <td colspan="10" style="text-align: center">
                        <p-button
                            label="Click to add product"
                            (onClick)="createProduct()"
                        ></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <p-paginator
            #p
            [first]="first"
            *ngIf="dataList?.length"
            (onPageChange)="onPageChange($event)"
            [rows]="rows"
            [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"
        ></p-paginator>

        <ng-container *ngIf="dataList?.length">
            <p-divider></p-divider>
            <div class="flex justify-content-end flex-wrap card-container">
                <div class="flex align-items-center justify-content-center">
                    <p-checkbox
                        [(ngModel)]="autoAdjustQty"
                        binary="true"
                        inputId="binary"
                    ></p-checkbox>
                    <label for="binary" style="margin-left: 10px"
                        >Auto Adjust Quantity on Hand?</label
                    >
                </div>
                <div
                    class="flex align-items-center justify-content-center"
                    style="margin-left: 20px"
                >
                    <button
                        pButton
                        type="button"
                        label="Post Inventory"
                        (click)="postInventory()"
                    ></button>
                </div>
            </div>
        </ng-container>
        <!-- <div class="conn-flex-right" style="padding: 20px" *ngIf="dataList">
            <div class="p-field-checkbox">
                <p-checkbox
                    [(ngModel)]="autoAdjustQty"
                    binary="true"
                    inputId="binary"
                ></p-checkbox>
                <label for="binary" style="margin-left: 10px"
                    >Auto Adjust Quantity on Hand?</label
                >
            </div>
            <button
                pButton
                type="button"
                label="Post Inventory"
                (click)="postInventory()"
            ></button>
        </div> -->
        <!-- </ng-container> -->
    </div>
</ng-container>

<p-sidebar
    *ngIf="gProductId"
    header="Item"
    [(visible)]="gProductId"
    [fullScreen]="true"
    class="productviewsidebar"
>
    <app-product-view-only [GID]="gProductId"></app-product-view-only>
</p-sidebar>
