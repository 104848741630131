import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { PermissionCheckerService } from 'src/app/_services/permission-checker.service';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit {
//   { label: 'Loyalty Program Configuration' },
  items: MenuItem[] = []

  activeItem: any = { label: 'General' }
  constructor(
    public _permissionsCheckerService:PermissionCheckerService,
  ) { }

  async ngOnInit(): Promise<void> {
   this.items =[    { label: 'General',
   visible: await this._permissionsCheckerService.isPermissionValid(['2027','2028','2029','2030','2031','2032','2034','2035','2061','2103']),
},
{ label: 'Tax Rate',
visible: await this._permissionsCheckerService.isPermissionValid(['2018']),
},
{ label: 'Invoice Configuration',
visible: await this._permissionsCheckerService.isPermissionValid(['2019']),

},
{ label: 'Printing Configuration',
visible: await this._permissionsCheckerService.isPermissionValid(['2020']),
},
{ label: 'Cashdrop Configuration',
visible: await this._permissionsCheckerService.isPermissionValid(['2021']),

},
{ label: 'Cashdrawer Configuration',
visible: await this._permissionsCheckerService.isPermissionValid(['2022']),

},
{ label: 'Security Configuration',
visible: await this._permissionsCheckerService.isPermissionValid(['2023']),

},
{ label: 'Tender Configuration',
visible: await this._permissionsCheckerService.isPermissionValid(['2024']),

},
{ label: 'Secondary Screen Configuration',
visible: await this._permissionsCheckerService.isPermissionValid(['2092']),

},
{ label: 'Purchase Order',
visible: await this._permissionsCheckerService.isPermissionValid(['2093']),

},
{ label: 'Time Tracking Configuration',
visible: await this._permissionsCheckerService.isPermissionValid(['2094']),

},
// { label: 'Customer Group Configuration',
// // visible: await this._permissionsCheckerService.isPermissionValid(['2094']),
// },
]
  }

  onTabItemClick(tab: any) {
    this.activeItem = tab.activeItem
  }

}
