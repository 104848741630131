import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { UserService } from 'src/app/_services/user.service';


@Component({
  selector: 'app-time-attendance',
  templateUrl: './time-attendance.component.html',
  styleUrls: ['./time-attendance.component.scss']
})
export class TimeAttendanceComponent implements OnInit {
 
 _global = GlobalService
 obj:any
 @Input() data:any = null;
 @Output() reLoad = new EventEmitter();
 userLocations:any

 userRoles:any
 isUserCheckedIn: boolean;
 checkOutReason;
 isCheckOutReason:FormControl = new FormControl(false) ;
 date:any = new Date();
 isCheckInUser:boolean= true;
  isCheckIn:boolean = false;
  clockInOutLocation:any = null;
  clockInOutRegister:any = null;
  clockInOutRole:any = null;
  isCurrentCheckIn = false;
  isShowCheckIn: boolean = false;

 reasonList =[
  {id:1,value:'Check Out'},
  {id:2,value:'Break'},
  {id:3,value:'Other'},
]
  isAllowed: any = false;
  isLoadingLocation: boolean = false;
  currentUser:any;

 constructor(
   public _userService:UserService,
   public _abd:AppBaseDataService,
   private _connMessageService: ConnMessageService,
 ){ }

  ngOnInit(): void {


    this.userLocations = this.getUserLocations(this._global?.userInfo?.userLocations,this._abd.locations)
    this.userRoles = this.getUserRoles(this._global?.userInfo?.role,this._abd.roles)
    this.clockInOutRole = this.userRoles[0]
    this.isLoadingLocation = true
      if(this.data.currentStatus === "Checkin"){
      this.isUserCheckedIn = true;  
      this.isLoadingLocation = true;
        this._userService.getTimeAttendance(this.data.userID).subscribe((res)=>{
       if(res.data.type === 0){
        this.isLoadingLocation = false;
        this.currentUser ={
        UserId:        res.data?.userId,
        EmployeeID:    res.data?.userId,
        EmployeeRole:  res.data?.employeeRole,
        EmployeeEmail: res.data?.employeeEmail, 
        EmployeeName:  res.data?.employeeName,
        LocationName:  res.data?.locationName,
        RegisterName:  res.data?.registerName,
        locationid:    res.data?.locationId,
        Type:          1,
        Method:        res.data?.method,
        reason:        this.checkOutReason.id,
       }
       this.clockInOutLocation = this.userLocations.filter((x)=>x.locationId === res.data?.locationId)[0]
    
      }})
   }else if(this.data.currentStatus === "Break" || this.data.currentStatus === "Other" || this.data.currentStatus === "Absent"|| this.data.currentStatus === "Checkout" ){
    this.isUserCheckedIn = false;
      
   }
     setTimeout(() => {
      this.isLoadingLocation = false
    this.locationChanged({value:this.clockInOutLocation})  
  }, 1000);
}
onDateChanged(event: any) {
   this.date = event;
 }
  clockIn(){

    this._abd.showSpinner()
    this.obj ={
        UserId:        this.data?.userID,
        EmployeeID:    this.data?.userID,
        EmployeeRole:  this.clockInOutRole?.name ,
        EmployeeEmail: this.data?.email,
        EmployeeName:  this.data?.displayName,
        LocationName:  this.clockInOutLocation?.locationName,
        // RegisterName:  this.isCheckOutReason?.value === true ? this.clockInOutRegister?.registerName || "Other":"Other",
        RegisterName: "Other",
        locationid:+this.clockInOutLocation?.id,
        Type:0,
        Method: 2,
        Reason:0,
       }
  //  if(!this.isAllowed && this._abd.locations.length > 0){
  this._userService.checkInUser(formatDate(this.date, 'YYYY-MM-ddTHH:mm:ss', 'en-US','UTC'),this.obj).subscribe((res)=>{
    if(res.success){
      this._userService.isCheckIn = false,
      this.reLoad.emit('')
      this._abd.hideSpinner()
    }
    this._abd.hideSpinner()
   })

 
  }
  clockOut(){
    this._abd.showSpinner()
    // this._userService.getTimeAttendance(this.data.userID).subscribe((res)=>{
    // if(res.data.type === 0){
    // this.currentUser ={
    //     UserId:        res.data?.userId,
    //     EmployeeID:    res.data?.userId,
    //     EmployeeRole:  res.data?.employeeRole,
    //     EmployeeEmail: res.data?.employeeEmail, 
    //     EmployeeName:  res.data?.employeeName,
    //     LocationName:  res.data?.locationName,
    //     RegisterName:  res.data?.registerName,
    //     locationid:    res.data?.locationId,
    //     Type:          1,
    //     Method:        res.data?.method,
    //     reason:        this.checkOutReason.id,
    //    }
 
    this._userService.checkOutUser(formatDate(this.date, 'YYYY-MM-ddTHH:mm:ss', 'en-US','UTC'),this.currentUser).subscribe((res)=>{
    if(res.success){
      this.reLoad.emit('')
      this._userService.isCheckIn = false
    }
      //     localStorage.setItem('userAttendance','not-check-in'),
    //  this.isCurrentCheckIn = false
    //  this.isCheckIn = false
    //  this.isCheckInUser = false; 
     })

    // }})
    this._abd.hideSpinner()
  }
  getUserLocations(array1?: string[], array2?: any[]): any[] {
    const roleIdSet = new Set(array1);
    return array2.filter(obj => roleIdSet.has(obj.locationId.toString()));
  }
  getUserRoles(array1?: string[], array2?: any[]): any[] {
    const roleIdSet = new Set(array1);
    return array2.filter(obj => roleIdSet.has(obj.roleId.toString()));
}
locationChanged(e){
  if(this.data.currentStatus === "Break" || this.data.currentStatus === "Other" || this.data.currentStatus === "Absent"|| this.data.currentStatus === "Checkout" ){
  this.isLoadingLocation = true;
  if(e?.value?.locationId !== undefined){
      this._abd.getTimeTrackingConfigurationWithFilter(e?.value?.locationId).subscribe((res)=>{
    this.isAllowed = res.data.active
     this.isLoadingLocation = false;
    },(error)=>{
      this.isLoadingLocation = false;
    })
  }
  }
}

dismiss(){
  this._userService.isCheckIn = false
}

}