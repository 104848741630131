import { GlobalService } from 'src/app/_services/global.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BarcodeLabelPrintingService {
  filter: any = null
  QOHChange=false;
  activeItem: any = null
  barcodeLabelInfo: any = ''
  locations: any = ''

  isNew = false
  isEdit = false
  isViewOnly = false
  isReject = false

  barcodeLabelName: any = 'any name of your choice'
  descriptionUrl: any = ''
  barcodeLabelDescription: any = ''
  barcodeLabelNotes = ''

  showEditBarcodeLabel = false;
  showNewEditBarcodeLabel = false;
  isLabelPrintBtn = false;
  showEditCustomBarcodeLabel = false;
  showNewEditCustomBarcodeLabel = false;
  productListPrintBarcodeLabel = false;
  productDetailPrintBarcodeLabel = false;

  addLabelFromCreateProduct:any;

  selectedListToPrintLabel:any[] = []

  sku: any = ''
  barcode: any = ''


       blankActiveItem(){
    this.activeItem = {
      ProductId: "",
      Barcode: "",
      Sku: "",
      Location: " ",//CS value"1,2,3"
      Business: "",
      Price: "0.00",
      Status: "",
      CreatedBy: "",
      LocationName: "",
      LocationId: 0,
      LastModified: "",
      NoOfCopy: '',
      TemplateId: ''
    }
  }


  validate = { pd: false, pp: false, pi: false }

  selectedLocations: any = []


  featureImage: any = null
  thImages: any = []
  thImageNames: any = []

 
 
  //----- tmp data
  pts = {
    t_barcodeLabelName: '',
    descriptionUrl: '',
    sku: '',
    barcode: '',
    priceA: null,
    priceB: null,
    priceC: null,
    costPrice: null,
    discountPrice: null,
    caseQty: null,
    maxLevel: 0,
    minLevel: 0,
    reorderLevel: 0,
    minOrderQty: 0
  }

  constructor(
    private _bs: BaseService,
    private _abd: AppBaseDataService,
    private _fb: FormBuilder,
    private http: HttpClient
  ) {
    this.filter = {
      searchText: '',
      selectedLocation: '',
    }
  }



  saveBarcodeLabel(model: any) {
    //console.log(model)
    const url = environment.InventoryBaseURL + 'BarCodeQue'
    return this._bs.httpPost(url, model, true).pipe(map(res => {
      return res
    }))
  }

  updateBarcodeLabel(model: any, id: any) {
    //console.log(model)
    const url = environment.InventoryBaseURL  + 'BarCodeQue/' + id
    return this._bs.httpPut(url, model, true).pipe(map(res => {
      return res
    }))
  }

  deleteBarcodeLabel(ids?: string) {
    let url = environment.InventoryBaseURL  + 'BarcodeByIds/'+ ids;
    return this.http.delete(url).pipe(map(res => {
      return this._bs.processErrors(res)
    }))
  }

  getAllbarcodeLabelList(paras?: any) {
    let url = environment.InventoryBaseURL + 'BarCodeQue';

    return this._bs.httpGetParams(url, paras).pipe(map(res => {
      return res
    }))
  }
  getbarcodeLabelConfiguration(paras?: any) {
    let url = environment.BusinessBaseURL + 'printer/label';

    return this._bs.httpGetParams(url, paras).pipe(map(res => {
      return res
    }))
  }


}
