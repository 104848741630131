<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            <button
                pButton
                pRipple
                type="button"
                label="Edit"
                class="p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-pencil"
                iconPos="right"
                (click)="editButtonClicked()"
            ></button>
            <button
            pButton
            pRipple
            type="button"
            label="Change Status"
            class="p-button-danger p-button-text"
            [style]="{ width: '100%' }"
            icon=""
            iconPos="right"
            (click)="optionStatusChangeClicked()"
        ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Delete"
                [style]="{ width: '100%' }"
                class="p-button-danger p-button-text"
                icon="pi pi-trash"
                iconPos="right"
                (click)="optionDeleteClicked()"
            ></button>
        </div>
    </ng-template>
</p-overlayPanel>
<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'discounts'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (deleteItemFn)="optionDeleteClicked()"
    ></app-report-top-filter-bar>
    <!-- (deleteItemFn)="deleteItem()" -->
    <br />
    <p-table
        #dt
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        *ngIf="dataList?.length > 0"
        [autoLayout]="true"
        responsiveLayout="scroll"
        [paginator]="false"
        [rows]="rows"
        showLoader="true"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} discounts"
        [globalFilterFields]="['customerName']"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        selectionMode="single"
        (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Codes</th>
                <th>Start Date</th>
                <th>End Date</th>

                <th style="text-align: center">Customer Type</th>
                <th>Allowed Locations</th>
                <th>
                    status
                </th>
                <th>Action</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item" class="text-center">
                <td>{{ item.discountName }}</td>
                <td>{{ item.couponCodesListWithSpace }}</td>
                <td>
                    <span *ngIf="item.validity == 1">{{
                        item.validityStartDate | date : "MM/dd/yyyy"
                    }}</span>
                </td>
                <td>
                    <span *ngIf="item.validity == 1">{{
                        item.validityEndDate | date : "MM/dd/yyyy"
                    }}</span>
                </td>
                <td style="text-align: center">
                    <span *ngIf="item.customerType == 0">ALL</span>
                    <span *ngIf="item.customerType == 1">Loyalty</span>
                    <span *ngIf="item.customerType == 2">Cash Customer</span>
                    <span *ngIf="item.customerType == 3">Group</span>
                </td>
                <td>{{ item.allowedLocationsListString }}</td>
                <td style="text-align: center;padding:20%" class="{{
                    item.isActive == true
                        ? 'Active badge'
                        : 'In-Active badge'
                }}">
                <span>{{
                    item.isActive == true ? "Active" : "Inactive"
                    }}</span>
            </td>
                <td>
                    <button
                        pButton
                        pRipple
                        icon="pi pi-angle-down"
                        iconPos="right"
                        type="button"
                        label="Actions"
                        class="n-action-button"
                        (click)="op.toggle($event)"
                        (click)="actionButtonClick(item)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length == 0">
        <div class="text-align-center" style="width: 100%">
            <span class="control-description text-muted text-align-center">
                No data found!</span
            >
        </div>
    </div>
    <div>
        <p-paginator
            #p
            [first]="first"
            *ngIf="dataList?.length"
            (onPageChange)="onPageChange($event)"
            [rows]="rows"
            [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Records: {first} of {last}, Total Records: {totalRecords}"
        >
        </p-paginator>
    </div>
</div>

<p-dialog
    *ngIf="_discountsService.showEditDiscount"
    header="Edit Discount Details"
    [(visible)]="_discountsService.showEditDiscount"
    [style]="{ width: '80%' }"
    modal="true"
>
    <app-new-edit-discounts
        [singupBusinessConfig]="true"
        [isNew]="false"
        [isViewOnly]="isViewOnly"
        (onSubmit)="refrestDataList($event)"
    ></app-new-edit-discounts>
</p-dialog>

<ng-container *ngIf="_discountsService.showNewEditDiscount">
    <p-dialog
        header="Add New Discount"
        [(visible)]="_discountsService.showNewEditDiscount"
        [style]="{ width: '80%' }"
        modal="true"
    >
        <app-new-edit-discounts
            [singupBusinessConfig]="true"
            [isNew]="true"
            [isViewOnly]="false"
            (onSubmit)="refrestDataList($event)"
        ></app-new-edit-discounts>
    </p-dialog>
</ng-container>
