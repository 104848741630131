<div>
    <div class="flex justify-content-between align-items-end flex-wrap card-container purple-container">
        <div>
            <label class="control-label" style="margin-right: 10px"><strong>Product Information URL
                    <span style="color: red; font-size: 1.25rem"></span>
                </strong>
            </label>
        </div>

    </div>
    <!-- [formControl]="$any(editform?.controls)['descriptionUrl']" -->
    <app-text-input [(ngModel)]="_productServices.pts.descriptionUrl" [label]="" [name]="'Description URL'" (input)="
            _productServices.pts.descriptionUrl =
                $event.target.value
        "
        [disabled]="_productServices.isViewOnly"
         [description]="'Additonal Info URL (e.g. specs, Datasheet)'"></app-text-input>
    <div>
        <p-checkbox [ngModelOptions]="{ standalone: true }" [(ngModel)]="_productServices.isPrintAsQR" binary="true"
            inputId="binary" label="Print as QR Code on Receipt" [disabled]="_productServices.isViewOnly"></p-checkbox>
    </div>

    <div class="mt-3">
        <p-checkbox [ngModelOptions]="{ standalone: true }" [(ngModel)]="_productServices.isReturnable" binary="true"
        (onChange)="isReturnableHandle($event)"
            inputId="binary" label="Is Product Returnable?"
            [disabled]="_productServices.isViewOnly"
            ></p-checkbox>
    </div>
</div>
<br /><br />

<div>
    <div class="flex justify-content-between align-items-end flex-wrap card-container purple-container">
        <div>
            <label class="control-label" style="margin-right: 10px"><strong>Notes
                    <span style="color: red; font-size: 1.25rem"></span>
                </strong>
            </label>
        </div>
        
    </div>
    <textarea pInputTextarea [(ngModel)]="_productServices.productNotes" [rows]="5" pInputTextarea
        autoResize="autoResize" style="width: 100%"
        [disabled]="_productServices.isViewOnly"
        ></textarea>

    <br />

    <br />

    <p-checkbox [(ngModel)]="_productServices.printNotesOnReceipt" binary="true" inputId="binary"
        label="Print Notes at the end of Receipt"
        [disabled]="_productServices.isViewOnly"
        ></p-checkbox>

    <br />
    <br />
    <p-checkbox [(ngModel)]="_productServices.ShowNotesWhenRingingItem" binary="true" inputId="binary"
        label="Show Notes to cashier when item is ringed"
        [disabled]="_productServices.isViewOnly"
        ></p-checkbox>
</div>

<br />
<br />
