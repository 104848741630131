import {
    AfterContentChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/_services/base.service';
import { SignupService } from 'src/app/_services/signup.service';
import { UsStates } from 'src/app/_data/us-states';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ConfirmationService } from 'primeng/api';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { debounceTime } from 'rxjs';
import { WhiteLabelList } from 'src/app/_data/white-label-list';
@Component({
    selector: 'app-signup-checkout',
    templateUrl: './signup-checkout.component.html',
    styleUrls: ['./signup-checkout.component.scss'],
})
export class SignupCheckoutComponent implements OnInit, AfterContentChecked {
    subscriptionPlanLocationQty = 1;

    subTotal: number = 0;
    taxAmount: number = 0;
    grandTotal: number = 0;

    products: any = [];
    usaStates = UsStates;
    showCityWait = false;
    showBCityWait = false;
    selectedBState: any = '';
    selectedState: any = '';

    localRegistersQty = 0;
    localLocationsQty = 0;
   
    displaySubscriptionPlan = false;
    oldPlanSelected: any = null;
    oldLocation: number = 0;
    oldRegister: number = 0;
    displayWebsiteAndOnlineStore = false;
    displayHardwareList = false;
    displayCustomerApp = false;

    billingForm: FormGroup;
    editform: FormGroup;
    _globals = GlobalService;

    isLoyaltyProgramAvailable: boolean = false;
    isLoyaltyProgramAvailableUsingSubscription: boolean = false;

    isStaticWebsiteAvailable: boolean = false;
    isECommerceWebsiteAvailable: boolean = false;
    
    isStaticWebsiteSelected: boolean = false;
    isECommerceWebsiteSelected: boolean = false;
    @Output() backButtonClick = new EventEmitter();
    totalLocation: number = 1;
    showChangeLoyaltyProgram: boolean = false;

    couponCodeDiscountValue:any ={
      value:0.00,type:null
    };
   
    couponCodeInput:FormControl = new FormControl();
    couponCodeNotValid:boolean = false;
    showCouponCode:boolean = false;
    loading:boolean = false;
    discount:any;
    couponCode:any;
    isBillingAndShipmentSame: boolean;

    constructor(
        public _signup: SignupService,
        private _fb: FormBuilder,
        public _abd: AppBaseDataService,
        private _bs: BaseService,
        private _confirmationService: ConfirmationService,
        private _connMessageService: ConnMessageService,
        private _router: Router,
        private ref: ChangeDetectorRef,
        private _http:HttpClient,
    ) {}
 
    ngAfterContentChecked() {
        this.ref.detectChanges();
    }

    get bf() {
        return this.billingForm.controls;
    }
    get f() {
        return this.editform.controls;
    }

    ngOnInit(): void {
        try {
            let subscriptionAgain: any =
                sessionStorage.getItem('sub-from-dashboard');

            if (subscriptionAgain != undefined) {
                let selectedHardware: any =
                    sessionStorage.getItem('selectedPlan');
                if (selectedHardware != undefined) {
                    this._signup.selectedPlan = JSON.parse(selectedHardware);

                    let locationsQty: any =
                        sessionStorage.getItem('locationsQty');
                    if (locationsQty != undefined) {
                        this._signup.checkoutPageCalcVars.locationsQty =
                            parseInt(locationsQty);
                        this._signup.outerLocationQty = parseInt(locationsQty);
                    }

                    let registersQty: any =
                        sessionStorage.getItem('registersQty');
                    if (registersQty != undefined) {
                        this._signup.checkoutPageCalcVars.registersQty =
                            parseInt(registersQty);
                        this._signup.outerRegisterQty = parseInt(registersQty);
                    }

                    let selectedPlanTimeframe: any = sessionStorage.getItem(
                        'selectedPlanTimeframe'
                    );
                    if (selectedPlanTimeframe != undefined) {
                        this._signup.selectedPlanTimeframe =
                            selectedPlanTimeframe;
                    }
                }
            }

            this._signup.checkoutPageCalcVars.locationsQty =
                this._signup.outerLocationQty;
            this._signup.checkoutPageCalcVars.registersQty =
                this._signup.outerRegisterQty;

            this.localRegistersQty =
                this._signup.checkoutPageCalcVars.registersQty;
            this.localLocationsQty =
                this._signup.checkoutPageCalcVars.locationsQty;

            this.totalLocation = this.localLocationsQty;

            if (this._signup.selectedPlanTimeframe == 'BilledMonthly') {
                this._signup.checkoutPageCalcVars.planCost =
                    this._signup.selectedPlan?.prices[1]?.value;
                this._signup.checkoutPageCalcVars.subscriptionPlanPrice =
                    this._signup.selectedPlan?.prices[1]?.value;
            } else {
                this._signup.checkoutPageCalcVars.planCost =
                    this._signup.selectedPlan?.prices[0]?.value;
                this._signup.checkoutPageCalcVars.subscriptionPlanPrice =
                    this._signup.selectedPlan?.prices[0]?.value * 12;
            }

            this.initForm();
            this.applyShippingAddressFromBusinessInfo(
                this._signup.checkoutPageInfo.useBusinessAddressForShipment
            );
            this.billingForm.valueChanges.pipe(debounceTime(1000)).subscribe((v)=>{
                this.applyShippingAddressFromBusinessInfo(
                    this._signup.checkoutPageInfo.useBusinessAddressForShipment
                );
            this.calcTotals()
            })
         
        } catch (error) {
            console.log(error);
        }   
    }

    initForm() {

            this.billingForm = this._fb.group({
                address: [
                    this._signup.businessInfo.address,
                    [Validators.required],
                ],
                postalCode: [
                    this._signup.businessInfo.zip,
                    Validators.required,
                ],
                city: [this._signup.businessInfo.city, Validators.required],
                state: '',
            });
        
      
    
        this.editform = this._fb.group({
            address: [
                this._signup.checkoutPageInfo.address,
                [Validators.required],
            ],
            postalCode: [
                this._signup.checkoutPageInfo.zip,
                Validators.required,
            ],
            city: [this._signup.checkoutPageInfo.city, Validators.required],
            state: '',
        });

       this.selectedBState = this.usaStates.filter(
            (f) => f.abbreviation == this._signup.businessInfo.state
        )[0];
        
        this.selectedState = this.usaStates.filter(
            (f) => f.abbreviation == this._signup.businessInfo.state
        )[0];

        this.calcHardwareNetPrice();

        this.verifyLoyaltyPointsAvailable();
        this.verifyWebsiteAvailable();
        this.calcTotals();
        location.href = location.origin + '/#/setup/signup';
    }
    verifyLoyaltyPointsAvailable() {
        try {
            this.isLoyaltyProgramAvailable = false;
            this.isLoyaltyProgramAvailableUsingSubscription = false;
            // already include in subscription plan
            if (
                this._signup.selectedPlan.featureData.filter(
                    (f) => f.typeString == 'LoyaltyProgram'
                )[0].price == 0
            ) {
                this.isLoyaltyProgramAvailable = true;
                this.isLoyaltyProgramAvailableUsingSubscription = true;
            }
            // not in subscription plan and is user selected or not?

            if (this._signup.loyaltyProgramAppBaseData[0].selected) {
                this.isLoyaltyProgramAvailable = true;
            }

            if (this.isLoyaltyProgramAvailable) {
                if (this._signup.selectedPlanTimeframe == 'BilledMonthly') {
                    this._signup.loyaltyProgramPrice =
                        this._signup.selectedPlan.featureData.filter(
                            (f) => f.typeString == 'LoyaltyProgram'
                        )[0].price;
                    this._signup.checkoutPageCalcVars.loyaltyProgram =
                        this._signup.selectedPlan.featureData.filter(
                            (f) => f.typeString == 'LoyaltyProgram'
                        )[0].price;
                } else {
                    this._signup.loyaltyProgramPrice =
                        this._signup.selectedPlan.featureData.filter(
                            (f) => f.typeString == 'LoyaltyProgram'
                        )[0].price * 12;
                    this._signup.checkoutPageCalcVars.loyaltyProgram =
                        this._signup.selectedPlan.featureData.filter(
                            (f) => f.typeString == 'LoyaltyProgram'
                        )[0].price * 12;
                }
            } else {
                this._signup.loyaltyProgramPrice = 0;
                this._signup.checkoutPageCalcVars.loyaltyProgram = 0;
            }

            this.calcTotals();
        } catch (error) {}
    }

    includePlan() {
        try {
            let self = this;
            this._signup.eComSubscriptionPlans.map(function (value) {
                if (
                    value.pricePlan.filter(
                        (f) => f.planID == self._signup.selectedPlan.planId
                    ).length > 0
                ) {
                    if (self._signup.selectedPlanTimeframe == 'BilledMonthly') {
                        value.price = value.pricePlan.filter(
                            (f) => f.planID == self._signup.selectedPlan.planId
                        )[0].monthlyPrice;
                    }
                    if (self._signup.selectedPlanTimeframe != 'BilledMonthly') {
                        value.price = value.pricePlan.filter(
                            (f) => f.planID == self._signup.selectedPlan.planId
                        )[0].monthlyPrice;
                    }

                    value.includedInPackage = false;
                    if (value.price == 0) {
                        if (value.title == 'Static Website') {
                            value.includedInPackage = true;
                            self.isStaticWebsiteAvailable = true;
                            self.isStaticWebsiteSelected = false;
                            self._signup.checkoutPageCalcVars.staticWebsite = 0;
                        }

                        if (value.title == 'Basic eCommerce') {
                            value.includedInPackage = true;
                            self.isECommerceWebsiteSelected = false;
                            self.isECommerceWebsiteAvailable = true;
                            self._signup.checkoutPageCalcVars.ecomCost = 0;
                        }
                    }
                }
            });
        } catch (error) {}
    }
    verifyWebsiteAvailable() {
        try {
            this.isStaticWebsiteAvailable = false;
            this.isECommerceWebsiteAvailable = false;
            this.isStaticWebsiteSelected = false;
            this.isECommerceWebsiteSelected = false;
            this.isLoyaltyProgramAvailableUsingSubscription = false;
            this.includePlan();

            let selectedWebsiteAndOnlineStorePlan: any = sessionStorage.getItem(
                'selectedWebsiteAndOnlineStorePlan'
            );
            if (selectedWebsiteAndOnlineStorePlan != undefined) {
                selectedWebsiteAndOnlineStorePlan = JSON.parse(
                    selectedWebsiteAndOnlineStorePlan
                );
                this._signup.selectedWebsiteAndOnlineStorePlan =
                    selectedWebsiteAndOnlineStorePlan;
            }

            if (this._signup.selectedWebsiteAndOnlineStorePlan != null) {
                let tempSubscription =
                    this._signup.selectedWebsiteAndOnlineStorePlan;
                if (tempSubscription.title == 'Static Website') {
                    this.isStaticWebsiteAvailable = true;
                    this.isStaticWebsiteSelected = true;
                    this._signup.selectedWebsiteAndOnlineStorePlan =
                        this._signup.selectedWebsiteAndOnlineStorePlan;
                    this._signup.selectedECommerceWebsitePlan =
                        this._signup.selectedWebsiteAndOnlineStorePlan;
                    this._signup.selectedECommerceWebsitePlan.price =
                        this._signup.eComSubscriptionPlans.filter(
                            (f) =>
                                f.id ==
                                this._signup.selectedECommerceWebsitePlan.id
                        )[0].price;

                    if (this._signup.selectedPlanTimeframe == 'BilledMonthly') {
                        this._signup.selectedECommerceWebsitePlan.price =
                            this._signup.selectedECommerceWebsitePlan.price;
                    } else {
                        this._signup.selectedECommerceWebsitePlan.price =
                            this._signup.selectedECommerceWebsitePlan.price *
                            12;
                    }

                    this._signup.checkoutPageCalcVars.staticWebsite =
                        this._signup.selectedECommerceWebsitePlan.price;
                }

                if (tempSubscription.title == 'Basic eCommerce') {
                    this.isECommerceWebsiteAvailable = true;
                    this.isECommerceWebsiteSelected = true;
                    this._signup.selectedECommerceWebsitePlan =
                        this._signup.selectedWebsiteAndOnlineStorePlan;
                    this._signup.selectedECommerceWebsitePlan =
                        this._signup.selectedWebsiteAndOnlineStorePlan;
                    this._signup.selectedECommerceWebsitePlan.price =
                        this._signup.eComSubscriptionPlans.filter(
                            (f) =>
                                f.id ==
                                this._signup.selectedECommerceWebsitePlan.id
                        )[0].price;

                    if (this._signup.selectedPlanTimeframe == 'BilledMonthly') {
                        this._signup.selectedECommerceWebsitePlan.price =
                            this._signup.selectedECommerceWebsitePlan.price;
                    } else {
                        this._signup.selectedECommerceWebsitePlan.price =
                            this._signup.selectedECommerceWebsitePlan.price *
                            12;
                    }

                    this._signup.checkoutPageCalcVars.ecomCost =
                        this._signup.selectedECommerceWebsitePlan.price;
                }

                let showCustomDomain: any =
                    sessionStorage.getItem('showCustomDomain');
                if (showCustomDomain != undefined) {
                    if (showCustomDomain == '1') {
                        this._signup.showCustomDomain = true;
                        if (
                            this._signup.selectedPlanTimeframe ==
                            'BilledMonthly'
                        ) {
                            this._signup.checkoutPageCalcVars.customDomainCost =
                                this._signup.selectedWebsiteAndOnlineStorePlan.domainPlan[0].monthlyPrice;
                        } else {
                            this._signup.checkoutPageCalcVars.customDomainCost =
                                this._signup.selectedWebsiteAndOnlineStorePlan
                                    .domainPlan[0].monthlyPrice * 12;
                        }
                    }
                }
            }
        } catch (error) {}
    }

    zipInputBEvent(value: string) {
        if (!value || value.length !== 5) return;
        this.showBCityWait = true;
        this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
            this.showBCityWait = false;
            if (res.success && res.data.city != undefined) {
                this.selectedBState = this.usaStates.find(
                    (s) => s.abbreviation == res.data.state
                );
                this.billingForm.patchValue({ city: res.data.city });
                this.refreshFormValues();
                this.calcTaxValue()
                this.calcTotals()
            } else {
                this.billingForm.patchValue({ city: 'Invalid zip code' });
            }
        });
        this.refreshFormValues();
    }

    zipInputEvent(value: string) {
        if (!value || value.length !== 5) return;
        this.showCityWait = true;
        this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
            this.showCityWait = false;
            if (res.success && res.data.city != undefined) {
                this.selectedState = this.usaStates.find(
                    (s) => s.abbreviation == res.data.state
                );
                this.editform.patchValue({ city: res.data.city });
                this.refreshFormValues();
            } else {
                this.editform.patchValue({ city: 'Invalid zip code' });
            }
        });
        this.refreshFormValues();
    }

    refreshFormValues() {
        if (!this._signup.checkoutPageInfo.useBusinessAddressForShipment) {
            let obj = this._signup.checkoutPageInfo;
            obj.address = this.editform.get('address').value;
            obj.city = this.editform.get('city').value;
            obj.zip = this.editform.get('postalCode').value;
        }
    }

    useBusinessAddressForShipmentChanged(e = null) {
        this.applyShippingAddressFromBusinessInfo(e.checked);
    }

    applyShippingAddressFromBusinessInfo(what: boolean) {
        if (what) {
            this.isBillingAndShipmentSame= true;
            this._signup.checkoutPageInfo.address =
                this.bf.address.value;
            this.editform.patchValue({
                address: this._signup.checkoutPageInfo.address,
            });

            this._signup.checkoutPageInfo.zip = this.bf.postalCode.value;
            this.editform.patchValue({
                postalCode: this._signup.checkoutPageInfo.zip,
            });

            this._signup.checkoutPageInfo.city = this.bf.city.value;
            this.editform.patchValue({
                city: this._signup.checkoutPageInfo.city,
            });
            this.selectedState = this.selectedBState;
        } else {
            this.isBillingAndShipmentSame= false;
            this._signup.checkoutPageInfo.address = '';
            this.editform.patchValue({
                address: this._signup.checkoutPageInfo.address,
            });

            this._signup.checkoutPageInfo.zip = '';
            this.editform.patchValue({
                postalCode: this._signup.checkoutPageInfo.zip,
            });

            this._signup.checkoutPageInfo.city = '';
            this.editform.patchValue({
                city: this._signup.checkoutPageInfo.city,
            });
        }
        
    }

    locationQtyClicked(input: any) {
        try {
            if (input.value != null && parseInt(input.value) > 0) {
                if (this.totalLocation > parseInt(input.value)) {
                    this._signup.outerRegisterQty =
                        this._signup.outerRegisterQty - 1;
                    this._signup.checkoutPageCalcVars.registersQty =
                        this._signup.checkoutPageCalcVars.registersQty - 1;
                } else {
                    this._signup.outerRegisterQty =
                        this._signup.outerRegisterQty + 1;
                    this._signup.checkoutPageCalcVars.registersQty =
                        this._signup.checkoutPageCalcVars.registersQty + 1;
                }
                this._signup.outerLocationQty = input.value;
                this._signup.checkoutPageCalcVars.locationsQty = input.value;
                sessionStorage.setItem(
                    'locationsQty',
                    this._signup.checkoutPageCalcVars.locationsQty
                );
            }

            this.totalLocation = this._signup.outerLocationQty;
            setTimeout(() => {
                this.calcTotals();
            }, 500);
        } catch (error) {
            console.log(error.message);
        }
    }

    updateLocation(input: any) {
        try {
            if (input.value != null && parseInt(input.value) > 0) {
                this._signup.outerRegisterQty = input.value;
                this._signup.checkoutPageCalcVars.registersQty = input.value;

                sessionStorage.setItem(
                    'registersQty',
                    this._signup.checkoutPageCalcVars.registersQty
                );
                setTimeout(() => {
                    this.calcTotals();
                }, 500);
            }
            this.calcTotals();
        } catch (error) {}
    }

    ok2changeLocation() {
        try {
            const plan = this._signup.selectedPlan;
            if (plan.featureData[0].value === '1') return true;
            if (plan.featureData[0].value === '1+') return false;
            return false;
        } catch (error) {}
    }

    ok2changeRegisters() {
        try {
            const plan = this._signup.selectedPlan;
            if (plan.featureData[1].value === '1') return true;
            if (plan.featureData[1].value === '1+') return false;
            return false;
        } catch (error) {}
    }

    saveFn() {}

    removeProductMessage = 'Would you like to remove this item from the cart?';
    productRemovedSuccssfullyMessage = 'Product removed successfully.';

    removeHardware(item) {
        this._confirmationService.confirm({
            message: this.removeProductMessage,
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                try {
                    const obj = this._signup.selectedHardware;
                    for (let i = 0; i < obj.length; i++) {
                        if (item.id == obj[i].id) {
                            obj.splice(i, 1);
                            break;
                        }
                    }
                    sessionStorage.setItem(
                        'selectedHardware',
                        JSON.stringify(obj)
                    );
                    this._connMessageService.showToastMessage(
                        this.productRemovedSuccssfullyMessage,
                        'success'
                    );

                    this.calcHardwareNetPrice();
                    this.calcTotals();
                } catch {}
            },
            reject: () => {},
        });
    }

    updateHardwareQuantity() {
        try {
            const obj = this._signup.selectedHardware;
            this.calcHardwareNetPrice();
            this.calcTotals();
            sessionStorage.setItem('selectedHardware', JSON.stringify(obj));
        } catch (error) {}
    }

    removeWebsiteAndOnlineStorePlan() {
        this._confirmationService.confirm({
            message: this.removeProductMessage,
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                try {
                    this.isECommerceWebsiteAvailable = false;
                    this.isECommerceWebsiteSelected = false;
                    this.isStaticWebsiteAvailable = false;
                    this.isStaticWebsiteSelected = false;
                    this._signup.selectedWebsiteAndOnlineStorePlan = null;
                    this._signup.checkoutPageCalcVars.staticWebsite = 0;
                    this._signup.checkoutPageCalcVars.ecomCost = 0;
                    this._signup.checkoutPageCalcVars.customDomainCost = 0;
                    sessionStorage.removeItem('onlineECommerceWebsiteSelected');
                    sessionStorage.removeItem(
                        'selectedWebsiteAndOnlineStorePlan'
                    );
                    sessionStorage.removeItem('showCustomDomain');
                    this._signup.eComSubscriptionPlans.map(
                        (f) => (f.selected = false)
                    );
                    this.calcTotals();
                    this._connMessageService.showToastMessage(
                        this.productRemovedSuccssfullyMessage,
                        'success'
                    );
                } catch {}
            },
            reject: () => {},
        });
    }

    removeSelectedCustomerAppPlan() {
        this._confirmationService.confirm({
            message: this.removeProductMessage,
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                try {
                    this._signup.selectedCustomerAppPlan = null;
                    this._signup.checkoutPageCalcVars.customerAppPrice = 0;
                    this.calcTotals();
                    this._connMessageService.showToastMessage(
                        this.productRemovedSuccssfullyMessage,
                        'success'
                    );
                } catch {}
            },
            reject: () => {},
        });
    }

    changePlanClicked() {}

    dummy() {
        this.products = [
            {
                one: '',
                two: 'Location(s)',
                three: 'Register(s)',
                four: 'Price',
            },
            {
                one: '',
                two: 'Location(s)',
                three: 'Register(s)',
                four: 'Price',
            },
        ];
    }

    //-- calc processing
    calcTax(val: any) {
        try {
            if (val >= 0) {
                let taxrate = this.calcTaxValue();

                if (taxrate == undefined) {
                    taxrate = 0;
                }
                taxrate = taxrate /= 100;

                let ret = val * taxrate;
                this._signup.checkoutPageCalcVars.tax = ret;
                return ret;
            }
            return 0;
        } catch (error) {}
    }

    calcTotals() {
        try {
            let obj = this._signup.checkoutPageCalcVars;

            let total = 0;
            let tax = 0;
            total = this._signup.checkoutPageCalcVars.subscriptionPlanPrice;
            total += obj.additionalLocationsCost + obj.additionalRegistersCost;
            total += this._signup.checkoutPageCalcVars.hardwareCost;
            total += this._signup.checkoutPageCalcVars.ecomCost; //obj.ecomCost
            total += this._signup.checkoutPageCalcVars.customDomainCost;
            total += this._signup.checkoutPageCalcVars.loyaltyProgram;
            total += this._signup.checkoutPageCalcVars.staticWebsite;
            this.subTotal = isNaN(total) ? 0 : total;
            // total += obj?.customerAppPrice??0 ? obj.customerAppPrice : 0
            obj.subTotal = isNaN(total) ? 0 : total;
            this._signup.checkoutPageCalcVars.subTotal = obj.subTotal;
            // Testing
            this._signup.checkoutPageCalcVars.subTotal = obj.subTotal;
            let netTotal =  obj.subTotal
            if(this.couponCodeDiscountValue.value !== null)
            {
                if(this.couponCodeDiscountValue.type === 1){
                    // Percentage
                    let discountValue = obj.subTotal * this.couponCodeDiscountValue.value
                    let  disVal = discountValue.toString().split('.')
                    let first = disVal[0];
                    let second = disVal[1].substring(0, 2);
                     
                    let discountOnly = Number(first+'.'+second);
                  
                    discountValue =  discountOnly
                    if(discountValue <= this._signup.couponCodeData.maxDiscount || this._signup.couponCodeData.maxDiscount === 0){
                    this._signup.couponCodeDiscount = discountValue;
                   }else{
                    this._signup.couponCodeDiscount = this._signup.couponCodeData.maxDiscount;
                   }              
                }else if(this.couponCodeDiscountValue.type === 0){
                //    Flat
                this._signup.couponCodeDiscount = this.couponCodeDiscountValue.value
                }
                if(obj.subTotal < this._signup.couponCodeDiscount){
                    netTotal = 0;
                }else{
                    netTotal = obj.subTotal - this._signup.couponCodeDiscount;
                }
                 tax = this.calcTax(netTotal);
            }else{
                tax = this.calcTax(netTotal);
            }
            
            // Testing
            obj.gTotal = netTotal + tax;
           
           
            // tax = this.calcTax(this._signup.checkoutPageCalcVars.subTotal);
            // this.taxAmount = tax;
            // obj.gTotal =
            //     this._signup.checkoutPageCalcVars.subTotal +
            //     this._signup.checkoutPageCalcVars.tax;
            this.grandTotal = obj.gTotal;
            obj.gTotal = parseFloat(obj.gTotal);
        } catch (error) {}
    }

    calculateLoyaltyProgram() {
        let price = 0;

        if (this.isLoyaltyProgramAvailable) {
            if (this._signup.selectedPlanTimeframe == 'BilledMonthly') {
                price = this._signup.selectedPlan.featureData.filter(
                    (f) => f.typeString == 'LoyaltyProgram'
                )[0].price;
            } else {
                price =
                    this._signup.selectedPlan.featureData.filter(
                        (f) => f.typeString == 'LoyaltyProgram'
                    )[0].price * 12;
            }
        }
        this._signup.checkoutPageCalcVars.loyaltyProgram = price;
        this._signup.loyaltyProgramPrice = price;
        return price;
    }

    calcEcomCost() {
        let price = 0;
        if (this.isECommerceWebsiteAvailable) {
            if (this._signup.selectedPlanTimeframe == 'BilledMonthly') {
                price = this._signup.selectedPlan.featureData.filter(
                    (f) => f.typeString == 'BasiceCommerceStore'
                )[0].price;
            } else {
                price =
                    this._signup.selectedPlan.featureData.filter(
                        (f) => f.typeString == 'BasiceCommerceStore'
                    )[0].price * 12;
            }
            this._signup.checkoutPageCalcVars.ecomCost = price;
        }

        return price;
    }

    calcStaticCost() {
        let price = 0;

        if (this.isStaticWebsiteAvailable) {
            if (this._signup.selectedPlanTimeframe == 'BilledMonthly') {
                price = this._signup.selectedPlan.featureData.filter(
                    (f) => f.typeString == 'StaticWebsite'
                )[0].price;
            } else {
                price =
                    this._signup.selectedPlan.featureData.filter(
                        (f) => f.typeString == 'StaticWebsite'
                    )[0].price * 12;
            }
            this._signup.checkoutPageCalcVars.staticWebsite = price;
        }

        return price;
    }

    calcCustomDomainCost() {
        if (
            this._signup.selectedDomainPlan.selected &&
            this._signup.selectedDomainPlan.monthlyPrice > 0
        )
            if (this._signup.selectedPlanTimeframe == 'BilledMonthly') {
                this._signup.checkoutPageCalcVars.customDomainCost =
                    this._signup.selectedDomainPlan.monthlyPrice;
            } else {
                this._signup.checkoutPageCalcVars.customDomainCost =
                    this._signup.selectedDomainPlan.monthlyPrice * 12;
            }
        else this._signup.checkoutPageCalcVars.customDomainCost = 0;

        return this._signup.checkoutPageCalcVars.customDomainCost;
    }

    calcSingleHardwarePrice(item) {
        try {
            let ret = this._signup.calcSelectedHardwarePrice(item);
            this.calcTotals();
            return ret;
        } catch (error) {}
    }

    calcEcommercePrice() {
        try {
            let ret = 0;
            if (this._signup.selectedWebsiteAndOnlineStorePlan) {
                ret = this._signup.selectedWebsiteAndOnlineStorePlan.price;
            }
            this._signup.checkoutPageCalcVars.ecomCost = ret;
            this.calcEcomCost();
            this.calcStaticCost();
            this.calcCustomDomainCost();
            this.calcTotals();
            return ret;
        } catch (error) {}
    }

    calcCustomerAppPrice() {
        let ret = 0;
        if (this._signup.selectedCustomerAppPlan) {
            ret = this._signup.selectedCustomerAppPlan.price * 12;
        }
        this._signup.checkoutPageCalcVars.customerAppPrice = ret;
        this.calcTotals();
        return ret;
    }

    calcHardwareNetPrice() {
        try {
            let total = 0;
            let addonTotal = 0;
            this._signup.selectedHardware.map(function (value) {
                if (value.addons.length > 0) {
                    if (value.addons.filter((f) => f.includeAddon).length > 0) {
                        addonTotal += value.addons
                            .filter((f) => f.includeAddon)
                            .reduce((a, b) => a + b.singleAddonTotalPrice, 0);
                    }
                }
            });
            this._signup.checkoutPageCalcVars.hardwareCost =
                this._signup.selectedHardware.reduce(
                    (a, b) => a + b.netPrice,
                    0
                ) + addonTotal;
            total = this._signup.checkoutPageCalcVars.hardwareCost;
            return total;
        } catch (error) {}
    }

    calcPlanCost() {
        try {
            let price = 0;

            if (this._signup.selectedPlanTimeframe == 'BilledMonthly') {
                price = this._signup.selectedPlan?.prices[1]?.value;
                this._signup.checkoutPageCalcVars.subscriptionPlanPrice =
                    this._signup.selectedPlan?.prices[1]?.value;
            } else {
                price = this._signup.selectedPlan?.prices[0]?.value;
                this._signup.checkoutPageCalcVars.planCost = price;
                this._signup.checkoutPageCalcVars.subscriptionPlanPrice =
                    this._signup.selectedPlan?.prices[0]?.value;
            }

            return price;
        } catch (error) {}
    }

    getSetSubscriptionPlanPrice() {
        if (!this._signup.selectedPlan.prices) return;
        let x = this._signup.selectedPlanTimeframe == 'BilledMonthly' ? 1 : 0;
        let price = this._signup.selectedPlan?.prices[x]?.value;
        // this._signup.checkoutPageCalcVars.planCost = price
        let ret = `${this._globals.formatNumberWithCurrencySign(price)}`;
        ret = (x == 0 ? '12 x ' : '') + ret;
        return ret;
    }

    getSetAdditionalLocationsPrice() {
        try {
            const obj = this._signup.selectedPlan.featureData;
            if (!obj) return 'ERR';
            let qty = this._signup.checkoutPageCalcVars.locationsQty - 1;
            if (qty <= 0) {
                this._signup.checkoutPageCalcVars.additionalLocationsCost = 0;
                return '$0.00';
            }
            let rate = obj.find(
                (f) => f.typeString == 'AdditionalLocationPlus1Reg'
            ).price;
            let price = qty * rate;
            price *= this._signup.selectedPlanTimeframe.startsWith(
                'BilledMonthly'
            )
                ? 1
                : 12;

            this._signup.checkoutPageCalcVars.additionalLocationsCost = price;
            let ret = `${qty} x ${this._globals.formatNumberWithCurrencySign(
                rate
            )}`;
            ret =
                (this._signup.selectedPlanTimeframe.startsWith('BilledMonthly')
                    ? ''
                    : `12 x `) + ret;
            this.calcTotals();
            return ret;
        } catch (error) {
            console.log(error.message);
        }
    }

    getSetAdditionalRegistersPrice() {
        try {
            const obj = this._signup.selectedPlan.featureData;
            if (!obj) return 'ERR';
            let qty =
                this._signup.checkoutPageCalcVars.registersQty -
                this._signup.checkoutPageCalcVars.locationsQty;
            if (qty <= 0) {
                this._signup.checkoutPageCalcVars.additionalRegistersCost = 0;
                return '$0.00';
            }

            let rate = obj.find(
                (f) => f.typeString == 'AdditionalRegister'
            ).price;
            let price = qty * rate;
            price *= this._signup.selectedPlanTimeframe.startsWith(
                'BilledMonthly'
            )
                ? 1
                : 12;

            this._signup.checkoutPageCalcVars.additionalRegistersCost = price;
            this.additionalRegisterTax = price * (this.calcTaxValue() / 100);

            let ret = `${qty} x ${this._globals.formatNumberWithCurrencySign(
                rate
            )}`;
            ret =
                (this._signup.selectedPlanTimeframe.startsWith('BilledMonthly')
                    ? ''
                    : `12 x `) + ret;
            this.calcTotals();
            return ret;
        } catch (error) {
            console.log(error.message);
        }
    }

    showCustomDomainIncludedMessage(item) {
        let ret = false;
        let obj = item.pricePlan;

        for (let i = 0; i < obj.length; i++) {
            if (
                this._signup.selectedPlan.planId == obj[i].planID &&
                obj[i].monthlyPrice <= 0
            )
                return true;
        }

        return ret;
    }

    //--- for payment module - begin
    hardwareCostRaw = 0;
    hardwareTaxAmount = 0;
    hardwareCostIncTax = 0;
    planCostRaw = 0;
    planTaxAmount = 0;
    planCostIncTax = 0;
    totalHardwarePkgCost = 0;

    additionalLocationCostRaw = 0;
    additionalRegisterCostRaw = 0;
    additionalLocationTax = 0;
    additionalRegisterTax = 0;

    hardwareIds = [];

    // total results
    trs = {
        taxrate: this.calcTaxValue(),
        plancostRaw: 0,
        plancostTax: 0,
        plancostTotal: 0,

        hardwareCostRaw: 0,
        hardwareTaxAmount: 0,
        hardwareTotalCost: 0,

        wooRawCost: 0,
        wooTaxAmount: 0,
        wooTotal: 0,

        custRawCost: 0,
        custTaxAmount: 0,
        custTotal: 0,

        domainRawCost: 0,
        domainTaxAmount: 0,
        domainTotal: 0,

        additionalLocationCostRaw: 0,
        additionalLocationTax: 0,
        additionalLocationTotal: 0,

        additionalRegisterCostRaw: 0,
        additionalRegisterTax: 0,
        additionalRegisterTotal: 0,
    };
    
    couponCodeChecking(){
        let url = environment.apiUrl +'/auth-svc/api/'+ 'Coupon/CouponValidity?'
        // this.couponCodeInput.valueChanges.pipe(debounceTime(1500)).subscribe((value)=>{
        let value = this.couponCodeInput.value   
        if(value.length > 0){
                this.loading = true
                    let code = value.toUpperCase()
                    var encodedSpecialCharacter = encodeURIComponent(code);
                    this._http.get(url+'couponCode='+encodedSpecialCharacter+'&customertype='+4+'&subscriptionId='+ Number(this._signup.selectedPlan.id)).subscribe((res:any)=>{      
                        this.loading = false
                        if(res.success){
                    let  couponValue = res.data;
                    if(res.data)
                    this._signup.couponCodeData ={
                        discountValueType:couponValue.discountValueType,
                        id:couponValue.id,
                        couponCodes:couponValue.couponCodes,
                        discountName:couponValue.discountName,
                        discountValue:couponValue.discountValue,
                        maxDiscount:couponValue.maximumAllowed,
                    }

                 if(couponValue.discountValueType === 0){
                //  Flat
                 this.couponCodeDiscountValue = {value:couponValue.discountValue,type:couponValue.discountValueType};
             this.couponCode = couponValue.couponCodes
                 this.showCouponCode = true;
              this.discount = null;
            this.loading = false;
            this.couponCodeNotValid = false;
         }else if(couponValue.discountValueType === 1){
        //    Percentage
            this.discount = couponValue.discountValue;
            let discountvalue = couponValue.discountValue / 100
            this.couponCodeDiscountValue = {value:discountvalue,type:couponValue.discountValueType};
            this.couponCode = couponValue.couponCodes
            this.showCouponCode = true;
            this.couponCodeNotValid = false;
         }}
         this.calcTotals()
                },(error)=>{
                    // console.log(error)
                    this.loading = false
                    this.couponCodeDiscountValue = {value:null,type:''};
                    this.discount = null
                     // this.loading = false
                     this.couponCode = null
                     this.showCouponCode = false;
                     this.couponCodeNotValid = true;
                     this.calcTotals()
                     this._signup.couponCodeData = {}
                })

       }
          
        
        // })
    }
    onRemoveCouponCode(){
        this.showCouponCode = false;
        this.couponCodeInput.setValue('');
        this.couponCodeDiscountValue = {value:null,type:''}
        this.loading = false
        this.discount = null
        this._signup.couponCodeDiscount = 0
        this._signup.couponCodeData = {}
        this.calcTotals();
    }
       makeReturnObject() {
        let ser = this._signup;
      let couponObj ;
      if(this._signup.couponCodeData?.couponCodes !== undefined){
           couponObj = {
        discountValueType:this._signup.couponCodeData?.discountValueType,
        id:this._signup.couponCodeData?.id,
        couponCodes:this._signup.couponCodeData?.couponCodes ,
        discountName:this._signup.couponCodeData?.discountName ,
        discountValue:this._signup.couponCodeDiscount
      }
    } else{
        couponObj = {}
    }
        let obj = {
            businessId: ser.businessInfo.businessId,
            NoOfLocations: ser.checkoutPageCalcVars.locationsQty,
            NoOfRegisters: ser.checkoutPageCalcVars.registersQty,
            PerNoOfRegister: 1,
            userID: ser.personalInfo.userID,

            subscription: {
                TransactionDetails: this.getTransactionDetails(),
                subscriptionPlanPkg: this.getPlanCost(),
                hardwarePkg: this.getHardwarePkg(),
                WooCommercePlanPkg: this.getWooCommercePlanPkg(),
                CustomerAppPlanPkg: this.getCustomerAppPlanPkg(),
                DomainPlanPkg: this.getDomainPlanPkg(),
                SubscribedFeatures: this.subscribedFeatures(),

                hardwarePkgId: this.hardwareIds,

                totalHardwarePkgCost: this.totalHardwarePkgCost,
                planCost: this.planCostRaw,
                planId: ser.selectedPlan.id,

                subTotalCost: this.calcTotalCostExTax(),
                TotalTaxValue: this.calcTaxValue(),
                totalCost: this.calculateTotalCostIncTax(),
                TotalTaxAmount: this.calcTaxTotal(),
                TotalDiscountAmount:this._signup.couponCodeDiscount,
                TotalDiscountValue: 0,
                IsTotalTaxPercente: true,
                IsTotalDiscountPercent: false,
            },
            DiscountDet:couponObj,
            CouponCode:this._signup.couponCodeData?.couponCodes || null
        };
        ser.mainReturnObject = obj;
        this.saveBusinessInfo()
        this.makeCustomerBillTo()
        let x = this._signup.customerBillTo
        ser.currentStep = 10
    }

    makeCustomerBillTo() {
        let address = '';
        let city = '';
        let state = '';
        let zip = '';
        let obj = this._signup.checkoutPageInfo;
        this._signup.businessInfo.address = this.bf.address.value
        this._signup.businessInfo.zip = this.bf.postalCode.value;
        this._signup.businessInfo.city = this.bf.city.value;
        this._signup.businessInfo.state = this.selectedBState.abbreviation; 
      
        if (!obj.useBusinessAddressForShipment) {
            address = obj.address;
            city = obj.city;
            zip = obj.zip;
            state = this.selectedState.abbreviation;
        } else {
            obj = this._signup.businessInfo;
            address = obj.address;
            city = obj.city;
            zip = obj.zip;
            state = 'TX'; // this._signup.businessInfo.selectedState.abbreviation
        }

        this._signup.customerBillTo = {
            firstName: this._signup.personalInfo.firstName,
            lastName: this._signup.personalInfo.lastName,

            email: this._signup.businessInfo.email,
            company: this._signup.businessInfo.companyName,
            address: address,
            city: city,
            state: state,
            zip: zip,
            country: 'USA',
        };
        if(this._globals.whiteLabel?.isStripe === true){
        //   Stripe Payment
            window.location.href = '/#/setup/spayment_process';   
        }else{
            // Auth. .Net Payment
            window.location.href = '/#/setup/payment_process';   
        }
            
    }
    saveBusinessInfo() {
        this._signup.businessInfo.businessName = this._signup.businessInfo.companyName
        this._signup.businessInfo.address = this.bf?.address.value
        this._signup.businessInfo.zip = this.bf?.postalCode.value;
        this._signup.businessInfo.city = this.bf?.city.value;
        this._signup.businessInfo.state = this.selectedBState?.abbreviation;
        // this._signup.businessInfo.contactEmail = this._signup.businessInfo.email;  
        this._signup.saveBusinessInfoV2(this._signup.businessInfo).subscribe((res)=>{
         return res
        })
    }

    calcTaxValue() {
        try {
            let ret = 0.0;
            let ser = this._signup;

            ret = 0;
            this._signup.checkoutPageCalcVars.taxRate = 0;
            if (this.selectedBState.abbreviation == 'TX') {
                ret = 8.25;
                this._signup.checkoutPageCalcVars.taxRate = 8.25;
            }
            return ret == undefined || ret == null ? ret : ret;
        } catch (error) {}
    }

    calcTaxTotal() {
        try {
            let obj = this.trs;
            let total = obj.plancostTax;
            total += obj.hardwareTaxAmount;
            total += obj.wooTaxAmount;
            total += obj.custTaxAmount;
            total += obj.domainTaxAmount;

            total += obj.additionalLocationTax;
            total += obj.additionalRegisterTax;

            return total;
        } catch (error) {}
    }

    calcTotalCostExTax() {
        try {
            let obj = this.trs;
            let total = obj.plancostRaw;
            total += obj.hardwareCostRaw;
            total += obj.wooRawCost;
            total += obj.custRawCost;
            total += obj.domainRawCost;

            total += obj.additionalLocationCostRaw;
            total += obj.additionalRegisterCostRaw;

            return total;
        } catch (error) {}
    }

    calculateTotalCostIncTax() {
        let total = 0;
        total = this.calcTotalCostExTax() + this.calcTaxTotal();
        return total;
    }

    getHardwarePkgAddons(para) {
        let ret = [];
        let addons = para.addons;
        if (addons.length < 1) return addons;

        for (let i = 0; i < addons.length; i++) {
            let item = addons[i];
            if (item.includeAddon) continue;
            let obj = item;
            obj.qty = para.selectedQty;
            obj.totalPrice = obj.qty * item.prices.value;
            ret.push(obj);
        }
        return ret;
    }

    getHardwarePkg() {
        try {
            let ser = this._signup.selectedHardware;

            let hws = [];
            let taxrate = this.calcTaxValue();
            this.hardwareIds = [];
            this.totalHardwarePkgCost = 0;

            for (let i = 0; i < ser.length; i++) {
                let item = ser[i];
                let rawcost = item.selectedQty * item.netPrice;
                let taxamount = taxrate > 0 ? rawcost * (taxrate / 100) : 0;

                this.hardwareCostRaw = rawcost;
                this.totalHardwarePkgCost += rawcost;
                this.hardwareCostIncTax += rawcost + taxamount;
                this.hardwareTaxAmount = taxamount;

                this.hardwareIds.push(item.id);

                let obj = {
                    packageId: item.id,
                    qty: item.selectedQty,
                    pricePerPackage: item.netPrice,
                    subTotalCost: rawcost,
                    packageType: item.packageType,
                    subscriptionInvoiceAddons: this.getHardwarePkgAddons(item),
                    discountValue: 0,
                    taxValue: taxrate,
                    discountAmount: 0,
                    taxAmount: taxamount,
                    isDiscountPercent: false,
                    isTaxPercent: true,
                    totalPrice: rawcost + taxamount,
                };

                hws.push(obj);
            }

            this.trs.hardwareCostRaw = 0;
            this.trs.hardwareTaxAmount = 0;
            this.trs.hardwareTotalCost = 0;

            for (let i = 0; i < hws.length; i++) {
                this.trs.hardwareCostRaw += hws[i].subTotalCost;
                this.trs.hardwareTaxAmount += hws[i].taxAmount;
                this.trs.hardwareTotalCost += hws[i].totalPrice;
            }
            return hws;
        } catch (error) {}
    }

    getPlanCost() {
        let plan = this._signup.selectedPlan;

        let cost = 0;
        let price = 0;
        if (this._signup.selectedPlanTimeframe == 'BilledMonthly') {
            cost = this._signup.selectedPlan?.prices[1].value;
            price = this._signup.selectedPlan?.prices[1].value;
        } else {
            cost = 12 * this._signup.selectedPlan?.prices[0].value;
            price = this._signup.selectedPlan?.prices[0].value;
        }

        let taxrate = this.calcTaxValue();
        let taxamount = taxrate > 0 ? cost * (taxrate / 100) : 0;
        taxamount = taxamount;
        this.planCostIncTax = taxamount + cost;

        this.planTaxAmount = taxamount;
        this.planCostRaw = cost;

        this.trs.plancostRaw = cost;
        this.trs.plancostTax = taxamount;
        this.trs.plancostTotal = cost + taxamount;

        let subscriptionPlanPkg = [
            {
                packageId: plan.id,
                qty: 1,
                pricePerPackage: price,
                subTotalCost: cost,
                packageType: 0,
                discountValue: 0,
                taxValue: taxrate,
                discountAmount: 0,
                taxAmount: taxamount,
                isDiscountPercent: false,
                isTaxPercent: true,
                totalPrice: this.planCostIncTax,
            },
        ];

        return subscriptionPlanPkg;
    }

    getTransactionDetails() {
        let TransactionDetails = {
            accountType: 'Visa',
            accountNumber: 'XXXX4242',
            transId: '60202632036',
            responseCode: '1',
            authorization: 'DYH45J',
            merchantName: 'Connect-360',
            billTo: {
                phoneNumber: '',
                firstName: 'akhsd',
                lastName: 'cjbksvcer',
                address: 'main st',
                city: 'Huffman',
                state: 'TX',
                zip: '77336',
                country: 'US',
            },
            totalAmount: '3840.68',
            dateTime: '10/18/2022 9:12:53 AM',
        };

        return TransactionDetails;
    }

    calcAdditionalLocationsPrice() {
        try {
            const obj = this._signup.selectedPlan;
            // if (!obj) return -1
            // let qty = this._signup.checkoutPageCalcVars.locationsQty - 1
            // if (qty <= 0) {
            //     this._signup.checkoutPageCalcVars.additionalLocationsCost = 0
            //     return 0
            // }
            // let rate = obj.find(f => f.typeString == 'AdditionalLocationPlus1Reg').price
            // this.additionalLocationCostRaw = qty * rate
            // this.additionalLocationCostRaw *= this._signup.selectedPlanTimeframe.startsWith('BilledMonthly') ? 1 : 12
            this.additionalLocationTax =
                obj.locationTotal * (this.calcTaxValue() / 100);
        } catch (error) {}
    }

    calcAdditionalRegistersPrice() {
        try {
            const obj = this._signup.selectedPlan;
            // if (!obj) return 'ERR'
            // let qty = this._signup.checkoutPageCalcVars.registersQty - this._signup.checkoutPageCalcVars.locationsQty
            // if (qty <= 0) {
            //     this._signup.checkoutPageCalcVars.additionalRegistersCost = 0
            //     return '$0.00'
            // }
            // let rate = obj.find(f => f.typeString == 'AdditionalRegister').price
            //  this.additionalRegisterCostRaw = qty * rate
            // this.additionalRegisterCostRaw *= this._signup.selectedPlanTimeframe.startsWith('BilledMonthly') ? 1 : 12
            this.additionalRegisterTax =
                obj.registerTotal * (this.calcTaxValue() / 100);
        } catch (error) {}
    }

    subscribedFeatures() {
        this.calcAdditionalLocationsPrice();
        this.calcAdditionalRegistersPrice();

        let subscribedFeatures = [
            {
                type: 'AdditionalLocationPlus1Reg',
                qty: this._signup.checkoutPageCalcVars.locationsQty - 1,
                pricePerPackage: this._signup.selectedPlan.locationAmount,
                discountValue: 0,
                taxAmount: this.additionalLocationTax,
                isDiscountPercent: false,
                isTaxPercent: false,
                totalPrice:
                    this.additionalLocationTax +
                    this._signup.selectedPlan.locationTotal,
                subTotalCost: this._signup.selectedPlan.locationTotal,
            },
            {
                type: 'AdditionalRegister',
                qty:
                    this._signup.checkoutPageCalcVars.registersQty -
                    this._signup.checkoutPageCalcVars.locationsQty,
                pricePerPackage: this._signup.selectedPlan.registerAmount,
                discountValue: 0,
                taxAmount: this.additionalRegisterTax,
                isDiscountPercent: false,
                isTaxPercent: false,
                totalPrice:
                    this._signup.selectedPlan.registerTotal +
                    this.additionalRegisterTax,
                subTotalCost: this._signup.selectedPlan.registerTotal,
            },
        ];

        let obj = this.trs;
        obj.additionalLocationCostRaw = this._signup.selectedPlan.locationTotal;
        obj.additionalLocationTax = this.additionalLocationTax;
        obj.additionalLocationTotal =
            this._signup.selectedPlan.locationTotal +
            this.additionalLocationTax;

        obj.additionalRegisterCostRaw = this._signup.selectedPlan.registerTotal;
        obj.additionalRegisterTax = this.additionalRegisterTax;
        obj.additionalRegisterTotal =
            this._signup.selectedPlan.registerTotal +
            this.additionalRegisterTax;

        return subscribedFeatures;
    }

    calWooCommCost() {
        let ser = this._signup;
        if (!ser.selectedWebsiteAndOnlineStorePlan) return 0;
        let woo = ser.selectedWebsiteAndOnlineStorePlan.pricePlan;
        let plan = ser.selectedPlan;

        for (let i = 0; i < woo.length; i++) {
            if (plan.planId == woo[i].planID) return woo[i].monthlyPrice;
        }
    }

    getWooCommercePlanPkg() {
        let ser = this._signup;
        if (
            !ser.selectedWebsiteAndOnlineStorePlan ||
            !ser.selectedECommerceWebsitePlan
        )
            return [];

        let woo = ser.selectedWebsiteAndOnlineStorePlan;
        let rawprice = this.calWooCommCost();
        let tax = this.calcTaxValue();
        let taxamount = rawprice * 12 * (tax / 100);

        let obj = [
            {
                packageId: woo.id,
                qty: 1,
                pricePerPackage: rawprice,
                subTotalCost: rawprice * 12,
                packageType: 1,
                discountValue: 0,
                taxValue: tax,
                discountAmount: 0,
                taxAmount: taxamount,
                isDiscountPercent: false,
                isTaxPercent: true,
                totalPrice: taxamount + rawprice * 12,
            },
        ];

        this.trs.wooRawCost = rawprice * 12;
        this.trs.wooTaxAmount = taxamount;
        this.trs.wooTotal = taxamount + rawprice * 12;

        return obj;
    }

    calcCustomerAppPlanPkg() {
        let ser = this._signup;
        if (!ser.selectedCustomerAppPlan) return 0;

        let obj = ser.selectedCustomerAppPlan.pricePlan;
        let plan = ser.selectedPlan;

        for (let i = 0; i < obj.length; i++) {
            if (plan.planId == obj[i].planID) return obj[i].monthlyPrice;
        }
    }

    getCustomerAppPlanPkg() {
        let ser = this._signup;

        if (!ser.selectedCustomerAppPlan) return [];

        let obj = ser.selectedCustomerAppPlan;
        let rawprice = this.calcCustomerAppPlanPkg();
        let tax = this.calcTaxValue();
        let taxamount = rawprice * 12 * (tax / 100);

        let retobj = [
            {
                packageId: obj.id,
                qty: 1,
                pricePerPackage: rawprice,
                subTotalCost: rawprice * 12,
                packageType: 1,
                discountValue: 0,
                taxValue: tax,
                discountAmount: 0,
                taxAmount: taxamount,
                isDiscountPercent: false,
                isTaxPercent: true,
                totalPrice: taxamount + rawprice * 12,
            },
        ];

        this.trs.custRawCost = rawprice * 12;
        this.trs.custTaxAmount = taxamount;
        this.trs.custTotal = taxamount + rawprice * 12;

        return retobj;
    }

    getDomainPlanPkg() {
        const obj = this._signup.selectedDomainPlan;
        if (obj && obj.description.startsWith('Yes') && obj.monthlyPrice > 0) {
            let tax = this.calcTaxValue();
            let taxamount = obj.monthlyPrice * 12 * (tax / 100);
            const retobj = [
                {
                    packageId: obj.id,
                    qty: 1,
                    pricePerPackage: obj.monthlyPrice,
                    subTotalCost: obj.monthlyPrice * 12,
                    packageType: 0,
                    discountValue: 0,
                    taxValue: tax,
                    discountAmount: 0,
                    taxAmount: taxamount,
                    isDiscountPercent: false,
                    isTaxPercent: true,
                    totalPrice: obj.monthlyPrice * 12 + taxamount,
                },
            ];

            this.trs.domainRawCost = obj.monthlyPrice * 12;
            this.trs.domainTaxAmount = taxamount;
            this.trs.domainTotal = taxamount + obj.monthlyPrice * 12;

            return retobj;
        } else return [];
    }

    closeDialogSubscriptionPlan() {
        this._signup.planChanged = false;
        this.oldPlanSelected = this._signup.selectedPlan;
        this.oldLocation = this._signup.outerLocationQty;
        this.oldRegister = this._signup.outerRegisterQty;
        //  this.displaySubscriptionPlan = false
        this._signup.planChanged = true;
    }

    closeDialogEcomm() {
        sessionStorage.setItem(
            'showCustomDomain',
            this._signup.showCustomDomain ? '1' : '0'
        );
        if (this._signup.selectedWebsiteAndOnlineStorePlan != null) {
            if (
                this._signup.selectedWebsiteAndOnlineStorePlan.title ==
                'Static Website'
            ) {
                this._signup.checkoutPageCalcVars.ecomCost = 0;
                this._signup.eComSubscriptionPlans
                    .filter((f) => f.title != 'Static Website')
                    .map(function (value) {
                        value.domainPlan.map((f) => (f.selected = false));
                    });
            } else {
                this._signup.eComSubscriptionPlans
                    .filter((f) => f.title == 'Static Website')
                    .map(function (value) {
                        value.domainPlan.map((f) => (f.selected = false));
                    });
                this._signup.checkoutPageCalcVars.staticWebsite = 0;
            }

            if (this._signup.showCustomDomain) {
                if (this._signup.selectedPlanTimeframe == 'BilledMonthly') {
                    this._signup.checkoutPageCalcVars.customDomainCost =
                        this._signup.selectedWebsiteAndOnlineStorePlan.domainPlan[0].monthlyPrice;
                } else {
                    this._signup.checkoutPageCalcVars.customDomainCost =
                        this._signup.selectedWebsiteAndOnlineStorePlan
                            .domainPlan[0].monthlyPrice * 12;
                }
            } else {
                this._signup.checkoutPageCalcVars.customDomainCost = 0;
            }

            this.verifyWebsiteAvailable();
        }

        this.displayWebsiteAndOnlineStore = false;
        this.verifyWebsiteAvailable();
        this.calcTotals();
    }

    closeDialogHardware() {
        this.displayHardwareList = false;
    }

    closeDialogCustApp() {
        this.displayCustomerApp = false;
    }

    backClicked() {
        let selectedHaveHardwareId: any = sessionStorage.getItem(
            'selectedHaveHardwareId'
        );
        let selectedDoProcessOnlinePaymentsId: any = sessionStorage.getItem(
            'selectedDoProcessOnlinePaymentsId'
        );
        if (
            selectedHaveHardwareId != undefined &&
            selectedDoProcessOnlinePaymentsId != undefined
        ) {
            // if (selectedHaveHardwareId == 0 && selectedDoProcessOnlinePaymentsId == 1){
            //     this._signup.currentStep = 6
            //     this._signup.savedSteps = this._signup.currentStep
            // }
            // else{
            this._signup.currentStep--;
            this._signup.savedSteps = this._signup.currentStep;
            // }
            sessionStorage.setItem(
                'currentStep',
                this._signup.currentStep.toString()
            );
            sessionStorage.setItem(
                'savedSteps',
                this._signup.savedSteps.toString()
            );
        } else {
            this._signup.currentStep -= 2;
            this._signup.savedSteps = this._signup.currentStep;
            sessionStorage.setItem(
                'currentStep',
                this._signup.currentStep.toString()
            );
            sessionStorage.setItem(
                'savedSteps',
                this._signup.savedSteps.toString()
            );
        }
        this.backButtonClick.emit(true);
    }

    removeLoyaltyProgram() {
        this._confirmationService.confirm({
            target: event.target,
            message: 'Would you like to delete loyalty program?',
            icon: 'pi pi-exclamation-triangle',
            header: 'Confirmation',
            accept: () => {
                this.isLoyaltyProgramAvailable = false;
                this._signup.loyaltyProgramPrice = 0;
                this._signup.checkoutPageCalcVars.loyaltyProgram = 0;
                this._signup.loyaltyProgramAppBaseData.map(
                    (f) => (f.selected = false)
                );
                sessionStorage.removeItem('loyaltyProgramAppBaseData');
                this.calcTotals();
            },
            reject: () => {
                this.calcTotals();
                //reject action
            },
        });
    }

    removeCustomDomain() {
        this._confirmationService.confirm({
            target: event.target,
            message: 'Would you like to delete custom domain?',
            icon: 'pi pi-exclamation-triangle',
            header: 'Confirmation',
            accept: () => {
                this._signup.showCustomDomain = false;

                this._signup.checkoutPageCalcVars.customDomainCost = 0;
                sessionStorage.removeItem('showCustomDomain');
                this.calcTotals();
            },
            reject: () => {
                //reject action
                this.calcTotals();
            },
        });
    }
    changeWebsitePlan() {
        this._confirmationService.confirm({
            target: event.target,
            message: 'Would you like to change website plan?',
            icon: 'pi pi-exclamation-triangle',
            header: 'Confirmation',
            accept: () => {
                this.displayWebsiteAndOnlineStore = true;
                this.calcTotals();
            },
            reject: () => {
                //reject action
                this.calcTotals();
            },
        });
    }

    changeSubscriptionPlan() {
        this._confirmationService.confirm({
            target: event.target,
            message: 'Would you like to change subscription plan?',
            icon: 'pi pi-exclamation-triangle',
            header: 'Confirmation',
            accept: () => {
                this.onRemoveCouponCode()
                this._signup.outerLocationQty =
                    this._signup.checkoutPageCalcVars.locationsQty;
                this._signup.outerRegisterQty =
                    this._signup.checkoutPageCalcVars.registersQty;
                this.oldLocation =
                    this._signup.checkoutPageCalcVars.locationsQty;
                this.oldRegister =
                    this._signup.checkoutPageCalcVars.registersQty;
                this.oldPlanSelected = this._signup.selectedPlan;
                this._signup.planChanged = false;
                this.displaySubscriptionPlan = true;
                this.calcTotals();
            },
            reject: () => {
                //reject action
                this.calcTotals();
            },
        });
    }

    showPlanChangedMessage() {
        this._confirmationService.confirm({
            target: event.target,
            message:
                'You have changed subscription plan please confirm to save or discard changes.',
            icon: 'pi pi-exclamation-triangle',
            header: 'Confirmation',
            acceptLabel: 'Save Changes',
            rejectLabel: 'Discard Changes',
            accept: () => {
                this._signup.planChanged = true;
                this.closeSubscriptionModal(2);
                sessionStorage.setItem(
                    'selectedPlan',
                    JSON.stringify(this._signup.selectedPlan)
                );
                this.displaySubscriptionPlan = false;
                this.displaySubscriptionPlan = false;
                this._confirmationService.close();
                this.calcTotals();
            },
            reject: () => {
                this.displaySubscriptionPlan = false;
                this.oldPlanSelected = this._signup.returnSelectedPlanInf();
                this._signup.selectedPlan = this.oldPlanSelected;

                this._signup.planChanged = false;
                this._signup.outerLocationQty =
                    this._signup.returnOldLocation();
                this._signup.outerRegisterQty =
                    this._signup.returnOldRegister();

                this._signup.checkoutPageCalcVars.locationsQty =
                    this._signup.returnOldLocation();
                this._signup.checkoutPageCalcVars.registersQty =
                    this._signup.returnOldRegister();
                this._signup.selectedPlanTimeframe =
                    this._signup.returnPlanDuration();
                this._confirmationService.close();
                this.calcTotals();
            },
        });
    }

    removeAddon(selectedHardware: any, addonObj: any) {
        this._confirmationService.confirm({
            target: event.target,
            message: 'Would you like to delete addon?',
            icon: 'pi pi-exclamation-triangle',
            header: 'Confirmation',
            accept: () => {
                addonObj.includeAddon = false;
                addonObj.singleAddonTotalPrice = 0;
                addonObj.addonAdded = 'no';
                selectedHardware.includeAddon = false;

                selectedHardware.addonAdded = false;

                this.calcHardwareNetPrice();
                this.calcTotals();
                sessionStorage.setItem(
                    'selectedHardware',
                    JSON.stringify(this._signup.selectedHardware)
                );
            },
            reject: () => {
                //reject action
            },
        });
    }

    //--- for payment module - end

    closeSubscriptionModal(buttonType: number) {
        if (buttonType == 1 && this._signup.planChanged) {
            this.showPlanChangedMessage();
        }
        if (buttonType == 1 && !this._signup.planChanged) {
            this.displaySubscriptionPlan = false;
        }

        if (buttonType == 2 && this._signup.planChanged) {
            sessionStorage.setItem(
                'selectedPlan',
                JSON.stringify(this._signup.selectedPlan)
            );
            sessionStorage.setItem(
                'locationsQty',
                this._signup.outerLocationQty.toString()
            );
            sessionStorage.setItem(
                'registersQty',
                this._signup.outerRegisterQty.toString()
            );
            sessionStorage.setItem(
                'selectedPlanTimeframe',
                this._signup.selectedPlanTimeframe
            );
            //     this._signup.eComSubscriptionPlans.map(f => f.selected = false);
            //    this._signup.eComSubscriptionPlans.map(f => f.includedInPackage = false);
            //    this.isStaticWebsiteAvailable = false;
            //    this.isECommerceWebsiteAvailable = false;
            //    this.isECommerceWebsiteAvailable = false;
            //    this._signup.showCustomDomain = false;
            //    this._signup.checkoutPageCalcVars.customDomainCost = 0;
            //    this._signup.checkoutPageCalcVars.staticWebsite = 0;
            //    this._signup.checkoutPageCalcVars.ecomCost = 0;
            this.calcTotals();

            if (this._signup.selectedPlanTimeframe == 'BilledMonthly') {
                this._signup.checkoutPageCalcVars.planCost =
                    this._signup.selectedPlan?.prices[1]?.value;
                this._signup.checkoutPageCalcVars.subscriptionPlanPrice =
                    this._signup.selectedPlan?.prices[1]?.value;
            } else {
                this._signup.checkoutPageCalcVars.planCost =
                    this._signup.selectedPlan?.prices[0]?.value;
                this._signup.checkoutPageCalcVars.subscriptionPlanPrice =
                    this._signup.selectedPlan?.prices[0]?.value * 12;
            }

            let self = this;
            if (this._signup.selectedWebsiteAndOnlineStorePlan != null) {
                // already selected website

                this._signup.eComSubscriptionPlans.map(function (value) {
                    if (
                        value.pricePlan.filter(
                            (f) => f.planID == self._signup.selectedPlan.planId
                        ).length > 0
                    ) {
                        if (
                            self._signup.selectedPlanTimeframe ==
                            'BilledMonthly'
                        ) {
                            value.price = value.pricePlan.filter(
                                (f) =>
                                    f.planID == self._signup.selectedPlan.planId
                            )[0].monthlyPrice;
                        }
                        if (
                            self._signup.selectedPlanTimeframe !=
                            'BilledMonthly'
                        ) {
                            value.price = value.pricePlan.filter(
                                (f) =>
                                    f.planID == self._signup.selectedPlan.planId
                            )[0].monthlyPrice;
                        }

                        value.includedInPackage = false;
                        if (value.price == 0) {
                            value.includedInPackage = true;
                        }
                    }
                });
                this.calcTotals();
            }

            this._signup.loyaltyProgramPrice = 0;
            if (this.isLoyaltyProgramAvailable) {
                if (this._signup.selectedPlanTimeframe == 'BilledMonthly') {
                    this._signup.loyaltyProgramPrice =
                        this._signup.selectedPlan.featureData.filter(
                            (f) => f.typeString == 'LoyaltyProgram'
                        )[0].price;
                } else {
                    this._signup.loyaltyProgramPrice =
                        this._signup.selectedPlan.featureData.filter(
                            (f) => f.typeString == 'LoyaltyProgram'
                        )[0].price * 12;
                }
                this.calculateLoyaltyProgram();
            }
            // this.isLoyaltyProgramAvailable = false;
            this.displaySubscriptionPlan = false;
            this.includePlan();
            if (
                this._signup.eComSubscriptionPlans.filter(
                    (f) => f.includedInPackage
                ).length > 0
            ) {
                this.displayWebsiteAndOnlineStore = true;
            }

            if (this._signup.selectedWebsiteAndOnlineStorePlan != null) {
                if (
                    this._signup.selectedWebsiteAndOnlineStorePlan.title ==
                    'Static Website'
                ) {
                    this._signup.checkoutPageCalcVars.ecomCost = 0;
                } else {
                    this._signup.checkoutPageCalcVars.staticWebsite = 0;
                }
                this.verifyWebsiteAvailable();
            }
        }
        this.calcTotals();
    }

    closeWebsiteAndOnlineModal() {
        sessionStorage.setItem(
            'selectedWebsiteAndOnlineStorePlan',
            JSON.stringify(this._signup.selectedWebsiteAndOnlineStorePlan)
        );
        sessionStorage.setItem(
            'showCustomDomain',
            this._signup.showCustomDomain ? '1' : '0'
        );
        this.displayWebsiteAndOnlineStore = false;
        this.calcTotals();
    }

    saveAndCloseWebsiteAndOnlineModal() {
        sessionStorage.setItem(
            'selectedWebsiteAndOnlineStorePlan',
            JSON.stringify(this._signup.selectedWebsiteAndOnlineStorePlan)
        );
        if (this._signup.selectedWebsiteAndOnlineStorePlan == null) {
            this.isStaticWebsiteSelected = false;
            this.isECommerceWebsiteSelected = false;
            this._signup.checkoutPageCalcVars.staticWebsite = 0;
            this._signup.checkoutPageCalcVars.ecomCost = 0;
            this._signup.showCustomDomain = false;
            sessionStorage.removeItem('selectedWebsiteAndOnlineStorePlan');
            sessionStorage.setItem(
                'selectedPlan',
                JSON.stringify(this._signup.selectedPlan)
            );
        }

        sessionStorage.setItem(
            'showCustomDomain',
            this._signup.showCustomDomain ? '1' : '0'
        );
        if (this._signup.selectedWebsiteAndOnlineStorePlan != null) {
            if (
                this._signup.selectedWebsiteAndOnlineStorePlan.title ==
                'Static Website'
            ) {
                this._signup.checkoutPageCalcVars.ecomCost = 0;
            } else {
                this._signup.checkoutPageCalcVars.staticWebsite = 0;
            }
            this.verifyWebsiteAvailable();
        }

        this.displayWebsiteAndOnlineStore = false;
    }

    closeHardWareModal() {
        const obj = this._signup.selectedHardware;

        this.calcHardwareNetPrice();
        sessionStorage.setItem('selectedHardware', JSON.stringify(obj));
        this.displayHardwareList = false;
    }

    changeLoyaltyPlan() {
        this.showChangeLoyaltyProgram = true;
    }

    closeLoyaltyPointsModal() {
        if (!this._signup.loyaltyProgramAppBaseData[0].selected) {
            this._signup.loyaltyProgramAppBaseData.map(
                (f) => (f.selected = false)
            );
        }
        this.verifyLoyaltyPointsAvailable();
        this.showChangeLoyaltyProgram = false;
    }
}
