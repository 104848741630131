import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class PermissionCheckerService {
  constructor(private _ngxpermissionsService: NgxPermissionsService) {}
  async isPermissionValid(permission: string[],checkUrl?:string): Promise<boolean> {
    try {
      const permissionValue = await this.checkPermission(permission);
      // if(!permissionValue){
      //  let localURL = localStorage.getItem('goto')
      //  if(localURL.includes(checkUrl)){
      //   // localStorage.removeItem('goto');
      //  }
      // } 
      return permissionValue;
    } catch (error) {
      return false;
    }
  }

  async checkPermission(permission: string[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._ngxpermissionsService
        .hasPermission(permission)
        .then((value: any) => {
          resolve(value);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
