<div class="col-lg-8" *ngIf="!isLabelPrinting">
    <div class="image-input image-input-outline my-3"
        data-kt-image-input="true"
        style="background-position: center;
        background-size: cover;background-image: url({{dummyImageData}})">
        <div class="image-input-wrapper"
            style="background-position: center;
            background-size: cover;background-image: url({{imageData?.length>0?imageData:dummyImageData}})">
        </div>
        <label
            class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
            data-kt-image-input-action="change" data-bs-toggle="tooltip"
            title="Add/Change image">
            <i class="pi pi-pencil"  style="color:var(--bs-primary)"></i>
            <input type="file" name="avatar"
                accept=".png, .jpg, .jpeg, .jfif,.webP" (change)="handleFileInput($event)" [disabled]="_productService.isViewOnly"/>
            <input type="hidden" name="avatar_remove" [disabled]="_productService.isViewOnly"/>
        </label>
        <span
            class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" *ngIf="imageData?.length>0 && imageData!=dummyImageData && !_productService.isViewOnly"
            data-kt-image-input-action="remove" data-bs-toggle="tooltip"
            title="Remove image" (click)="removeImage()">
            <i class="pi pi-times" style="color:var(--bs-danger);"></i>
        </span>
    </div>
</div>

<div class="col-lg-8" *ngIf="isLabelPrinting">
    <div class="image-input image-input-outline my-3"
        data-kt-image-input="true"
        style="background-position: center;
        background-size: cover;background-image: url({{dummyImageData}})">
        <div class="image-input-wrapper"
            style="background-position: center;
            background-size: cover;background-image: url({{imageData?.length>0?imageData:dummyImageData}})">
        </div>
        <label
            class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
            data-kt-image-input-action="change" data-bs-toggle="tooltip"
            title="Add/Change image">
            <i class="pi pi-pencil"  style="color:var(--bs-primary)"></i>
            <input type="file" name="avatar"
                accept=".png, .jpg, .jpeg, .jfif,.webP" (change)="handleLabelFileInput($event)" [disabled]="_productService.isViewOnly"/>
            <input type="hidden" name="avatar_remove" [disabled]="_productService.isViewOnly"/>
        </label>
        <span
            class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" *ngIf="imageData?.length>0 && imageData!=dummyImageData && !_productService.isViewOnly"
            data-kt-image-input-action="remove" data-bs-toggle="tooltip"
            title="Remove image" (click)="removeLabelImage()">
            <i class="pi pi-times" style="color:var(--bs-danger);"></i>
        </span>
    </div>
</div>