import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { InventoryService } from 'src/app/_services/inventory.service';
import { ReportsService } from 'src/app/_services/reports.service';
export interface Daily_Money_Report {
  storeNumber: string;
  stationId: string;
  cashierId: string;
  cash: number;
  cashRefunds: number;
  change: number;
  check: number;
  checkRefunds: number;
  creditCard: number;
  creditCardRefunds: number;
  debitCard: number;
  debitRefunds: number;
  dialupCreditCard: number;
  dialupCCRefunds: number;
  giftCard: number;
  giftCardRefunds: number;
  totalInvoices: number;
  taxableSales: number;
  nonTaxableSales: number;
  totalNetSales: number;
  salesTax: number;
  returnInvoicesTax: number;
  avgTicket: number;
  totalTaxCollected: number;
  totalGrossSales: number;
  paidOuts: number;
  cashDrop: number;
  lastDrop: number;
  dropQty: number;
  zelleTotal: number;
  venomoTotal: number;
  paypalTotal: number;
  cashDiscount?:number
  zelleRefunds: number;
  venomoRefunds: number;
  paypalRefunds: number;

}




@Component({
  selector: 'app-daily-money-report',
  templateUrl: './daily-money-report.component.html',
  styleUrls: ['./daily-money-report.component.scss']
})
export class DailyMoneyReportComponent implements OnInit {

  _globals = GlobalService

  dataList: Daily_Money_Report[] = [];
  dataListDisplay: any[] = [];
  locationIds: string[] = [];
  constructor(
    private _inventoryService: InventoryService,
    private _bs: BaseService,
    private _reportService: ReportsService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Daily Money Report'

    if (!this._filterService.dateRange || this._filterService.dateRange.length < 1)
      this._filterService.initDates()

    this._filterService.selectedLocation = this._abd.locations;
    this.getDataList()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  getDataList() {

    this.getLocationsList();


    if (!this._abd.dateRange) {
      this._abd.initDates()
    }

    const data = {
      "ReportName": 'DailyMoneyReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds
      }
    }

    const url = this._abd.reports.filter(x => x.componentName == 'Daily Money Report')[0].reportURL;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      var obj1 = [
        {
          heading: 'System Money Total',
          data: [{
            name: 'Taxable Sales:',
            value: this.currencyPipe.transform(this.dataList.map(x => x.taxableSales).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          },
          {
            name: 'Sales-Tax Collected:',
            value: this.currencyPipe.transform(this.dataList.map(x => x.totalTaxCollected).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          },
          {
            name: 'Non Taxable Sales:',
            value: this.currencyPipe.transform(this.dataList.map(x => x.nonTaxableSales).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          },
          {
            name: 'Total Gross Sales:',
            value: this.currencyPipe.transform(this.dataList.map(x => x.totalGrossSales).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          }]
        },
        {
          heading: 'Sales Transactions',
          data: [
            {
              name: 'Cash:',
              value: this.currencyPipe.transform(this.dataList.map(x => x.cash).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
            },
            {
              name: 'Credit/Debit Cards:',
              value: this.currencyPipe.transform(this.dataList.map(x => x.creditCard + x.debitCard).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
            },
            {
              name: 'Venmo',
              value: this.currencyPipe.transform(this.dataList.map(x => x.venomoTotal).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
            },
            {
              name: 'Zelle',
              value: this.currencyPipe.transform(this.dataList.map(x => x.zelleTotal).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
            },
            {
              name: 'Paypal',
              value: this.currencyPipe.transform(this.dataList.map(x => x.paypalTotal).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
            },

            {
              name: 'Credit Cards (Dial-Up):',
              value: this.currencyPipe.transform(this.dataList.map(x => x.dialupCreditCard).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
            },
            {
              name: 'Gift Card Redeemed:',
              value: this.currencyPipe.transform(this.dataList.map(x => x.giftCard).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
            },
            {
              name: 'Total Sales TRX:',
              value: this.currencyPipe.transform(this.dataList.map(x => x.cash + x.giftCard + x.debitCard + x.creditCard + x.dialupCreditCard + x.zelleTotal + x.paypalTotal + x.venomoTotal).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
            }]
        },
        {
          heading: 'Sale TRX Adjustments',
          data: [{
            name: 'Cash Refunds:',
            value: this.currencyPipe.transform(this.dataList.map(x => x.cashRefunds).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          }, {
            name: 'Credit/Debit Card Refunds:',
            value: this.currencyPipe.transform(this.dataList.map(x => x.creditCardRefunds + x.debitRefunds + x.dialupCCRefunds).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          },
          {
            name: 'Venmo Refunds',
            value: this.currencyPipe.transform(this.dataList.map(x => x.venomoRefunds).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          },
          {
            name: 'Zelle Refunds',
            value: this.currencyPipe.transform(this.dataList.map(x => x.zelleRefunds).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          },
          {
            name: 'Paypal Refunds',
            value: this.currencyPipe.transform(this.dataList.map(x => x.paypalRefunds).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          }, 
          {
            name: 'Paid Out:',
            value: this.currencyPipe.transform(this.dataList.map(x => x.paidOuts).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          },
          {
            name: 'Cash Discount:',
            value: this.currencyPipe.transform(this.dataList.map(x => x.cashDiscount).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          },
           {
            name: 'Total Sales TRX Adjustments:',
            value: this.currencyPipe.transform(this.dataList.map(x => x.cashRefunds + x.creditCardRefunds + x.paidOuts + x.debitRefunds + x.zelleRefunds + x.paypalRefunds + x.venomoRefunds).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          }, {
            name: 'Total Adjusted Sales TRX:',
            value: this.currencyPipe.transform(this.dataList.map(x => (x.cash + x.giftCard + x.debitCard + x.creditCard + x.dialupCreditCard) - (x.cashRefunds + x.creditCardRefunds + x.paidOuts + x.debitRefunds + x.zelleRefunds + x.paypalRefunds + x.venomoRefunds)).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          }]
        },
        {
          heading: 'Cash Drop Details',
          data: [{
            name: 'Cash Drop:',
            value: this.currencyPipe.transform(this.dataList.map(x => x.cashDrop).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          }, {
            name: 'Last Drop:',
            value: this.currencyPipe.transform(this.dataList.map(x => x.lastDrop).reduce((a, b) => a + b, 0), 'USD', 'symbol-narrow', '1.2-2')
          }, {
            name: 'Drop Qty:',
            value: this.dataList.map(x => x.dropQty).reduce((a, b) => a + b, 0)
          }]
        }


      ]

      this.dataListDisplay = obj1;
      // //console.log(this.dataListDisplay)
      this._abd.hideSpinner();


    })
  }

  filterButtonPressed() {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

}
