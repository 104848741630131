import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterServiceService {
  searchText = ''
  searchTextLabel = ''
  productsearchText = ''
  OverViewproductsearchText = ''

  ProductSearchTextInput = ''
  selectedVendor: any = null
  selectedCategory: any = ''
  selectedLocation: any = ''
  selectedDepartment: any = ''
  selectedRegister: any = null
  selectedManufacturer: any = null
  selectedExtra1Location: any = null
  selectedInventoryAdjustment: any = null
  selectedProductSellTypeFilter: any = ''
  selectedGroup: any = ''
  selectedChannel: any = null
  selectedTranferType: any = null
  selectedTenderType: any = null
  selectedTranferStatus: any = null
  selectedTransactionType: any = null
  selectedInvoiceStatus: any = null
  selectedRegisters: any = null
  selectedStatus: any = null
  selectedCashier: any = null
  selectedInvoiceType: any = null
  selectedCouponCodeDiscountType ={
    id:''
  };
  selectedPromotionStatus:any = null
  selectedSaleChannel:any = null;
  selectedSubscriptionPlan:any = []
  startAmount:any = 0;
  endAmount:any = 0;
  singleDate: any = ''
  singleDateForReconcile: any = null
  dateRange = []
  // Audit Trial
  selectedActivityType:any = 'All';
  selectedOperationType:any ='All';
  selectedUser:any;
  
  productSellTypeFilter: any = ''
  selectedTimeZone:any = null
  filters: any = null
  ppages: any = null
  oorderby: any = null

  constructor() {
    this.loadInitFilters()
    this.loadInitPages()
    this.loadInitOrderby()
  }

  loadInitFilters() {
    let str = localStorage.getItem('ffilters')
    if (!str) {
      this.filters = null
      return null
    }
    this.filters = JSON.parse(str)
  }

  loadInitPages() {
    let str = localStorage.getItem('ppages')
    if (!str) {
      this.filters = null
      return null
    }
    this.ppages = JSON.parse(str)
  }

  loadInitOrderby() {
    let str = localStorage.getItem('oorderby')
    if (!str) {
      this.oorderby = null
      return null
    }
    this.oorderby = JSON.parse(str)
  }

  RestFiltersandPages() {
    localStorage.removeItem('ffilters');
    localStorage.removeItem('ppages');
    localStorage.removeItem('oorderby');

    this.searchText = ''
    this.searchTextLabel = ''
    this.productsearchText = ''
    this.OverViewproductsearchText = ''

    this.ProductSearchTextInput = ''
    this.selectedVendor = null
    this.selectedCategory = ''
    this.selectedLocation = ''
    this.selectedDepartment = ''
    this.selectedRegister = null
    this.selectedManufacturer = null
    this.selectedExtra1Location = null
    this.selectedInventoryAdjustment = null
    this.selectedProductSellTypeFilter = ''
    this.selectedGroup = ''
    this.selectedChannel = null
    this.selectedTranferType = null
    this.selectedTenderType = null
    this.selectedTranferStatus = null
    this.selectedTransactionType = null
    this.selectedInvoiceStatus = null
    this.selectedRegisters = null
    this.selectedStatus = null
    this.selectedCashier = null
    this.selectedInvoiceType = null
   this.selectedCouponCodeDiscountType = null
   this.startAmount = 0
   this.endAmount = 0
   this.selectedCouponCodeDiscountType = null
    this.singleDate = ''
    this.singleDateForReconcile = null
    this.dateRange = []

    this.productSellTypeFilter = ''

    this.loadInitFilters()
    this.loadInitPages()
    this.loadInitOrderby()

    this.initDates()
  }

  updateFilters(componentName, options) {
    if (!this.filters) {
      this.filters = []
    }

    let found = false
    for (let i = 0; i < this.filters.length; i++) {
      if (this.filters[i].componentName == componentName) {
        this.filters[i].options = options
        found = true
        break
      }
    }

    if (!found) {
      let obj = {
        componentName: componentName,
        options: options
      }
      this.filters.push(obj)
    }
    localStorage.setItem('ffilters', JSON.stringify(this.filters))
    this.loadInitFilters()
  }

  updatePages(componentName, options) {
    if (!this.ppages) {
      this.ppages = []
    }

    let found = false
    for (let i = 0; i < this.ppages.length; i++) {
      if (this.ppages[i].componentName == componentName) {
        this.ppages[i].options = options
        found = true
        break
      }
    }

    if (!found) {
      let obj = {
        componentName: componentName,
        options: options
      }
      this.ppages.push(obj)
    }
    localStorage.setItem('ppages', JSON.stringify(this.ppages))
    this.loadInitPages()
  }

  updateOrderby(componentName, options) {
    if (!this.oorderby) {
      this.oorderby = []
    }

    let found = false
    for (let i = 0; i < this.oorderby.length; i++) {
      if (this.oorderby[i].componentName == componentName) {
        this.oorderby[i].options = options
        found = true
        break
      }
    }

    if (!found) {
      let obj = {
        componentName: componentName,
        options: options
      }
      this.oorderby.push(obj)
    }
    localStorage.setItem('oorderby', JSON.stringify(this.oorderby))
    this.loadInitOrderby()
  }

  getFilters(componentName) {
    this.loadInitFilters()
    if (!this.filters) return null

    for (let i = 0; i < this.filters.length; i++) {
      if (this.filters[i].componentName == componentName)
        return this.filters[i].options
    }

    return null
  }

  getPages(componentName) {
    this.loadInitPages()
    if (!this.ppages) return null

    for (let i = 0; i < this.ppages.length; i++) {
      if (this.ppages[i].componentName == componentName)
        return this.ppages[i].options
    }

    return null
  }

  getOrderby(componentName) {
    this.loadInitOrderby()
    if (!this.oorderby) return null

    for (let i = 0; i < this.oorderby.length; i++) {
      if (this.oorderby[i].componentName == componentName)
        return this.oorderby[i].options
    }

    return null
  }

  initDates() {
    this.singleDate = new Date()
    this.dateRange = []
    this.dateRange.push(new Date(new Date().setDate(new Date().getDate() - 7)))
    this.dateRange.push(new Date())
  }

  fillDateArrIfInvalid() {
    try {
      if (!isNaN(this.dateRange[0].getTime()))
        this.dateRange[0] = new Date()
    } catch { this.dateRange[0] = new Date() }

    try {
      if (!isNaN(this.dateRange[1].getTime()))
        this.dateRange[1] = new Date()
    } catch { this.dateRange[1] = new Date() }
  }
}
