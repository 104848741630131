<h3
    style="
        margin-top: 0px;
        font-size: 1.7rem;
        color: var(--bs-active-primary);
        font-weight: 700px;
    "
>
    General Information
</h3>
<p-divider></p-divider>
<br />
<div class="nas-grid">
    <div
        style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px"
    >
        First Name
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _bizzCustomerService.activeItem?.firstName }}
    </div>
    <div
        style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px"
    >
        Last Name
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _bizzCustomerService.activeItem?.lastName }}
    </div>
</div>
<br />
<div class="nas-grid">
    <div
        style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px"
    >
        Business Name
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _bizzCustomerService.activeItem?.businessName }}
    </div>
    <div
        style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px"
    >
        Contact Number
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        ({{_bizzCustomerService.activeItem?.businessPhone | slice:0:3}}) {{_bizzCustomerService.activeItem?.businessPhone | slice:3:6}}-{{_bizzCustomerService.activeItem?.businessPhone | slice:6:10}}
    </div>
</div>
<br />
<div class="nas-grid">
    <!-- <div
        style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px"
    >
        White Label
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _bizzCustomerService.activeItem?.wlName }}
    </div> -->
    <div
        style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px"
    >
        Contact Email
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _bizzCustomerService.activeItem?.email }}
    </div>

<div
style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px"
>
No. of Locations
</div>
<div
class="col"
style="
    background-color: var(--bluegray-50);
    border-radius: 5px;
    padding: 10px;
"
>
{{ _bizzCustomerService.activeItem?.noOfLocations }}
</div>
</div>
<br />
<div class="nas-grid">
    <!-- <div
        style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px"
    >
        Account Status
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _bizzCustomerService.activeItem?.accountStatusString }}
    </div> -->
    <div
    style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px"
>
    Created Date
</div>
<div
    class="col"
    style="
        background-color: var(--bluegray-50);
        border-radius: 5px;
        padding: 10px;
    "
>
    {{ _bizzCustomerService.activeItem?.createdDate |  date:'fullDate'}}
</div>
<ng-container *ngIf="isChangeStatus">
    <div
    style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px" 
>
    Account Status
</div>
<div 
    class="col flex"
    style="
         border-radius: 5px;
        padding: 0px;
    "
>
<span class="col-8 p-0 m-0 flex">
  <p-dropdown
[formControl]="statusValue"
class="p-dropdown-1 w-full"
[options]="statusList"
optionLabel="viewValue"
optionValue="value"
[autoDisplayFirst]="false"
></p-dropdown>  
</span>
<span class="col-4 p-0 m-0 flex justify-content-end pl-2">
   <button
pButton
pRipple
(click)="saveStatus()"
label="Save"
[style]="{ width: '100%',padding:'8px'}"
></button> 
</span>


    <!-- {{ _bizzCustomerService.activeItem?.accountStatusString }} -->
</div>
</ng-container> 
</div>
<br />

<!-- <div class="nas-grid"> -->
    <!-- <div
        style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px"
    >
        Sub. Completed
    </div>
    <p-selectButton
        [options]="stateOptions"
        [(ngModel)]="_bizzCustomerService.activeItem.isSubscriptionCompleted"
        optionLabel="label"
        optionValue="value"
        [disabled]="true"
    ></p-selectButton> -->
    <!-- <div
        style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px"
    >
        Created Date
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _bizzCustomerService.activeItem?.createdDate |  date:'fullDate'}}
    </div> -->
<!-- </div> -->
<!-- <br /> -->
<br *ngIf="_bizzCustomerService.activeItem.isFreeTrial"/>
<!-- <div class="nas-grid">
    <div
        style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px"
    >
        Free Trial
    </div>
    <p-selectButton
        [options]="stateOptions"
        [(ngModel)]="_bizzCustomerService.activeItem.isFreeTrial"
        optionLabel="label"
        optionValue="value"
        [disabled]="true"
    ></p-selectButton>
</div> -->
<br />
<div class="nas-grid" *ngIf="_bizzCustomerService.activeItem.isFreeTrial">
    <div
        style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px"
    >
        Trial Start Date
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _bizzCustomerService.activeItem?.trialStartDate |  date:'fullDate'}}
    </div>
    <div
        style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px"
    >
        Trial End Date
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _bizzCustomerService.activeItem?.trialEndDate |  date:'fullDate'}}
    </div>
</div>


