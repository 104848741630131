
    <div>
        <div>
            <div>
                <!-- <div class="mb-2">
                
                    <label class="control-label"><strong>Location
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label>
                                <p-dropdown
                                [style]="{width:'100%',margin:'5px 0px'}"
                                [(ngModel)]="currentSelectedLocation"
                                (onChange)="locationChanged($event)"
                                class="p-dropdown-1"
                                [options]="_abd.locations"
                                optionLabel="locationName"
                                appendTo="body"
                            >
                            </p-dropdown></div> -->
         <br /> 
         <!-- <div *ngIf="tenderSettingList === null">
        <i class="pi pi-spin pi-spinner  flex justify-content-center align-items-center" style="color: var(--brandColor);font-size: x-large;" ></i>     
       </div> -->
                        
         
                <div *ngIf="tenderSettingList !== null">
                      <form
                    [formGroup]="tenderForm"
                    autocomplete="off"
                >
                
                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(tenderForm.controls)['enableTip']"
                        inputId="rememberme7"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme7">Enable Tip</label>
                </div>
                <br/>
                <div  *ngIf="f.enableTip?.value === true">
                <div class="field-checkbox mx-4 px-4">
                    <span  class="px-4">
                    <p-checkbox
                        [formControl]="$any(tenderForm.controls)['promptCustomerTip']"
                        inputId="rememberme8"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme8">Enable Prompt Customer For Tip</label>
                </span>
                </div>
            </div>

                <br *ngIf="f.enableTip?.value === true" />
                <div formArrayName="tenderTypes">
                    <div *ngFor="let tender of tenderForm.get('tenderTypes')['controls']; let i = index" [formGroupName]="i">
                        <div class="field-checkbox">
                            <p-checkbox
                            formControlName="status"
                            inputId="{{i+4}}"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                    <label for="{{i+4}}"> {{ tender.get('displayName').value }}</label>
                </div>
                <div class="flex flex-column mx-4 px-4"  *ngIf="tender.get('status').value === true">
                   <span [ngClass]="{'disabled-input': true }" class="isDisable px-4 pt-4 pb-1">
                    <div class="field-checkbox" *ngIf="tender.get('enableCashDisc').value">
                        <p-checkbox
                        formControlName="enableCashDisc"
                        inputId="{{i+2}}"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="{{i+2}}">Enable Cash Discount</label>
                    </div>  
                    <br *ngIf="tender.get('enableCashDisc').value === true" />  
                     <div class="field-checkbox" *ngIf="tender.get('enableCashDisc').value">
                    <label class="mx-2">Cash Discount(%) </label>    <input type="text" pInputText class="px-2" formControlName="cashDiscountPer" />
                    </div> 
                    <br *ngIf="tender.get('enableCashDisc').value === true" />   
                     <div class="field-checkbox">
                        <p-checkbox
                        formControlName="showRemarks"
                         inputId="{{i+3}}"
                         [binary]="true"
                         styleClass="mr-2"
                     ></p-checkbox>
                     <label for="{{i+3}}">Enable Remarks</label>
                    </div> 
                    <span *ngIf="tender.get('enableCashDisc').value" class="control-description text-muted">In Case you want to modify the Cash Discount, please contact SAASYPOS support.</span> 
                </span>
                </div>  <br />
            </div>
                </div>


                    <div
                        class="flex justify-content-end flex-wrap card-container green-container mt-3"
                    >
                        <div class="flex align-items-center justify-content-center">
                               <!-- <button
                                class="mx-2"
                                pButton
                                pRipple
                                type="button"
                                (click)="saveOnAllLocations()"
                                label="Apply to All Locations"
                                [disabled]="!tenderForm.valid"
                                ></button>  -->
                                 <button
                                pButton
                                pRipple
                               (click)="saveFn()"
                                label="Save"
                                [disabled]="!tenderForm.valid"
                                ></button>
                        </div>
                        <div
                            class="flex align-items-center justify-content-center ml-2"
                            *ngIf ="singupBusinessConfig == true"
                            >
                            <button
                                pButton
                                pRipple
                                type="button"
                                label="Cancel"
                                class="p-button-outlined p-button-danger"
                                (click)="cancelButtonClick()"
                            ></button>
                        </div>
                    </div>
                </form>   
                </div>
                  
             
            </div>
        </div>

    </div>