<div class="conn-container">
    <div class="items w-full">
        <div class="items-head">
            <p>
                Order Details
                <span style="font-size: 1rem">
                    <!-- #({{
                        _reportService.selectedInvoiceRow.invoiceNumber
                    }}) -->
                </span>
            </p>
        </div>

        <div class="items-body">
            <div class="items-body-content">
                <p-table
                    #dt
                    [columns]=""
                    [autoLayout]="true"
                    [value]="
                        _reportService.selectedInvoiceDetails.invoiceOrderDetail
                    "
                    responsiveLayout="scroll"
                    showLoader="true"
                    rowHover="true"
                    [customSort]="true"
                    [style]="{ 'min-width': '100%' }"
                >
                    <ng-template pTemplate="body" let-item>
                        <tr [ngStyle]="{ color: 'var(--gray-600)' }">
                            <td
                                style="font-weight: 700; color: var(--gray-400)"
                            >

                                {{ item.label }}
                            </td>
                            <td style="text-align: right" *ngIf="item.label=='Order Status'">                              
                              
                                <span>{{_reportService.selectedInvoiceDetails.postedStatusString}}</span>    
                            </td>
                            
                            <td style="text-align: right" *ngIf="item.label!='Order Status'">                              
                                <span >{{item.value}}</span>
                            </td>
                            
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
