import { map } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomerGroup } from 'src/app/_models/customer-group';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { GlobalService } from 'src/app/_services/global.service';
import { CustomerGroupComponent } from '../customer-group.component';
import { ProductsService } from 'src/app/_services/products.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
@Component({
  selector: 'app-group-customers',
  templateUrl: './group-customers.component.html',
  styleUrls: ['./group-customers.component.scss']
})
export class GroupCustomersComponent implements OnInit,OnDestroy {

    _globals = GlobalService
    @ViewChild('op') op: OverlayPanel
    page = 0
    rows = 10
    totalRecords = 0
    whatAction = ''
    editform: FormGroup
    @Output() loadFreshDataEvent = new EventEmitter();
    @Output() cancelCustomerGroup = new EventEmitter();
    @Input() isNew: boolean = true
    @Input() showNewNewGroupEdit: boolean = true

    @ViewChild(CustomerGroupComponent) __parent!: CustomerGroupComponent

    searchText = ''
    lastSearchText = ''
    dataList: any = null

    colheader = [[
        'ID', 'Last Transaction'
    ]];

    colflds = [
        'custID',
        'lastTransaction',
    ];

    csvcols = []
    exportColumns: any = ''

    actions: MenuItem[] = [
        // { label: 'Edit', icon: 'pi pi-book' },
        { label: 'Delete', icon: 'pi pi-trash' },
    ]
    @ViewChild('p', { static: false }) paginator: Paginator;
    @Input() groupInfo : any;
    selectedProducts4: any[];
    selectAll: boolean=false;
    selectedCustomerArray : any[]
    priceList:any[] = []
    loadingCustomer: boolean = false;
    locationIds: string[] = [];
    isLoading:boolean = true;
    showError : boolean = false;

    tempCustomerList : any[] = [];
    constructor(
        public _customerService: CustomersService,
        private _spinner: NgxSpinnerService,
        public _fb: FormBuilder,
        public _productService: ProductsService,
        private _connMessageService: ConnMessageService,
        private _confirmationService: ConfirmationService,
        public _abd: AppBaseDataService,
        private _router: Router,
        private _filterServices:FilterServiceService,
    ) { }

    ngOnInit(): void {
        this.getProductPrice()
        this._abd.selectedLocation = this._abd.locations;
        this.tempCustomerList = [];
        if(this._customerService.groupInfo != null){
            this.groupInfo = this._customerService.groupInfo;
            this.getAssignedCustomers()
        }
        else {
            this.loadingCustomer = true;
            this.selectAll = false;
            this.getDataList();
        }
    }
    getProductPrice() {
        this.isLoading = true;
        this._productService.getProductGeneralInformation().subscribe(res => {
            if (res.success) {
                let list = res.data.defaultPrice
               this.priceList = [{value:0,name:'Default'},...list]
            setTimeout(() => {
                if (this.isNew) {    
                    this.setPrice(this.priceList[0])
                }else{
                    this.setPrice(this.priceList.filter((v)=>v.value === this._customerService.groupInfo?.price)[0] || this.priceList[0])
                }
               this.isLoading = false;
            }, 500);
               
            }
            else{
                this.isLoading = false;
            }
        },

        )
    }
    setPrice(value: any) {
        this.editform.patchValue({price:value})
      }
    ngAfterContentInit() {
        this.initForm()
      }

      initForm() {
        let self = this;
        this.editform = this._fb.group({
          customerGroupName: [{value:this._customerService.groupInfo?.title, disabled: this._customerService.isViewOnly}, Validators.required],
          description: [{value:this._customerService.groupInfo?.description, disabled: this._customerService.isViewOnly}, Validators.required],
          price: [{value:'', disabled: this._customerService.isViewOnly}, Validators.required],
        })
      }

      initModel(): CustomerGroup {
        var model: CustomerGroup = {
          // id: null,
          // createdDate: new Date(S),
          title: null,
          isActive: true
        }
        return model
    }

    getAssignedCustomers() {
        this._abd.showSpinner();
        this.loadingCustomer = true;
        this._customerService.getCustomerListBelongsToGroupV2(this.groupInfo.id).subscribe(res => {
            if (res.success) {
                this.selectedCustomerArray = res.data;
              //  this.tempCustomerList = [];
                let self = this;
                this.selectedCustomerArray.map(function(value){
                    if (self.tempCustomerList.filter(f => f.id == value).length == 0) {
                        self.tempCustomerList.push({
                            id: value,
                            selected: true
                        })
                    }
                })

                this.getDataList();
            }
            else{
                this.selectedCustomerArray = [];
                this.getDataList();
            }
        }, error=>{
            this.selectedCustomerArray = [];
            this.getDataList();
            this._connMessageService.showToastMessage(error.message, 'error')
        })
    }

    getDataList() {
        this.loadingCustomer = true;
        this.getLocationsList();

        this._customerService.getAllCustomers(this.makeParams()).subscribe(res => {
            if (res.success) {
                this._abd.hideSpinner()
                this.dataList = [];
                this.dataList = res.data
                this.loadingCustomer = false;
                this.dataList.map(s => s.selected = false);
                this.totalRecords = res.totalRecords
                let self = this;


                if (this.tempCustomerList.length > 0){
                    this.dataList.map(function (value) {
                        if (self.tempCustomerList.filter(f => f.id == value.id).length > 0) {
                            value.selected = self.tempCustomerList.filter(f => f.id == value.id)[0].selected;
                        }
                    })
                }


                self.selectAll = false;
                if (this.dataList.filter(f => !f.selected).length == 0){
                    this.selectAll = true
                }

                this.loadingCustomer = false;
            }
            else{
                this._abd.hideSpinner()
                this.dataList = [];
                this.totalRecords = 0;
                this.loadingCustomer = false;
            }
        },
        error => {
            this.dataList = [];
            this._connMessageService.showToastMessage(error.message, 'error')
        }
        )
    }

    getLocationsList() {
        let self = this;
        this.locationIds = [];
        let selLocation = this._abd.selectedLocation;
        selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
        selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
            self.locationIds.push(value.locationId.toString())
        })
    }


    makeParams() {
        let params = new HttpParams();
        this.page = this.page < 1 ? 0 : this.page
        this.rows = this.rows < 1 ? 10 : this.rows

        if (this._abd.searchText != ''){
            this.page = 0;
        }

        params = params.append('pageNo', this.page.toString());
        params = params.append('pageSize', this.rows.toString());
        params = params.append('type', "all");
        if(!this.showNewNewGroupEdit){
            params = params.append('groupId', this.groupInfo.id);
        }
        params = params.append('LocationIds', this.locationIds.toString());
        //come back again
        if (this._filterServices.searchText.length) params = params.append('filterValue', this._filterServices.searchText);
        // params = params.append('filterValue', this._abd.searchText);
        return params
    }

    selectAllChanged(event) {
        this.dataList.map(f=>f.selected= event.checked)
        let self = this;
        if (this.tempCustomerList.length > 0) {
            this.dataList.map(function (value) {
                if (self.tempCustomerList.filter(f => f.id == value.id).length > 0) {
                    self.tempCustomerList.filter(f => f.id == value.id)[0].selected = event.checked
                }
                else{
                    if (event.checked){
                        if (self.tempCustomerList.filter(f => f.id == value.id).length == 0) {
                            self.tempCustomerList.push({
                                id: value.id,
                                selected: event.checked
                            })
                        }
                    }
                }
            })
        }

         // run-time create list if no list selected first time
        if (this.tempCustomerList.length == 0) {
            this.dataList.map(function (value) {
                if (self.tempCustomerList.filter(f => f.id == value.id).length == 0) {
                    self.tempCustomerList.push({
                        id: value.id,
                        selected: event.checked
                    })
                }
            })
        }

    }

    selectionChanged(event:any, item:any) {
        item.selected= event.checked;

        if (this.tempCustomerList.length > 0){
            if (this.tempCustomerList.filter(f => f.id == item.id).length > 0){
                this.tempCustomerList.filter(f => f.id == item.id)[0].selected = event.checked
            }
        }

        // run-time create list if no list selected first time
        if (this.tempCustomerList.length == 0) {
            if(item.selected){
                this.tempCustomerList.push({
                    id: item.id,
                    selected: event.checked
                })
            }
        }
        // add if customer not assigned before
        if (item.selected) {
            if (this.tempCustomerList.filter(f => f.id == item.id).length == 0) {
                this.tempCustomerList.push({
                    id: item.id,
                    selected: event.checked
                })
            }
        }



        if(this.dataList.filter(f=> !f.selected).length > 0){
            this.selectAll = false;
        }
        // if (this.tempCustomerList.filter(f => f.id == item.id).length > 0 && !item.selected){
        //     this.tempCustomerList.splice(this.tempCustomerList.findIndex(f => f.id == item.id),1)
        // }
    }

    saveGroupName() {
        this.showError = false;
        if (this.editform.controls.customerGroupName.status != "VALID"){
            this.showError = true;
            return false;
        }
        let params = new HttpParams();
        params = params.append('pageNo', 0);
        params = params.append('pageSize', 1500);

        let obj : any = {};
        obj.title = this.editform.controls.customerGroupName.value,
        obj.description = this.editform.controls.description.value,
        obj.price = this.editform.controls.price.value.value,
        obj.isActive = true;
        this._abd.showSpinner();
        // if (
        //     typeof this.groupInfo === 'object' &&
        //     this.groupInfo !== null && this.groupInfo !== undefined
        // ) {
        if (this.isNew) { 
             this._customerService.createCustomerGroup(obj).subscribe(res => {
                if (res.success) {
                  this.groupInfo = res.data;
                  this.assignCustomersToGroup();
                }
            })
        }else{
           obj.id = this.groupInfo.id,
            this._customerService.updateCustomerGroup(obj,this.groupInfo.id).subscribe(res => {
                if (res.success) {
                  this.assignCustomersToGroup();
                }
            })
        }
      }


    assignCustomersToGroup() {

        if (this.tempCustomerList.length > 0){
            let params = new HttpParams();
            params = params.append('pageSize', 1500);

            let temp = [];
            let self = this;
            this.tempCustomerList.map(function (value) {
                temp.push({
                    'Assigned': value.selected,
                    "CustomerGroupId": self.groupInfo.id,
                    "CustomerId": value.id

                })
            });

            this._customerService.assignCustomerGroup(JSON.stringify(temp)).subscribe(res => {
                if (res.success) {

                    this._connMessageService.showToastMessage(`Customer group has been saved successfully`, 'success')

                    this.loadFreshDataEvent.emit(true);

                    this._abd.hideSpinner()
                }
            })
        }
        else{
            this._connMessageService.showToastMessage(`Customer group has been saved successfully`, 'success')

            this.loadFreshDataEvent.emit(true);

            this._abd.hideSpinner()
        }
    }

    cancelCustomerGroupModal(){
        this.cancelCustomerGroup.emit(false)
    }

    callbackFuncFromFilter(event) {
        this._globals.showProductDelButton = false
        this.getDataList()
    }

    onPageChange(e) {
        e.totalRecords = this.totalRecords
        this.rows = e.rows
        this.page = e.page
        this.loadingCustomer = true;
        if (this._customerService.groupInfo != null) {
            this.getAssignedCustomers();
        }
        else{
            this.getDataList()
        }
    }
    ngOnDestroy(): void {
      this._filterServices.RestFiltersandPages()
    }
}
