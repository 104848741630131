    <p-table
        #dt
        [columns]=""
        styleClass="p-datatable-striped"
        id="table-001"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="true"
        dataKey="id"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Invoice No #</th>
                <th>Date/Time</th>
                <th>Total Items</th>
                <th>Discount</th>
                <th class="text-center">Total Price</th>
                <th class="text-center">Points Achieved</th>
                <th class="text-center">Points Redeemed</th>
                <th class="text-center">Invoice Status</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item" class="text-center">
                <td>{{ item.invoiceNo }}</td>
                <td>{{ item.invoiceDate }}</td>
                <td class="text-center">{{ item.invoiceCount | number }}</td>
                <td>{{ item.totalDiscount | currency }}</td>
                <td class="text-center">
                    {{ item.totalPrice | currency }}
                </td>
                <td class="text-center">{{ item.pointAchieved | number }}</td>
                <td class="text-center">{{ item.pointRedeemed | number }}</td>
                <td class="text-center">{{ item.invoiceStatus  }}</td>
             
                
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="14" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
    </p-table>

    <br />

