<p-confirmDialog
    [style]="{ width: '25vw' }"
    [baseZIndex]="10000"
></p-confirmDialog><!-- <p-confirmDialog #cd [style]="{width: '50vw'}" [closable]="false">    <ng-template pTemplate="header">        <h3>Confirmation</h3>    </ng-template>    <ng-template pTemplate="footer">        <button type="button" pButton icon="pi pi-times" class="p-button-outlined" label="No" (click)="cd.reject()"></button>        <button type="button" pButton icon="pi pi-check" class="p-button-danger p-button-outlined" label="Yes" (click)="cd.accept()"></button>    </ng-template></p-confirmDialog> --><ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="default"
    style="z-index: 999999999999999"
    color="var(--primary-color)"
    type="square-jelly-box"
    [fullScreen]="true"
>    <p style="color: white">Please wait while Loading...</p></ngx-spinner
><p-toast position="bottom-center"></p-toast><router-outlet></router-outlet>
<!-- <ng-container *ngIf="_userService.isCheckIn && !_abd.isAdmin">
<p-dialog [(visible)]="_userService.isCheckIn" [showHeader]="false" [modal]="true" 
[dismissableMask]="false" [focusTrap]="true" [draggable]="false" 
[style]="{width: '30%'}">
<app-time-attendance *ngIf="_userService.isCheckIn"></app-time-attendance>
</p-dialog>
</ng-container> -->
<p-dialog
    header="Inactivity Alert!"
    [closable]="false"
    [modal]="true"
    [(visible)]="displayUserInactivity"
    [style]="{ width: '25vw' }"
>    <div class="flex justify-content-center">        <div
            class="flex align-items-center justify-content-center text-orange-500 text-xl font-bold m-2"
        >            AUTO LOGOUT IN
        </div>    </div>    <div class="flex justify-content-center">        <div
            class="flex align-items-center justify-content-center text-6xl font-bold text-red-600 m-2"
        >            {{ userDialogTimer }}
        </div>    </div>    <div class="flex justify-content-center">        <div
            class="flex align-items-center justify-content-center text-red-400 m-2"
        >            seconds
        </div>    </div>    <p-divider></p-divider>    <button
        pButton
        type="button"
        label="Cancel"
        class="p-button-secondary"
        [style]="{ width: '100%' }"
        (click)="clearAutologoffHandle()"
    ></button></p-dialog>
    <!-- <div class="overlay" *ngIf="this._userService.isShowCheckIn" style=" 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.32);
    z-index: 1099; 
    "></div> -->