<div class="conn-container">
    <div class="items w-full">
        <!-- <div class="items-head">
            <p>Documents</p>
        </div> -->

        <div class="items-body">
            <div class="items-body-content">
                <p-table
                    #dt
                    [columns]=""
                    [autoLayout]="true"
                    [value]="
                        _reportService.selectedInvoiceDetails.invoiceDocuments
                    "
                    responsiveLayout="scroll"
                    showLoader="true"
                    rowHover="true"
                    [customSort]="true"
                    [style]="{ 'min-width': '100%' }"
                >
                    <ng-template pTemplate="body" let-item>
                        <tr [ngStyle]="{ color: 'var(--gray-600)' }">
                            <td
                                style="font-weight: 700; color: var(--gray-400)"
                            >
                                {{ item.label }}
                            </td>
                            <td style="text-align: right">
                                <span
                                    *ngIf="item.label === 'Receipt'"
                                    style="
                                        font-size: 0.75rem;
                                        font-weight: 700;
                                        color: var(--gray-400);
                                    "
                                    >
                                    <!-- {{ item.value }} -->
                                    </span
                                >
                                <span
                                    *ngIf="item.label !== 'Receipt'"
                                    style="
                                        font-weight: 700;
                                        color: var(--gray-400);
                                    "
                                    >{{ item.value }}</span
                                >
                                <ng-container
                                    *ngIf="
                                        _reportService.selectedInvoiceDetails
                                            .receiptRecords.length > 0
                                    "
                                >
                                    <button
                                        *ngIf="
                                            item.label === 'Receipt' &&
                                            !_reportService
                                                .selectedInvoiceDetails
                                                ?.isOnlineOrder
                                        "
                                        pButton
                                        pRipple
                                        type="button"
                                        icon="pi pi-print"
                                        class="p-button-rounded p-button-help p-button-text"
                                        [style]="{
                                            margin: '0px',
                                            'margin-top': '-32px',
                                            'padding-top': '32px'
                                        }"
                                        pTooltip="Print invoice"
                                        (click)="printButtonClicked()"
                                    ></button>
                                </ng-container>
                            </td>
                            <!-- <td >
                                
                            </td> -->
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="showPrintPreview">
    <p-dialog
        [header]="title"
        [(visible)]="showPrintPreview"
        [maximizable]="true"
        [modal]="true"
    >
        <app-invoice-print-preview></app-invoice-print-preview>
    </p-dialog>
</ng-container>
